import React from 'react';

import {
  UtilityDownloadPresentationIcon as PresentationIcon,
  UtilityExportToExcelIcon as ExcelIcon,
} from '@peakon/bedrock/icons/system';
import { TranslatedString, t } from '@peakon/shared/features/i18next/t';
import { exhaustiveMatchingGuard } from '@peakon/shared/utils/typescript/exhaustiveMatchingGuard';

import { SupportedExportType } from './Export';

type ExportContent = {
  icon: (props: React.SVGProps<SVGSVGElement>) => React.JSX.Element;
  inProgress: {
    title: TranslatedString;
    text: TranslatedString;
  };
  completed: {
    title: TranslatedString;
    text: TranslatedString;
    callToAction: TranslatedString;
  };
  error: {
    title: TranslatedString;
  };
};

export const getNotificationContent = (
  exportType: SupportedExportType,
): ExportContent => {
  switch (exportType) {
    case 'engagementPresentation':
      return {
        inProgress: {
          title: t('background_task__presentation__generating__title__v2', {
            replace: { groupName: t('question_group__engagement') },
          }),
          text: t('background_task__presentation__generating__text'),
        },
        completed: {
          title: t('background_task__presentation__ready__title__v2', {
            replace: { groupName: t('question_group__engagement') },
          }),
          text: t('background_task__presentation__ready__text'),
          callToAction: t('background_task__presentation__ready__download'),
        },
        error: {
          title: t('background_task__presentation__error__title'),
        },
        icon: PresentationIcon,
      } as const;
    case 'diversityAndInclusionPresentation':
      return {
        inProgress: {
          title: t('background_task__presentation__generating__title__v2', {
            replace: {
              groupName: t('question_group__diversity_inclusion'),
            },
          }),
          text: t('background_task__presentation__generating__text'),
        },
        completed: {
          title: t('background_task__presentation__ready__title__v2', {
            replace: {
              groupName: t('question_group__diversity_inclusion'),
            },
          }),
          text: t('background_task__presentation__ready__text'),
          callToAction: t('background_task__presentation__ready__download'),
        },
        error: {
          title: t('background_task__presentation__error__title'),
        },
        icon: PresentationIcon,
      } as const;
    case 'healthAndWellbeingPresentation':
      return {
        inProgress: {
          title: t('background_task__presentation__generating__title__v2', {
            replace: { groupName: t('question_group__health_wellbeing') },
          }),
          text: t('background_task__presentation__generating__text'),
        },
        completed: {
          title: t('background_task__presentation__ready__title__v2', {
            replace: { groupName: t('question_group__health_wellbeing') },
          }),
          text: t('background_task__presentation__ready__text'),
          callToAction: t('background_task__presentation__ready__download'),
        },
        error: {
          title: t('background_task__presentation__error__title'),
        },
        icon: PresentationIcon,
      } as const;
    case 'commentExport':
      return {
        inProgress: {
          title: t('background_task__comment-export__generating__title'),
          text: t('background_task__comment-export__generating__text'),
        },
        completed: {
          title: t('background_task__comment-export__ready__title'),
          text: t('background_task__comment-export__ready__text'),
          callToAction: t('background_task__comment-export__ready__download'),
        },
        error: {
          title: t('background_task__comment-export__error__title'),
        },
        icon: ExcelIcon,
      } as const;
    case 'topicExport':
      return {
        inProgress: {
          title: t('background_task__topic-export__generating__title'),
          text: t('background_task__topic-export__generating__text'),
        },
        completed: {
          title: t('background_task__topic-export__ready__title'),
          text: t('background_task__topic-export__ready__text'),
          callToAction: t('background_task__topic-export__ready__download'),
        },
        error: {
          title: t('background_task__topic-export__error__title'),
        },
        icon: ExcelIcon,
      } as const;
    case 'groupExport':
      return {
        inProgress: {
          title: t('background_task__group-export__generating__title'),
          text: t('background_task__group-export__generating__text'),
        },
        completed: {
          title: t('background_task__group-export__ready__title'),
          text: t('background_task__group-export__ready__text'),
          callToAction: t('background_task__group-export__ready__download'),
        },
        error: {
          title: t('background_task__group-export__error__title'),
        },
        icon: ExcelIcon,
      } as const;
    case 'segmentExport':
      return {
        inProgress: {
          title: t('background_task__segment-export__generating__title'),
          text: t('background_task__segment-export__generating__text'),
        },
        completed: {
          title: t('background_task__segment-export__ready__title'),
          text: t('background_task__segment-export__ready__text'),
          callToAction: t('background_task__segment-export__ready__download'),
        },
        error: {
          title: t('background_task__segment-export__error__title'),
        },
        icon: ExcelIcon,
      };
    case 'metricsExport':
      return {
        inProgress: {
          title: t('background_task__metrics-export__generating__title'),
          text: t('background_task__metrics-export__generating__text'),
        },
        completed: {
          title: t('background_task__metrics-export__ready__title'),
          text: t('background_task__metrics-export__ready__text'),
          callToAction: t('background_task__metrics-export__ready__download'),
        },
        error: {
          title: t('background_task__metrics-export__error__title'),
        },
        icon: ExcelIcon,
      } as const;
    case 'employeesExport':
      return {
        inProgress: {
          title: t('background_task__employee-export__generating__title'),
          text: t('background_task__employee-export__generating__text'),
        },
        completed: {
          title: t('background_task__employee-export__ready__title'),
          text: t('background_task__employee-export__ready__text'),
          callToAction: t('background_task__employee-export__ready__download'),
        },
        error: {
          title: t('background_task__employee-export__error__title'),
        },
        icon: ExcelIcon,
      } as const;
    case 'segmentManagersExport':
      return {
        inProgress: {
          //  TODO (PEAKONADX-989): replace with background_task__segment_managers_export__generating__title once translations are available
          title: t('background_task__employee-export__generating__title'),
          //  TODO (PEAKONADX-989): replace with background_task__segment_managers_export__generating__text once translations are available
          text: t('background_task__segment-export__generating__text'),
        },
        completed: {
          //  TODO (PEAKONADX-989): replace with background_task__segment_managers_export__ready__title once translations are available
          title: t('background_task__segment-export__ready__title'),
          //  TODO (PEAKONADX-989): replace with background_task__segment_managers_export__ready__text once translations are available
          text: t('background_task__segment-export__ready__text'),
          //  TODO (PEAKONADX-989): replace with background_task__segment_managers_export__ready__download once translations are available
          callToAction: t('background_task__segment-export__ready__download'),
        },
        error: {
          //  TODO (PEAKONADX-989): replace with background_task__segment_managers_export__error__title once translations are available
          title: t('background_task__segment-export__error__title'),
        },
        icon: ExcelIcon,
      } as const;
    case 'kioskCodeExport':
      return {
        inProgress: {
          title: t('background_task__kiosk-code-export__generating__title'),
          text: t('background_task__kiosk-code-export__generating__text'),
        },
        completed: {
          title: t('background_task__kiosk-code-export__ready__title'),
          text: t('background_task__kiosk-code-export__ready__text'),
          callToAction: t(
            'background_task__kiosk-code-export__ready__download',
          ),
        },
        error: {
          title: t('background_task__kiosk-code-export__error__title'),
        },
        icon: ExcelIcon,
      } as const;
    case 'benchmarkMappingTemplate':
      return {
        inProgress: {
          title: t('background_task__benchmark_mapping__generating__title'),
          text: t('background_task__benchmark_mapping__generating__text'),
        },
        completed: {
          title: t('background_task__benchmark_mapping__ready__title'),
          text: t('background_task__benchmark_mapping__ready__text'),
          callToAction: t(
            'background_task__benchmark_mapping__ready__download',
          ),
        },
        error: {
          //  TODO (PEAKONADX-989): replace with background_task__benchmark_mapping_export__error__title once translations are available
          title: t('background_task__benchmark_mapping__error__title'),
        },
        icon: ExcelIcon,
      } as const;
    default:
      return exhaustiveMatchingGuard(exportType);
  }
};
