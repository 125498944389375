import { List } from 'immutable';

let logBuffer = List();

export const getBuffer = () => logBuffer.toArray();

const logMiddleware =
  (maxActions = 20) =>
  () => {
    return (next: $TSFixMe) => (action: $TSFixMe) => {
      // @ts-expect-error TS(2740): Type 'Iterable<number, unknown>' is missing the fo... Remove this comment to see the full error message
      logBuffer = logBuffer.push(action.type).takeLast(maxActions);
      return next(action);
    };
  };

// eslint-disable-next-line import/no-default-export
export default logMiddleware;
