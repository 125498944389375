import React from 'react';

import { useParams } from 'react-router';

import { Paragraph } from '@peakon/bedrock/react/typography';
import { StackV } from '@peakon/bedrock/react-alpha/stack';
import { Card } from '@peakon/components';
import ErrorScreen from '@peakon/shared/components/ErrorScreen/ErrorScreen';
import { t } from '@peakon/shared/features/i18next/t';

import { TeamSuggestionCardCategory } from './components/TeamSuggestionCard/TeamSuggestionCardCategory';
import { TeamSuggestionCardForm } from './components/TeamSuggestionCard/TeamSuggestionCardForm';
import { TeamSuggestionCardHeader } from './components/TeamSuggestionCard/TeamSuggestionCardHeader';
import { ExpiredState } from './ExpiredState';
import { Layout } from './Layout';
import { LoadingPlaceholder } from './LoadingPlaceholder';
import { useSessionQuery } from './queries/useSessionQuery';
import { useStandaloneTeamSuggestionsQuery } from './queries/useTeamSuggestionsQuery';
import { CardMessage } from '../../components/dashboard/DriverOverview/TeamSuggestionsTab/PriorityCard/CardMessage';

export function StandaloneSuggestionPage() {
  const { token } = useParams<{ token: string }>();

  const { isLoading, isError, data, error } = useStandaloneTeamSuggestionsQuery(
    {
      token,
    },
  );

  const {
    isLoading: isLoadingSession,
    isError: isErrorSession,
    data: sessionData,
  } = useSessionQuery({
    enabled: !isLoading && !isError,
  });

  if (isError && error?.id === 'employee_dashboard_category_deleted') {
    return (
      <Layout
        companyLogo={sessionData?.company?.logo}
        companyName={sessionData?.company?.name}
      >
        <ExpiredState />
      </Layout>
    );
  }

  if (isError || isErrorSession) {
    return <ErrorScreen />;
  }

  if (isLoading || isLoadingSession) {
    return <LoadingPlaceholder />;
  }

  const {
    category,
    parentCategory,
    employee,
    priority,
    context,
    teamSuggestion,
  } = data;

  return (
    <Layout
      companyLogo={sessionData.company?.logo}
      companyName={sessionData.company?.name}
      showDisclaimer
    >
      <Card>
        <TeamSuggestionCardHeader segmentName={context?.attributes.name} />
        <StackV padding="16" spacing="16">
          <TeamSuggestionCardCategory
            group={category?.attributes.group}
            categoryName={category?.attributes.nameTranslated}
            categoryStandard={category?.attributes.standard}
            description={category?.attributes.descriptionTranslated}
            parentCategoryStandard={parentCategory?.attributes.standard}
          />
          {employee?.attributes.name && priority?.attributes.managerMessage && (
            <CardMessage
              name={employee?.attributes.name}
              avatar={employee?.attributes.avatar}
              message={priority?.attributes.managerMessage}
            />
          )}
          {teamSuggestion ? (
            <React.Fragment>
              <CardMessage isReply message={teamSuggestion?.attributes.text} />
              <Paragraph size="small" variant="hint">
                {t('team_suggestions__standalone__card__disclaimer')}
              </Paragraph>
            </React.Fragment>
          ) : (
            priority?.id && <TeamSuggestionCardForm priorityId={priority?.id} />
          )}
        </StackV>
      </Card>
    </Layout>
  );
}
