import get from 'lodash/get';
import { createSelector } from 'reselect';

import DEFAULT_FILTERS from '@peakon/records/defaultFilters';

import { getFilterQuery } from '../utils';

const filterSelector = (state: $TSFixMe) => state.filters;
const filterOptions = (state: $TSFixMe) => state.filterOptions;
const filterColumnsSelector = (state: $TSFixMe) => state.filterColumns;

const accountSelector = (state: $TSFixMe) => state.account;
const employeesSelector = (state: $TSFixMe) => state.employees;
const paginationSelector = (state: $TSFixMe) => state.employeePagination.items;
const currentEmployeeSelector = (state: $TSFixMe) =>
  state.session && state.session.employeeId;

export const currentEmployee = createSelector(
  [employeesSelector, currentEmployeeSelector],
  (employees, id) => employees.get(id),
);

export const isOtherEmployee = createSelector(
  [currentEmployee, accountSelector],
  (employee, account) => employee && employee.accountId !== get(account, 'id'),
);

export const employeeHits = createSelector(
  [employeesSelector, paginationSelector],
  // @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
  (employees, items) => items.map((id) => employees.get(id)),
);

export const getEmployeeQuery = createSelector(
  [filterSelector, filterOptions, filterColumnsSelector],
  (filters, options, columns) => {
    const fields = filters.filter(
      (filter: $TSFixMe) =>
        columns.includes(filter.id) ||
        DEFAULT_FILTERS.map((f) => f.id).includes(filter.id),
    );

    return getFilterQuery({
      filters,
      fields,
      sort: options.getSortBy(),
      order: options.direction,
      query: options.query,
    });
  },
);
