import React from 'react';

import { Combobox } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

const PERCENTILES = [95, 90, 75, 50, 25, 10, 5] as const;

export type Percentile = (typeof PERCENTILES)[number];

const isPercentile = (value: number): value is Percentile => {
  return PERCENTILES.includes(value);
};

type PercentileSelectorProps = {
  onChange: ({ value }: { value: Percentile }) => void;
  value?: Percentile;
};

export const PercentileSelector = ({
  onChange,
  value,
}: PercentileSelectorProps) => {
  const options = PERCENTILES.map((percentile) => {
    const percentage = percentile > 50 ? 100 - percentile : percentile;
    return {
      value: percentile,
      label:
        percentile === 50
          ? t('dashboard_settings__engagement_score__average', {
              replace: { percentage },
            })
          : percentile < 50
            ? t('dashboard__benchmark__bottom', { replace: { percentage } })
            : t('dashboard__benchmark__top', { replace: { percentage } }),
    };
  });

  const selectedValue =
    options.find((option) => option.value === value) || options[3];

  return (
    <Combobox
      key="percentile-selector"
      isClearable={false}
      selectedItem={selectedValue.label}
      onSelect={(item) => {
        const numberItem = Number(item);
        if (isPercentile(numberItem)) {
          onChange({ value: numberItem });
        }
      }}
    >
      <Combobox.Select hasClear={false}>{selectedValue.label}</Combobox.Select>
      <Combobox.Menu fitTargetWidth>
        {options.map((option) => (
          <Combobox.Option key={option.value} value={option.value}>
            {option.label}
          </Combobox.Option>
        ))}
      </Combobox.Menu>
    </Combobox>
  );
};
