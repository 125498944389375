import React, { Fragment } from 'react';

import { Spinner } from '@peakon/bedrock/react/spinner';
import { Toggle, Typography } from '@peakon/components';
import { Badge } from '@peakon/shared/components/Badge/Badge';
import { t } from '@peakon/shared/features/i18next/t';

import { useGetAttributesQuery } from './queries/useGetAttributesQuery';
import { useUpdateAttributeMutation } from './queries/useUpdateAttributeMutation';

import styles from './styles.css';

export const TrueBenchmark = () => {
  const { data: sortedAttributes, isLoading } = useGetAttributesQuery();
  const { mutate: updateAttribute } = useUpdateAttributeMutation();

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Typography.H4>
        {t('true_benchmarks_settings__attributes_title')}
      </Typography.H4>

      <Typography.P>
        {t('true_benchmarks_settings__attributes_info')}
      </Typography.P>

      {sortedAttributes && sortedAttributes.length ? (
        <div className={styles.list}>
          {sortedAttributes.map(
            ({ id, attributes: { nameTranslated, trueBenchmark } }) => (
              <Toggle
                key={id}
                checked={trueBenchmark}
                name="attribute-benchmark"
                id={`attribute-benchmark-${id}`}
                testId={`attribute-benchmark-${nameTranslated}`}
                onChange={(isActive) => updateAttribute({ id, isActive })}
              >
                <Badge variant="primary" label={nameTranslated} />
              </Toggle>
            ),
          )}
        </div>
      ) : (
        <Fragment>
          <Typography.P>
            {t('true_benchmarks_settings__no_attributes_v2', {
              replace: { tb_name: 'True Benchmark\xAE' },
            })}
          </Typography.P>
          <Typography.P>
            {t('true_benchmarks_settings__contact_cs_v2')}
          </Typography.P>
        </Fragment>
      )}
    </div>
  );
};
