import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { SegmentManagerResponse } from '@peakon/shared/schemas/api/segments';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Employee from './EmployeeRecord';
import Segment from './SegmentRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class SegmentManager
  extends Record({
    id: undefined,
    automatic: undefined,
    level: undefined,
    segment: undefined,
    employee: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  automatic?: boolean;
  level?: number;
  segment?: Segment;
  employee?: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'SegmentManager',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'SegmentManager',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: SegmentManagerResponse) {
    const {
      id,
      attributes,
      relationships: { employee, segment },
    } = data;

    return new SegmentManager(
      fromJS({
        id,
        ...attributes,
        employee: Employee.createFromApi(employee),
        segment:
          segment && segment.attributes
            ? Segment.createFromApi(segment)
            : undefined,
      }),
    );
  }
}
