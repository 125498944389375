import React, { Component } from 'react';

import { errorReporter } from '../../utils';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode | ((error: unknown) => React.ReactNode);
};

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state = {
    error: null,
  };

  componentDidCatch(error: $TSFixMe) {
    errorReporter.error(error);

    this.setState({
      error,
    });
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      if (fallback) {
        return typeof fallback === 'function' ? fallback(error) : fallback;
      }

      return null;
    }

    return children;
  }
}

// eslint-disable-next-line import/no-default-export
export default ErrorBoundary;
