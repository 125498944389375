import React from 'react';

import { EmptyStateNoData } from '@peakon/bedrock/illustrations/react/empty-state';
import { Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import { DEFAULT_EMPHASIS_KEY } from '@peakon/shared/utils/emphasizeTranslationString';

import styles from './styles.css';

export const EmptyState = () => {
  return (
    <div className={styles.emptyState}>
      <EmptyStateNoData />
      <Typography.H3
        className={styles.emptyStateTitle}
        size="lg"
        weight="bolder"
      >
        {t('values__empty__title')}
      </Typography.H3>
      <Typography.P className={styles.emptyStateText}>
        {t('values__empty__text')}
      </Typography.P>

      <div className={styles.emptyStateLink}>
        <MoreText />
      </div>
    </div>
  );
};

function MoreText() {
  const delimiter = DEFAULT_EMPHASIS_KEY;

  const translatedText = t('values__empty__more', {
    replace: { link_start: delimiter, link_end: delimiter },
  });
  const [prefix, linkText, affix] = translatedText.split(delimiter);
  return (
    <React.Fragment>
      {prefix}
      <a
        href="https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/surveys/survey-questions/evx1656931291926.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkText}
      </a>
      {affix}
    </React.Fragment>
  );
}
