import { getCurrentLocaleInfo } from '@peakon/shared/features/i18next/helpers';
import { t } from '@peakon/shared/features/i18next/t';
import { exhaustiveMatchingGuard } from '@peakon/shared/utils/typescript/exhaustiveMatchingGuard';

import {
  SensitiveConcept,
  SensitiveConceptType,
} from './queries/useSensitiveConceptsQuery';

type SensitiveKeyword = {
  attributes: { keyword: string; locale: string };
};

const getPreferredLocale = (
  currentLocale: $TSFixMe,
  keywords: SensitiveKeyword[],
) => {
  const [baseLocale] = currentLocale.id.split('-'); // sensitive keywords use locale tags that match elasticsearch
  const [primaryKeywordLocale] = keywords.map(
    (keyword) => keyword.attributes.locale,
  );

  let preferredLocale = null;
  if (primaryKeywordLocale === baseLocale) {
    preferredLocale = baseLocale;
  }

  return preferredLocale;
};

const getTranslatedType = (type: SensitiveConceptType) => {
  switch (type) {
    case 'danger': {
      return t('engagement__settings__sensitive_comments_concept_type_danger');
    }
    case 'health': {
      return t('engagement__settings__sensitive_comments_concept_type_health');
    }
    case 'discrimination': {
      return t(
        'engagement__settings__sensitive_comments_concept_type_discrimination',
      );
    }
    case 'ethics': {
      return t('engagement__settings__sensitive_comments_concept_type_ethics');
    }
    case null: {
      return t('engagement__settings__sensitive_comments_concept_type_custom');
    }
    default:
      return exhaustiveMatchingGuard(type);
  }
};

export const mapSensitiveConcepts = (
  sensitiveConcepts: SensitiveConcept[],
): $TSFixMeFunction => {
  // @ts-expect-error TS(7034): Variable 'mappedGroups' implicitly has type 'any[]... Remove this comment to see the full error message
  const mappedGroups = [];

  sensitiveConcepts.forEach((sensitiveConcept) => {
    const {
      id,
      attributes: { type, name, standard, status, notifications },
      relationships: { keywords },
    } = sensitiveConcept;

    const preferredLocale = getPreferredLocale(
      getCurrentLocaleInfo(),
      keywords,
    );

    const sensitiveKeywords = keywords.map((sensitiveKeyword) => {
      const {
        id: sensitiveKeywordId,
        attributes: { locale: keywordLocale, keyword },
      } = sensitiveKeyword;

      return {
        id: sensitiveKeywordId,
        locale: keywordLocale,
        keyword,
        primaryLocale: keywordLocale === preferredLocale,
      };
    });

    const concept = {
      id,
      name,
      standard,
      status,
      enabled: status === 'active',
      type,
      keywords: sensitiveKeywords,
      notifications,
      preferredLocale,
    };

    const translatedType = getTranslatedType(type);

    // @ts-expect-error TS(7005): Variable 'mappedGroups' implicitly has an 'any[]' ... Remove this comment to see the full error message
    const index = mappedGroups.findIndex((sg) => sg.type === type);

    if (index > -1) {
      // @ts-expect-error TS(7005): Variable 'mappedGroups' implicitly has an 'any[]' ... Remove this comment to see the full error message
      const { concepts } = mappedGroups[index];
      concepts.push(concept);
    } else {
      mappedGroups.push({ type, translatedType, concepts: [concept] });
    }
  });

  // @ts-expect-error TS(2322): Type 'any[]' is not assignable to type '$TSFixMeFu... Remove this comment to see the full error message
  return mappedGroups;
};
