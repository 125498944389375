import React from 'react';

import { Redirect } from 'react-router-dom';

import {
  productFallBackOrder,
  useNavigation,
} from '@peakon/shared/components/Navigation/hooks/useNavigation';
import { assignWindowLocation } from '@peakon/shared/utils/window';

import { useGetOpenSurveys } from '../../containers/Schedules/transfers/queries/useGetOpenSurveys';

/**
 *
 * @returns The best available route for any particular user. The best available route is determined based on the route the user tried to access and didn't have permissions to view by the following criteria (in order of prevalence):
 * - the first route that the user has access to in the same menu group
 * - the first route that the user has access to in the same product
 * - the first route that the user has access to following the products "fallback" order (ranked from the most important product to least important product)
 */
const getBestAvailableRoute = ({
  navigation,
  activeProductName,
  activeMenuGroupName,
}: {
  navigation: $TSFixMe;
  activeProductName: string;
  activeMenuGroupName: string;
}) => {
  const activeProduct = navigation[activeProductName];

  if (activeProduct) {
    const activeMenuGroup = activeProduct.children.find(
      (menuGroup: $TSFixMe) => menuGroup.name === activeMenuGroupName,
    );

    if (activeMenuGroup) {
      return activeMenuGroup.children[0].getPath();
    }

    const firstAvailableMenuGroup = activeProduct.children[0];
    const firstAvailableMenuPoint = firstAvailableMenuGroup.children[0];
    return firstAvailableMenuPoint.getPath();
  }

  for (const fallbackProductName of productFallBackOrder) {
    if (navigation[fallbackProductName]) {
      const fallbackMenuGroup = navigation[fallbackProductName].children[0];
      const fallbackMenuPoint = fallbackMenuGroup.children[0];

      return fallbackMenuPoint.getPath();
    }
  }
};

const RedirectToAvailableRoute = () => {
  const {
    isLoading: isLoadingOpenSurveys,
    isOpenSurveyQueryExecutable,
    data: openSurveys,
  } = useGetOpenSurveys();

  const {
    navigation,
    activeProductName,
    activeMenuGroupName,
    isLoading: isLoadingNavigation,
  } = useNavigation();

  // loading
  if (
    !navigation ||
    (isOpenSurveyQueryExecutable && isLoadingOpenSurveys) ||
    isLoadingNavigation
  ) {
    return null;
  }

  const hasOpenedSurveys = openSurveys?.length > 0;
  const hasNoAccess = Object.keys(navigation).length === 0;
  const onlyHasAccessToProfile =
    Object.keys(navigation).length === 1 && navigation.profile !== undefined;

  if (onlyHasAccessToProfile || hasNoAccess) {
    if (hasOpenedSurveys) {
      assignWindowLocation(`/transfer/employee/survey/${openSurveys[0].id}`);
      return null;
    } else {
      assignWindowLocation(`/surveys/no-surveys`);
      return null;
    }
  }

  const redirectTo = getBestAvailableRoute({
    navigation,
    activeProductName,
    // @ts-expect-error   Type 'undefined' is not assignable to type 'string'.ts(2322)
    activeMenuGroupName,
  });

  if (redirectTo) {
    if (redirectTo.startsWith('/employee-dashboard')) {
      assignWindowLocation(redirectTo);
      return null;
    }

    return <Redirect to={redirectTo} />;
  }

  assignWindowLocation('/login');

  return null;
};

// eslint-disable-next-line import/no-default-export
export default RedirectToAvailableRoute;
