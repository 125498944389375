import React from 'react';

import { Spinner } from '@peakon/components';

import styles from './styles.css';

const PageLoader = () => {
  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default PageLoader;
