import { fromJS, Map, Record } from 'immutable';
import get from 'lodash/get';
// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';
import { z } from 'zod';

import { CategoryResponse } from '@peakon/shared/schemas/api/categories';
import { EngagementDriversType } from '@peakon/shared/types/Drivers';
import { getUrl, getShareBaseUrl } from '@peakon/shared/utils/appUrl';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { ALL_QUESTION_SETS } from './constants/questionSets';
import TranslationRecord from './TranslationRecord';
import { sortTranslations, validateTestingSchema } from './utils';
import ValueRecord from './ValueRecord';

type FromGroupProps = {
  id?: string;
  main?: boolean;
  standard?: string;
  nameTranslated?: string;
  // eslint-disable-next-line no-use-before-define
  parentCategory?: CategoryRecord;
};

const categorySchema = z.object({
  id: z.string().optional(),
  nameTranslations: z.record(z.string(), z.object({})).optional(), // z.object is TranslationRecord
  descriptionTranslations: z.record(z.string(), z.object({})).optional(), // z.object is TranslationRecord
  parentCategory: z.object({}).nullish(), // z.object is CategoryRecord
  group: z.enum([...ALL_QUESTION_SETS, 'values', 'text']).optional(),
});
const testingCategorySchema = categorySchema.extend({
  group: z.any(),
  standard: z.any(),
  legacy: z.any(),
  legacyDriver: z.any(),
  legacySubdriver: z.any(),
  nameTranslated: z.any(),
  readMoreUrl: z.any(),
  name: z.any(),
  value: z.any(),
  descriptionTranslated: z.any(),
  main: z.any(),
  description: z.any(),
  theory: z.any(),
});

export type CategorySchema = z.infer<typeof categorySchema>;

const REQUEST_BY_DEFAULT_FIELDS = {
  id: undefined,
  description: undefined,
  descriptionTranslated: undefined,
  descriptionTranslations: Map(),
  group: undefined,
  name: undefined,
  nameTranslated: undefined,
  nameTranslations: Map<string, TranslationRecord>(),
  parentCategory: undefined,
  standard: undefined,
  main: false,
  legacy: false,
  legacyDriver: undefined,
  legacySubdriver: undefined,
  theory: undefined,
};

class CategoryRecord
  extends Record({
    ...REQUEST_BY_DEFAULT_FIELDS,
    readMoreUrl: undefined,
    value: undefined,
  })
  implements CategorySchema
{
  id: CategorySchema['id'];
  nameTranslations?: Map<string, TranslationRecord>;
  descriptionTranslations?: Map<string, TranslationRecord>;
  group?: CategorySchema['group'];
  // eslint-disable-next-line no-use-before-define
  parentCategory?: CategoryRecord | null;

  // We need to add all the properties below to the schema validation.
  description?: string;
  descriptionTranslated?: string;
  name?: string;
  nameTranslated?: string;
  standard?: string;
  main?: boolean;
  legacy?: boolean;
  legacyDriver?: EngagementDriversType;
  legacySubdriver?: string;
  theory?: string;

  constructor(props: unknown = {}) {
    validateRecord(props, categorySchema, {
      errorMessagePrefix: 'categorySchema',
    });
    validateTestingSchema(props, testingCategorySchema, {
      errorMessagePrefix: 'categorySchema',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get root(): CategoryRecord {
    if (this.isSubcategory()) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return this.parentCategory as CategoryRecord;
    }

    return this;
  }

  get parentCategoryId() {
    return this.getInParent(['id']);
  }

  get subcategoryId() {
    if (this.isSubcategory()) {
      return this.id;
    }

    return null;
  }

  /**
   * @deprecated: use .root instead
   */
  get category() {
    return this.root;
  }

  get subcategory(): CategoryRecord | null {
    if (!this.isSubcategory()) {
      return null;
    }

    return this;
  }

  /**
   * @deprecated: LEGACY - for fallback purposes
   */
  get driver() {
    return this.category?.legacyDriver;
  }

  /**
   * @deprecated: LEGACY - for fallback purposes
   */
  get subdriver() {
    if (this.subcategory) {
      return this.subcategory.legacySubdriver;
    }

    return null;
  }

  getDriverUrl() {
    if (this.legacy) {
      let url = `/driver/${this.legacyDriver}`;

      if (this.isSubcategory()) {
        url += `/subdriver/${this.legacySubdriver}`;
      }

      return url;
    }

    return `/driver/${this.id}`;
  }

  getUrl({
    contextId,
    segmentId = null,
    isShare,
  }: {
    contextId?: string;
    segmentId?: string | null;
    isShare?: boolean;
  } = {}) {
    let url;
    const baseUrl = isShare
      ? getShareBaseUrl(window.location.pathname)
      : `/dashboard`;

    // engagement driver
    if (
      this.legacy &&
      !this.isSubcategory() &&
      this.isEngagementCategoryOrSubcategory()
    ) {
      return baseUrl;
    }

    if (this.main) {
      if (segmentId) {
        return getUrl(`/dashboard/group/${this.group}/segment/${segmentId}`, {
          contextId,
        });
      } else {
        return getUrl(`/dashboard/group/${this.group}`, { contextId });
      }
    }

    if (segmentId) {
      url = `${baseUrl}/segment/${segmentId}${this.getDriverUrl()}`;
    } else {
      url = `${baseUrl}${this.getDriverUrl()}`;
    }

    const isSharePreview = isShare && baseUrl.includes('/preview');

    return getUrl(
      url,
      isSharePreview
        ? {
            contextId,
            ...qs.parse(window.location.search, {
              ignoreQueryPrefix: true,
            }),
          }
        : { contextId },
    );
  }

  isSubcategory() {
    return Boolean(this.parentCategory);
  }

  hasParentCategory() {
    return this.isSubcategory();
  }

  isParentCategory() {
    return !this.parentCategory;
  }

  isGroup(name: string) {
    return this.group === name;
  }

  isOther() {
    return this.isGroup('other');
  }

  isStandard(standard: string) {
    return this.standard === standard;
  }

  isEngagementCategoryOrSubcategory({ onlyMain = false } = {}) {
    if (onlyMain) {
      return this.isGroup('engagement') && this.isStandard('engagement');
    }

    return (
      this.isGroup('engagement') &&
      (this.isStandard('engagement') ||
        (this.parentCategory && this.parentCategory.isStandard('engagement')))
    );
  }

  isMainCategoryOrSubcategory() {
    return this.getInParent(['main']);
  }

  isInvalid() {
    return (
      !this.name ||
      this.nameTranslations?.some((item) => !item?.translation) ||
      this.descriptionTranslations?.some((item) => !item?.translation)
    );
  }

  getInParent(keyPath: string[]) {
    if (this.isSubcategory()) {
      return this.parentCategory?.getIn(keyPath);
    }

    return this.getIn(keyPath);
  }

  static compare(a: CategoryRecord, b: CategoryRecord, order = -1) {
    if (a.root.main && !b.root.main) {
      return -101;
    } else if (b.root.main && !a.root.main) {
      return 101;
    }

    if (a.root.id === b.root.id && a.root.group !== 'other') {
      if (a.isParentCategory()) {
        return -1;
      } else if (b.isParentCategory()) {
        return 1;
      } else if (a.parentCategory?.id === a.id) {
        return -101;
      } else if (b.parentCategory?.id === b.id) {
        return 101;
      }

      return (
        (a.nameTranslated ?? '').localeCompare(b.nameTranslated ?? '') * -order
      );
    }

    return (
      (a.root.nameTranslated ?? '').localeCompare(b.root.nameTranslated ?? '') *
      -order
    );
  }

  static fromGroup(group: string, props: FromGroupProps) {
    return new CategoryRecord({
      group,
      ...props,
    });
  }

  static createFromApi(data: CategoryResponse): CategoryRecord {
    const { id, attributes } = data;

    // FIXME: is this really necessary?
    // I imagine it is here because in some places we are not including categories relationships
    // but we should fix that instead
    if (!attributes) {
      return new CategoryRecord({ id });
    }

    const nameTranslations = sortTranslations(attributes.nameTranslations);

    const descriptionTranslations = sortTranslations(
      attributes.descriptionTranslations,
    );

    const parentCategory = get(data, 'relationships.parentCategory', null);
    const value = get(data, 'relationships.value', null);

    return new CategoryRecord(
      fromJS({
        id,
        ...attributes,
        nameTranslations,
        descriptionTranslations,
        parentCategory: parentCategory
          ? CategoryRecord.createFromApi(parentCategory)
          : parentCategory,
        value:
          value && value.attributes ? ValueRecord.createFromApi(value) : value,
      }),
    );
  }

  static get defaultFields() {
    return Object.keys(REQUEST_BY_DEFAULT_FIELDS);
  }
}

// eslint-disable-next-line import/no-default-export
export default CategoryRecord;
