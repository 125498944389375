import React from 'react';

import { NavLink } from 'react-router-dom';

import PageHeader from '@peakon/shared/components/PageHeader';
import { t } from '@peakon/shared/features/i18next/t';

import RightsFilter from '../../RightsFilter';

export type SettingsHeaderProps = {
  hasSharing: boolean;
  hasSensitiveComments: boolean;
  hasBenchmark: boolean;
  hasAdminRecalculation: boolean;
};

export const SettingsHeader = ({
  hasSharing,
  hasSensitiveComments,
  hasBenchmark,
  hasAdminRecalculation,
}: SettingsHeaderProps) => (
  <PageHeader>
    <PageHeader.Nav>
      <PageHeader.Title>{t('configure__data_settings')}</PageHeader.Title>
    </PageHeader.Nav>
    <PageHeader.SubNav>
      {/* page-header-title is the id output by <PageHeader.Title> */}
      <PageHeader.Tabs ariaLabelledBy="page-header-title">
        <RightsFilter rights={['company:admin']}>
          <NavLink
            id="engagement-settings-overview"
            to="/admin/data-settings/dashboard"
          >
            {t('engagement__settings__tab_dashboard_settings')}
          </NavLink>
        </RightsFilter>
        <RightsFilter rights={['company:admin']}>
          <NavLink
            id="engagement-settings-data-aggregation"
            to="/admin/data-settings/data-aggregation"
          >
            {t('engagement__settings__tab_data_deprecation')}
          </NavLink>
        </RightsFilter>
        <RightsFilter rights={['company:admin']}>
          <NavLink
            id="engagement-settings-data-comments"
            to="/admin/data-settings/comments"
          >
            {t('engagement__settings__comments')}
          </NavLink>
        </RightsFilter>

        {hasSharing && (
          <RightsFilter rights={['company:admin']}>
            <NavLink
              id="engagement-settings-sharing"
              to="/admin/data-settings/sharing"
            >
              {t('engagement__settings__sharing')}
            </NavLink>
          </RightsFilter>
        )}

        {hasBenchmark && (
          <RightsFilter rights={['company:admin']}>
            <NavLink
              id="engagement-settings-benchmark"
              to="/admin/data-settings/benchmark"
            >
              {t('engagement__settings__true_benchmarks')}
            </NavLink>
          </RightsFilter>
        )}

        {hasSensitiveComments && (
          <NavLink
            id="engagement-settings-sensitive-comments"
            title={t('engagement__settings__sensitive_comments')}
            to="/admin/data-settings/sensitive-comments"
          >
            {t('engagement__settings__sensitive_comments')}
          </NavLink>
        )}

        {hasAdminRecalculation && (
          <RightsFilter rights={['company:admin']}>
            <NavLink
              id="data-settings-recalculation"
              title={t('data_settings__recalculation__nav')}
              to="/admin/data-settings/recalculation"
            >
              {t('data_settings__recalculation__nav')}
            </NavLink>
          </RightsFilter>
        )}
      </PageHeader.Tabs>
    </PageHeader.SubNav>
  </PageHeader>
);
