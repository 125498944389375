import React from 'react';

import { z } from 'zod';

import { Button } from '@peakon/bedrock/react/button';
import { Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { ProgressBar } from '../../TaskNotification/ProgressBar/ProgressBar';
import { TaskNotification } from '../../TaskNotification/TaskNotification';
import { Task } from '../types';

import styles from './styles.css';

const completedDataSchema = z.object({
  status: z.literal('completed'),
});

const errorDataSchema = z.object({
  status: z.literal('error'),
  error: z.string(),
});

const inProgressDataSchema = z.object({
  status: z.literal('inProgress'),
  percent: z.number().min(0).max(100),
});

const dataSchema = z.union([
  inProgressDataSchema,
  completedDataSchema,
  errorDataSchema,
]);

const typeSchema = z.literal('employeesUpdate');

const employeesUpdateTask = z.object({
  id: z.string(),
  type: typeSchema,
  data: dataSchema,
});

type EmployeesUpdateTask = z.infer<typeof employeesUpdateTask>;

export type EmployeesUpdateTaskInitializationData = Pick<
  EmployeesUpdateTask,
  'type'
>;

export const isEmployeesUpdateTask = (
  task: Task,
): task is EmployeesUpdateTask => {
  if (typeSchema.safeParse(task.type).success) {
    validateData(task, employeesUpdateTask, {
      errorMessagePrefix: 'EmployeesUpdateBackgroundTask',
    });
    return true;
  }
  return false;
};

export const EmployeesUpdateTaskNotification = ({
  task,
  onClose,
}: {
  task: EmployeesUpdateTask;
  onClose: () => void;
}) => {
  if (task.data.status === 'inProgress') {
    return (
      <TaskNotification
        taskId={task.id}
        heading={t('background_task__employee-update__generating__title')}
        onClose={onClose}
        status="inProgress"
      >
        <TaskNotification.Content>
          <div className={styles.description}>
            <Typography.P>
              {t('background_task__employee-update__generating__text')}
            </Typography.P>
          </div>
          <ProgressBar percent={task.data.percent} />
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  if (task.data.status === 'completed') {
    return (
      <TaskNotification
        taskId={task.id}
        heading={t('background_task__employee-update__ready__title')}
        onClose={onClose}
        status="completed"
      >
        <TaskNotification.Content>
          <div className={styles.description}>
            <Typography.P>
              {t('background_task__employee-update__ready__text')}
            </Typography.P>
          </div>
          <Button
            variant="primary"
            onClick={() => {
              // We should probably invalidating the employee query data instead, but this is not easy to implement
              window.location.reload();
            }}
          >
            {t('background_task__employee-update__ready__refresh')}
          </Button>
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  return (
    <TaskNotification
      taskId={task.id}
      heading={t('background_task__employee-update__error__title')}
      onClose={onClose}
      status="error"
    >
      <TaskNotification.Content>
        <Typography.P className={styles.error}>{task.data.error}</Typography.P>
      </TaskNotification.Content>
    </TaskNotification>
  );
};
