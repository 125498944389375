import React from 'react';

import RedirectToSubdomain from './RedirectToSubdomain';
import TransferToSubdomain from './TransferToSubdomain/TransferToSubdomain';
import { APP_SUBDOMAIN, DEV_SUBDOMAIN } from '../constants';
import { getCurrentSubdomain } from '../getCurrentSubdomain';

type Props = {
  isLoading?: boolean;
  primarySubdomain?: string;
  shouldPreserveSession: boolean;
  children?: React.ReactNode;
};

/**
 *
 * Check whether the user needs to be redirected to the primary subdomain. If so, redirect them to the subdomain, if not, render the children.
 */
const CheckRedirectToSubdomain = ({
  isLoading,
  primarySubdomain,
  children,
  shouldPreserveSession,
}: Props) => {
  const currentSubdomain = getCurrentSubdomain();

  if (isLoading) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (primarySubdomain && currentSubdomain !== primarySubdomain) {
    return shouldPreserveSession ? (
      <TransferToSubdomain
        currentSubdomain={currentSubdomain}
        newSubdomain={primarySubdomain}
      />
    ) : (
      <RedirectToSubdomain
        currentSubdomain={currentSubdomain}
        newSubdomain={primarySubdomain}
      />
    );
  }

  // Fallback to the app subdomain if the subdomain is missing
  // This won't be necessary after subdomains are required
  if (
    !primarySubdomain &&
    currentSubdomain !== APP_SUBDOMAIN &&
    currentSubdomain !== DEV_SUBDOMAIN
  ) {
    return shouldPreserveSession ? (
      <TransferToSubdomain
        currentSubdomain={currentSubdomain}
        newSubdomain={APP_SUBDOMAIN}
      />
    ) : (
      <RedirectToSubdomain
        currentSubdomain={currentSubdomain}
        newSubdomain={APP_SUBDOMAIN}
      />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

// eslint-disable-next-line import/no-default-export
export default CheckRedirectToSubdomain;
