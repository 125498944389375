import React, { Fragment, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { Prompt } from 'react-router';

import { EditWriteIcon } from '@peakon/bedrock/icons/system';
import { Button, IconButton } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import { RadioGroup, Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import { updateSettings } from '../../../../actions/CompanyActions';
import { useFeature } from '../../../../hooks/useFeature';
import {
  CommentDateVisibility,
  ConversationEmail,
} from '../../../../reducers/CompanySettingsTypes';
import { useAppDispatch, useAppSelector } from '../../../../utils/reduxHooks';
import ReviewChangesBar from '../../../ReviewChangesBar';
import { aggregationValues, anonymityValues } from '../Data';
import { CustomValueSetting } from '../Data/CustomValueSetting';

import dashboardStyles from './../DashboardSettings/styles.css';
import styles from './../Data/styles.css';

const commentLevelOptions = range(3, 11);
const commentRoundLevelOptions = range(2, 8);

type Settings = {
  commentLevel: number;
  commentRoundLevel: number;
  commentDateVisibility: CommentDateVisibility;
  conversationEmail: ConversationEmail;
} & Record<string, $TSFixMe>;

export const CommentsSettings = () => {
  const dispatch = useAppDispatch();
  const [isSubmittingChanges, setIsSubmittingChanges] = useState(false);

  const [isSettingCustomCommentLevel, setIsSettingCustomCommentLevel] =
    useState(false);
  const [isSaveConfirmationVisible, setIsSaveConfirmationVisible] =
    useState(false);

  const [
    isSettingCustomCommentRoundLevel,
    setIsSettingCustomCommentRoundLevel,
  ] = useState(false);

  const hasConversationEmail = useFeature('conversationEmail');

  const companySettings: Map<$TSFixMe, $TSFixMe> = useAppSelector(
    (state) => state.company.settings,
  );

  const [settings, setSettings] = useState<Settings>({
    commentLevel: companySettings.get('commentLevel'),
    commentRoundLevel: companySettings.get('commentRoundLevel'),
    commentDateVisibility: companySettings.get('commentDateVisibility'),
    conversationEmail: companySettings.get('conversationEmail'),
  });

  const [originalSettings, setOriginalSettings] = useState<Settings>({
    commentLevel: companySettings.get('commentLevel'),
    commentRoundLevel: companySettings.get('commentRoundLevel'),
    commentDateVisibility: companySettings.get('commentDateVisibility'),
    conversationEmail: companySettings.get('conversationEmail'),
  });

  const isDirty = !isEqual(settings, originalSettings);

  const setCommentLevelValueAndAlignCommentRoundLevelValue = (
    level: number,
  ) => {
    setSettings({
      ...settings,
      commentLevel: level,
      commentRoundLevel: Math.min(level, settings.commentRoundLevel),
    });
  };

  const handleSetCustomCommentLevel = (level: number) => {
    setCommentLevelValueAndAlignCommentRoundLevelValue(level);
    setIsSettingCustomCommentLevel(false);
  };

  const handleSetCustomCommentRoundLevel = (level: number) => {
    setSettings({
      ...settings,
      commentRoundLevel: level,
    });
    setIsSettingCustomCommentRoundLevel(false);
  };

  function getScaleRecommendations() {
    return (
      <div className={styles.scaleRecommendations}>
        <span className={styles.scaleHelpSmall}>
          {t('company__segment-size__small')}
        </span>
        <span className={styles.scaleHelpRecommended}>
          {t('company__segment-size__recommended')}
        </span>
        <span className={styles.scaleHelpLarge}>
          {t('company__segment-size__large')}
        </span>
      </div>
    );
  }

  const getSettingsDiff = (
    currentSettings: Settings,
    previousSettings: Settings,
  ): Partial<Settings> => {
    const settingsDiff: Partial<Settings> = {};

    each(currentSettings, (value, setting) => {
      if (
        value !== null &&
        value !== undefined &&
        value !== previousSettings[setting]
      ) {
        settingsDiff[setting] = value;
      }
    });

    return settingsDiff;
  };

  const handleSubmitChanges = async () => {
    if (!isSubmittingChanges) {
      setIsSubmittingChanges(true);

      try {
        const updatedSettings = getSettingsDiff(settings, originalSettings);
        await dispatch(updateSettings(updatedSettings));

        setOriginalSettings(cloneDeep(settings));
        setIsSaveConfirmationVisible(false);
      } catch {
      } finally {
        setIsSubmittingChanges(false);
      }
    }
  };

  const handleCancelChanges = () => {
    setSettings(cloneDeep(originalSettings));
  };

  return (
    <Fragment>
      <div className={styles.root}>
        <div className={styles.header}>
          <Typography type="h2" className={styles.sectionTitle}>
            {t('dashboard_data_settings__comments_level__confidential')}
          </Typography>
        </div>

        <div className={styles.container}>
          <div className={styles.row}>
            <div
              className={styles.section}
              data-test-id="comment-level-segments-section"
            >
              <Typography type="h3" className={styles.title}>
                {t('dashboard_data_settings__comment_level__title__v2')}
              </Typography>

              <div
                className={styles.description}
                id="segments-accessible-description"
              >
                {t(
                  'dashboard_data_settings__comment_level__text__v2__confidential',
                )}
              </div>

              {!isSettingCustomCommentLevel && (
                <React.Fragment>
                  <div className={styles.scale}>
                    <VisuallyHidden>
                      <p id="segments-scale-component-accesible-description">
                        {t(
                          'dashboard_data_settings__comment_level__input_accesible_description',
                        )}
                      </p>
                    </VisuallyHidden>
                    <RadioGroup
                      aria-label={t(
                        'dashboard_data_settings__comment_level__title__v2',
                      )}
                      aria-describedby="segments-accessible-description"
                      orientation="horizontal"
                      variant="card"
                      value={settings.commentLevel}
                      onChange={(value) => {
                        setCommentLevelValueAndAlignCommentRoundLevelValue(
                          // @ts-expect-error - Argument of type 'RadioInputValue | undefined' is not assignable to parameter of type 'number'.
                          value,
                        );
                      }}
                    >
                      {commentLevelOptions.map((level, index) => (
                        <RadioGroup.Button
                          key={index}
                          value={level}
                          aria-describedby="segments-scale-component-accesible-description"
                        >
                          {level}
                        </RadioGroup.Button>
                      ))}

                      {!commentLevelOptions.includes(settings.commentLevel) && (
                        <RadioGroup.Button value={settings.commentLevel}>
                          {settings.commentLevel}
                        </RadioGroup.Button>
                      )}
                    </RadioGroup>
                    <IconButton
                      variant="secondary"
                      onClick={() => setIsSettingCustomCommentLevel(true)}
                      accessibleName={t('custom_anonymity__set_custom')}
                      icon={<EditWriteIcon />}
                    />
                  </div>
                  {getScaleRecommendations()}
                </React.Fragment>
              )}

              {isSettingCustomCommentLevel && (
                <CustomValueSetting
                  id="custom-comment-level-segments"
                  initial={settings.commentLevel}
                  min={3}
                  onCancel={() => setIsSettingCustomCommentLevel(false)}
                  onSet={handleSetCustomCommentLevel}
                />
              )}
            </div>

            <div
              className={styles.section}
              data-test-id="comment-level-responses-section"
            >
              <Typography type="h3" className={styles.title}>
                {t('dashboard_data_settings__comment_round_level__title')}
              </Typography>

              <div className={styles.description}>
                {t('dashboard_data_settings__comment_round_level__text')}

                <VisuallyHidden>
                  <p id="responses-accessible-description">
                    {t('dashboard_data_settings__comment_round_level__text')}
                  </p>
                </VisuallyHidden>
              </div>

              {!isSettingCustomCommentRoundLevel && (
                <React.Fragment>
                  <div className={styles.scale}>
                    <VisuallyHidden>
                      <p id="responses-scale-component-accesible-description">
                        {t(
                          'dashboard_data_settings__comment_round_level__input_accesible_description',
                        )}
                      </p>
                    </VisuallyHidden>
                    <RadioGroup
                      aria-label={t(
                        'dashboard_data_settings__comment_round_level__title',
                      )}
                      aria-describedby="responses-accessible-description"
                      orientation="horizontal"
                      variant="card"
                      value={settings.commentRoundLevel}
                      onChange={(value) =>
                        // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
                        setSettings({ ...settings, commentRoundLevel: value })
                      }
                    >
                      <RadioGroup.Button
                        value={0}
                        aria-describedby="responses-scale-component-accesible-description"
                      >
                        {t('dashboard_data_settings__difference_level__off')}
                      </RadioGroup.Button>
                      {commentRoundLevelOptions.map((level, index) => (
                        <RadioGroup.Button
                          key={index}
                          value={level}
                          disabled={level > settings.commentLevel}
                          aria-describedby="responses-scale-component-accesible-description"
                        >
                          {level}
                        </RadioGroup.Button>
                      ))}

                      {!commentRoundLevelOptions.includes(
                        settings.commentRoundLevel,
                      ) &&
                        settings.commentRoundLevel !== 0 && (
                          <RadioGroup.Button value={settings.commentRoundLevel}>
                            {settings.commentRoundLevel}
                          </RadioGroup.Button>
                        )}
                    </RadioGroup>
                    <IconButton
                      variant="secondary"
                      onClick={() => setIsSettingCustomCommentRoundLevel(true)}
                      disabled={
                        settings.commentLevel <=
                        commentRoundLevelOptions[
                          commentRoundLevelOptions.length - 1
                        ]
                      }
                      accessibleName={t('custom_anonymity__set_custom')}
                      icon={<EditWriteIcon />}
                    />
                  </div>
                  {getScaleRecommendations()}
                </React.Fragment>
              )}

              {isSettingCustomCommentRoundLevel && (
                <CustomValueSetting
                  id="custom-comment-level-responses"
                  initial={settings.commentRoundLevel}
                  min={3}
                  max={settings.commentLevel}
                  onCancel={() => setIsSettingCustomCommentRoundLevel(false)}
                  onSet={handleSetCustomCommentRoundLevel}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.root}>
        <div className={styles.header}>
          <Typography type="h2" className={styles.sectionTitle}>
            {t('dashboard_data_settings__comments_visibility')}
          </Typography>
        </div>

        <div className={dashboardStyles.rowWrap}>
          <div className={styles.section} data-test-id="date-section">
            <Typography type="h3" className={styles.title}>
              {t('attributes__type__date')}
            </Typography>

            <RadioGroup
              value={settings?.commentDateVisibility}
              onChange={(value) => {
                // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
                setSettings({ ...settings, commentDateVisibility: value });
              }}
            >
              <div className={dashboardStyles.settings}>
                <RadioGroup.Radio
                  value={CommentDateVisibility.Date}
                  testId="comment-date-visibility-date"
                >
                  <div className={dashboardStyles.label}>
                    <strong>
                      {t('dashboard_settings__comment_date_visibility__date')}
                      <span className={dashboardStyles.standard}>
                        ({t('dashboard_settings__standard')})
                      </span>
                    </strong>
                    <span className={dashboardStyles.settingInfo}>
                      {t(
                        'dashboard_settings__comment_date_visibility__date_info',
                      )}
                    </span>
                  </div>
                </RadioGroup.Radio>
              </div>

              <div className={dashboardStyles.settings}>
                <RadioGroup.Radio
                  value={CommentDateVisibility.Round}
                  testId="comment-date-visibility-round"
                >
                  <div className={dashboardStyles.label}>
                    <strong>
                      {t('dashboard_settings__comment_date_visibility__round')}
                    </strong>
                    <span className={dashboardStyles.settingInfo}>
                      {t(
                        'dashboard_settings__comment_date_visibility__round_info',
                      )}
                    </span>
                  </div>
                </RadioGroup.Radio>
              </div>
            </RadioGroup>
          </div>

          {hasConversationEmail && (
            <div
              className={styles.section}
              data-test-id="conversation-email-section"
            >
              <Typography type="h3" className={styles.title}>
                {t('dashboard_settings__conversation_email')}
              </Typography>

              <RadioGroup
                value={settings?.conversationEmail}
                onChange={(value) => {
                  // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
                  setSettings({ ...settings, conversationEmail: value });
                }}
              >
                <div className={dashboardStyles.settings}>
                  <RadioGroup.Radio
                    value={ConversationEmail.Full}
                    testId="conversation-email-full"
                  >
                    <div className={dashboardStyles.label}>
                      <strong>
                        {t('dashboard_settings__conversation_email__full')}
                        <span className={dashboardStyles.standard}>
                          ({t('dashboard_settings__standard')})
                        </span>
                      </strong>
                      <span className={dashboardStyles.settingInfo}>
                        {t('dashboard_settings__conversation_email__full_info')}
                      </span>
                    </div>
                  </RadioGroup.Radio>
                </div>

                <div className={dashboardStyles.settings}>
                  <RadioGroup.Radio
                    value={ConversationEmail.Limited}
                    testId="conversation-email-limited"
                  >
                    <div className={dashboardStyles.label}>
                      <strong>
                        {t('dashboard_settings__conversation_email__limited')}
                      </strong>
                      <span className={dashboardStyles.settingInfo}>
                        {t(
                          'dashboard_settings__conversation_email__limited_info',
                        )}
                      </span>
                    </div>
                  </RadioGroup.Radio>
                </div>
              </RadioGroup>
            </div>
          )}
        </div>
      </div>

      <Modal
        closeLabel={t('cancel')}
        data-test-id="data-settings-confirmation-modal"
        heading={t('dashboard_data_settings__confirm__title')}
        onDismiss={() => setIsSaveConfirmationVisible(false)}
        open={isSaveConfirmationVisible}
      >
        <Modal.Content>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('dashboard_data_settings__confirm_v2__confidential', {
                replace: {
                  link_aggr_start: aggregationValues.link_start,
                  link_anon_start: anonymityValues.link_start,
                  link_aggr_end: aggregationValues.link_end,
                  link_anon_end: anonymityValues.link_end,
                },
              }),
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => setIsSaveConfirmationVisible(false)}
            variant="secondary"
          >
            {t('cancel')}
          </Button>

          <Button
            onClick={handleSubmitChanges}
            variant="primary"
            busy={isSubmittingChanges}
          >
            {t('question__confirm')}
          </Button>
        </Modal.Actions>
      </Modal>

      <div>
        <ReviewChangesBar
          isDirty={isDirty}
          confirmLabel={t('save')}
          onConfirm={() => setIsSaveConfirmationVisible(true)}
          onCancel={handleCancelChanges}
        />
      </div>
      <Prompt
        when={isDirty}
        message={() => t('schedules__form__unsaved_changes')}
      />
    </Fragment>
  );
};
