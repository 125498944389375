import { useMutation } from '@tanstack/react-query';

import { HttpError } from '@peakon/shared/utils';
import api from '@peakon/shared/utils/api';

const RECALCULATION_TYPE = ['topics', 'priorities'] as const;
export type RecalculationType = (typeof RECALCULATION_TYPE)[number];

type PostRecalculationParams = {
  recalculationType: RecalculationType;
  contextId: string;
};

const postRecalculation = ({
  recalculationType,
  contextId,
}: PostRecalculationParams) =>
  api.post(`/${recalculationType}/contexts/${contextId}/recalculate`);

type UsePostRecalculationType = {
  onSuccess: () => void;
  onError: (error: HttpError, variables: PostRecalculationParams) => void;
};

export const usePostRecalculation = ({
  onSuccess,
  onError,
}: UsePostRecalculationType) => {
  return useMutation({
    mutationFn: ({ recalculationType, contextId }: PostRecalculationParams) =>
      postRecalculation({ recalculationType, contextId }),
    onSuccess,
    onError,
  });
};
