import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import api from '@peakon/shared/utils/api';

export type DeleteValueProps = {
  valueId: string;
};

const deleteValue = (id: string) => {
  return api.delete(`/values/${id}`);
};

const useDeleteValue = ({
  onSuccess,
  onError,
}: UseMutationOptions<unknown, Error, unknown, unknown>) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (valueId: string) => deleteValue(valueId),
    onError,
    onSuccess,
    onSettled: async () => {
      await client.invalidateQueries(['admin_values']);
    },
  });
};

// eslint-disable-next-line import/no-default-export
export default useDeleteValue;
