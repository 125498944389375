import React, { useCallback, useEffect, useState } from 'react';

import find from 'lodash/find';
import first from 'lodash/first';

import jsonapiparser from '@peakon/jsonapiparser';
import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import {
  ConversationContent,
  ConversationKind,
  Employee,
} from '../../../../context/ConversationsContext';
import { useDashboardContext } from '../../../../hooks/useDashboardContext';
import MentionsTextarea from '../../../MentionsTextarea';

const MAX_MENTIONABLE = 25;

type ParsedEmployee = {
  id: string;
  display: string;
  avatar?: string;
  abbreviation: string;
};

type ApiResponse = {
  data: {
    id: string;
  };
};

type Props = {
  comment: ConversationContent;
  kind: ConversationKind;
  onChange: (value: string) => void;
  value: string;
  id: string;
  ariaDescribedby: string;
};

const parseMentionable = (employee: Employee) => {
  const {
    id,
    attributes: { avatar, firstName, lastName, name },
  } = employee;

  return {
    id,
    display: name,
    avatar,
    abbreviation: (first(firstName) || '') + (first(lastName) || ''),
  };
};

const MentionsInput = ({
  comment,
  kind,
  onChange,
  value,
  id,
  ariaDescribedby,
}: Props) => {
  const [hasLoadedEmployees, setHasLoadedEmployees] = useState(false);
  const [employees, setEmployees] = useState([]);
  const dashboardContext = useDashboardContext();

  const getMentionable = useCallback(
    async (q = '', callback?: (employees: ParsedEmployee[]) => void) => {
      if (!comment) {
        return;
      }

      if (!hasLoadedEmployees) {
        setHasLoadedEmployees(true);
      }

      let url = `/conversations/comments/${comment.id}/mentionable`;

      if (dashboardContext) {
        url += `/contexts/${dashboardContext.id}`;
      }

      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      q = q.toLowerCase().trim();

      if (q.length < 2 && callback) {
        return callback(
          employees.map(parseMentionable).slice(0, MAX_MENTIONABLE),
        );
      }

      const params = {
        fields: {
          employees: 'name,firstName,lastName,avatar,account,email',
        },

        limit: MAX_MENTIONABLE,
      };

      if (q) {
        // @ts-expect-error TS(2339): Property 'q' does not exist on type '{ fields: { e... Remove this comment to see the full error message
        params.q = q;
      }

      // @ts-expect-error TS(2345): Argument of type '(response: ApiResponse) => void'... Remove this comment to see the full error message
      return api.get(url, params).then((response: ApiResponse) => {
        const parsedResponse = jsonapiparser(response.data);

        setEmployees((prevEmployees) => {
          return prevEmployees.concat(
            parsedResponse.filter(
              (item: Employee) =>
                !find(
                  employees,
                  (employee: Employee) => employee.id === item.id,
                ),
            ),
          );
        });

        const data = parsedResponse.map(parseMentionable);

        if (callback) {
          callback(data);
        }
      });
    },
    [employees, dashboardContext, comment, hasLoadedEmployees],
  );

  useEffect(() => {
    if (!hasLoadedEmployees) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
      getMentionable();
    }
  }, [getMentionable, hasLoadedEmployees]);

  return (
    <MentionsTextarea
      employees={employees}
      getMentionable={getMentionable}
      onChange={onChange}
      placeholder={
        kind === ConversationKind.note
          ? t('conversation__note__placeholder')
          : t('conversation__message__placeholder')
      }
      value={value}
      id={id}
      ariaDescribedby={ariaDescribedby}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default MentionsInput;
