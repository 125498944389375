/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react';

import { createPortal } from 'react-dom';

import { EditCloseIcon as Cross } from '@peakon/bedrock/icons/system';

import styles from './styles.css';

const Header = ({ children }: { children: React.ReactNode }) => (
  <div>{children}</div>
);

const Content = ({ children }: { children: React.ReactNode }) => (
  <div>{children}</div>
);

type MobileTooltipProps = {
  children: React.ReactNode;
  toggler: React.ReactNode;
};

type MobileTooltipState = {
  open: boolean;
};

class MobileTooltip extends Component<MobileTooltipProps, MobileTooltipState> {
  constructor(props: MobileTooltipProps) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentWillUnmount() {
    window.document.body.classList.remove('no-overflow');
  }

  render() {
    const { children, toggler } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span onClick={this.show}>{toggler}</span>
        {open &&
          createPortal(
            <div className={styles.content}>
              <Fragment>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <div className={styles.close} onClick={this.hide}>
                  <Cross />
                </div>
                <div className={styles.main}>{children}</div>
              </Fragment>
            </div>,
            window.document.body,
          )}
      </Fragment>
    );
  }

  show = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ open: true }, () => {
      window.document.body.classList.add('no-overflow');
    });
  };

  hide = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ open: false }, () => {
      window.document.body.classList.remove('no-overflow');
    });
  };
}

// @ts-expect-error TS(2339): Property 'Header' does not exist on type 'typeof M... Remove this comment to see the full error message
MobileTooltip.Header = Header;
// @ts-expect-error TS(2339): Property 'Content' does not exist on type 'typeof ... Remove this comment to see the full error message
MobileTooltip.Content = Content;

// eslint-disable-next-line import/no-default-export
export default MobileTooltip;
