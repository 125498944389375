import { List } from 'immutable';

import { SegmentComments } from '@peakon/records/metrics';

import createPagination from '../utils/createPagination';

const segmentActions = (
  state = List<SegmentComments>(),
  action: $TSFixMe,
): List<SegmentComments> => {
  switch (action.type) {
    case 'MANAGER_METRICS_COMMENT_SEGMENTS_SUCCESS': {
      const { data: segments } = action.data;

      return List(segments.map(SegmentComments.createFromApi));
    }

    case 'MANAGER_METRICS_COMMENT_SEGMENTS_APPEND_SUCCESS': {
      const { data: segments } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.concat(
        List(segments.map(SegmentComments.createFromApi)),
      ) as List<SegmentComments>;
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default createPagination(segmentActions, {
  startTypes: ['MANAGER_METRICS_COMMENT_SEGMENTS'],
  appendTypes: ['MANAGER_METRICS_COMMENT_SEGMENTS_APPEND'],
  removeTypes: [],
  resetTypes: [],
});
