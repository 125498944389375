import { createSelector } from 'reselect';

const rowsSelector = (state: $TSFixMe) => state.heatmap.rows;
const scoresSelector = (state: $TSFixMe) => state.heatmap.scores;
const segmentsSelector = (state: $TSFixMe) => state.heatmap.segments;

export const columnSelector = (state: $TSFixMe) => state.heatmap.columns;

export const getAttritionRows = createSelector([rowsSelector], (rows) =>
  // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
  rows.filter((row) => !row.isContext()),
);

export const getActiveColumns = createSelector(
  [columnSelector, scoresSelector],
  (columns, scores) => {
    const columnIdsWithDescendantScores = new Set(
      Array.prototype.concat(
        ...columns
          // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
          .filter((column) =>
            // @ts-expect-error TS(7006): Parameter 'segment' implicitly has an 'any' type.
            scores.some((segment) => column.hasScore(segment)),
          )
          // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
          .map((column) =>
            column.parentColumnId != null
              ? [column.id, column.parentColumnId]
              : [column.id],
          ),
      ),
    );
    // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
    return columns.filter((column) => {
      return (
        !column.hidden &&
        (column.type === 'participation' ||
          scores.isEmpty() || // initial load
          columnIdsWithDescendantScores.has(column.id))
      );
    });
  },
);

export const hasContextScore = createSelector([rowsSelector], (rows) => {
  return !rows.isEmpty() && rows.first().isContext();
});

export const getColumnStates = createSelector([columnSelector], (columns) => {
  // we only need to look at one participation column
  // to see if all of them are visible
  // @ts-expect-error TS(7006): Parameter 'col' implicitly has an 'any' type.
  const participation = columns.find((col) => col.isParticipation());

  return {
    participation: participation ? !participation.hidden : false,
    // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
    expanded: columns.some((column) => column.expanded),
    // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
    withQuestions: columns.some((column) => column.questionId),
  };
});

export const hasSubdriverColumns = createSelector(
  [columnSelector],
  (columns) => {
    // @ts-expect-error TS(7006): Parameter 'col' implicitly has an 'any' type.
    return columns.some((col) => col.isCollapsible());
  },
);

export const isCriticalSegments = createSelector(
  [rowsSelector, segmentsSelector],
  (rows, segments) =>
    // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
    rows.every((row) => {
      if (!segments.has(row.id)) {
        return false;
      }

      const segment = segments.get(row.id);

      // handle for the context row
      return typeof segment.isCritical === 'function'
        ? segment.isCritical()
        : false;
    }),
);

export const hasChildren = createSelector(
  [rowsSelector, segmentsSelector],
  (rows, segments) => {
    // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
    return rows.some((row) => {
      if (row.isContext() || !segments.has(row.id)) {
        return false;
      }

      const segment = segments.get(row.id);
      return segment.links > 0;
    }, false);
  },
);

export const getHierarchyLevel = createSelector([rowsSelector], (rows) =>
  // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
  rows.reduce((acc, row) => {
    if (!row.hidden) {
      return Math.max(acc, row.level);
    }

    return acc;
  }, 0),
);
