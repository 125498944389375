import { extractMessage } from '@peakon/error-reporter';

const asyncDispatch = async ({
  resource,
  dispatch,
  data = {},
  action,
  showNotification = true,
  ignoreNotFound,
  successNotification,
}: $TSFixMe) => {
  if (!action) {
    throw new Error('Missing action');
  }

  const dispatchPending = () => {
    dispatch({
      type: `${resource}_LOADING`,
      data,
    });
  };

  const dispatchSuccess = (response: $TSFixMe) => {
    const payload =
      Array.isArray(response) || (typeof response === 'string' && response)
        ? response
        : Object.assign({}, data, response);

    dispatch({
      type: `${resource}_SUCCESS`,
      data: payload,
    });

    if (successNotification) {
      dispatch({
        type: 'SYSTEM_NOTIFICATION_SHOW',
        data: {
          ...successNotification,
          type: 'success',
        },
      });
    }

    return payload;
  };

  const dispatchFailure = (error: $TSFixMe) => {
    const showError = !ignoreNotFound || !error || error.code !== 404;
    if (showNotification && showError) {
      dispatch({
        type: 'SYSTEM_NOTIFICATION_SHOW',
        data: {
          ...extractMessage(error),
          code: error.status,
          type: 'error',
          duration: 10000,
          dismissable: true,
        },
      });
    }

    dispatch({
      type: `${resource}_FAILED`,
      data: { ...data, error },
    });

    if (showError) {
      throw error;
    }
  };

  dispatchPending();

  try {
    const response = await action;
    return dispatchSuccess(response);
  } catch (err) {
    dispatchFailure(err);
  }
};

// eslint-disable-next-line import/no-default-export
export default asyncDispatch;
