import React from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
  EmptyStateFeatureText,
  EmptyStateFeatureValue,
  EmptyStateFeatureDriver,
  EmptyStateFeatureComments,
} from '@peakon/bedrock/illustrations/react/empty-state';
import { Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import SensitiveEmptyState from './SensitiveEmptyState';
import ExternalLink from '../../../../components/ExternalLink';

import styles from './styles.css';

const getContent = (delimiter: string, type?: string | null) => {
  switch (type) {
    case 'driver': {
      return {
        IconComponent: EmptyStateFeatureDriver,
        title: t('dashboard__comments_empty__title'),
        text: t('dashboard__comments_empty__text__confidential', {
          replace: { link_start: delimiter, link_end: delimiter },
        }),
      };
    }

    case 'text': {
      return {
        IconComponent: EmptyStateFeatureText,
        title: t('dashboard__inputs_empty__title'),
        text: t('dashboard__inputs_empty__text__confidential', {
          replace: { link_start: delimiter, link_end: delimiter },
        }),
      };
    }

    case 'value': {
      return {
        IconComponent: EmptyStateFeatureValue,
        title: t('dashboard__value_comments_empty__title'),
        text: t('dashboard__value_comments_empty__text__confidential', {
          replace: { link_start: delimiter, link_end: delimiter },
        }),
      };
    }

    case 'explore': {
      return {
        IconComponent: EmptyStateFeatureComments,
        title: t('comments__explore__empty__title'),
        text: t('comments__explore__empty__text'),
        genericConfidentialText: true,
      };
    }

    default: {
      return {
        IconComponent: EmptyStateFeatureComments,
        title: t('comments__empty__title'),
        text: t('comments__empty__text__confidential', {
          replace: { link_start: delimiter, link_end: delimiter },
        }),
      };
    }
  }
};

type EmptyStateProps = {
  isAdmin?: boolean;
  isFiltering?: boolean;
  isSensitive?: boolean;
  isSensitiveEnabled?: boolean;
  type?: string | null;
};

const EmptyState = ({
  isAdmin,
  isFiltering,
  isSensitive,
  isSensitiveEnabled,
  type,
}: EmptyStateProps) => {
  if (isFiltering && type !== 'explore') {
    return (
      <div className={styles.root}>
        <div>
          <EmptyStateFeatureComments />
        </div>
        <Typography.H4 className={styles.title}>
          {t('dashboard__input__no-inputs')}
        </Typography.H4>
      </div>
    );
  } else if (isSensitive) {
    return (
      <SensitiveEmptyState
        isAdmin={isAdmin}
        isSensitiveEnabled={isSensitiveEnabled}
      />
    );
  }

  const delimiter = uuidv4();

  const { text, title, IconComponent } = getContent(delimiter, type);

  const [prefix, message, suffix] = text.split(delimiter);

  return (
    <div className={styles.root}>
      <div>
        <IconComponent />
      </div>
      <Typography.H4 className={styles.title}>{title}</Typography.H4>
      <p className={styles.text}>
        {prefix}
        {message && (
          <ExternalLink
            href="https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/general/confidentiality-and-data-visibility/kyw1653644699757.html"
            target="_blank"
          >
            {message}
          </ExternalLink>
        )}
        {suffix}
      </p>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default EmptyState;
