import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

const authenticationResponseSchema = z.object({
  type: z.literal('bearer_tokens'),
  id: z.string(),
});

const getAuthenticationToken = async ({
  transferToken,
}: {
  transferToken: string;
}) => {
  const response = await api.post('/auth/transfer', null, {
    token: transferToken,
    desiredScopeGroup: 'regular',
  });

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  return validateData(response.data, authenticationResponseSchema, {
    errorMessagePrefix: 'authenticationResponseSchema',
  });
};

export const useAuthenticationTokenQuery = ({
  transferToken,
}: {
  transferToken: string;
}) => {
  return useQuery({
    queryFn: () => getAuthenticationToken({ transferToken }),
    queryKey: ['subdomainRedirect', 'authenticationToken', transferToken],
    select: (data) => data.id,
  });
};
