import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { NOT_INITIALIZED, SUCCESS } from './constants/states';

const schema = z.object({
  name: z.string(),
  state: z.enum([NOT_INITIALIZED, SUCCESS, 'LOADING']).optional(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Resource
  extends Record({
    name: undefined,
    state: NOT_INITIALIZED,
  })
  implements Schema
{
  name!: Schema['name'];
  state!: Schema['state'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Resource',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
