import React, { useEffect, useRef, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { Prompt } from 'react-router';

import { EditWriteIcon } from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { Heading3, Heading5 } from '@peakon/bedrock/react/typography';
import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import { RadioGroup } from '@peakon/components';
import { Badge } from '@peakon/shared/components/Badge/Badge';
import { t } from '@peakon/shared/features/i18next/t';

import { CustomValueSetting } from './CustomValueSetting';
import { updateSettings } from '../../../../actions/CompanyActions';
import {
  AllDataTime,
  EmployeeTime,
  FormerEmployeeTime,
} from '../../../../reducers/CompanySettingsTypes';
import { useAppDispatch, useAppSelector } from '../../../../utils/reduxHooks';
import ReviewChangesBar from '../../../ReviewChangesBar';

import styles from './styles.css';

export const anonymityValues = {
  link_start: `<a
      href="https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/general/confidentiality-and-data-visibility/kyw1653644699757.html"
      class="link-text"
      target="_blank"
      rel="noopener noreferrer"
    >`,
  link_end: `</a>`,
};

export const aggregationValues = {
  link_start: `<a
        href="https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/general/confidentiality-and-data-visibility/lgj1653656808448.html"
        class="link-text"
        target="_blank"
        rel="noopener noreferrer"
      >`,
  link_end: `</a>`,
};

// TODO: UNCOMMENT THESE LINES ONCE THE STRINGS ARE TRANSLATED
// const AGGREGATION_LINK = (
//   <ExternalLink
//     href="https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/general/confidentiality-and-data-visibility/lgj1653656808448.html"
//     target="_blank"
//   />
// );
// const ANONYMITY_LINK = (
//   <ExternalLink
//     href="https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/general/confidentiality-and-data-visibility/kyw1653644699757.html"
//     target="_blank"
//   />
// );

const anonymityDefaultLevelOptions = range(3, 11);
const significanceDefaultLevelOptions = range(3, 11);
const differenceDefaultLevelOptions = range(2, 8);

type Settings = {
  validityOverall: AllDataTime;
  validityDriver: EmployeeTime;
  validityEnded: FormerEmployeeTime;
  anonymityLevel: number;
  significanceLevel: number;
  differenceLevel: number;
} & Record<string, $TSFixMe>;

export const DashboardDataSettings = () => {
  const didMount = useRef(false);

  const dispatch = useAppDispatch();
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmittingChanges, setIsSubmittingChanges] = useState(false);
  const [isSaveConfirmationVisible, setIsSaveConfirmationVisible] =
    useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  const companySettings = useAppSelector((state) => state.company.settings);

  const hasValidityDriver = companySettings.get('validityDriver') > 0;

  const [settings, setSettings] = useState<Settings>({
    validityOverall: companySettings.get('validityOverall'),
    validityDriver: companySettings.get('validityDriver'),
    validityEnded: companySettings.get('validityEnded'),
    anonymityLevel: companySettings.get('anonymityLevel'),
    significanceLevel: companySettings.get('significanceLevel'),
    differenceLevel: companySettings.get('differenceLevel'),
  });

  const [originalSettings, setOriginalSettings] = useState<Settings>({
    validityOverall: companySettings.get('validityOverall'),
    validityDriver: companySettings.get('validityDriver'),
    validityEnded: companySettings.get('validityEnded'),
    anonymityLevel: companySettings.get('anonymityLevel'),
    significanceLevel: companySettings.get('significanceLevel'),
    differenceLevel: companySettings.get('differenceLevel'),
  });

  const aggregationHelpRef = useRef<null | HTMLDivElement>(null);
  const anonymityHelpRef = useRef<null | HTMLDivElement>(null);
  const saveConfirmationDescriptionRef = useRef<null | HTMLDivElement>(null);

  const [isSettingCustomAnonymityLevel, setIsSettingCustomAnonymityLevel] =
    useState(false);

  const [
    isSettingCustomSignificanceLevel,
    setIsSettingCustomSignificanceLevel,
  ] = useState(false);

  const [isSettingCustomDifferenceLevel, setIsSettingCustomDifferenceLevel] =
    useState(false);

  useEffect(() => {
    setIsDirty(!isEqual(settings, originalSettings));
  }, [settings, originalSettings]);

  useEffect(() => {
    if (aggregationHelpRef?.current) {
      aggregationHelpRef.current.innerHTML = t(
        'dashboard_data_settings__data_aggregation__info',
        { replace: aggregationValues },
      );
    }
  }, [aggregationHelpRef]);

  useEffect(() => {
    if (anonymityHelpRef?.current) {
      anonymityHelpRef.current.innerHTML = t(
        'dashboard_data_settings__anonymity__info__confidential',
        { replace: anonymityValues },
      );
    }
  }, [anonymityHelpRef]);

  useEffect(() => {
    if (!isSaveConfirmationVisible) {
      return;
    }
    if (saveConfirmationDescriptionRef?.current) {
      saveConfirmationDescriptionRef.current.innerHTML = t(
        'dashboard_data_settings__confirm_v2__confidential',
        {
          replace: {
            link_aggr_start: aggregationValues.link_start,
            link_anon_start: anonymityValues.link_start,
            link_aggr_end: aggregationValues.link_end,
            link_anon_end: anonymityValues.link_end,
          },
        },
      );
    }
  }, [saveConfirmationDescriptionRef, isSaveConfirmationVisible]);

  useEffect(() => {
    // do not update significanceLevel and differenceLevel on the first load since some customers
    // can have wrong data and we don't want to show save confirmation when they load the page
    if (didMount.current && !isCancelling) {
      setSettings({
        ...settings,
        significanceLevel: Math.min(
          settings.significanceLevel,
          settings.anonymityLevel,
        ),
        differenceLevel: Math.min(
          settings.differenceLevel,
          settings.anonymityLevel,
        ),
      });
    } else {
      didMount.current = true;
      setIsCancelling(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.anonymityLevel]);

  useEffect(() => {
    if (isCancelling) {
      setIsDirty(false);
      setSettings(cloneDeep(originalSettings));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelling]);

  const handleSetCustomAnonymityLevel = (level: number) => {
    setSettings({
      ...settings,
      anonymityLevel: level,
      significanceLevel: Math.min(settings.significanceLevel, level),
      differenceLevel: Math.min(settings.differenceLevel, level),
    });

    setIsSettingCustomAnonymityLevel(false);
  };

  const handleSetCustomSignificanceLevel = (level: number) => {
    setSettings({ ...settings, significanceLevel: level });
    setIsSettingCustomSignificanceLevel(false);
  };

  const handleSetCustomDifferenceLevel = (level: number) => {
    setSettings({ ...settings, differenceLevel: level });
    setIsSettingCustomDifferenceLevel(false);
  };

  const handleCancelChanges = () => {
    setIsCancelling(true);
  };

  const handleSubmitChanges = async () => {
    if (!isSubmittingChanges) {
      setIsSubmittingChanges(true);

      try {
        const updatedSettings = getSettingsDiff(settings, originalSettings);
        await dispatch(updateSettings(updatedSettings));

        setIsDirty(false);
        setOriginalSettings(cloneDeep(settings));
        setIsSaveConfirmationVisible(false);
      } catch {
      } finally {
        setIsSubmittingChanges(false);
      }
    }
  };

  const getSettingsDiff = (
    currentSettings: Settings,
    previousSettings: Settings,
  ): Partial<Settings> => {
    const settingsDiff: Partial<Settings> = {};

    each(currentSettings, (value, setting) => {
      if (
        value !== null &&
        value !== undefined &&
        value !== previousSettings[setting]
      ) {
        settingsDiff[setting] = value;
      }
    });

    return settingsDiff;
  };

  function dataSettingsForFormerEmployee() {
    return (
      <div className={styles.section}>
        <div className={styles.title}>
          <Heading5 level={3}>
            {t('dashboard_data_settings__time_former__title')}
          </Heading5>
        </div>

        <div className={styles.description}>
          {t('dashboard_data_settings__time_former__text')}
        </div>

        <div
          className={styles.options}
          data-test-id="inclusion-of-former-employees"
        >
          <RadioGroup
            orientation="horizontal"
            variant="card"
            value={settings.validityEnded}
            onChange={(value) =>
              // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
              setSettings({ ...settings, validityEnded: value })
            }
          >
            <RadioGroup.Button value={FormerEmployeeTime.oneMonth}>
              {t('dashboard_data_settings__1_month')}
            </RadioGroup.Button>
            <RadioGroup.Button value={FormerEmployeeTime.threeMonths}>
              <div className={styles.badgeTile}>
                <span>
                  <Badge
                    label={t('dashboard_data_settings__default')}
                    variant="primary"
                  />
                </span>

                {t('dashboard_data_settings__3_months')}
              </div>
            </RadioGroup.Button>

            <RadioGroup.Button value={FormerEmployeeTime.sixMonths}>
              {t('dashboard_data_settings__6_months')}
            </RadioGroup.Button>
          </RadioGroup>
        </div>
      </div>
    );
  }

  function getScaleRecommendations() {
    return (
      <div className={styles.scaleRecommendations}>
        <span className={styles.scaleHelpSmall}>
          {t('company__segment-size__small')}
        </span>
        <span className={styles.scaleHelpRecommended}>
          {t('company__segment-size__recommended')}
        </span>
        <span className={styles.scaleHelpLarge}>
          {t('company__segment-size__large')}
        </span>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.sectionTitle}>
          <Heading3 level={2}>
            {t('dashboard_data_settings__data_aggregation')}
          </Heading3>
        </div>

        <div className={styles.description}>
          {/* TODO: REPLACE STRING ONCE TRANSLATED */}
          {/* {t('dashboard_data_settings__data_aggregation__info__v2', {
            components: {
              link: AGGREGATION_LINK,
            },
          })} */}
          <div ref={aggregationHelpRef} />
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Heading5 level={3}>
                {t('dashboard_data_settings__time__title')}
              </Heading5>
            </div>

            <div className={styles.description}>
              {t('dashboard_data_settings__time__text')}
            </div>
            <div className={styles.options} data-test-id="aggregation-period">
              <RadioGroup
                orientation="horizontal"
                variant="card"
                value={settings.validityOverall}
                onChange={(value) =>
                  // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
                  setSettings({ ...settings, validityOverall: value })
                }
              >
                <RadioGroup.Button value={AllDataTime.threeMonths}>
                  {t('dashboard_data_settings__3_months')}
                </RadioGroup.Button>

                <RadioGroup.Button value={AllDataTime.sixMonths}>
                  {t('dashboard_data_settings__6_months')}
                </RadioGroup.Button>

                <RadioGroup.Button value={AllDataTime.oneYear}>
                  <div className={styles.badgeTile}>
                    <span>
                      <Badge
                        label={t('dashboard_data_settings__default')}
                        variant="primary"
                      />
                    </span>
                    {t('dashboard_data_settings__1_year')}
                  </div>
                </RadioGroup.Button>
              </RadioGroup>
            </div>
          </div>

          {hasValidityDriver && (
            <div className={styles.section}>
              <div className={styles.title}>
                <Heading5 level={3}>
                  {t('dashboard_data_settings__time_employee__title')}
                </Heading5>
              </div>

              <div className={styles.description}>
                {t('dashboard_data_settings__time_employee__text')}
              </div>

              <div className={styles.options}>
                <RadioGroup
                  orientation="horizontal"
                  variant="card"
                  value={settings.validityDriver}
                  onChange={(value) =>
                    // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
                    setSettings({ ...settings, validityDriver: value })
                  }
                >
                  <RadioGroup.Button
                    id="validityDriver-0"
                    value={EmployeeTime.differenceOff}
                  >
                    <div className={styles.badgeTile}>
                      <span>
                        <Badge
                          label={t('dashboard_data_settings__default')}
                          variant="primary"
                        />
                      </span>

                      {t('dashboard_data_settings__difference_level__off')}
                    </div>
                  </RadioGroup.Button>
                  <RadioGroup.Button
                    id="validityDriver-4"
                    value={EmployeeTime.oneMonth}
                  >
                    {t('dashboard_data_settings__1_month')}
                  </RadioGroup.Button>

                  <RadioGroup.Button
                    id="validityDriver-12"
                    value={EmployeeTime.threeMonths}
                  >
                    {t('dashboard_data_settings__3_months')}
                  </RadioGroup.Button>

                  <RadioGroup.Button
                    id="validityDriver-26"
                    value={EmployeeTime.sixMonths}
                  >
                    {t('dashboard_data_settings__6_months')}
                  </RadioGroup.Button>
                </RadioGroup>
              </div>
            </div>
          )}

          {!hasValidityDriver && dataSettingsForFormerEmployee()}
        </div>

        {hasValidityDriver && (
          <div className={styles.row}>{dataSettingsForFormerEmployee()}</div>
        )}
      </div>

      <div className={styles.header}>
        <div className={styles.sectionTitle}>
          <Heading3 level={2}>
            {t('dashboard_data_settings__anonymity__confidential')}
          </Heading3>
        </div>

        {/* TODO: REPLACE STRING ONCE TRANSLATED */}
        {/* {t('dashboard_data_settings__anonymity__info__confidential__v2', {
          components: {
            link: ANONYMITY_LINK,
          },
        })} */}

        <div className={styles.description}>
          <div ref={anonymityHelpRef} />
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.section}>
            <div className={styles.title} id="numberOfResponses">
              <Heading5 level={3}>{t('company__segment-size')}</Heading5>
            </div>

            <div
              className={styles.description}
              id="number-of-responses-scale-component-accesible-description"
            >
              {t('company__segment-size__explanation__confidential')}
            </div>

            {!isSettingCustomAnonymityLevel && (
              <React.Fragment>
                <div className={styles.scale} data-test-id="anonymity-level">
                  <VisuallyHidden>
                    <p id="number-of-responses-scale-component-accesible-input-description">
                      {t(
                        'company__segment-size__explanation__confidential__input_accesible_description',
                      )}
                    </p>
                  </VisuallyHidden>
                  <RadioGroup
                    aria-label={t('company__segment-size')}
                    aria-describedby="number-of-responses-scale-component-accesible-description"
                    orientation="horizontal"
                    variant="card"
                    value={settings.anonymityLevel}
                    onChange={(value) =>
                      // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
                      setSettings({ ...settings, anonymityLevel: value })
                    }
                  >
                    {anonymityDefaultLevelOptions.map((level, index) => (
                      <RadioGroup.Button
                        key={index}
                        value={level}
                        aria-describedby="number-of-responses-scale-component-accesible-input-description"
                      >
                        {level}
                      </RadioGroup.Button>
                    ))}

                    {!anonymityDefaultLevelOptions.includes(
                      settings.anonymityLevel,
                    ) && (
                      <RadioGroup.Button value={settings.anonymityLevel}>
                        {settings.anonymityLevel}
                      </RadioGroup.Button>
                    )}
                  </RadioGroup>
                  <Button
                    variant="secondary"
                    onClick={() => setIsSettingCustomAnonymityLevel(true)}
                    data-test-id="custom-anonymity-level"
                  >
                    <EditWriteIcon />
                  </Button>
                </div>
                {getScaleRecommendations()}
              </React.Fragment>
            )}

            {isSettingCustomAnonymityLevel && (
              <CustomValueSetting
                initial={settings.anonymityLevel}
                min={3}
                onCancel={() => setIsSettingCustomAnonymityLevel(false)}
                onSet={handleSetCustomAnonymityLevel}
                id="customNumberOfResponse"
                aria-describedby="numberOfResponses"
                data-test-id="custom-anonymity-level"
              />
            )}
          </div>

          <div className={styles.section}>
            <div className={styles.title} id="numberOfScores">
              <Heading5 level={3}>{t('company__significance_level')}</Heading5>
            </div>

            <div
              className={styles.description}
              id="number-of-scores-per-question-scale-component-accessible-description"
            >
              {t('company__significance_level__explanation__confidential')}
            </div>

            {!isSettingCustomSignificanceLevel && (
              <React.Fragment>
                <div className={styles.scale} data-test-id="number-of-scores">
                  <VisuallyHidden>
                    <p id="number-of-scores-scale-component-accesible-input-description">
                      {t(
                        'company__significance_level__explanation__confidential__input_accesible_description',
                      )}
                    </p>
                  </VisuallyHidden>
                  <RadioGroup
                    aria-label={t('company__significance_level')}
                    aria-describedby="number-of-scores-per-question-scale-component-accessible-description"
                    orientation="horizontal"
                    variant="card"
                    value={settings.significanceLevel}
                    onChange={(value) =>
                      // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
                      setSettings({ ...settings, significanceLevel: value })
                    }
                  >
                    {significanceDefaultLevelOptions.map((level, index) => (
                      <RadioGroup.Button
                        key={index}
                        value={level}
                        aria-describedby="number-of-scores-scale-component-accesible-input-description"
                        disabled={level > settings.anonymityLevel}
                      >
                        {level}
                      </RadioGroup.Button>
                    ))}

                    {!significanceDefaultLevelOptions.includes(
                      settings.significanceLevel,
                    ) && (
                      <RadioGroup.Button value={settings.significanceLevel}>
                        {settings.significanceLevel}
                      </RadioGroup.Button>
                    )}
                  </RadioGroup>
                  <Button
                    variant="secondary"
                    onClick={() => setIsSettingCustomSignificanceLevel(true)}
                    data-test-id="custom-significance-level"
                  >
                    <EditWriteIcon />
                  </Button>
                </div>
                {getScaleRecommendations()}
              </React.Fragment>
            )}

            {isSettingCustomSignificanceLevel && (
              <CustomValueSetting
                initial={settings.significanceLevel}
                min={3}
                max={settings.anonymityLevel}
                onCancel={() => setIsSettingCustomSignificanceLevel(false)}
                onSet={handleSetCustomSignificanceLevel}
                id="customNumberOfScores"
                aria-describedby="numberOfScores"
                data-test-id="custom-significance-level"
              />
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.section}>
            <div className={styles.title} id="numberOfUniqueEmployees">
              <Heading5 level={3}>
                {t(
                  'dashboard_data_settings__difference_level__title__confidential',
                )}
              </Heading5>
            </div>

            <div
              className={styles.description}
              id="unique-employees-scale-component-accesible-description"
            >
              {t('dashboard_data_settings__difference_level__text')}
            </div>

            {!isSettingCustomDifferenceLevel && (
              <React.Fragment>
                <div className={styles.scale}>
                  <VisuallyHidden>
                    <p id="unique-employees-scale-component-accesible-input-description">
                      {t(
                        'dashboard_data_settings__difference_level__input_accesible_description',
                      )}
                    </p>
                  </VisuallyHidden>
                  <RadioGroup
                    aria-label={t(
                      'dashboard_data_settings__difference_level__title__confidential',
                    )}
                    aria-describedby="unique-employees-scale-component-accesible-description"
                    orientation="horizontal"
                    variant="card"
                    value={settings.differenceLevel}
                    onChange={(value) =>
                      // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
                      setSettings({ ...settings, differenceLevel: value })
                    }
                  >
                    <RadioGroup.Button
                      value={0}
                      aria-describedby="unique-employees-scale-component-accesible-input-description"
                    >
                      {t('dashboard_data_settings__difference_level__off')}
                    </RadioGroup.Button>
                    {differenceDefaultLevelOptions.map((level, index) => (
                      <RadioGroup.Button
                        key={index}
                        value={level}
                        disabled={level > settings.anonymityLevel}
                        aria-describedby="unique-employees-scale-component-accesible-input-description"
                      >
                        {level}
                      </RadioGroup.Button>
                    ))}

                    {!differenceDefaultLevelOptions.includes(
                      settings.differenceLevel,
                    ) &&
                      settings.differenceLevel !== 0 && (
                        <RadioGroup.Button value={settings.differenceLevel}>
                          {settings.differenceLevel}
                        </RadioGroup.Button>
                      )}
                  </RadioGroup>
                  <Button
                    variant="secondary"
                    onClick={() => setIsSettingCustomDifferenceLevel(true)}
                  >
                    <EditWriteIcon />
                  </Button>
                </div>
                {getScaleRecommendations()}
              </React.Fragment>
            )}

            {isSettingCustomDifferenceLevel && (
              <CustomValueSetting
                initial={settings.differenceLevel}
                min={2}
                max={settings.anonymityLevel}
                onCancel={() => setIsSettingCustomDifferenceLevel(false)}
                onSet={handleSetCustomDifferenceLevel}
                id="customNumberOfUniqueEmployees"
                aria-describedby="numberOfUniqueEmployees"
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        data-test-id="data-settings-confirmation-modal"
        closeLabel={t('cancel')}
        heading={t('dashboard_data_settings__confirm__title')}
        onDismiss={() => setIsSaveConfirmationVisible(false)}
        open={isSaveConfirmationVisible}
      >
        <Modal.Content>
          {/* TODO: REPLACE STRING ONCE TRANSLATED */}
          {/* {t('dashboard_data_settings__confirm_v2__confidential__v2', {
            components: {
              link_aggr: AGGREGATION_LINK,
              link_anon: ANONYMITY_LINK,
            },
          })} */}
          <div ref={saveConfirmationDescriptionRef} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => setIsSaveConfirmationVisible(false)}
            variant="secondary"
          >
            {t('cancel')}
          </Button>

          <Button
            onClick={handleSubmitChanges}
            variant="primary"
            busy={isSubmittingChanges}
          >
            {t('question__confirm')}
          </Button>
        </Modal.Actions>
      </Modal>

      <ReviewChangesBar
        isDirty={isDirty}
        onConfirm={() => setIsSaveConfirmationVisible(true)}
        onCancel={handleCancelChanges}
        confirmLabel={t('save')}
      />
      <Prompt
        when={isDirty}
        message={() => t('schedules__form__unsaved_changes')}
      />
    </div>
  );
};
