import React, { useState } from 'react';

import classnames from 'classnames';

import { NavigationBasicNavigationChevronRightIcon as ArrowRight } from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { t } from '@peakon/shared/features/i18next/t';
import { useShellContext } from '@peakon/shell';

import styles from './styles.css';

type Props = {
  confirmLabel: string;
  disabled?: boolean;
  isBulkEditor?: boolean;
  isReviewing?: boolean;
  isDirty?: boolean;
  onCancel: () => void;
} & (
  | {
      isSubmitButton: true;
      onConfirm?: never;
    }
  | {
      isSubmitButton?: false;
      onConfirm: () => void;
    }
);

const ReviewChangesBar = ({
  confirmLabel,
  disabled,
  isBulkEditor,
  isDirty,
  isReviewing,
  onCancel,
  onConfirm,
  isSubmitButton = false,
}: Props) => {
  const { isConsultant, hasNewVersion, hasOutage } = useShellContext();
  const [isLoading, setIsLoading] = useState(false);
  const handleSave = () => {
    try {
      setIsLoading(true);
      onConfirm?.();
    } finally {
      setIsLoading(false);
    }
  };
  const ctaProps: {
    type: 'submit' | 'button';
    onClick?: () => void;
    busy?: boolean;
  } = isSubmitButton
    ? {
        type: 'submit',
      }
    : {
        type: 'button',
        onClick: handleSave,
        busy: isLoading,
      };
  return (
    <div
      className={classnames(styles.container, {
        [styles.dirty]: isDirty,
        [styles.elevated]: isConsultant || hasNewVersion || hasOutage,
      })}
      data-test-id="review-changes-bar"
      // @ts-expect-error TS(2322): Type '{ children: Element[]; className: string; "d... Remove this comment to see the full error message
      inert={!isDirty ? '' : null}
    >
      <Button
        variant="primary"
        disabled={disabled}
        data-test-id="save-employee"
        {...ctaProps}
      >
        {isBulkEditor && !isReviewing ? (
          <span className={styles.reviewButton}>
            {t('employees__bulk_edit__review_changes')}
            <span className={styles.arrow}>
              <ArrowRight />
            </span>
          </span>
        ) : (
          confirmLabel
        )}
      </Button>
      <Button
        onClick={onCancel}
        data-test-id="cancel-changes"
        variant="secondary"
        type="button"
      >
        {t('cancel')}
      </Button>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ReviewChangesBar;
