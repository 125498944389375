import { useMutation } from '@tanstack/react-query';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import {
  catchHandler,
  showSuccessNotification,
} from '../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../utils/reduxHooks';

type UpdateContextParams = {
  onSuccess: () => void;
};

type MutateFnParams = {
  id: string;
  access: string;
  isPublic: boolean;
};

const updateContext = async ({
  id,
  access,
  isPublic,
}: MutateFnParams): Promise<$TSFixMe> =>
  await api.put(`/shares/contexts/${id}`, null, {
    data: {
      type: 'shares',
      attributes: {
        access,
        isPublic,
      },
    },
  });

export const useUpdateContextMutation = ({
  onSuccess,
}: UpdateContextParams) => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: updateContext,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: () => {
      onSuccess();
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t('share_modal__sharing_updated'),
        }),
      );
    },
  });
};
