import React, { Dispatch, Fragment, SetStateAction } from 'react';

import classNames from 'classnames';

import { EditCloseIcon } from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  managerName?: string | null;
  setShowProxyViewModal?: Dispatch<SetStateAction<boolean>>;
  setCloseProxyViewModal?: Dispatch<SetStateAction<boolean>>;
};

export const ProxyViewBar = ({
  managerName,
  setShowProxyViewModal,
  setCloseProxyViewModal,
}: Props) => {
  const handleSwitchView = () => {
    setShowProxyViewModal && setShowProxyViewModal(true);
  };

  const handleCloseView = () => {
    setCloseProxyViewModal && setCloseProxyViewModal(true);
  };

  return (
    <div
      className={classNames(styles.root, { [styles.loading]: !managerName })}
      data-test-id="proxy-view-bar"
    >
      {managerName ? (
        <Fragment>
          <span className={styles.text}>
            {t('view_as_manager__viewing_as_v2', {
              replace: { managerName },
              components: {
                bold: <strong />,
              },
            })}
          </span>
          <Button variant="primary" onClick={handleSwitchView}>
            {t('view_as_manager__switch_view')}
          </Button>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <div className={styles.closeView}>
        <Button
          variant="secondary"
          icon={<EditCloseIcon />}
          iconPlacement="end"
          onClick={handleCloseView}
        >
          {t('view_as_manager__end_view_v2')}
        </Button>
      </div>
    </div>
  );
};
