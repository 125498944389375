import { Record, OrderedMap } from 'immutable';
import merge from 'lodash/merge';
// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  list: z.any(),
});
type Schema = z.infer<typeof schema>;

class LegacyPagination
  extends Record({
    id: undefined,
    isLoading: false,
    isContinuation: false,
    continuation: undefined,
    links: {},
    query: undefined,
    list: OrderedMap(),
    pages: {},
    total: 0,
    nextUrl: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  isLoading!: boolean;
  isContinuation!: boolean;
  continuation?: $TSFixMe;
  links!: object;
  query?: Map<string, string | Map<string, string>>;
  list!: OrderedMap<string, unknown>;
  pages!: {
    first: number;
    last: number;
    next: number;
    prev: number;
    self: number;
  };

  total!: number;
  nextUrl?: string;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'LegacyPagination',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'LegacyPagination',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  getResults() {
    return this.list?.toList();
  }

  getPrimaryButtonUrl() {
    return this.getUrl(this.pages?.next);
  }

  getUrl(page: $TSFixMe) {
    if (this.isContinuation) {
      return qs.stringify({
        ...this.query,
      });
    }

    return qs.stringify({
      ...this.query,
      page,
    });
  }

  isFirst() {
    return this.pages?.self === this.pages?.first;
  }

  hasResults() {
    return !this.list?.isEmpty();
  }

  // @ts-expect-error TS(2416): Property 'first' in type 'LegacyPagination' is not... Remove this comment to see the full error message
  first(params: $TSFixMe) {
    if (this.isContinuation) {
      return {
        params: merge(this.query, params),
      };
    }

    return {
      params: merge(this.query, params),
      page: 1,
    };
  }

  next() {
    if (this.isContinuation) {
      return {
        params: this.query,
        continuation: this.continuation,
      };
    }

    return {
      params: this.query,
      page: this.pages?.next,
    };
  }

  hasNext() {
    if (this.pages) {
      return (
        typeof this.pages.next !== 'undefined' ||
        this.pages.self < this.pages.last
      );
    }
    return false;
  }

  hasPrevious() {
    if (this.pages) {
      return (
        typeof this.pages.prev !== 'undefined' ||
        this.pages.self > this.pages.first
      );
    }
    return false;
  }

  isLast() {
    return this.pages?.self === this.pages?.last;
  }
}

// eslint-disable-next-line import/no-default-export
export default LegacyPagination;
