import { Record, fromJS, List } from 'immutable';
import get from 'lodash/get';
import { z } from 'zod';

import {
  ReasonStatus,
  ScopeGroup,
  SessionData,
  TwoFactorStatus,
} from '@peakon/shared/schemas/api/sessions';
import hasRight from '@peakon/shared/utils/hasRight';
import { Right } from '@peakon/shared/utils/rights/types';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  isAuthenticated: z.boolean().optional(),
  accountId: z.string().optional(),
});
const testingSchema = schema.extend({
  agent: z.any(),
  lastUsedAt: z.any(),
  mixpanel: z.any(),
  employeeid: z.string().optional(),
  scopeGroup: z.any(),
  twofactorStatus: z.any(),
  address: z.any(),
  location: z.any(),
  reasonStatus: z.any(),
  rights: z.any(),
  companyid: z.string().optional(),
  current: z.any(),
  employeeAccountid: z.string().optional(),
  locale: z.any(),
  createdAt: z.any(),
  features: z.any(),
  gainsight: z.any(),
  rawAttributes: z.any(),
  accountid: z.string().optional(),
  employeeId: z.string().optional(),
  companyId: z.number().optional(),
  employeeAccountId: z.string().optional(),
});

type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Session
  extends Record({
    isAuthenticated: false,
    isLoading: false,
    isShare: false,
    accountId: undefined,
    companyId: undefined,
    employeeId: undefined,
    employeeAccountId: undefined,
    createdAt: undefined,
    address: undefined,
    twofactorStatus: undefined,
    rawAttributes: undefined,
    reasonStatus: undefined,
    scopeGroup: undefined,
    rights: List(),
    agent: undefined,
    features: List(),
  })
  implements Schema
{
  isAuthenticated!: Schema['isAuthenticated'];
  isLoading!: boolean;
  isShare!: boolean;
  accountId?: Schema['accountId'];
  companyId?: string;
  employeeId?: string;
  employeeAccountId?: string;
  createdAt?: string;
  address?: string;
  twofactorStatus?: TwoFactorStatus;
  rawAttributes?: SessionData & { toJS: () => SessionData };
  reasonStatus?: ReasonStatus;
  scopeGroup?: ScopeGroup;
  rights!: List<Right>;
  agent?: string;
  features!: List<string>;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Session',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'Session',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
    const { attributes, relationships: { account, company, employee } = {} } =
      data;

    return new Session(
      fromJS({
        ...attributes,
        isAuthenticated: true,
        accountId: get(account, 'id'),
        rawAttributes: data,
        companyId: Number(get(company, 'id')),
        employeeId: employee ? employee.id : null,
        employeeAccountId: get(employee, 'relationships.account.id'),
      }),
    );
  }

  hasRight(right: Right[] | Right) {
    return hasRight(this.rights.toArray(), right);
  }

  get raw() {
    return this.rawAttributes?.toJS();
  }

  get isOtherCompany() {
    return this.employeeId === null;
  }
}
