import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import api from '@peakon/shared/utils/api';

import { TranslationsType } from './schema';

type ValueFormData = {
  attributes: {
    name: string;
    description: string;
    translations: TranslationsType;
  };
  relationships: {
    question: {
      data: {
        type: 'questions';
        attributes: {
          status?: string;
        };
        relationships: {
          excludedSegments?: { id: string; type: 'segments' } | null;
          includedSegments?: { id: string; type: 'segments' } | null;
        };
      };
    };
  };
};

export type CreateValueProps = ValueFormData & {
  isCreate: true;
};

export type UpdateValueProps = ValueFormData & {
  valueId?: string;
  isCreate: false;
};

const createOrUpdateValue = (value: CreateValueProps | UpdateValueProps) => {
  if (!value.isCreate) {
    return api.patch(`/values/${value.valueId}`, null, {
      data: {
        type: 'values',
        id: value.valueId,
        attributes: value.attributes,
        relationships: value.relationships,
      },
    });
  }

  return api.post('/values', null, {
    data: {
      type: 'values',
      attributes: value.attributes,
      relationships: value.relationships,
    },
  });
};

const useCreateOrUpdateValue = ({
  onSuccess,
  onError,
}: UseMutationOptions<
  unknown,
  Error,
  CreateValueProps | UpdateValueProps,
  unknown
>) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (value) => createOrUpdateValue(value),
    onError,
    onSuccess,
    onSettled: async () => {
      await client.resetQueries(['admin_values']);
    },
  });
};

// eslint-disable-next-line import/no-default-export
export default useCreateOrUpdateValue;
