import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  from: z.number().nullable(),
  to: z.number().nullable(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class AttributeRange
  extends Record({
    id: undefined,
    autoFocus: false,
    from: undefined,
    invalid: false,
    to: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  autoFocus!: boolean;
  from!: number | null;
  invalid?: boolean;
  to?: number | null;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'AttributeRange',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'AttributeRange',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
