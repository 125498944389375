import { match } from 'path-to-regexp';
// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';

import { getAdministrationMenu } from './menus/administration';
import { getAnalysisMenu } from './menus/analysis';
import { getEmployeeDashboardMenu } from './menus/employeeDashboard';
import { getImproveMenu } from './menus/improve';
import { getInsightsMenu } from './menus/insights';
import { getProfileMenu } from './menus/profile';
import { GetPathFn, RawNavigation } from './types';

export const getPath: GetPathFn = ({ path, defaultQueryParams = {} }) => {
  return function ({ queryParams, includeParams = true } = {}) {
    if (!includeParams) {
      return path;
    }

    const currentParams = new URLSearchParams(window.location.search);
    const contextId = currentParams.get('contextId');

    const paramsToPreserve: {
      contextId?: string | qs.ParsedQs | string[] | qs.ParsedQs[];
    } = {};

    if (contextId) {
      paramsToPreserve.contextId = contextId;
    }

    const queryParamsResult = {
      ...defaultQueryParams,
      ...paramsToPreserve,
      ...queryParams,
    };

    if (Object.keys(queryParamsResult).length === 0) {
      return path;
    }

    return `${path}?${qs.stringify(queryParamsResult)}`;
  };
};

export const isActive = ({ matchingPaths }: { matchingPaths: string[] }) => {
  for (const matchingPath of matchingPaths) {
    const checkMatch = match(matchingPath, {
      decode: decodeURIComponent,
    });

    const isMatch = checkMatch(window.location.pathname);
    if (isMatch !== false) {
      return true;
    }
  }
  return false;
};

export const getDefaultQueryParams = (contextId?: string) => {
  return { contextId };
};

export const getRawNavigation = ({
  rights,
  categoryGroup,
  categoryGroupWithDrivers,
  companyName,
  contextId,
  contextLevel,
  contextIsDirect: contextIsDirect_,
  screenWidth,
  isConsultant: isConsultant_,
  isProxyViewing = false,
  features,
}: {
  rights: {
    [key: string]: $TSFixMe;
  };
  categoryGroup?: string | null;
  categoryGroupWithDrivers?: string | null;
  companyName: string;
  contextId?: string;
  contextLevel?: string;
  contextIsDirect?: boolean;
  screenWidth: number;
  isConsultant?: boolean;
  isProxyViewing?: boolean;
  features: string[];
  isTest?: boolean;
}): RawNavigation => {
  const isConsultant = Boolean(isConsultant_);
  const contextIsDirect = Boolean(contextIsDirect_);

  return {
    profile: getProfileMenu({ isConsultant, isProxyViewing }),
    insights: getInsightsMenu({
      rights,
      contextId,
      contextLevel,
      contextIsDirect,
      features,
    }),
    analysis: getAnalysisMenu({
      rights,
      contextId,
      categoryGroupWithDrivers,
      categoryGroup,
      screenWidth,
      features,
    }),
    improve: getImproveMenu({ rights, companyName, contextId }),
    employeeDashboard: getEmployeeDashboardMenu({ rights, features }),
    administration: getAdministrationMenu({
      rights,
      contextId,
      screenWidth,
      features,
    }),
  };
};
