import React, {
  Fragment,
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import classnames from 'classnames';

import { ViewAcknowledgeIcon as Checkmark } from '@peakon/bedrock/icons/system';
import { Menu, useMenuContext } from '@peakon/components';
import Acknowledgement from '@peakon/records/AcknowledgementRecord';
import { AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import { t } from '@peakon/shared/features/i18next/t';

import AcknowledgementLabel from '../AcknowledgementLabel';
import AcknowledgementMenu from '../AcknowledgementMenu';
import AcknowledgementModal from '../AcknowledgementModal';

import styles from './styles.css';

const AcknowledgementTarget = forwardRef((props, ref) => {
  const { isOpen } = useMenuContext();

  return (
    <div
      // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      ref={ref}
      className={classnames(styles.root, {
        [styles.active]: isOpen,
      })}
      data-test-id="acknowledge-button"
      {...props}
    >
      <div className={styles.checkmark}>
        <Checkmark className={styles.checkmarkIcon} />
      </div>
      <span>{t('acknowledge__mark_as_read')}</span>
    </div>
  );
});

AcknowledgementTarget.displayName = 'AcknowledgementTarget';

type AcknowledgementDropdownProps = {
  acknowledgement?: Acknowledgement | null;
  onAcknowledge?: $TSFixMeFunction;
  shouldConfirm?: boolean;
  timeout?: number;
};

const AcknowledgementDropdown = ({
  acknowledgement,
  onAcknowledge,
  shouldConfirm,
  timeout = 600,
}: AcknowledgementDropdownProps) => {
  const [isOpen, setOpen] = useState(false);
  const [activeType, setActiveType] = useState<AcknowledgementType | null>(
    null,
  );

  const mouseEnterRef = useRef();
  const mouseEnterTimeoutRef = useRef();

  const timeoutRef = useRef();

  const onKeyDown = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.document.addEventListener('keydown', onKeyDown);
    } else {
      window.document.removeEventListener('keydown', onKeyDown);
    }

    return () => {
      window.document.removeEventListener('keydown', onKeyDown);
    };
  }, [isOpen, onKeyDown]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(mouseEnterTimeoutRef.current);
    };
  }, []);

  const onConfirm = useCallback(
    async ({ optOut }: { optOut?: boolean } = {}) => {
      try {
        await onAcknowledge?.(activeType, {
          optOutConfirm: optOut,
        });
      } finally {
        setActiveType(null);
      }
    },
    [onAcknowledge, activeType],
  );

  useEffect(() => {
    if (activeType && !shouldConfirm) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
      onConfirm();
    }
  }, [activeType, shouldConfirm, onConfirm]);

  const onShowMenu = () => {
    // @ts-expect-error TS(2322): Type 'true' is not assignable to type 'undefined'.
    mouseEnterRef.current = true;

    // @ts-expect-error TS(2322): Type 'Timeout' is not assignable to type 'undefine... Remove this comment to see the full error message
    mouseEnterTimeoutRef.current = setTimeout(() => {
      // @ts-expect-error TS(2322): Type 'false' is not assignable to type 'undefined'... Remove this comment to see the full error message
      mouseEnterRef.current = false;
    }, 100);

    clearTimeout(timeoutRef.current);

    setOpen(true);
  };

  const onHideMenu = () => {
    clearTimeout(timeoutRef.current);

    if (activeType) {
      return;
    }

    // @ts-expect-error TS(2322): Type 'Timeout' is not assignable to type 'undefine... Remove this comment to see the full error message
    timeoutRef.current = setTimeout(() => {
      clearTimeout(mouseEnterTimeoutRef.current);
      // @ts-expect-error TS(2322): Type 'false' is not assignable to type 'undefined'... Remove this comment to see the full error message
      mouseEnterRef.current = false;

      setOpen(false);
    }, timeout);
  };

  if (acknowledgement) {
    return <AcknowledgementLabel role="alert" type={acknowledgement.type} />;
  }

  return (
    <Fragment>
      <Menu
        closeOnSelection={false}
        isOpen={isOpen}
        onSelect={(value) => {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          setActiveType(value);
        }}
        onStateChange={(changes) => {
          if (changes.hasOwnProperty('isOpen')) {
            // @ts-expect-error TS(2345): Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
            setOpen(changes.isOpen);
          }
        }}
      >
        <Menu.Target
          aria-expanded={isOpen}
          aria-label={t('acknowledge__mark_as_read')}
          onClick={(e) => {
            // when we have just opened via mouse enter, ignore
            if (isOpen && mouseEnterRef.current) {
              e.preventDefault();
            }
          }}
          onMouseEnter={onShowMenu}
          onMouseLeave={onHideMenu}
        >
          <AcknowledgementTarget />
        </Menu.Target>
        <AcknowledgementMenu
          onMouseEnter={onShowMenu}
          onMouseLeave={onHideMenu}
          activeType={activeType}
        />
      </Menu>
      {activeType && shouldConfirm && (
        <AcknowledgementModal
          onCancel={() => {
            setActiveType(null);
            setOpen(false);
          }}
          onConfirm={onConfirm}
          type={activeType}
        />
      )}
    </Fragment>
  );
};

// eslint-disable-next-line import/no-default-export
export default AcknowledgementDropdown;
