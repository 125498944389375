import { z } from 'zod';

import { AppError } from '@peakon/error-reporter';
import { createTranslationMap } from '@peakon/shared/features/i18next/createTranslationMap';
import { t, translatedStringSchema } from '@peakon/shared/features/i18next/t';

const FILE_UPLOAD_ERRORS = ['file__type__invalid', 'file__too-large'] as const;

const apiResponseErrorSchema = z.object({
  status: z.number(),
  message: z.string().optional(),
  body: z.string().optional(),
});

type ApiResponseError = z.infer<typeof apiResponseErrorSchema>;

const isApiResponseError = (error: unknown): error is ApiResponseError => {
  return apiResponseErrorSchema.safeParse(error).success;
};

type ApiResponseErrorBody = {
  data: {
    uuid?: string;
    message?: string;
  };
};

type FileError = (typeof FILE_UPLOAD_ERRORS)[number];

const isFileError = (message: string): message is FileError => {
  return FILE_UPLOAD_ERRORS.includes(message);
};

function isApiResponseErrorBody(body: unknown): body is ApiResponseErrorBody {
  return typeof body === 'object' && body !== null && 'data' in body;
}

const extractResponseMessage = (response: ApiResponseError) => {
  const title = t('notifications__error');
  const defaultErrorNotification = {
    title,
    message: t('notifications__error__default_v2'),
  };

  if (response.status === -1) {
    return {
      title,
      message: t('notifications__no-connection'),
    };
  } else if (response.status === 429) {
    return {
      title,
      message: t('notifications__enhance-your-calm'),
    };
  } else if (response.status === 503) {
    return {
      title,
      message: t('notifications__unavailable'),
    };
  } else {
    try {
      const body = response.body && JSON.parse(response.body);

      if (isApiResponseErrorBody(body)) {
        const {
          data: { message },
        } = body;

        return {
          title,
          message: translatedStringSchema.parse(
            (body.data.uuid ? `${message} (${body.data.uuid})` : message) || '',
          ),
        };
      }

      return defaultErrorNotification;
    } catch {
      return defaultErrorNotification;
    }
  }
};

const extractErrorMessage = (error: Error | AppError) => {
  const title = t('notifications__error');
  const uploadErrorMessageTranslationMap = createTranslationMap({
    file__type__invalid: t('file__type__invalid'),
    'file__too-large': t('file__too-large'),
  });

  if (error.message === 'Failed to fetch') {
    return {
      title,
      message: t('notifications__no-connection'),
    };
  } else if (/Loading\schunk\s(\d+)\sfailed/.test(error.message)) {
    return {
      title: t('error__loading-chunk__title'),
      message: t('error__loading-chunk'),
    };
  } else {
    if (isFileError(error.message)) {
      return {
        title,
        message: uploadErrorMessageTranslationMap.get(error.message),
      };
    } else {
      return {
        title,
        message: translatedStringSchema.parse(error.message || ''),
      };
    }
  }
};

const extractMessage = (
  error: unknown | Error | AppError | ApiResponseError,
) => {
  if (error instanceof Error || error instanceof AppError) {
    return extractErrorMessage(error);
  } else if (isApiResponseError(error)) {
    return extractResponseMessage(error);
  }

  return {
    title: t('notifications__error'),
    message: t('notifications__error__default_v2'),
  };
};

// eslint-disable-next-line import/no-default-export
export default extractMessage;
