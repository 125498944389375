import { Record } from 'immutable';
import { z } from 'zod';

import { ACKNOWLEDGEMENT_TYPES } from '@peakon/shared/constants/acknowledgementTypes';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

export const schema = z.object({
  id: z.string(),
  kind: z.enum(['message', 'direct']),
  type: z.enum(ACKNOWLEDGEMENT_TYPES),
});

type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Acknowledgement
  extends Record({
    id: undefined,
    kind: undefined,
    type: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  kind!: Schema['kind'];
  type!: Schema['type'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Acknowledgement',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes: { kind, type },
    } = data;

    return new Acknowledgement({
      id,
      kind,
      type,
    });
  }
}
