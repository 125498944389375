import { useMutation, useQueryClient } from '@tanstack/react-query';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import { sharedDashboardsQueryKeys } from './keys';
import {
  catchHandler,
  showSuccessNotification,
} from '../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../utils/reduxHooks';

type Params = {
  onSuccess: () => void;
};

const markAllPrivate = (): Promise<$TSFixMe> =>
  api.patch('/shares/contexts', null, {
    data: {
      type: 'shares',
      attributes: {
        isPublic: false,
      },
    },
  });

export const useMarkAllPrivateMutation = ({ onSuccess }: Params) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markAllPrivate,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
      queryClient.invalidateQueries({
        queryKey: sharedDashboardsQueryKeys.sharedDashboards(),
        exact: true,
      });
      onSuccess();
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t('share_settings__marked_all_private'),
        }),
      );
    },
  });
};
