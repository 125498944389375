import { useQuery } from '@tanstack/react-query';

import { NewSummariesSchema } from './schema';
import api from '../../utils/api';
import { validateData } from '../../utils/validateData/validateData';

type GetUpdatedSummariesArgs = {
  contextId?: string;
  enabled?: boolean;
};

async function getUpdatedSummaries({ contextId }: GetUpdatedSummariesArgs) {
  const response = await api.get(`/summaries/nps/contexts/${contextId}/new`);

  return validateData(response, NewSummariesSchema, {
    errorMessagePrefix: 'NewSummariesSchema',
  });
}

export const useCheckUpdatedSummaries = ({
  contextId,
  enabled,
}: GetUpdatedSummariesArgs) => {
  return useQuery({
    queryKey: ['new_nps_summaries', contextId],
    queryFn: () => getUpdatedSummaries({ contextId }),
    enabled: Boolean(contextId) && enabled,
    select: (data) => data?.hasNewSummaries,
  });
};
