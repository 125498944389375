import React from 'react';

import { StackH } from '@peakon/bedrock/react-alpha/stack';

import { t } from '../../../../features/i18next/t';
import { useCheckUpdatedSummaries } from '../../../../queries/summaries/useCheckUpdatedSummaries';
import { NotificationBadge } from '../../../NotificationBadge/NotificationBadge';

type Props = {
  contextId?: string;
};

export function SummaryMenuLabel({ contextId }: Props) {
  const { data: hasNewSummaries } = useCheckUpdatedSummaries({
    contextId,
    enabled: true,
  });

  return (
    <StackH spacing="08" alignBlock="center">
      {t('multi_product__insights__menu_item__summary')}
      {hasNewSummaries && (
        <NotificationBadge
          label={t('multi_product__insights__menu_item__summary__badge')}
          variant="highlight"
        />
      )}
    </StackH>
  );
}
