import React from 'react';

import { getResizedImageUrl } from '@peakon/shared/utils';

type Props = {
  fit?: string;
  height?: number;
  src: string;
  width?: number;
  alt?: string;
};

const ResizedImage = ({ src, fit, height, width, alt, ...props }: Props) => (
  <img
    src={getResizedImageUrl({ src, fit, height, width })}
    alt={alt || ''}
    {...props}
  />
);

// eslint-disable-next-line import/no-default-export
export default ResizedImage;
