import { useInfiniteQuery } from '@tanstack/react-query';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';

import { sharedDashboardsQueryKeys } from './keys';
import { catchHandler } from '../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../utils/reduxHooks';

type Data = $TSFixMe;

async function fetchSharedDashboards({ pageParam }: $TSFixMe): Promise<Data> {
  let endpoint;
  let params;

  if (pageParam) {
    endpoint = pageParam;
  } else {
    endpoint = '/shares';
    params = {
      'fields[shares]': 'createdAt,context,token,isPublic,access,url',
      'fields[context]': 'name,logo,abbreviation,attribute',
      include: 'context,context.attribute',
      include_total: true,
    };
  }
  return jsonapiparser(await api.get(endpoint, params));
}

function useSharedDashboards() {
  const dispatch = useAppDispatch();
  const query = useInfiniteQuery({
    queryKey: sharedDashboardsQueryKeys.sharedDashboards(),
    queryFn: fetchSharedDashboards,
    onError: (error) => dispatch(catchHandler(error)),
    getNextPageParam: (previousData) => {
      return previousData?.links?.next;
    },
  });

  const sharedDashboards =
    query?.data?.pages?.flatMap((page) => page.data) ?? [];

  // already first page holds meta information about total count
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const totalCount = query?.data?.pages[0]?.meta?.page?.total as number;

  return { ...query, data: sharedDashboards, totalCount };
}

// eslint-disable-next-line import/no-default-export
export default useSharedDashboards;
