import { useEffect, useRef } from 'react';

function usePrevious<T>(value: T, { defaultValue }: { defaultValue?: T } = {}) {
  const ref = useRef<T | undefined>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  if (typeof ref.current === 'undefined') {
    return defaultValue;
  }

  return ref.current;
}

// eslint-disable-next-line import/no-default-export
export default usePrevious;
