import { TranslatedString } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import { GetState, Dispatch } from '../types/redux';
import { asyncDispatch } from '../utils';

type UpdateParams = {
  field: string;
  value: string | null;
};

export const update = ({ field, value }: UpdateParams) => ({
  type: 'COMPANY_UPDATE',
  data: {
    field,
    value,
  },
});

export const onChangeName = (value: string) => update({ field: 'name', value });

export const onChangeSector = (selected: $TSFixMe) =>
  update({ field: 'sector', value: selected ? selected.value : null });

export const onChangeSize = (selected: $TSFixMe) =>
  update({ field: 'companySize', value: selected ? selected.value : null });

export const updateCompany = () => (dispatch: Dispatch, getState: GetState) => {
  const { company } = getState();

  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPDATE',
    action: api.company.update(
      // @ts-expect-error Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      company.id,
      null,
      {
        data: company.toJsonApi(),
      },
    ),
  });
};

export const getEmployeeCount = (id: string) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'COMPANY_COUNT',
    action: api.company.get(id, {
      fields: {
        companies: ['employeeCount'].join(),
      },
    }),
  });

type GetCompanyParams = {
  id?: string;
  params?: { [x: string]: $TSFixMe };
};

export const getCompany =
  ({ id = 'me', params }: GetCompanyParams = {}) =>
  (dispatch: Dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_READ',
      action: api.company.get(id, params),
    });
  };

export const getCompanyDomains =
  ({ id = 'me', params = {} }: GetCompanyParams = {}) =>
  (dispatch: Dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_DOMAINS_READ',
      action: api.company.get(id, {
        fields: {
          companies: ['domains', 'allowAllDomains'].join(','),
        },

        ...params,
      }),
    });
  };

export const updateSettings =
  (
    settings: $TSFixMe,
    {
      original,
      successMessage,
    }: {
      original?: $TSFixMe;
      successMessage?: TranslatedString;
    } = {},
  ) =>
  (dispatch: Dispatch) =>
    asyncDispatch({
      dispatch,
      resource: 'COMPANY_UPDATE_SETTINGS',
      action: api.patch('/companies/me/settings', null, settings),
      data: {
        settings,
        original,
      },

      successMessage,
    });

export const updateBenchmarkType =
  (
    benchmarkType: string | null,
    { successMessage }: { successMessage?: TranslatedString } = {},
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const company = getState().company;
    const { benchmarkSelector, sector } = company;
    const { sector: benchmarkSector, percentile } = benchmarkSelector || {};

    const industry = benchmarkSector || sector;

    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_UPDATE_BENCHMARK_TYPE',
      action: api.patch('/companies/me', null, {
        data: {
          type: 'companies',
          attributes: {
            benchmarkType,
            benchmarkSelector:
              benchmarkType === 'industry' && industry
                ? {
                    sector: industry,
                    percentile,
                  }
                : {
                    percentile:
                      benchmarkType === 'overall' && percentile
                        ? percentile
                        : undefined,
                  },
          },
        },
      }),
      data: {
        benchmarkType,
      },
      successMessage,
    });
  };

export const updatePercentile =
  (
    percentile: number,
    { successMessage }: { successMessage?: TranslatedString } = {},
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const company = getState().company;
    const { benchmarkType, benchmarkSelector, sector } = company;
    const { sector: benchmarkSector } = benchmarkSelector || {};

    const industry = benchmarkSector || sector;
    const newBenchmarkSelector = {
      sector: benchmarkType === 'industry' ? industry : undefined,
      percentile,
    };

    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_UPDATE_PERCENTILE',
      action: api.patch('/companies/me', null, {
        data: {
          type: 'companies',
          attributes: {
            benchmarkType,
            benchmarkSelector: newBenchmarkSelector,
          },
        },
      }),
      data: {
        benchmarkSelector: newBenchmarkSelector,
      },
      successMessage,
    });
  };

export const updateIndustry =
  (
    industry: string,
    { successMessage }: { successMessage?: TranslatedString } = {},
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const company = getState().company;
    const { benchmarkSelector } = company;

    const newBenchmarkSelector = {
      ...benchmarkSelector,
      sector: industry,
    };

    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_UPDATE_INDUSTRY',
      action: api.patch('/companies/me', null, {
        data: {
          type: 'companies',
          attributes: {
            benchmarkType: 'industry',
            benchmarkSelector: newBenchmarkSelector,
          },
        },
      }),
      data: {
        benchmarkSelector: newBenchmarkSelector,
      },
      successMessage,
    });
  };
