// eslint-disable-next-line no-restricted-imports -- this is fine getting the TOptions
import { TOptions } from 'i18next';
import MessageFormat, {
  ErrorCode,
  MissingValueError,
} from 'intl-messageformat';

import { errorReporter } from '../../../utils';
import { getCurrentLocaleInfo } from '../helpers';

export const messageFormatProcessor = {
  type: 'postProcessor',
  name: 'messageFormat',
  process: function (str: string, keys: string[], options: TOptions) {
    return format(str, keys, options);
  },
} as const;

function format(
  str: string,
  keys: string[],
  options: TOptions,
  recursionCount = 0,
) {
  const locale = getCurrentLocaleInfo();
  try {
    return new MessageFormat(str, locale.messageFormat).format(options.replace);
  } catch (e: unknown) {
    // completely arbitrary number, it's just to prevent infinite recursion in case I made a mistake
    const MAX_RECURSION = 20;

    // `intl-messageformat` throws an error when a variable is missing
    // we have historically ignored these errors, so this is just to keep that behavior
    // but we report it to the `errorReporter`
    if (
      e instanceof Error &&
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- I would do `instanceof` but that doesn't work for some reason
      (e as MissingValueError).code === ErrorCode.MISSING_VALUE &&
      recursionCount < MAX_RECURSION
    ) {
      const variableRegex = /The intl string context variable "(\w+)"/;
      const missingVariable = variableRegex.exec(e.message)?.[1];
      if (missingVariable) {
        if (recursionCount === 0) {
          const key = keys[0];
          // report it only once
          errorReporter.warning(new Error(`For key: ${key}: ${e.message}`));
        }
        return format(
          str,
          keys,
          {
            ...options,
            replace: {
              ...options.replace,
              [missingVariable]: '',
            },
          },
          recursionCount + 1,
        );
      }
    }
    if (e instanceof Error) {
      errorReporter.error(e, { keys, options });
    }

    return str;
  }
}
