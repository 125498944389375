import React from 'react';

import { Card } from '@peakon/bedrock/react/card';
import { StackH, StackV } from '@peakon/bedrock/react-alpha/stack';
import { Placeholder } from '@peakon/components';

import { Layout } from './Layout';

export function LoadingPlaceholder() {
  return (
    <Layout>
      <Card>
        <StackV spacing="16" padding="16">
          <StackH spacing="08">
            <Placeholder width={32} height={32} />
            <Placeholder width={200} height={32} />
          </StackH>
          <StackV spacing="08">
            <Placeholder width="100%" height={20} />
            <Placeholder width="100%" height={20} />
            <Placeholder width="25%" height={20} />
          </StackV>
          <StackV spacing="08">
            <Placeholder width={120} height={16} />
            <Placeholder width="100%" height={60} />
            <Placeholder width={140} height={40} />
          </StackV>
        </StackV>
      </Card>
    </Layout>
  );
}
