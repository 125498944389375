import React from 'react';

import { Button } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { Paragraph } from '@peakon/bedrock/react/typography';
import Segment from '@peakon/records/SegmentRecord';
import { t } from '@peakon/shared/features/i18next/t';

import { ExternalLink } from '../../../../../components';
import { RecalculationType } from '../queries/usePostRecalculation';

import styles from './RecalculationModal.css';

type RecalculationModalProps = {
  modalRefCallback: (node: HTMLDialogElement | null) => void;
  recalculationType?: RecalculationType;
  showRecalculationModal: boolean;
  handleCloseModal: () => void;
  focusAreasSegment?: Segment;
  topicsSegment?: Segment;
  recalculate: (params: {
    recalculationType: RecalculationType;
    contextId: string;
  }) => void;
};

export const RecalculationModal = ({
  modalRefCallback,
  recalculationType,
  showRecalculationModal,
  handleCloseModal,
  focusAreasSegment,
  topicsSegment,
  recalculate,
}: RecalculationModalProps) => {
  const SUPPORT_LINK =
    'https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/insights/manager-dashboards/recalculate-dashboards.html';

  return (
    <Modal
      ref={modalRefCallback}
      open={showRecalculationModal}
      onDismiss={handleCloseModal}
      heading={
        recalculationType === 'priorities'
          ? t('data_settings__recalculation__focus_areas__modal_title')
          : t('data_settings__recalculation__topics__modal_title')
      }
      closeLabel={t('common__close')}
      data-test-id="recalculation-modal"
    >
      <Modal.Content>
        <Paragraph>
          {recalculationType === 'priorities'
            ? t(
                'data_settings__recalculation__focus_areas__modal_description',
                {
                  components: {
                    strong: <strong />,
                  },
                  replace: {
                    segment_name: focusAreasSegment?.name,
                  },
                },
              )
            : t('data_settings__recalculation__topics__modal_description', {
                components: {
                  strong: <strong />,
                },
                replace: {
                  segment_name: topicsSegment?.name,
                },
              })}
        </Paragraph>
        <br />
        <Paragraph>
          {t('data_settings__recalculation__modal_info__v2', {
            components: {
              link: (
                <ExternalLink
                  href={SUPPORT_LINK}
                  target="_blank"
                  className={styles.link}
                />
              ),
            },
          })}
        </Paragraph>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="primary"
          onClick={() => {
            recalculationType &&
              recalculate({
                recalculationType,
                // @ts-expect-error contextId should exist
                contextId:
                  recalculationType === 'priorities'
                    ? focusAreasSegment?.contextId
                    : topicsSegment?.contextId,
              });
          }}
        >
          {t('common__confirm')}
        </Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          {t('common__cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
