import z from 'zod';

import { AccessEnum, attributeSchema } from './attributes';
import { employeeBaseSchemaWithRelationships } from './employees';
import { translatedStringSchema } from '../../features/i18next/t';
import { BenchmarkTypeEnum } from '../../types/Benchmark';

const baseSegmentSchema = z.object({
  id: z.string(),
  type: z.literal('segments'),
  attributes: z.object({
    abbreviation: z.string(),
    comparisonAccess: AccessEnum,
    contextId: z.string(),
    direct: z.boolean(),
    employeeCount: z.number(),
    employeeCounts: z
      .object({
        employed: z.number(),
        hired: z.number(),
        left: z.number(),
      })
      .optional(),
    externalId: z.string(),
    group: z.string(),
    links: z.number(),
    loading: z.boolean(),
    logo: z.string(),
    name: z.string(),
    nameTranslated: translatedStringSchema,
    nameTranslations: z.record(z.string(), z.string()),
    rights: z.array(z.string()),
    standard: z.string(),
    benchmarkPropagate: z.boolean().optional(),
    benchmarkId: z.string().optional(),
    benchmarkSelector: z.record(z.string(), z.unknown()).optional(),
    benchmarkType: BenchmarkTypeEnum,
  }),
});

const relationshipsSchema = z.object({
  attribute: attributeSchema.optional(),
  benchmark: baseSegmentSchema.optional(),
});

export const baseSegmentSchemaWithRelationships = baseSegmentSchema.extend({
  relationships: relationshipsSchema,
});

export const segmentManagerSchema = z.object({
  id: z.string(),
  type: z.literal('segment_managers'),
  attributes: z.object({
    automatic: z.boolean(),
    level: z.number(),
  }),
  relationships: z.object({
    employee: z.lazy(() => employeeBaseSchemaWithRelationships),
    segment: baseSegmentSchemaWithRelationships.optional(),
  }),
});

const segmentSchema = baseSegmentSchemaWithRelationships.extend({
  relationships: relationshipsSchema.merge(
    z.object({
      managers: z.array(segmentManagerSchema).optional(),
    }),
  ),
});

export type BaseSegmentWithRelationships = z.infer<
  typeof baseSegmentSchemaWithRelationships
>;
export type SegmentManagerResponse = z.infer<typeof segmentManagerSchema>;
export type SegmentResponse = z.infer<typeof segmentSchema>;
