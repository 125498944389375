import { List } from 'immutable';
import pick from 'lodash/pick';

import { Context } from '@peakon/records';

const COMPANY_CONTEXT_FIELDS = ['abbreviation', 'name', 'logo'];

const contexts = (state = List<Context>(), action: $TSFixMe): List<Context> => {
  switch (action.type) {
    case 'CONTEXT_LIST_RESET':
      return List();
    case 'CONTEXT_LIST_SUCCESS': {
      const { data } = action.data;

      return List(
        // @ts-expect-error TS(7006): Parameter 'context' implicitly has an 'any' type.
        data.map((context) =>
          Context.createFromApi(context, { keepRawAttributes: true }),
        ),
      );
    }
    case 'CONTEXT_CHANGED': {
      return state
        .filter((context) => context.id !== action.data.id)
        .unshift(action.data);
    }
    case 'CONTEXT_VIEW_AS': {
      const segment = action.data;

      // @ts-expect-error TS(2339): Property 'fromSegment' does not exist on type 'typ... Remove this comment to see the full error message
      return List([Context.fromSegment(segment)]);
    }
    case 'COMPANY_ACCOUNT_UPDATE_SUCCESS': {
      const { form } = action.data;
      // @ts-expect-error - Type 'List<Map<string, any>>' is not assignable to type 'List<Context>'.
      return state.map((context) => {
        if (context.level === 'company') {
          return context.merge(pick(form, COMPANY_CONTEXT_FIELDS));
        }

        return context;
      });
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default contexts;
