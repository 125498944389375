import { useMutation } from '@tanstack/react-query';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';

type QueryParams = {
  onSuccess: (name: string) => void;
};

type SensitiveKeyword = {
  keyword: string;
  locale: string;
};

type CreateConceptParams = {
  name: string;
  sensitiveKeywords: Array<SensitiveKeyword>;
};

const createConcept = async ({
  name,
  sensitiveKeywords,
}: CreateConceptParams): Promise<$TSFixMe> => {
  return await api.post(
    `/sensitive/concepts`,
    { include: 'keywords' },
    {
      data: {
        type: 'sensitive_concepts',
        attributes: { name, notifications: 'digest' },
        relationships: {
          keywords: {
            data: sensitiveKeywords.map((keyword: SensitiveKeyword) => {
              return {
                type: 'sensitive_keywords',
                attributes: keyword,
              };
            }),
          },
        },
      },
    },
  );
};

export const useCreateConceptMutation = ({ onSuccess }: QueryParams) => {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: createConcept,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: ({ data: { attributes } }) => {
      onSuccess(attributes.name);
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t(
            'engagement__settings__sensitive_comments_custom_concept_created',
          ),
        }),
      );
    },
  });
};
