import { useQuery } from '@tanstack/react-query';

import jsonapiparser from '@peakon/jsonapiparser';
import {
  changeLanguage,
  getCurrentLocaleInfo,
} from '@peakon/shared/features/i18next/helpers';
import api from '@peakon/shared/utils/api';

import { StandaloneTeamSuggestionsSessionResponse } from './schema';
import { standaloneTeamSuggestionsQueryKeys } from './useTeamSuggestionsQuery';

const params = {
  include: 'company',
  fields: {
    companies: ['logo', 'name'],
  },
};

async function getSession(): Promise<StandaloneTeamSuggestionsSessionResponse> {
  return jsonapiparser(await api.get('/session', params));
}

type Params = {
  enabled: boolean;
};

export function useSessionQuery({ enabled }: Params) {
  return useQuery({
    queryKey: standaloneTeamSuggestionsQueryKeys.session,
    enabled,
    queryFn: getSession,
    select: (sessionData) => {
      return {
        company: sessionData?.included.find((item) => item.type === 'companies')
          ?.attributes,
        locale: sessionData?.data.attributes.locale,
      };
    },
    onSuccess: async (sessionData) => {
      const locale = sessionData?.locale;
      const { id } = getCurrentLocaleInfo();
      if (locale !== id) {
        await changeLanguage(locale);
      }
    },
  });
}
