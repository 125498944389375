import { OrderedSet } from 'immutable';
import moment from 'moment';

import jsonapiparser from '@peakon/jsonapiparser';
import { EmployeeRounds, Round } from '@peakon/records';

const employeeRounds = (
  state = new EmployeeRounds(),
  action: $TSFixMe,
): EmployeeRounds => {
  switch (action.type) {
    case 'EMPLOYEE_ROUNDS_FLUSH': {
      return new EmployeeRounds();
    }

    case 'LIST_EMPLOYEE_ROUNDS_SUCCESS': {
      const {
        data,
        links,
        meta: {
          open,
          page: { total },
        },
      } = action.data;

      // @ts-expect-error Incorrect inference of return type from .merge
      return state.merge({
        isLoading: false,
        nextUrl: links && links.next,
        open,
        rounds: OrderedSet(data.map(Round.createFromApi)),
        total,
      });
    }

    case 'EMPLOYEE_ROUNDS_LOAD_MORE_LOADING':
      return state.set('isLoading', true);

    case 'EMPLOYEE_ROUNDS_LOAD_MORE_SUCCESS': {
      const { data: response } = action;
      const { links, data } = jsonapiparser(response);

      // @ts-expect-error Incorrect inference of return type from .merge
      return state.merge({
        rounds: state.rounds.concat(data.map(Round.createFromApi)),
        nextUrl: links && links.next,
        isLoading: false,
      });
    }

    case 'ADD_EMPLOYEE_TO_OPEN_ROUNDS_SUCCESS':
      return state.set('open', 0);

    case 'ROUND_RESPONSE_RESENT': {
      const { responseId } = action.data;

      // @ts-expect-error Incorrect inference of return type from .merge
      return state.merge({
        rounds: state.rounds.map((round?: Round) => {
          if (round && round.responseId === responseId) {
            return round.set(
              'lastResentAt',
              moment().format('YYYY-MM-DD HH:mm:ss'),
            );
          }

          return round;
        }),
      });
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default employeeRounds;
