import React from 'react';

import classNames from 'classnames';

import { Paragraph } from '@peakon/bedrock/react/typography';
import { StackV } from '@peakon/bedrock/react-alpha/stack';
import { AnonymousAvatar } from '@peakon/shared/components/avatars/AnonymousAvatar/AnonymousAvatar';
import { t } from '@peakon/shared/features/i18next/t';

import { ManagerAvatar } from './ManagerAvatar';

import styles from './styles.css';

type Props =
  | {
      isReply?: true;
      message: string;
      name?: never;
      avatar?: never;
    }
  | {
      isReply?: false;
      message: string;
      name: string;
      avatar?: string | null;
    };

export function CardMessage({ message, name, avatar, isReply = false }: Props) {
  if (isReply) {
    return (
      <BaseCardMessage
        name={t('conversations__reply__you')}
        message={message}
        isReply
        avatar={<AnonymousAvatar variant="light" />}
        data-test-id="card-message-reply"
      />
    );
  }

  return (
    <BaseCardMessage
      message={message}
      name={name}
      avatar={<ManagerAvatar name={name ?? ''} src={avatar} />}
      data-test-id="card-message"
    />
  );
}

type BaseProps = {
  message: string;
  name?: string;
  avatar?: React.ReactNode;
  isReply?: boolean;
};

export function BaseCardMessage({
  name,
  avatar,
  message,
  isReply,
  ...rest
}: BaseProps) {
  return (
    <StackV spacing="08" alignInline={isReply ? 'end' : 'start'} {...rest}>
      <Paragraph size="small" bold>
        {name}
      </Paragraph>
      <div className={styles.messageWrapper}>
        {!isReply && avatar}
        <div
          className={classNames(styles.message, {
            [styles.reply]: isReply,
          })}
        >
          <Paragraph>{message}</Paragraph>
        </div>
        {isReply && avatar}
      </div>
    </StackV>
  );
}
