import React, { useState } from 'react';

import { Label, Typography } from '@peakon/components';
import { Company } from '@peakon/records';
import { t } from '@peakon/shared/features/i18next/t';

import {
  updateBenchmarkType,
  updateIndustry,
  updatePercentile,
} from '../../../../actions/CompanyActions';
import { Sector } from '../../../../data/sectors';
import { useAppDispatch } from '../../../../utils/reduxHooks';
import { BenchmarkSelector } from '../BenchmarkSelector/BenchmarkSelector';
import { IndustrySelector } from '../IndustrySelector/IndustrySelector';
import {
  PercentileSelector,
  Percentile,
} from '../PercentileSelector/PercentileSelector';

import styles from './styles.css';

type BenchmarkSelector = {
  sector: Sector;
  percentile?: Percentile;
};

type BenchmarkType = null | 'industry' | 'overall';

type Benchmark = {
  benchmarkType: BenchmarkType;
  benchmarkSelector: BenchmarkSelector;
};

type CompanyBenchmarkProps = {
  company: Company;
};

export const CompanyBenchmark = ({ company }: CompanyBenchmarkProps) => {
  const dispatch = useAppDispatch();
  const { benchmarkType, benchmarkSelector, sector } = company;
  const { sector: benchmarkSector, percentile } = benchmarkSelector || {};
  const [benchmark, setBenchmark] = useState<Benchmark>({
    benchmarkType,
    benchmarkSelector: {
      sector: benchmarkSector || sector,
      percentile,
    },
  });

  const handleBenchmarkTypeChange = ({ value }: { value: BenchmarkType }) => {
    setBenchmark({
      ...benchmark,
      benchmarkType: value,
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(
      updateBenchmarkType(value, {
        successMessage: t('company_benchmark_settings__updated'),
      }),
    );
  };

  const handlePercentileChange = ({ value }: { value: Percentile }) => {
    setBenchmark({
      ...benchmark,
      benchmarkSelector: {
        ...benchmarkSelector,
        percentile: value,
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(
      updatePercentile(value, {
        successMessage: t('company_benchmark_settings__updated'),
      }),
    );
  };

  const handleIndustryChange = (value: Sector) => {
    setBenchmark({
      benchmarkType: 'industry',
      benchmarkSelector: {
        ...benchmark.benchmarkSelector,
        sector: value,
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(
      updateIndustry(value, {
        successMessage: t('company_benchmark_settings__updated'),
      }),
    );
  };

  return (
    <div className={styles.root}>
      <Typography.H4>{t('company_benchmark_settings__title')}</Typography.H4>
      <Typography.P>{t('company_benchmark_settings__info')}</Typography.P>
      <div className={styles.form}>
        <div
          className={styles.col}
          data-test-id="company-benchmark-type-combobox-container"
        >
          <Label htmlFor="benchmark-selector">
            {t('company_benchmark_settings__benchmark')}
          </Label>
          <BenchmarkSelector
            onChange={handleBenchmarkTypeChange}
            value={benchmark.benchmarkType}
          />
        </div>
        {benchmark.benchmarkType === 'industry' && (
          <div
            className={styles.col}
            data-test-id="company-industry-combobox-container"
          >
            <Label htmlFor="industry-selector">
              {t('company_benchmark_settings__industry')}
            </Label>
            <IndustrySelector
              onChange={handleIndustryChange}
              value={benchmark.benchmarkSelector.sector}
            />
          </div>
        )}

        {benchmark.benchmarkType && (
          <div
            className={styles.col}
            data-test-id="company-benchmark-percentile-combobox-container"
          >
            <Label htmlFor="percentile-selector">
              {t('company_benchmark_settings__percentile')}
            </Label>
            <PercentileSelector
              onChange={handlePercentileChange}
              value={benchmark.benchmarkSelector.percentile}
            />
          </div>
        )}
      </div>
    </div>
  );
};
