import GroupExport from '@peakon/records/GroupExportRecord';

const groups = (state = new GroupExport(), action: $TSFixMe): GroupExport => {
  switch (action.type) {
    case 'GROUP_EXPORT_FLUSH': {
      return new GroupExport();
    }

    case 'GROUP_EXPORT_LOAD_MEMBERS_SUCCESS': {
      const {
        meta: {
          page: { total },
        },
      } = action.data;

      // @ts-expect-error Property 'total' is missing in type 'Map<string, any>' but required in type 'GroupExport'.ts(2741)
      return state.merge({
        total,
        totalFiltered: null,
      });
    }

    case 'GROUP_EXPORT_FILTER_MEMBERS_SUCCESS': {
      const {
        meta: {
          page: { total },
        },
      } = action.data;

      // @ts-expect-error Property 'total' is missing in type 'Map<string, any>' but required in type 'GroupExport'.ts(2741)
      return state.merge({
        totalFiltered: total,
      });
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default groups;
