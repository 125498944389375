import React from 'react';

import sortBy from 'lodash/sortBy';

import { Combobox } from '@peakon/components';

import { getSectors, Sector } from '../../../../data/sectors';

type IndustrySelectorProps = {
  onChange: (value: Sector) => void;
  value?: Sector;
  id?: string;
};

const isSector = (value: string): value is Sector => {
  return getSectors().some((sector) => sector.value === value);
};

export const IndustrySelector = ({
  onChange,
  value: currentValue,
  id,
}: IndustrySelectorProps) => {
  const sectors = getSectors();

  const options = sortBy(
    sectors.map(({ value, label }) => ({
      value,
      label,
    })),
    'label',
  );

  const selectedItem = options.find((option) => option.value === currentValue);

  return (
    <Combobox
      isClearable={false}
      selectedItem={selectedItem?.value || ''}
      onSelect={(value: string) => isSector(value) && onChange(value)}
      id={id}
    >
      <Combobox.Select hasClear={false}>{selectedItem?.label}</Combobox.Select>
      <Combobox.Menu fitTargetWidth>
        {options.map((option) => (
          <Combobox.Option key={option.value} value={option.value}>
            {option.label}
          </Combobox.Option>
        ))}
      </Combobox.Menu>
    </Combobox>
  );
};
