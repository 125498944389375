import React, { useEffect, useState } from 'react';

import {
  FiltersSensitiveGroupIcon,
  UtilityStopIcon,
} from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { Card } from '@peakon/bedrock/react/card';
import { ToggleGroup } from '@peakon/bedrock/react/form';
import { ButtonLink } from '@peakon/bedrock/react/link';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { Heading2, Heading3 } from '@peakon/bedrock/react/typography';
import { t } from '@peakon/shared/features/i18next/t';

import { useSensitiveConceptsQuery } from './queries/useSensitiveConceptsQuery';
import {
  SensitiveCommentConceptNotifications,
  SensitiveCommentConceptStatus,
  SensitiveCommentsConcept,
  SensitiveConcept,
} from './SensitiveCommentsConcept';
import { SensitiveCommentsConceptForm } from './SensitiveCommentsConceptForm';
import { mapSensitiveConcepts } from './utils';
import { updateSettings } from '../../../../actions/CompanyActions';
import { useAddon } from '../../../../hooks/useAddon';
import { useAppDispatch, useAppSelector } from '../../../../utils/reduxHooks';
import EmptyState from '../../../Comments/components/EmptyState';

import styles from './styles.css';

export const SensitiveComments = () => {
  const [isCreatingConcept, setIsCreatingConcept] = useState(false);
  const [isCreationEnabled, setIsCreationEnabled] = useState(true);
  const canModifyKeywords = useAddon('modify_sensitive_keywords');
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [groups, setGroups] = useState([]);
  const [isLoadingToggle, setIsLoadingToggle] = useState(false);
  const dispatch = useAppDispatch();

  const session = useAppSelector((state) => state.session);
  const companySettings: Map<$TSFixMe, $TSFixMe> = useAppSelector(
    (state) => state.company.settings,
  );

  const isEnabled = companySettings.get('sensitiveComments');

  const {
    data: fetchedConcepts,
    refetch: refetchConcepts,
    isLoading: isQueryLoading,
  } = useSensitiveConceptsQuery(isEnabled);

  const isLoading = isEnabled && isQueryLoading;

  useEffect(() => {
    if (fetchedConcepts) {
      setGroups(mapSensitiveConcepts(fetchedConcepts));
      setIsLimitReached(fetchedConcepts.filter((sc) => !sc.type).length >= 50);
    }
  }, [fetchedConcepts]);

  useEffect(() => {
    setIsCreationEnabled(isEnabled);
  }, [isEnabled, refetchConcepts]);

  const createNewGroup = () => {
    setIsCreatingConcept(true);
  };

  const getNewConcept = (): SensitiveConcept => {
    return {
      keywords: [],
      enabled: true,
      id: '',
      name: '',
      notifications: SensitiveCommentConceptNotifications.Digest,
      status: SensitiveCommentConceptStatus.Active,
    };
  };

  const handleCreationCancelled = (): void => {
    setIsCreatingConcept(false);
  };

  const handleSensitiveCommentEnabled = async (
    isSensitiveCommentEnabled: boolean,
  ) => {
    if (isLoadingToggle) {
      return;
    }

    setIsLoadingToggle(true);

    if (!isSensitiveCommentEnabled) {
      setIsCreatingConcept(false);
    }

    await dispatch(
      updateSettings(
        { sensitiveComments: isSensitiveCommentEnabled },
        {
          original: '',
          successMessage: isSensitiveCommentEnabled
            ? t('engagement__settings__sensitive_comments_on_updated')
            : t('engagement__settings__sensitive_comments_off_updated'),
        },
      ),
    ).finally(() => {
      setIsLoadingToggle(false);
    });
  };

  const handleOnSaveCustomConcept = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
    refetchConcepts();
    setIsCreatingConcept(false);
  };

  const handleOnConceptRenamed = (
    groupType: string,
    name: string,
    conceptId: string,
  ) => {
    // @ts-expect-error Property 'type' does not exist on type 'never'.ts(2339)
    const groupIndex = groups.findIndex((group) => group.type === groupType);
    // @ts-expect-error Property 'concepts' does not exist on type 'never'.ts(2339)
    const conceptIndex = groups[groupIndex].concepts.findIndex(
      (concept: $TSFixMe) => concept.id === conceptId,
    );
    // @ts-expect-error Property 'concepts' does not exist on type 'never'.ts(2339)
    groups[groupIndex].concepts[conceptIndex].name = name;
    setGroups(groups);
  };

  const hasCompanyAccessRight = session.hasRight('company:admin');

  return (
    <div className={styles.root}>
      {hasCompanyAccessRight && (
        <div className={styles.radioContainer}>
          <Heading2 level={2}>
            {t('engagement__settings__sensitive_comments_toggle')}
          </Heading2>

          <ToggleGroup
            groupLabel={t('engagement__settings__sensitive_comments_toggle')}
            hideGroupLabel
          >
            <ToggleGroup.Item
              name="sensitiveComments"
              value="true"
              icon={<FiltersSensitiveGroupIcon />}
              label={t('engagement__settings__sensitive_comments_on')}
              description={t(
                'engagement__settings__sensitive_comments_on-description',
              )}
              checked={isEnabled}
              onChange={() => handleSensitiveCommentEnabled(true)}
            />
            <ToggleGroup.Item
              name="sensitiveComments"
              value="false"
              icon={<UtilityStopIcon />}
              label={t('engagement__settings__sensitive_comments_off')}
              description={t(
                'engagement__settings__sensitive_comments_off-description',
              )}
              checked={!isEnabled}
              onChange={() => handleSensitiveCommentEnabled(false)}
            />
          </ToggleGroup>
        </div>
      )}

      {!hasCompanyAccessRight && !isEnabled && (
        <EmptyState isSensitiveEnabled={false} isSensitive />
      )}

      {isCreatingConcept && (
        <SensitiveCommentsConceptForm
          concept={getNewConcept()}
          canModifyKeywords={true}
          isCreating={true}
          onCancelled={handleCreationCancelled}
          onSave={handleOnSaveCustomConcept}
        />
      )}

      {isLoading && (
        <div className={styles.spinner}>
          <Spinner size="32" />
        </div>
      )}

      {isLimitReached && (
        <Card>
          <div className={styles.limitReached}>
            <h3 className={styles.limitReachedTitle}>
              {t(
                'engagement__settings__sensitive_comments_custom_limit_reached',
              )}
            </h3>
            <strong>
              {t(
                'engagement__settings__sensitive_comments_custom_limit_reached_text',
              )}
            </strong>
            <span>
              {t(
                'engagement__settings__sensitive_comments_custom_limit_reached_info',
              )}
            </span>
          </div>
        </Card>
      )}

      {!isLoading && isEnabled && (
        <React.Fragment>
          <div className={styles.monitoredWordsHeader}>
            <Heading2 level={2}>
              {t('engagement__settings__sensitive_comments_monitored')}
            </Heading2>
            {isCreationEnabled && !isCreatingConcept && (
              <Button variant="primary" onClick={createNewGroup} size="small">
                {t('engagement__settings__sensitive_comments_custom_create')}
              </Button>
            )}
          </div>
          {groups.length === 0 && (
            <div>
              {t('engagement__settings__sensitive_comments_empty')}
              {isCreationEnabled && !isCreatingConcept && (
                <span className={styles.link}>
                  <ButtonLink onClick={createNewGroup}>
                    {t(
                      'engagement__settings__sensitive_comments_custom_create',
                    )}
                  </ButtonLink>
                </span>
              )}
            </div>
          )}

          {groups.map((group, index) => (
            <div
              key={index}
              className={styles.group}
              data-test-id="sensitive-comments-group"
            >
              <div className={styles.groupName}>
                {/* @ts-expect-error Property 'translatedType' does not exist on type 'never'.ts(2339) */}
                <Heading3 level={3}>{group.translatedType}</Heading3>
              </div>

              {/* @ts-expect-error Property 'concepts' does not exist on type 'never'.ts(2339) */}
              {group.concepts.length === 0 ? (
                <div className={styles.conceptsEmpty}>
                  <span>
                    {t('engagement__settings__sensitive_comments_group_empty')}
                  </span>
                </div>
              ) : (
                <div className={styles.conceptsTableWrapper}>
                  <table className={styles.conceptsTable}>
                    <thead>
                      <tr>
                        <th scope="col">
                          {t(
                            'engagement__settings__sensitive_comments_concept',
                          )}
                        </th>
                        <th scope="col">
                          {t(
                            'engagement__settings__sensitive_comments_variations',
                          )}
                        </th>
                        <th id="sensitive-comments-frequency">
                          {t(
                            'engagement__settings__sensitive_comments_frequency_label_flag',
                          )}
                        </th>
                        <th scope="col">
                          {t(
                            'engagement__settings__sensitive_comments_monitor',
                          )}
                        </th>
                        {canModifyKeywords && (
                          <th scope="col">
                            {t('engagement__settings__sensitive_comments_edit')}
                          </th>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {/* @ts-expect-error TS(7006): Parameter 'concept' implicitly has an 'any' type. */}
                      {group.concepts.map((concept) => (
                        <SensitiveCommentsConcept
                          key={concept.id}
                          concept={concept}
                          canModifyKeywords={canModifyKeywords}
                          onConceptDeleted={refetchConcepts}
                          onConceptRenamed={(name) =>
                            // @ts-expect-error Property 'type' does not exist on type 'never'.ts(2339)
                            handleOnConceptRenamed(group.type, name, concept.id)
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
        </React.Fragment>
      )}
    </div>
  );
};
