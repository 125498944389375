import { useInfiniteQuery } from '@tanstack/react-query';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';

import { useAppDispatch } from './../../../../../../../app-manager/src/utils/reduxHooks';
import { queryKeys } from './queryKeys';
import { catchHandler } from '../../../../../../../app-manager/src/actions/NotificationActions';
import {
  NormalizedNotificationItem,
  NotificationItem,
  RelationshipsKeys,
} from '../Notification/types';

const URL_PARAMS = {
  include: 'context,context.attribute',
  per_page: 10,
};

type GetParams = {
  nextPageLink?: string;
};

const getNotifications = async ({ nextPageLink }: GetParams) => {
  const URL: [string, typeof URL_PARAMS | null] = nextPageLink
    ? [nextPageLink, null]
    : ['/notifications', URL_PARAMS];
  const response = await api.get(...URL);
  return jsonapiparser(response);
};

export const useGetNotificationsInfiniteQuery = (enabled = false) => {
  const appDispatch = useAppDispatch();

  const { data, ...rest } = useInfiniteQuery({
    queryKey: queryKeys.unreadNotifications(),
    queryFn: ({ pageParam }) => getNotifications({ nextPageLink: pageParam }),
    getNextPageParam: (previousData) => previousData?.links?.next,
    onError: (error) => appDispatch(catchHandler(error)),
    enabled,
  });

  return {
    data,
    unreadNotifications:
      data?.pages?.flatMap((page) => page?.data).map(normalize) ?? [],
    ...rest,
  };
};

function normalize(item: NotificationItem | null): NormalizedNotificationItem {
  if (!item) {
    return null;
  }

  const relationships: {
    [key in RelationshipsKeys]?: $TSFixMe;
  } = {};

  if (item.relationships) {
    const keys = Object.keys(item.relationships);
    for (const key of keys) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      relationships[key as RelationshipsKeys] = normalize(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        item.relationships[key as RelationshipsKeys],
      );
    }
  }

  return {
    id: item.id,
    ...item.attributes,
    ...relationships,
  };
}
