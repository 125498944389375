import { fromJS, List, Map, Record as ImmutableRecord } from 'immutable';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import trim from 'lodash/trim';
import { z } from 'zod';

import {
  localeIdsSchema,
  ValidLocaleIds,
} from '@peakon/shared/features/i18next/localesValidation';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Category from './CategoryRecord';
import Context from './ContextRecord';
import Segment from './SegmentRecord';
import { ImmutableObject } from './types/ImmutableObject';
import Value from './ValueRecord';

/*
  FIXME / Note:

  Some of the properties here shouldn't really be optionals, like id, category, status and type for example.
  Using this record to create new questions in the question admin page is preventing us from doing that.
  We should try to use a separate record there.
*/
const schema = z.object({
  id: z.string().optional(),
  // CategoryRecord
  category: z.object({}).optional(),
  categoryId: z.string().optional(),
  // ContextRecord
  context: z.object({}).optional(),
  customized: z.boolean().optional(),
  // SegmentRecord[]
  excludedSegments: z.array(z.object({})).nullable().optional(),
  frequency: z.number().nullable().optional(),
  identifier: z.string().nullable().optional(),
  illustration: z.string().optional(),
  // SegmentRecord[]
  includedSegments: z.array(z.object({})).nullable().optional(),
  parentCategoryId: z.string().nullable().optional(),
  rights: z.array(z.enum(['view', 'edit', 'delete'])).optional(),
  rule: z
    .object({
      ruleUnit: z.enum(['days', 'weeks']),
      ruleValue: z.string(),
      id: z.string(),
    })
    .optional(),
  schedule: z.enum(['recur', 'once']).nullable().optional(),
  standard: z.boolean().optional(),
  standardText: z.string().optional(),
  standardTranslations: z.record(localeIdsSchema, z.string()).optional(),
  status: z.enum(['active', 'inactive']).optional(),
  text: z.string(),
  textRaw: z.string().optional(),
  translations: z.record(localeIdsSchema, z.string()).optional(),
  type: z.enum(['scale', 'text', 'value']).optional(),
  // ValueRecord
  value: z.object({}).nullable().optional(),

  // legacy properties
  driver: z.string().nullable().optional(),
  subdriver: z.string().nullable().optional(),
});

type ZodSchema = z.infer<typeof schema>;

type QuestionRecordType = Omit<ZodSchema, 'rule'> & {
  rule?: ImmutableObject<ZodSchema['rule']>;
};

class QuestionRecord
  extends ImmutableRecord({
    id: undefined,
    category: undefined,
    categoryId: undefined,
    context: undefined,
    customized: undefined,
    driver: undefined,
    excludedSegments: List(),
    frequency: undefined,
    identifier: undefined,
    illustration: undefined,
    includedSegments: List(),
    parentCategoryId: undefined,
    rights: List(),
    schedule: undefined,
    standard: undefined,
    standardText: undefined,
    standardTranslations: Map(),
    status: undefined,
    subdriver: undefined,
    text: '',
    textRaw: '',
    translations: Map(),
    type: undefined,
    value: undefined,
    rule: undefined,
  })
  implements
    Omit<
      QuestionRecordType,
      | 'excludedSegments'
      | 'includedSegments'
      | 'standardTranslations'
      | 'translations'
    >
{
  id!: QuestionRecordType['id'];
  category?: Category;
  categoryId: QuestionRecordType['categoryId'];
  context!: Context;
  customized: QuestionRecordType['customized'];
  /** @deprecated Please use category instead */
  driver?: QuestionRecordType['driver'];
  excludedSegments!: List<Segment>;
  frequency: QuestionRecordType['frequency'];
  identifier: QuestionRecordType['identifier'];
  illustration: QuestionRecordType['illustration'];
  includedSegments!: List<Segment>;
  parentCategoryId: QuestionRecordType['parentCategoryId'];
  rights: QuestionRecordType['rights'];
  rule?: QuestionRecordType['rule'];
  schedule: QuestionRecordType['schedule'];
  standard: QuestionRecordType['standard'];
  standardText: QuestionRecordType['standardText'];
  standardTranslations!: Map<ValidLocaleIds, string>;
  status: QuestionRecordType['status'];
  /** @deprecated Please use category instead */
  subdriver: QuestionRecordType['subdriver'];
  text!: QuestionRecordType['text'];
  textRaw: QuestionRecordType['textRaw'];
  translations!: Map<ValidLocaleIds, string>;
  type: QuestionRecordType['type'];
  value?: Value | null;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'QuestionRecord',
    });

    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get dashboardContext() {
    return this.context;
  }

  isSubcategory() {
    if (this.driver) {
      return Boolean(this.subdriver);
    }

    return Boolean(this.parentCategoryId);
  }

  isOpenEnded() {
    return this.type === 'text';
  }

  isRequired() {
    return this.category?.isEngagementCategoryOrSubcategory({ onlyMain: true });
  }

  isSegmentable() {
    if (this.category?.isEngagementCategoryOrSubcategory({ onlyMain: true })) {
      return false;
    }
    return true;
  }

  isReadOnly() {
    return Boolean(this.id) && !this.rights?.includes('edit');
  }

  getBadgeType() {
    return this.standard ? 'standard' : 'custom';
  }

  diff({
    categoryId,
    excludedSegments,
    frequency,
    includedSegments,
    schedule,
    text,
    translations,
    weeks,
    rule,
  }: Record<string, $TSFixMe> & {
    rule?: {
      timeUnit?: 'days' | 'weeks';
      value?: number;
    };
  }) {
    const attributes: Record<string, unknown> = {};
    const relationships: Record<string, unknown> = {};
    const previousRule = this?.rule?.toJS();

    if (!rule && !previousRule) {
      // No diff, nothing to do
    } else if (!rule && previousRule) {
      // Deleting the rule
      // Deleting the rule is not allowed, so we return no diff to ensure the rule is not deleted
    } else if (rule && !previousRule) {
      // Creating a new rule
      relationships.rule = {
        data: {
          type: 'question_rules',
          attributes: {
            ruleValue: rule.value?.toString(),
            ruleUnit: rule.timeUnit,
          },
        },
      };
    } else if (rule && previousRule) {
      // Updating an existing rule
      const currentRuleUnit = rule?.timeUnit;
      const currentRuleValue = rule?.value?.toString();
      if (
        currentRuleUnit !== previousRule.ruleUnit ||
        currentRuleValue !== previousRule.ruleValue
      ) {
        relationships.rule = {
          data: {
            type: 'question_rules',
            id: previousRule.id,
            attributes: {
              ruleValue: currentRuleValue ?? previousRule.ruleValue,
              ruleUnit: currentRuleUnit ?? previousRule.ruleUnit,
            },
          },
        };
      }
    }

    if (text !== this.textRaw) {
      attributes.text = trim(text);
    }

    if (frequency !== this.frequency) {
      if (frequency === 'custom' && weeks) {
        attributes.frequency = weeks;
      } else if (frequency !== 'custom') {
        attributes.frequency = frequency;
      }
    }

    if (schedule !== this.schedule) {
      attributes.schedule = schedule;
    }

    if (translations && this.hasTranslationsChanges(translations)) {
      attributes.translations = {};

      translations.forEach(
        (
          // @ts-expect-error no implicit any
          item,
        ) => {
          if (!this.standard || item.translation !== item.original) {
            const translationText = trim(item.translation);

            if (
              !this.standard ||
              translationText !== this.standardTranslations.get(item.locale)
            ) {
              // @ts-expect-error TS(2339): Property 'translations' does not exist on type '{}... Remove this comment to see the full error message
              attributes.translations[item.locale] = translationText;
            }
          }
        },
      );
    }

    if (
      excludedSegments &&
      !isEqual(
        this.excludedSegments
          .map((segment) => segment?.id)
          .toArray()
          .sort(),

        excludedSegments
          .map(
            (
              // @ts-expect-error no implicit any
              segment,
            ) => segment.id,
          )
          .sort(),
      )
    ) {
      relationships.excludedSegments = {
        data:
          excludedSegments.length > 0
            ? excludedSegments.map(
                (
                  // @ts-expect-error no implicit any
                  segment,
                ) => ({
                  type: 'segments',
                  id: segment.id,
                }),
              )
            : null,
      };
    }

    if (
      includedSegments &&
      !isEqual(
        this.includedSegments
          .map((segment) => segment?.id)
          .toArray()
          .sort(),

        includedSegments
          .map(
            (
              // @ts-expect-error no implicit any
              segment,
            ) => segment.id,
          )
          .sort(),
      )
    ) {
      relationships.includedSegments = {
        data:
          includedSegments.length > 0
            ? includedSegments.map(
                (
                  // @ts-expect-error no implicit any
                  segment,
                ) => ({
                  type: 'segments',
                  id: segment.id,
                }),
              )
            : null,
      };
    }

    if (
      categoryId &&
      (!this.id || !this.categoryId || categoryId !== this.categoryId)
    ) {
      relationships.category = {
        data: {
          type: 'categories',
          id: categoryId,
        },
      };
    }

    return {
      attributes: isEmpty(attributes) ? undefined : attributes,
      relationships: isEmpty(relationships) ? undefined : relationships,
    };
  }

  hasChanges(form: Parameters<QuestionRecord['diff']>[0]) {
    const { attributes, relationships } = this.diff(form);

    return !isEmpty(attributes) || !isEmpty(relationships);
  }

  hasTranslationsChanges(translations: $TSFixMe) {
    if (this.standard) {
      return translations.some(
        (item: $TSFixMe) => item.translation !== item.current,
      );
    }

    return (
      !isEqual(
        translations
          .map(
            ({
              // @ts-expect-error no implicit any
              locale,
            }) => locale,
          )
          .sort(),
        this.translations.keySeq().toArray().sort(),
      ) ||
      translations.some(
        (item: $TSFixMe) =>
          !this.translations.has(item.locale) ||
          item.translation !== this.translations.get(item.locale),
      )
    );
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes,
      relationships: {
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        context,
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        includedSegments,
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        excludedSegments,
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        value,
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        rule,
      } = {},
    } = data;

    const categoryId = get(data, 'relationships.category.id');
    const parentCategoryId = get(
      data,
      'relationships.category.relationships.parentCategory.id',
    );

    return new QuestionRecord(
      /*
       * The `fromJS` call here forces us to manually overwrite a lot of the properties in the Record schema
       * to take into account the fact that the object's properties all get converted to Immutable Objects, for example using `List<T>`, `Map<T>` or ImmutableObject<T>.
       *
       * Remember to update those manually overridden properties in the schema if we remove this `fromJS` call.
       */
      fromJS({
        id,
        ...attributes,
        category:
          categoryId &&
          Category.createFromApi(get(data, 'relationships.category')),
        categoryId,
        parentCategoryId,
        context: context && Context.createFromApi(context),
        includedSegments: includedSegments
          ? includedSegments
              .filter(
                (
                  // @ts-expect-error no implicit any
                  segment,
                ) => segment.attributes,
              ) // Filter segments that are relationships but have not been loaded
              .map(Segment.createFromApi)
          : List(),
        excludedSegments: excludedSegments
          ? excludedSegments
              .filter(
                (
                  // @ts-expect-error no implicit any
                  segment,
                ) => segment.attributes,
              )
              .map(Segment.createFromApi)
          : List(),
        value: value && Value.createFromApi(value),
        rule: rule
          ? {
              ruleUnit: rule.attributes?.ruleUnit,
              ruleValue: rule.attributes?.ruleValue,
              id: rule.id,
            }
          : undefined,
      }),
    );
  }

  static createFromCommentApi(data: $TSFixMe) {
    const {
      relationships: { question },
    } = data;
    const { id, attributes } = question;

    return new QuestionRecord({
      id,
      ...attributes,
    });
  }

  static get defaultFields() {
    return [
      'context',
      'category',
      'standard',
      'standardText',
      'text',
      'textRaw',
    ];
  }

  static get defaultAdminFields() {
    return [
      ...QuestionRecord.defaultFields,
      'customized',
      'driver', // FIXME: legacy
      'excludedSegments',
      'frequency',
      'includedSegments',
      'rights',
      'schedule',
      'standardTranslations',
      'status',
      'subdriver', // FIXME: legacy
      'translations',
      'type',
      'rule',
    ];
  }
}

// eslint-disable-next-line import/no-default-export
export default QuestionRecord;
