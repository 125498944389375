// eslint-disable-next-line no-restricted-imports -- we need this in order to provide the initialization function below
import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
// eslint-disable-next-line no-restricted-imports -- we intentionally use this import
import { initReactI18next } from 'react-i18next';
import store from 'store2';
import { z } from 'zod';

import * as localization from '@peakon/localization';
import errorReporter from '@peakon/shared/utils/errorReporter';

import { DEFAULT_LOCALE } from './constants';
import {
  getManagerScopedLocaleInfo,
  updateLanguageDependentServices,
} from './helpers';
import { messageFormatProcessor } from './postProcessors/messageFormat';
import {
  temporaryNamespace,
  temporaryTranslations,
} from './temporaryTranslations';

function missingKeyHandler(
  languages: readonly string[],
  namespace: string,
  key: string,
  fallbackValue: string,
) {
  /** contains only lowercase letters, numbers, underscores, dashes and might end in .message */
  const keyRegex = /^[a-z0-9_-]+(\.message)?$/;

  /** we do this to try and detect double translations
   * ie. we have a lot of `t(t(key))` in the code-base
   * which results in missingKeyHandler being triggered despite it already being translated */
  const isKey = keyRegex.test(key);

  if (!isKey) {
    return;
  }

  errorReporter.error(
    new Error(
      `Missing translation for translation key:
        {
          key: ${key}
          languages: ${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            languages
          }
          namespace: ${namespace}
          fallback value: ${fallbackValue}
        }`,
    ),
  );
}

type I18nOptions = {
  namespace: 'web' | 'employee-dashboard';
  getLocaleResource: (locale: string) => Promise<string>;
  /** options used in `ENV.clustEnv === test` */
  testingOptions?: InitOptions;
};

export const supportedLocales = localization
  .all({ employee: true, full: true })
  .map((language) => language.id);

export const initializeI18n = ({
  namespace,
  getLocaleResource,
  testingOptions = {},
}: I18nOptions) => {
  const showTranslationKeys = z
    .boolean()
    .nullish()
    .transform((value) => value ?? false) // ensure that the returned value is always a boolean, even if it's not set in local storage
    .parse(store.get('peakon.showTranslationKeys'));

  const extraOptions =
    ENV.clusterEnv === 'test'
      ? {
          ...testingOptions,
          /** in order to default to en-GB */
          lng: 'en',
        }
      : {};

  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
  i18n
    .use(messageFormatProcessor)
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init<HttpBackendOptions>(
      showTranslationKeys
        ? {
            lng: 'cimode',
            supportedLngs: ['cimode'],
            fallbackLng: 'cimode',
          }
        : {
            ns: [namespace, temporaryNamespace],
            defaultNS: namespace,
            returnNull: false,
            // this allows us to both load resources inline and using the backend middleware
            partialBundledLanguages: true,
            resources: temporaryTranslations,
            nsSeparator: '.',
            supportedLngs: [...supportedLocales, DEFAULT_LOCALE],
            fallbackLng: DEFAULT_LOCALE,
            debug: false,
            load: 'currentOnly',
            interpolation: {
              escapeValue: false,
              skipOnVariables: false,
            },
            backend: {
              loadPath: '{{lng}}',
              request: (async (_options, localeId, _payload, callback) => {
                const locale = await getLocaleResource(localeId);
                callback(null, {
                  data: locale,
                  status: 200,
                });
              }) satisfies HttpBackendOptions['request'],
            },
            saveMissing: true,
            missingKeyHandler,
            ...extraOptions,
          },
    )
    .then(() => {
      // ensure the API and other dependant services have the correct language set, otherwise we risk fetching data with the wrong language
      const locale = getManagerScopedLocaleInfo(
        i18n.resolvedLanguage ?? i18n.language,
      );
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
      updateLanguageDependentServices(locale);
    });
};
