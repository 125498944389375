import { fromJS, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

const schema = z.object({
  isRelevant: z.boolean(),
  isBiased: z.boolean(),
  isBiasedComment: z.string().nullable(),
});
type Schema = z.infer<typeof schema>;

class CommentFeedback
  extends Record({
    isRelevant: undefined,
    isBiased: undefined,
    isBiasedComment: undefined,
  })
  implements Schema
{
  isRelevant!: Schema['isRelevant'];
  isBiased!: Schema['isBiased'];
  isBiasedComment!: Schema['isBiasedComment'];

  static createFromApi(data: $TSFixMe) {
    const { isRelevant, isBiased, isBiasedComment } = data;

    return new CommentFeedback(
      fromJS({
        isRelevant,
        isBiased,
        isBiasedComment,
      }),
    );
  }

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CommentFeedback',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}

// eslint-disable-next-line import/no-default-export
export default CommentFeedback;
