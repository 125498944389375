import hasRight from '@peakon/shared/utils/hasRight';
import { Right } from '@peakon/shared/utils/rights/types';

import { contextSelector } from '../selectors/ContextSelectors';
import { sessionSelector } from '../selectors/SessionSelectors';
import { useAppSelector } from '../utils/reduxHooks';

export const useRightsChecker = (
  requiredRights: Right[],
  { context = false, globalRight = false } = {},
): [boolean, { sessionRights: string[]; contextRights: string[] }] => {
  const session = useAppSelector(sessionSelector);
  const sessionRights = session?.rights.toArray() ?? [];

  const contexts = useAppSelector(contextSelector);
  const contextRights = contexts.isEmpty()
    ? []
    : contexts.first().rights?.toArray() ?? [];

  if (globalRight) {
    return [
      hasRight(sessionRights, requiredRights) ||
        hasRight(contextRights, requiredRights),
      { sessionRights, contextRights },
    ];
  }

  const rights = context ? contextRights : sessionRights;

  return [hasRight(rights, requiredRights), { sessionRights, contextRights }];
};
