import React from 'react';

import get from 'lodash/get';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';

import { NotFoundScreen } from '@peakon/shared/components/NotFoundScreen/NotFoundScreen';
import { t } from '@peakon/shared/features/i18next/t';
import usePageTitle from '@peakon/shared/hooks/usePageTitle';
import hasRight from '@peakon/shared/utils/hasRight';
import { useShellContext } from '@peakon/shell';

import { BenchmarkSettings } from './Benchmark';
import { CommentsSettings } from './Comments/Comments';
import { DashboardSettings } from './DashboardSettings';
import { DashboardDataSettings } from './Data';
import { Recalculation } from './Recalculation/Recalculation';
import { SensitiveComments } from './SensitiveComments';
import { SharingSettings } from './Sharing';
import AdminSpinner from '../../../components/admin/AdminSpinner/AdminSpinner';
import { SettingsHeader } from '../../../components/navigation/pages/SettingsHeader';
import { RestrictedRoute } from '../../../components/RestrictedRoute';
import { useFeature } from '../../../hooks/useFeature';
import { useAppSelector } from '../../../utils/reduxHooks';

import styles from './styles.css';

export const DataSettingsMainPage = () => {
  usePageTitle(t('dashboard__settings__page_title'));

  const { session } = useShellContext();

  const company = useAppSelector((state) => state.company);
  const hasAdminRecalculation = useFeature('adminRecalculation');

  if (!company) {
    return <AdminSpinner />;
  }

  const hasShareAddOn = company
    ? company.hasAddOn('collaboration_shareable')
    : false;

  const hasShareRight = hasRight(get(session, 'data.attributes.rights'), [
    'share:admin:all',
  ]);

  const hasBenchmark = hasRight(get(session, 'data.attributes.rights'), [
    'attribute:admin',
  ]);

  const hasSensitiveComments = hasRight(
    get(session, 'data.attributes.rights'),
    ['sensitive:admin'],
  );

  return (
    <div className={styles.root}>
      <SettingsHeader
        hasSharing={hasShareRight && hasShareAddOn}
        hasBenchmark={hasBenchmark}
        hasSensitiveComments={hasSensitiveComments}
        hasAdminRecalculation={hasAdminRecalculation}
      />
      <div className={styles.componentRoot}>
        <Switch>
          <RestrictedRoute
            path="/admin/data-settings/dashboard"
            rights={['company:admin', 'sensitive:admin']}
            sessionRight={true}
            component={DashboardSettings}
          />
          <RestrictedRoute
            path="/admin/data-settings/data-aggregation"
            rights={['company:admin']}
            sessionRight={true}
            component={DashboardDataSettings}
          />
          <RestrictedRoute
            path="/admin/data-settings/comments"
            rights={['company:admin']}
            sessionRight={true}
            component={CommentsSettings}
          />

          <RestrictedRoute
            exact
            path="/admin/data-settings/sharing"
            rights={['share:admin:all']}
            sessionRight={true}
            addOn="collaboration_shareable"
            component={SharingSettings}
          />

          <RestrictedRoute
            exact
            path="/admin/data-settings/benchmark"
            rights={['attribute:admin']}
            sessionRight={true}
            component={BenchmarkSettings}
          />

          <RestrictedRoute
            exact
            path="/admin/data-settings/sensitive-comments"
            rights={['sensitive:admin']}
            sessionRight={true}
            component={SensitiveComments}
          />

          <RestrictedRoute
            exact
            path="/admin/data-settings/recalculation"
            feature="adminRecalculation"
            rights={['company:admin']}
            sessionRight={true}
            component={Recalculation}
          />

          <Route component={NotFoundScreen} />
        </Switch>
      </div>
    </div>
  );
};
