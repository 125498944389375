import React from 'react';

import { Combobox } from '@peakon/components';
import { BenchmarkType } from '@peakon/shared/types/Benchmark';

import { useBenchmarkTypeTranslationMap } from '../BenchmarkLabel/BenchmarkLabel';

type BaseOptions = Extract<BenchmarkType, 'overall' | 'industry'>;
type ExtendedOptionType = Exclude<BenchmarkType, BaseOptions>;
type Options<T extends ExtendedOptionType = null> = Readonly<
  Array<T extends null ? BaseOptions : BaseOptions | T>
>;

const BASE_OPTIONS: Options = ['overall', 'industry'] as const;
const BENCHMARK_OPTIONS: Options<'automatic'> = [
  'automatic',
  ...BASE_OPTIONS,
] as const;
const SEGMENT_BENCHMARK_OPTIONS: Options<'context'> = [
  ...BASE_OPTIONS,
  'context',
] as const;

export type SegmentBenchmarkOptions =
  (typeof SEGMENT_BENCHMARK_OPTIONS)[number];
export type BenchmarkOptions = (typeof BASE_OPTIONS)[number] | null;

const isSegmentBenchmarkOption = (
  option: string,
): option is SegmentBenchmarkOptions =>
  SEGMENT_BENCHMARK_OPTIONS.includes(option);

const isBenchmarkOptionNonNullable = (
  option: string,
): option is NonNullable<BenchmarkOptions> =>
  BASE_OPTIONS.includes(option) || option === '';

type CommonProps = {
  value: string | null;
};

type BenchmarkSelectorPropsWithTrue = CommonProps & {
  hasContext: true;
  onChange: ({ value }: { value: SegmentBenchmarkOptions }) => void;
};

type BenchmarkSelectorPropsWithFalse = CommonProps & {
  hasContext?: false;
  onChange: ({ value }: { value: BenchmarkOptions }) => void;
};

type BenchmarkSelectorProps =
  | BenchmarkSelectorPropsWithTrue
  | BenchmarkSelectorPropsWithFalse;

export const BenchmarkSelector = ({
  hasContext,
  onChange,
  value,
}: BenchmarkSelectorProps) => {
  const { benchmarkTypeTranslationMap } = useBenchmarkTypeTranslationMap();
  const options = (
    hasContext ? SEGMENT_BENCHMARK_OPTIONS : BENCHMARK_OPTIONS
  ).map((option) => ({
    value: option === 'automatic' ? null : option,
    label: benchmarkTypeTranslationMap.get(option),
  }));

  const selectedValue =
    options.find((option) => option.value === value) || options[0];

  return (
    <Combobox
      key="benchmark-selector"
      isClearable={false}
      selectedItem={selectedValue.label}
      onSelect={(item) => {
        if (hasContext && isSegmentBenchmarkOption(item)) {
          onChange({ value: item });
        } else if (isBenchmarkOptionNonNullable(item)) {
          onChange({ value: item || null });
        }
      }}
    >
      <Combobox.Select hasClear={false}>{selectedValue.label}</Combobox.Select>
      <Combobox.Menu fitTargetWidth>
        {options.map((option) => (
          // Using empty string '' here because Combobox.Option doesn't work with null for automatic benchmark
          <Combobox.Option key={option.value} value={option.value || ''}>
            {option.label}
          </Combobox.Option>
        ))}
      </Combobox.Menu>
    </Combobox>
  );
};
