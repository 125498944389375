import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

type DatasetParams = {
  isShare?: boolean;
  isTestMode?: boolean;
  closesAt?: string;
};

type GetDatasetParamsParams = {
  segmentId?: string | null;
  ignoreSegment?: boolean;
  isWithCategoryGroups?: boolean;
  categoryGroup?: string;
};

export type AdditionalDatasetParams = {
  filter?: {
    'round.type'?: 'test';
    'employee.segmentIds'?: string;
  };
  closesAt?: string;
  withCategoryGroups?: boolean;
  categoryGroup?: string;
};

export const createGetDatasetParams =
  ({ isShare, isTestMode, closesAt }: DatasetParams = {}) =>
  <T>(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    params = {} as T,
    {
      segmentId,
      ignoreSegment,
      isWithCategoryGroups,
      categoryGroup,
    }: GetDatasetParamsParams = {},
  ) => {
    // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
    params = cloneDeep(params);

    if (!isShare) {
      if (isTestMode) {
        // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
        params = merge(params, {
          filter: {
            'round.type': 'test',
          },
        });
      }
    }

    if (segmentId && !ignoreSegment) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      params = merge(params, {
        filter: {
          'employee.segmentIds': `${segmentId}$contains`,
        },
      });
    }

    if (closesAt) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      params = merge(params, {
        closesAt,
      });
    }

    if (isWithCategoryGroups) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      params = merge(params, {
        withCategoryGroups: true,
      });
    }

    if (categoryGroup) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      params = merge(params, {
        categoryGroup,
      });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return params as T & AdditionalDatasetParams;
  };
