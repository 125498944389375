import { List } from 'immutable';

import { Resource } from '@peakon/records';

import { getResourceName, getResourceState } from '../utils';

const initialSubscriptions = List(
  ['session_read', 'context_list'].map((name) => new Resource({ name })),
);

const loaders = (
  state = initialSubscriptions,
  action: {
    data: string[];
    type: 'LOADER_SUBSCRIBE' | 'LOADER_UNSUBSCRIBE';
  },
) => {
  if (action.type === 'LOADER_SUBSCRIBE') {
    const resources = action.data;

    return state.concat(
      resources.map((resource) => new Resource({ name: resource })),
    );
  } else if (action.type === 'LOADER_UNSUBSCRIBE') {
    const resources = action.data;

    return state.filter((r) => (r ? !resources.includes(r.name) : false));
  }

  const resourceName = getResourceName(action.type);
  const index = state.findIndex((r) => r?.name === resourceName);

  return index > -1
    ? state.update(
        index,
        (resource) =>
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          resource.set('state', getResourceState(action.type)) as Resource,
      )
    : state;
};

// eslint-disable-next-line import/no-default-export
export default loaders;
