import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  total: z.number().optional(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

class GroupExport
  extends Record({
    total: undefined,
    totalFiltered: undefined,
  })
  implements Schema
{
  total!: Schema['total'];
  totalFiltered?: number;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'GroupExport',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'GroupExport',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}

// eslint-disable-next-line import/no-default-export
export default GroupExport;
