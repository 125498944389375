import { fromJS, Record } from 'immutable';
import uniqueId from 'lodash/uniqueId';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  completed: z.any(),
  text: z.any(),
  isNew: z.any(),
});
type Schema = z.infer<typeof schema>;

class ActionItemRecord
  extends Record({
    id: undefined,
    completed: undefined,
    text: undefined,

    isNew: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  completed!: $TSFixMe;
  text!: $TSFixMe;
  isNew!: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionItemRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionItemRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(actionItem: $TSFixMe) {
    const {
      id,
      attributes: { completed, text },
    } = actionItem;

    // set as new when dealing with suggested checklist items
    const isNew = id.startsWith('action_');

    return new ActionItemRecord(
      fromJS({
        id,
        completed,
        text,
        isNew,
      }),
    );
  }

  static createNew() {
    return new ActionItemRecord({
      id: uniqueId('action_items_'),
      text: '',
      completed: false,
      isNew: true,
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default ActionItemRecord;
