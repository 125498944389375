import React, { useState } from 'react';

import {
  NavigationBasicNavigationChevronDownIcon,
  NavigationBasicNavigationChevronUpIcon,
} from '@peakon/bedrock/icons/system';
import { Button, IconButton } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { Checkbox } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import { ValueType } from './queries/schema';
import useDeleteValue from './queries/useDeleteValue';
import useToggleValue from './queries/useToggleValue';
import { ValueEdit } from './ValueEdit';
import { catchHandler } from '../../../actions/NotificationActions';
import { useAppDispatch } from '../../../utils/reduxHooks';

import styles from './styles.css';

export type ValueRowProps = {
  value: ValueType;
  onValueUpdatedOrDeleted: () => void;
};

export const ValueRow = ({ value, onValueUpdatedOrDeleted }: ValueRowProps) => {
  const dispatch = useAppDispatch();

  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isValueEnabled, setIsValueEnabled] = useState(
    value.question.status === 'active',
  );

  const handleError = (error: Error) => {
    dispatch(catchHandler(error));
  };

  const { mutate: deleteValue, isLoading } = useDeleteValue({
    onError: handleError,
    onSuccess: onValueUpdatedOrDeleted,
  });

  const { mutate: toggleValue } = useToggleValue({
    onError: handleError,
  });

  const handleOnDelete = async () => {
    await deleteValue(value.id);
    setIsDeleteModalOpen(false);
  };

  const handleValueStatusToggled = async (isEnabled: boolean) => {
    setIsValueEnabled(isEnabled);
    await toggleValue({ isEnabled, valueId: value.id });
  };

  return (
    <React.Fragment>
      <Modal
        open={isDeleteModalOpen}
        onDismiss={() => setIsDeleteModalOpen(false)}
        heading={t('values__delete__confirm__title')}
        closeLabel={t('common__close')}
      >
        <Modal.Content>
          <div>
            {t('values__delete__confirm', { replace: { name: value.name } })}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            variant="secondary"
            onClick={() => setIsDeleteModalOpen(false)}
            data-test-id="confirm-modal-cancel"
          >
            {t('cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={handleOnDelete}
            disabled={isLoading}
            data-test-id="confirm-modal-confirm"
          >
            {t('question__delete')}
          </Button>
        </Modal.Actions>
      </Modal>

      <div className={styles.valuesRow}>
        <span>{value.name}</span>
        <div className={styles.valuesRowButtons}>
          <Checkbox
            type="switch"
            checked={isValueEnabled}
            disabled={isDetailsExpanded}
            onChange={handleValueStatusToggled}
            aria-label={value.name}
            testId={`${value.name}_value_toggler`}
          />
          <IconButton
            variant="secondary"
            size="small"
            onClick={() => setIsDetailsExpanded(!isDetailsExpanded)}
            aria-expanded={isDetailsExpanded}
            icon={
              isDetailsExpanded ? (
                <NavigationBasicNavigationChevronUpIcon />
              ) : (
                <NavigationBasicNavigationChevronDownIcon />
              )
            }
            accessibleName={`${
              isDetailsExpanded ? t('a11y_collapse') : t('a11y_expand')
            }, ${value.name}`}
          />
        </div>
      </div>
      {isDetailsExpanded && (
        <ValueEdit
          valueData={value}
          isCreating={false}
          onCancel={() => setIsDetailsExpanded(false)}
          onDelete={() => {
            setIsDeleteModalOpen(true);
          }}
        />
      )}
    </React.Fragment>
  );
};
