import { isPreviewApp } from './isPreviewApp';
import { APP_SUBDOMAIN } from '../features/subdomains/constants';
import { getCurrentSubdomain } from '../features/subdomains/getCurrentSubdomain';

const currentSubdomain = getCurrentSubdomain();
const envConfig = ENV;

if (currentSubdomain !== APP_SUBDOMAIN) {
  if (isPreviewApp()) {
    envConfig.survey.host = `https://${currentSubdomain}.staging-peakon.com`;
  } else {
    envConfig.survey.host = window.location.origin;
  }
}

// eslint-disable-next-line import/no-default-export
export default envConfig;
