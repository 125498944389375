import { Map, List } from 'immutable';

import { StandardAction, Course, Resource } from '@peakon/records/improve';
import CustomAction from '@peakon/records/improve/CustomActionRecord';
import CustomResource from '@peakon/records/improve/CustomResourceRecord';

const getType = (data: $TSFixMe) => {
  const [type] = data.id.split('_');
  return type;
};

const createRecordsFromJsonApi = (data = {}) => {
  const {
    // @ts-expect-error TS(2339): Property 'attributes' does not exist on type '{}'.
    attributes: { provider },
  } = data;

  const ResourceRecord = provider === 'company' ? CustomResource : Resource;
  const ActionRecord = provider === 'company' ? CustomAction : StandardAction;

  switch (getType(data)) {
    case 'course': {
      const {
        // @ts-expect-error TS(2339): Property 'relationships' does not exist on type '{... Remove this comment to see the full error message
        relationships: { course },
      } = data;
      return Course.createFromApi(course);
    }
    case 'tutorial': {
      const {
        // @ts-expect-error TS(2339): Property 'relationships' does not exist on type '{... Remove this comment to see the full error message
        relationships: { category, tutorial },
      } = data;
      return ResourceRecord.createFromApi(tutorial, { category });
    }
    case 'action': {
      const {
        // @ts-expect-error TS(2339): Property 'relationships' does not exist on type '{... Remove this comment to see the full error message
        relationships: { action },
      } = data;
      return ActionRecord.createFromApi(action);
    }
    default:
      return null;
  }
};

const getInitialState = () =>
  Map({
    items: List(),
    isLoading: false,
    resources: List(),
    customActions: List(),
  });

const improveResources = (state = getInitialState(), action: $TSFixMe) => {
  switch (action.type) {
    case 'ACTION_SUGGESTED_CREATE_SUCCESS': {
      const {
        data: { id },
      } = action;

      return state.update('items', (items) =>
        // @ts-expect-error TS(2339): Property 'filter' does not exist on type 'boolean ... Remove this comment to see the full error message
        items.filter((item) => {
          // only filter if we're dealing with an action
          if (item instanceof CustomAction || item instanceof StandardAction) {
            return item.id !== id;
          }

          // always return true for other content types
          return true;
        }),
      );
    }
    case 'IMPROVE_OVERVIEW_V2_LOADING':
    case 'IMPROVE_OVERVIEW_V2_READ_LOADING': {
      return state.merge({
        isLoading: true,
      });
    }
    case 'IMPROVE_OVERVIEW_V2_READ_SUCCESS': {
      const { isPriorityRequest, data, links, meta } = action.data;

      // @ts-expect-error TS(2339): Property 'map' does not exist on type 'boolean | L... Remove this comment to see the full error message
      const currentIds = state.get('items').map((item) => item.id);

      const nextRecords = List(data.map(createRecordsFromJsonApi)).filter(
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        (item) => !currentIds.includes(item.id),
      );

      return state.merge({
        // @ts-expect-error TS(2339): Property 'concat' does not exist on type 'boolean ... Remove this comment to see the full error message
        items: state.get('items').concat(nextRecords),
        links,
        meta,
        isLoading: isPriorityRequest,
      });
    }
    case 'IMPROVE_RESOURCES_READ_SUCCESS': {
      const { data } = action.data;

      const resources = List(data.map(createRecordsFromJsonApi));

      return state.merge({
        resources,
      });
    }
    case 'IMPROVE_CUSTOM_ACTIONS_READ_SUCCESS': {
      const { data } = action.data;

      const customActions = List(data.map(createRecordsFromJsonApi));

      return state.merge({
        customActions,
      });
    }
    case 'IMPROVE_OVERVIEW_V2_RESET': {
      return getInitialState();
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default improveResources;
