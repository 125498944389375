import React from 'react';

import Acknowledgement from '@peakon/records/AcknowledgementRecord';
import { AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';

import AcknowledgementToggler from '../../acknowledgement/AcknowledgementToggler';
import ConversationToggler from '../../ConversationToggler';

import styles from './styles.css';

type Props = {
  acknowledgement?: Acknowledgement | null;
  active?: boolean;
  messageCount?: number;
  hasAcknowledgements?: boolean;
  hasConversations: boolean;
  onAcknowledge?: (
    type: AcknowledgementType,
    opts: { optOutConfirm?: boolean },
  ) => Promise<void>;
  onConversationsClick?: React.MouseEventHandler<HTMLButtonElement>;
  shouldConfirmAcknowledgement: boolean;
};

export const CommentFooter = ({
  acknowledgement,
  active,
  messageCount,
  hasAcknowledgements,
  hasConversations,
  onAcknowledge,
  onConversationsClick,
  shouldConfirmAcknowledgement,
}: Props) => {
  if (!hasAcknowledgements && !hasConversations) {
    return null;
  }

  return (
    <div className={styles.root}>
      {hasAcknowledgements && (
        <AcknowledgementToggler
          acknowledgement={acknowledgement}
          onAcknowledge={onAcknowledge}
          shouldConfirm={shouldConfirmAcknowledgement}
        />
      )}

      {hasConversations && (
        <ConversationToggler
          active={active}
          count={messageCount}
          onClick={onConversationsClick}
        />
      )}
    </div>
  );
};
