import React from 'react';

import { useController } from 'react-hook-form';
import { z } from 'zod';

import { RadioGroup as BRRadioGroup } from '@peakon/bedrock/react/form';

import { ControlledProps, ZodSchemaOrRecord } from '../../utils/types';
import { useRequiredFields } from '../RequiredFieldsProvider';

type RadioFieldProps = React.ComponentPropsWithoutRef<typeof BRRadioGroup> & {
  required?: boolean;
};

type RadioItemProps = React.ComponentPropsWithRef<typeof BRRadioGroup.Item>;
type FormRadioItem = React.FC<Omit<RadioItemProps, 'name' | 'required'>> & {
  children: React.ReactNode;
};

type Props<T extends ZodSchemaOrRecord> = ControlledProps<T, RadioFieldProps>;

export function RadioGroup<T extends ZodSchemaOrRecord = z.ZodRawShape>(
  props: Props<T>,
) {
  const {
    name,
    shouldUnregister,
    disabled,
    required,
    children,
    ...restRadioGroupProps
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    shouldUnregister,
    disabled,
  });

  const requiredFields = useRequiredFields();
  const isRequired = Boolean(required ?? requiredFields?.includes(name));

  const { onChange, ...restField } = field;

  // Pass required and name field to each child (radio item)
  const radioItems = React.Children.map(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    children as React.ReactElement<RadioItemProps>[],
    (radioItem) =>
      React.isValidElement(radioItem) &&
      React.cloneElement(radioItem, {
        required: isRequired,
        onChange,
        name,
        checked: field.value === radioItem.props.value,
      }),
  );

  return (
    <BRRadioGroup
      status={error && 'error'}
      feedbackMessage={error?.message}
      {...restRadioGroupProps}
      {...restField}
    >
      {radioItems}
    </BRRadioGroup>
  );
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
RadioGroup.Item = BRRadioGroup.Item as unknown as FormRadioItem;
