import { List, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import ActionRecord from './ActionRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({
  isLoading: z.boolean().optional(),
});
const testingSchema = schema.extend({
  list: z.any(),
  nextUrl: z.any(),
  total: z.any(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class ActionsListRecord
  extends Record({
    isLoading: false,
    list: List<ActionRecord>(),
    nextUrl: undefined,
    total: 0,
  })
  implements Schema
{
  isLoading!: Schema['isLoading'];
  list!: List<ActionRecord>;
  nextUrl: string | undefined;
  total!: number;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionsListRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionsListRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
