import { createSelector } from 'reselect';

import { DEFAULT_LOCALE } from '../constants/locales';
import { RootState } from '../types/redux';

const accountSelector = (state: RootState) => state.account;

export const getAccountLocale = createSelector([accountSelector], (account) =>
  account ? account.localeEffective : DEFAULT_LOCALE,
);
