import { List } from 'immutable';

import Segment from '@peakon/records/SegmentRecord';

const segments = (state = List<Segment>(), action: $TSFixMe) => {
  switch (action.type) {
    case 'SEGMENT_READ_SUCCESS': {
      return state.push(Segment.createFromApi(action.data.data));
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default segments;
