import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import QuestionRecord from './QuestionRecord';
import { validateTestingSchema } from './utils';

type FromQuestionProps = {
  id: string;
  question: QuestionRecord;
};

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

class QuestionScoresRecord
  extends Record({
    id: undefined,
    score: undefined,
    scores: undefined,
    question: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  question!: QuestionRecord;
  score?: string;
  scores?: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'QuestionScoresRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'QuestionScoresRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get categoryId() {
    return this.question?.categoryId;
  }

  get parentCategoryId() {
    return this.question?.parentCategoryId;
  }

  get text() {
    return this.question?.text;
  }

  get questionId() {
    return this.question?.id;
  }

  static fromQuestion(data: FromQuestionProps) {
    return new QuestionScoresRecord({
      id: data.id,
      question: QuestionRecord.createFromApi(data),
    });
  }

  static createFromApi(data: $TSFixMe) {
    const { id } = data;
    const scores = data.attributes.scores;
    const question = QuestionRecord.createFromApi(data.relationships.question);

    return new QuestionScoresRecord({ id, scores, question });
  }
}

// eslint-disable-next-line import/no-default-export
export default QuestionScoresRecord;
