import { combineReducers } from '@reduxjs/toolkit';

import frequencies from './frequencies';
import schedule from './schedule';
import createForm from '../utils/createForm';

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  form: createForm(schedule, {
    persistAndReset: ['SCHEDULE_READ_SUCCESS', 'SCHEDULE_UPDATE_SUCCESS'],
    reset: 'SCHEDULE_FORM_RESET',
    persist: ['SCHEDULE_QUESTION_LIMIT_REMOVED'],
  }),
  frequencies: createForm(frequencies, {
    persistAndReset: [
      'SCHEDULE_FREQUENCIES_READ_SUCCESS',
      'SCHEDULE_FREQUENCIES_UPDATE_SUCCESS',
    ],
    reset: 'SCHEDULE_FREQUENCY_FLUSH',
  }),
});
