import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class EngagementSegment
  extends Record({
    id: undefined,
    closesAt: undefined,
    categoryGroups: undefined,
  })
  implements Schema
{
  id!: Schema['id'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'EngagementSegment',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'EngagementSegment',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes: { closesAt, categoryGroups },
    } = data;

    return new EngagementSegment({
      id,
      closesAt,
      categoryGroups,
    });
  }
}
