import React from 'react';

import { GuidanceWhatDoesThisMeanOutlineIcon as HelpIcon } from '@peakon/bedrock/icons/system';
import { Menu } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import styles from '../styles.css';

type Props = {
  helpLabel: string;
  welcomeTourItem?: React.ReactNode;
};

const Help = ({ helpLabel, welcomeTourItem }: Props) => {
  return (
    <Menu id="app-header-help">
      <Menu.Target aria-label={helpLabel}>
        <div className={styles.iconLink}>
          <HelpIcon />
        </div>
      </Menu.Target>
      <Menu.Popover placement="bottom-start">
        <Menu.ItemLink
          href="https://doc.workday.com/en-us/peakon.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('multi_product__help__menu_item__help_centre')}
        </Menu.ItemLink>

        <Menu.ItemLink
          href="https://community-content.workday.com/content/workday-community/en-us/kits-and-tools/get-help/support/create-a-case.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('multi_product__help__menu_item__contact_support')}
        </Menu.ItemLink>

        <Menu.ItemLink
          href="https://status.peakon.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('multi_product__help__menu_item__contact_status')}
        </Menu.ItemLink>

        <Menu.ItemLink
          href="https://www.workday.com/content/dam/web/en-us/documents/privacy/workday-peakon-employee-voice-cookie-notice.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('multi_product__help__menu_item__cookie_policy')}
        </Menu.ItemLink>

        {welcomeTourItem}
      </Menu.Popover>
    </Menu>
  );
};

// eslint-disable-next-line import/no-default-export
export default Help;
