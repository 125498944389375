type State = {
  expandedSegments: Array<string>;
  isSorting: boolean;
  sort: string;
  order: 'asc' | 'desc';
};

const getInitialState = () => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    expandedSegments: [],
    isSorting: false,
    sort: 'name',
    order: 'asc',

    /**
     *
     * Some reading here if you want to know about `satisfies T as T` https://github.com/microsoft/TypeScript/issues/51853
     *
     */
  } satisfies State as State;
};

const segmentTables = (state = getInitialState(), action: $TSFixMe): State => {
  switch (action.type) {
    case 'MANAGER_METRICS_ACTION_SEGMENTS_LOADING':
    case 'MANAGER_METRICS_COMMENT_SEGMENTS_LOADING':
    case 'MANAGER_METRICS_ACTIVITY_SEGMENTS_LOADING': {
      return getInitialState();
    }

    case 'MANAGER_METRICS_TABLES_COLLAPSE_SEGMENT': {
      const { segmentId } = action.data;
      const { expandedSegments } = state;

      return {
        ...state,
        expandedSegments: expandedSegments.filter(
          (segment) => segment !== segmentId,
        ),
      };
    }

    case 'MANAGER_METRICS_TABLES_EXPAND_SEGMENT': {
      const { segmentId } = action.data;
      const { expandedSegments } = state;

      if (expandedSegments.includes(segmentId)) {
        return state;
      }

      return {
        ...state,
        expandedSegments: expandedSegments.concat([segmentId]),
      };
    }

    case 'MANAGER_METRICS_SEGMENT_TABLE_SORT': {
      const { sort, order } = action.data;

      return {
        ...state,
        isSorting: true,
        sort,
        order,
      };
    }

    case 'MANAGER_METRICS_SEGMENT_TABLE_SORT_COMPLETED': {
      return {
        ...state,
        isSorting: false,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default segmentTables;
