import { List, Map, Set, Record, fromJS } from 'immutable';
import pickBy from 'lodash/pickBy';
import { z } from 'zod';

import { t } from '@peakon/shared/features/i18next/t';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { AttributeOption } from './AttributeRecord';
import Segment from './SegmentRecord';
import { AccessSettingGroupKey } from './settings';
import { validateTestingSchema } from './utils';

// Data requirements for AccessGroup
export const REQUIRED_FIELDS = {
  fields: {
    groups: [
      'attribute',
      'attributeOption',
      'excludedSegments',
      'includedSegments',
      'level',
      'memberCount',
      'memberType',
      'name',
      'settings',
      'standard',
      'status',
      'categoryIds',
      'categoryGroupSettings',
      'categoryIds',
      'specialist',
      'sort',
    ].join(','),
  },

  include: [
    'attribute',
    'attributeOption',
    'includedSegments',
    'excludedSegments',
    'includedSegments.attribute',
    'excludedSegments.attribute',
  ].join(','),
};

// https://github.com/peakon/api/blob/master/models/common/group_model.js#L398
export const ACCESS_GROUP_STANDARDS = [
  'admin',
  'manager',
  'employee',
  'hr',
  'leadership',
] as const;
type AccessGroupStandard = (typeof ACCESS_GROUP_STANDARDS)[number];

// https://github.com/peakon/api/blob/master/models/common/group_model.js#L385
export const ACCESS_GROUP_MEMBER_TYPES = [
  'all',
  'manager',
  'member',
  'attribute',
] as const;
export type AccessGroupMemberType = (typeof ACCESS_GROUP_MEMBER_TYPES)[number];

// https://github.com/peakon/api/blob/master/models/common/group_model.js#L392
export const ACCESS_GROUP_LEVELS = [
  'all',
  'manager',
  'overall',
  'context',
] as const;
export type AccessGroupLevel = (typeof ACCESS_GROUP_LEVELS)[number];

const accessGroupSchema = z.object({
  id: z.string().optional(),
});

const testingAccessGroupSchema = accessGroupSchema.extend({
  settings: z.any(),
  categoryGroupSettings: z.any(),
  level: z.any(),
  excludedSegments: z.any(),
  name: z.any(),
  specialist: z.any(),
  attributeOption: z.any(),
  status: z.any(),
  memberType: z.any(),
  memberCount: z.any(),
  categoryIds: z.any(),
  includedSegments: z.any(),
  standard: z.any(),
  sort: z.number(),

  // probably a typo somewhere
  attributeId: z.any().optional(),
  attributeid: z.string().optional(),
  attributeOptionId: z.any().optional(),
  attributeOptionid: z.string().optional(),
});

type AccessGroupSchema = z.infer<typeof accessGroupSchema>;

class AccessGroup
  extends Record({
    id: undefined,
    name: undefined,
    standard: undefined,
    memberType: undefined,
    level: undefined,
    specialist: false,
    memberCount: 0,
    status: undefined,
    settings: Map(),
    categoryGroupSettings: Map(),
    categoryIds: Set(),

    attributeId: undefined,
    attributeOptionId: undefined,
    attributeOption: undefined,

    includedSegments: undefined,
    excludedSegments: undefined,

    sort: undefined,
  })
  implements AccessGroupSchema
{
  id!: AccessGroupSchema['id'];
  name?: string;
  standard?: AccessGroupStandard;
  memberType?: AccessGroupMemberType;
  level?: AccessGroupLevel;
  specialist!: boolean;
  memberCount!: number;
  status?: 'enabled' | 'disabled';
  settings!: Map<AccessSettingGroupKey, boolean>;
  categoryGroupSettings!: Map<string, string>;
  categoryIds!: Set<string>;

  attributeId?: string;
  attributeOptionId?: string;
  attributeOption?: AttributeOption;

  includedSegments?: Segment[];
  excludedSegments?: Segment[];

  // FIXME change prop name to avoid conflicting with Record.sort
  // @ts-expect-error Property sort in type is not assignable to the same property in base type
  sort?: number;

  constructor(props: unknown = {}) {
    validateRecord(props, accessGroupSchema, {
      errorMessagePrefix: 'AccessGroup',
    });
    validateTestingSchema(props, testingAccessGroupSchema, {
      errorMessagePrefix: 'AccessGroup',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get url() {
    return this.standard || this.id;
  }

  get path() {
    return `/admin/access/${this.specialist ? 'specialist' : 'groups'}/${
      this.url
    }`;
  }

  get levelTitle() {
    const titleDirectory = {
      manager: t('groups__level-picker__manager__title'),
      all: t('groups__level-picker__all__title'),
      overall: t('groups__level-picker__overall__title'),
      context: t('groups__level-picker__context__title'),
    };

    return this.level ? titleDirectory[this.level] : '';
  }

  canRemoveMembers() {
    return this.memberType === 'member';
  }

  isEnabled() {
    return this.status === 'enabled';
  }

  canNotify() {
    const notifiableSettings = [
      'attributeAdmin',
      'questionAdmin',
      'scheduleAdmin',
      'dataset',
      'readEmployees',
    ] as const;

    return (
      this.isEnabled() &&
      notifiableSettings.some((setting) => this.settings.get(setting))
    );
  }

  /**
   * Checks if the current user can notify a given member from this group.
   * @param employeeId  The authSession employee id.
   * @param memberId    The employee id of the member to be notified.
   */
  canNotifyMember(employeeId: string, memberId: string) {
    return (
      this.canNotify() &&
      this.standard !== 'employee' &&
      employeeId !== memberId
    );
  }

  /**
   * Checks if the current user can remove a given member from this group.
   * @param employeeId  The authSession employee id.
   * @param memberId    The employee id of the member to be removed.
   */
  canRemoveMember(employeeId: string, memberId: string) {
    return (
      this.canRemoveMembers() &&
      (this.standard !== 'admin' || employeeId !== memberId)
    );
  }

  toJsonApi({
    type = 'PATCH',
    hasAccessByQuestionSet = false,
    hasAccessByCategory = false,
  } = {}) {
    const {
      name,
      level,
      memberType,
      attributeId,
      attributeOptionId,
      specialist,
      standard,
      status,
      settings,
      categoryGroupSettings,
      categoryIds,
      includedSegments,
      excludedSegments,
      sort,
    } = this.toJS();

    let attributes = {
      name,
      level,
      memberType,
      status,
      specialist,
      sort,
      standard,
    };

    if (attributeId && attributeOptionId) {
      // @ts-expect-error TS(2322): Type '{ attributeId: any; attributeOptionId: any; ... Remove this comment to see the full error message
      attributes = { ...attributes, attributeId, attributeOptionId };
    }

    if (hasAccessByQuestionSet && standard !== 'admin') {
      // @ts-expect-error TS(2322): Type '{ categoryGroupSettings: any; name: any; lev... Remove this comment to see the full error message
      attributes = { ...attributes, categoryGroupSettings };
      if (hasAccessByCategory && categoryIds && level !== 'overall') {
        // @ts-expect-error TS(2322): Type '{ categoryIds: any; name: any; level: any; m... Remove this comment to see the full error message
        attributes = { ...attributes, categoryIds };
      }
    }

    let relationships;

    if (includedSegments) {
      if (!relationships) {
        relationships = {};
      }

      // @ts-expect-error TS(2339): Property 'includedSegments' does not exist on type... Remove this comment to see the full error message
      relationships.includedSegments = {
        data:
          includedSegments.length > 0
            ? includedSegments.map(
                (
                  // @ts-expect-error Parameter 'segment' implicitly has an 'any' type.ts(7006)
                  segment,
                ) => ({
                  type: 'segments',
                  id: segment.id,
                }),
              )
            : null,
      };
    }

    if (excludedSegments) {
      if (!relationships) {
        relationships = {};
      }

      // @ts-expect-error TS(2339): Property 'excludedSegments' does not exist on type... Remove this comment to see the full error message
      relationships.excludedSegments = {
        data:
          excludedSegments.length > 0
            ? excludedSegments.map(
                (
                  // @ts-expect-error no implicit any
                  segment,
                ) => ({
                  type: 'segments',
                  id: segment.id,
                }),
              )
            : null,
      };
    }

    const hasPermissionEnabled = Object.keys(settings).some(
      (setting) => settings[setting],
    );

    // When creating, only send settings if there as it least one enabled
    if ((type === 'POST' && hasPermissionEnabled) || type === 'PATCH') {
      // @ts-expect-error TS(2322): Type '{ settings: any; name: any; level: any; memb... Remove this comment to see the full error message
      attributes = { ...attributes, settings };
    }

    return pickBy({ type: 'groups', attributes, relationships });
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes,
      relationships: {
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        attribute,
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        attributeOption,
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        includedSegments,
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        excludedSegments,
      } = {},
    } = data;

    return new AccessGroup(
      fromJS({
        id,
        ...attributes,
        attributeId: attribute ? attribute.id : undefined,
        attributeOptionId: attributeOption ? attributeOption.id : undefined,
        attributeOption: attributeOption
          ? AttributeOption.createFromApi(attributeOption)
          : undefined,
        settings: Map(attributes.settings),
        categoryGroupSettings: Map(attributes.categoryGroupSettings),
        categoryIds: attributes.categoryIds
          ? Set(
              // we need to convert to String so it is typed correctly to be filtered against categories, which we store by ID
              attributes.categoryIds.map((c: string) => c.toString()),
            )
          : Set(),
        includedSegments: includedSegments
          ? includedSegments
              .filter(
                (
                  // @ts-expect-error no implicit any
                  segment,
                ) => segment.attributes,
              )
              .map(Segment.createFromApi)
          : List(),
        excludedSegments: excludedSegments
          ? excludedSegments
              .filter(
                (
                  // @ts-expect-error no implicit any
                  segment,
                ) => segment.attributes,
              )
              .map(Segment.createFromApi)
          : List(),
      }),
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default AccessGroup;
