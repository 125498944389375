import { APP_SUBDOMAIN } from '@peakon/shared/features/subdomains/constants';
import { getCurrentSubdomain } from '@peakon/shared/features/subdomains/getCurrentSubdomain';
import { isPreviewApp } from '@peakon/shared/utils/isPreviewApp';

const currentSubdomain = getCurrentSubdomain();
const envConfig = ENV;

if (currentSubdomain !== APP_SUBDOMAIN) {
  if (isPreviewApp()) {
    envConfig.training = `https://${currentSubdomain}.staging-peakon.com`;
    envConfig.survey.host = `https://${currentSubdomain}.staging-peakon.com`;
  } else {
    envConfig.training = window.location.origin;
    envConfig.survey.host = window.location.origin;
  }
}

// eslint-disable-next-line import/no-default-export
export default envConfig;
