import React from 'react';

import { useController } from 'react-hook-form';
import { z } from 'zod';

import { TextArea as BRTextArea } from '@peakon/bedrock/react/form';

import { ZodSchemaOrRecord, ControlledProps } from '../../utils/types';
import { useRequiredFields } from '../RequiredFieldsProvider';

type TextAreaProps = React.ComponentPropsWithoutRef<typeof BRTextArea>;

type Props<T extends ZodSchemaOrRecord> = ControlledProps<T, TextAreaProps>;

export function TextArea<T extends ZodSchemaOrRecord = z.ZodRawShape>(
  props: Props<T>,
) {
  const { name, shouldUnregister, disabled, required, ...restInputProps } =
    props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    shouldUnregister,
    disabled,
  });

  const requiredFields = useRequiredFields();
  const isRequired = Boolean(required ?? requiredFields?.includes(name));

  return (
    <BRTextArea
      status={error && 'error'}
      feedbackMessage={error?.message}
      disabled={disabled}
      required={isRequired}
      {...restInputProps}
      {...field}
    />
  );
}
