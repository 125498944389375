import React, { useEffect, useRef } from 'react';

import ProductTourStepModal from './ProductTourStepModal/ProductTourStepModal';
import ProductTourStepTooltip from './ProductTourStepTooltip/ProductTourStepTooltip';
import { ProductTourStep } from './types';

export type ProductTourStepRendererProps<
  StepType extends ProductTourStep = ProductTourStep,
> = {
  step: StepType;
  currentStep: number;
  onClose: () => void;
  onComplete: () => void;
  onStepClick: (step: number) => void;
  totalSteps: number;
  hasSkippedTour: boolean;
};

export type ProductTourStepComponentProps<
  StepType extends ProductTourStep = ProductTourStep,
> = Omit<ProductTourStepRendererProps<StepType>, 'onComplete'> & {
  isLast: boolean;
  isFirst: boolean;
};

// eslint-disable-next-line import/no-default-export
export default function ProductTourStepRenderer(
  props: ProductTourStepRendererProps,
) {
  const initialOverflowStyleRef = useRef<string>('auto');
  const { step, onComplete, onClose, ...rest } = props;
  const isLast = props.currentStep > props.totalSteps;
  const isFirst = props.currentStep === 1;

  useEffect(() => {
    isLast && onComplete?.();
  }, [isLast, onComplete]);

  // Remove scroll on app-content
  useEffect(() => {
    const container = window.document.getElementById('app-content');
    initialOverflowStyleRef.current = container?.style.overflow ?? 'auto';

    if (container) {
      container.style.overflow = 'hidden';
    }

    return () => {
      if (container) {
        container.style.overflow = initialOverflowStyleRef.current;
      }
    };
  }, []);

  const handleClose = () => {
    const container = window.document.getElementById('app-content');

    if (container) {
      container.style.overflow = initialOverflowStyleRef.current;
    }

    onClose();
  };

  const componentProps = {
    isLast,
    isFirst,
    onClose: handleClose,
    ...rest,
  };

  switch (step.type) {
    case 'modal':
      return <ProductTourStepModal step={step} {...componentProps} />;
    case 'tooltip':
      return <ProductTourStepTooltip step={step} {...componentProps} />;
  }
}
