import Context from '@peakon/records/ContextRecord';
import api from '@peakon/shared/utils/api';

import { Dispatch } from '../types/redux';
import { asyncDispatch } from '../utils';

export const change = (context: Context) => ({
  type: 'CONTEXT_CHANGED',
  data: context,
});

export const resetContexts = () => ({
  type: 'CONTEXT_LIST_RESET',
});

export const list = () => (dispatch: Dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'CONTEXT_LIST',
    action: api.context.list({
      fields: {
        attributes: ['name', 'nameTranslated', 'type'].join(','),
        contexts: [
          'rights',
          'level',
          'logo',
          'name',
          'employee',
          'direct',
          'attribute',
          'abbreviation',
          'contextCategoryGroups',
        ].join(','),
      },

      include:
        'attribute,contextCategoryGroups,contextCategoryGroups.categoryGroup',
    }),
  });
};

export const pushContextList = (contexts: $TSFixMe) => ({
  type: 'CONTEXT_LIST_SUCCESS',
  data: contexts,
});
