import FormEditorRecord from '@peakon/records/FormEditorRecord';

const createForm = (
  reducer: $TSFixMe,
  { persistAndReset = [], reset, persist = [] }: $TSFixMe = {},
) => {
  const initialState = new FormEditorRecord();

  return (state = initialState, action: $TSFixMe): FormEditorRecord => {
    if (persistAndReset.includes(action.type)) {
      const current = reducer(state.current, action);

      return FormEditorRecord.startEditing(current);
    } else if (reset === action.type) {
      return FormEditorRecord.startEditing(state.original);
    } else if (persist.includes(action.type)) {
      return state
        .set('current', reducer(state.current, action))
        .set('original', reducer(state.original, action));
    }

    return state.set('current', reducer(state.current, action));
  };
};

// eslint-disable-next-line import/no-default-export
export default createForm;
