import React from 'react';

import { Link } from 'react-router-dom';

import { EmptyStateFeatureSensitiveComments } from '@peakon/bedrock/illustrations/react/empty-state';
import { Button, Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

const getContent = (enabled?: boolean, isAdmin?: boolean) => {
  if (enabled) {
    return {
      title: t('comments__sensitive_empty__title'),
      text: t('comments__sensitive_empty__text'),
    };
  }

  return {
    title: t('comments__sensitive_disabled__title'),
    text: isAdmin
      ? t('comments__sensitive_disabled__text')
      : t('comments__sensitive_disabled__text_non-admin'),
  };
};

type SensitiveEmptyStateProps = {
  isAdmin?: boolean;
  isSensitiveEnabled?: boolean;
};

const SensitiveEmptyState = ({
  isAdmin,
  isSensitiveEnabled,
}: SensitiveEmptyStateProps) => {
  const { title, text } = getContent(isSensitiveEnabled, isAdmin);

  return (
    <div className={styles.root}>
      <div>
        <EmptyStateFeatureSensitiveComments />
      </div>
      <Typography.H4 className={styles.title}>{title}</Typography.H4>
      <p className={styles.text}>{text}</p>
      {isAdmin && !isSensitiveEnabled && (
        <Button
          // @ts-expect-error TS(2322): Type '{ children: string & BRAND<"TranslatedString... Remove this comment to see the full error message
          to="/admin/data-settings/sensitive-comments"
          type="primary"
          size="small"
          tag={Link}
        >
          {t('comments__sensitive_disabled__cta')}
        </Button>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default SensitiveEmptyState;
