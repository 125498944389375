import React, { Component, Fragment } from 'react';

import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import { analytics } from '@peakon/shared/features/analytics/analytics';
import { t } from '@peakon/shared/features/i18next/t';

import { FeedbackType } from './CommentFeedback/CommentFeedback';
import {
  Comment as CommentComponent,
  Props as CommentComponentProps,
} from './Core';
import {
  listCommentManagers as listCommentManagersAction,
  onTranslate as onTranslateAction,
  onRevertTranslate as onRevertTranslateAction,
  sendFeedback as sendFeedbackAction,
  sendSemanticTopicsFeedback as sendSemanticTopicsFeedbackAction,
  deleteComment as deleteCommentAction,
  acknowledgeComment as acknowledgeCommentAction,
  markAsSensitive as markAsSensitiveAction,
  markNotSensitive as markNotSensitiveAction,
} from '../../actions/CommentActions';
import { showSuccessNotification as showSuccessNotificationAction } from '../../actions/NotificationActions';
import { complete as completeUXFlowAction } from '../../actions/UxFlowsActions';
import { ConversationsContext } from '../../context/ConversationsContext';
import { getAccountLocale } from '../../selectors/AccountSelectors';
import { companySelector } from '../../selectors/CompanySelectors';
import { currentContext } from '../../selectors/ContextSelectors';
import { RootState, Dispatch } from '../../types/redux';
import ConfirmModal from '../ConfirmModal';

export const Comment = CommentComponent;

type OuterProps = {
  sessionRights?: boolean;
  searchTerm?: string;
  isCommentsPage?: boolean;
} & Omit<CommentComponentProps, 'contextId'>;

type ConnectedCommentProps = OuterProps &
  ReturnType<
    // eslint-disable-next-line no-use-before-define
    typeof mapStateToProps
  > &
  ReturnType<
    // eslint-disable-next-line no-use-before-define
    typeof mapDispatchToProps
  >;

type ConnectedCommentState = {
  isDeleting: boolean;
};

class ConnectedComment extends Component<
  ConnectedCommentProps,
  ConnectedCommentState
> {
  static defaultProps: Partial<ConnectedCommentProps> = {
    withHighlightBanner: true,
  };

  static contextType = ConversationsContext;
  context!: React.ContextType<typeof ConversationsContext>;

  state = {
    isDeleting: false,
    isLoadingManagers: true,
    managers: [],
  };

  render() {
    const {
      canDelete,
      canMarkAsSensitive,
      category,
      dashboardContext,
      segmentId,
      comment,
      commentActions: { listCommentManagers, onTranslate, onRevertTranslate },
      hasAcknowledgements,
      hasCommentManagers,
      hasCommentRank,
      hasConversations,
      isSemanticTagsEnabled,
      isCommentsPage,
      isExplore,
      commentFeedback,
      sessionRights,
      withHighlightBanner,
      feedbackType,
      hasNewTranslations,
      ...other
    } = this.props;

    const { isDeleting } = this.state;

    return (
      <Fragment>
        <Comment
          hasNewTranslations={hasNewTranslations}
          comment={comment}
          category={category}
          contextId={dashboardContext.id}
          segmentId={segmentId}
          hasAcknowledgements={hasAcknowledgements}
          hasCommentManagers={hasCommentManagers}
          hasCommentRank={hasCommentRank}
          hasConversations={hasConversations}
          isSemanticTagsEnabled={isSemanticTagsEnabled}
          isExplore={isExplore}
          onAcknowledge={this.onAcknowledge}
          onTranslate={(id) =>
            onTranslate(id, { type: isCommentsPage ? 'overview' : undefined })
          }
          onRevertTranslate={onRevertTranslate}
          onDelete={canDelete ? this.onDelete : undefined}
          onConversationsClick={this.toggleConversation}
          onLoadManagers={
            hasCommentManagers
              ? () =>
                  listCommentManagers(comment.id, {
                    singleComment: sessionRights,
                  })
              : undefined
          }
          onMarkAsSensitive={
            canMarkAsSensitive ? this.onMarkAsSensitive : undefined
          }
          commentFeedback={commentFeedback}
          sendFeedback={this.onSendFeedback}
          translated={comment.translated}
          withHighlightBanner={withHighlightBanner}
          feedbackType={feedbackType}
          {...other}
        />

        {isDeleting && (
          <ConfirmModal
            asyncConfirm
            isOpen
            onConfirm={this.onConfirmDelete}
            onCancel={this.onCancelDelete}
            title={
              comment.type === 'driver'
                ? t('comment__delete__confirm__title')
                : comment.type === 'text'
                  ? t('input__delete__confirm__title')
                  : comment.type === 'value'
                    ? t('value_comment__delete__confirm__title')
                    : undefined
            }
            confirmLabel={t('delete')}
            type="negative"
          >
            {comment.type === 'driver'
              ? t('comment__delete__confirm')
              : comment.type === 'text'
                ? t('input__delete__confirm')
                : comment.type === 'value'
                  ? t('value_comment__delete__confirm')
                  : undefined}
          </ConfirmModal>
        )}
      </Fragment>
    );
  }

  onDelete = () => {
    this.setState({
      isDeleting: true,
    });
  };

  onCancelDelete = () => {
    this.setState({
      isDeleting: false,
    });
  };

  onConfirmDelete = async () => {
    const {
      comment,
      commentActions: { deleteComment },
    } = this.props;

    await deleteComment(comment.id);
  };

  onAcknowledge = (
    type: AcknowledgementType,
    { optOutConfirm }: { optOutConfirm?: boolean },
  ) => {
    const {
      comment,
      accountLocale,
      commentActions: { acknowledgeComment },
      uxFlowsActions: { complete },
    } = this.props;

    analytics.track('comment_acknowledge__clicked', {
      comment_locale: comment.locale,
      account_locale: accountLocale,
    });

    return acknowledgeComment(comment.id, type).then(() => {
      if (optOutConfirm) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        complete('account', 'dontAskConfirmationAcknowledge');
      }
    });
  };

  onSendFeedback = async (
    isRelevant: boolean,
    isBiased: boolean,
    biasedComment: string,
  ) => {
    const {
      comment,
      commentActions: {
        sendFeedback: sendSemanticSearchFeedback,
        sendSemanticTopicsFeedback,
      },
      searchTerm,
      feedbackType,
      topicId,
    } = this.props;

    let result = true;
    try {
      if (feedbackType === FeedbackType.SemanticTopics) {
        await sendSemanticTopicsFeedback({
          commentId: comment.id,
          isRelevant,
          isBiased,
          isBiasedComment: biasedComment,
          // @ts-expect-error - Type 'string | undefined' is not assignable to type 'string'.
          topicId,
        });
      } else {
        await sendSemanticSearchFeedback({
          commentId: comment.id,
          // @ts-expect-error - Type 'string | undefined' is not assignable to type 'string'.
          searchTerm,
          isRelevant,
          isBiased,
          isBiasedComment: biasedComment,
        });
      }
    } catch {
      result = false;
    }

    return result;
  };

  onMarkAsSensitive = () => {
    const {
      comment,
      commentActions: { markAsSensitive, markNotSensitive },
      notificationActions: { showSuccessNotification },
    } = this.props;

    return comment.sensitive
      ? markNotSensitive(comment.id).then(() => {
          showSuccessNotification({
            message: t('sensitive_comments_unmarked'),
          });
        })
      : markAsSensitive(comment.id).then(() => {
          showSuccessNotification({
            message: t('sensitive_comments_marked'),
          });
        });
  };

  toggleConversation = () => {
    const { comment, accountLocale } = this.props;

    analytics.track('comment_conversation__clicked', {
      comment_locale: comment.locale,
      account_locale: accountLocale,
    });

    this.context.onConversationToggle(comment);
  };
}

const mapStateToProps = (
  state: RootState,
  { comment, sessionRights }: OuterProps,
) => {
  const dashboardContext = currentContext(state);

  const { categories } = state;
  const company = companySelector(state);
  const session = state.session;

  const rightChecker = sessionRights ? session : dashboardContext;
  const accountLocale = getAccountLocale(state);

  const hasNewTranslations = state.features.includes(
    'useCommentMachineTranslations',
  );
  const hasTranslations = hasNewTranslations
    ? company.settings.get('commentMachineTranslations') !== 'off'
    : company.hasAddOn('google_translate');

  const category =
    comment.question?.category ||
    (comment.categoryId && categories.get(comment.categoryId));

  return {
    canDelete: rightChecker.hasRight(`comment:${comment.type}:delete`),
    comment,
    category,
    dashboardContext,
    canMarkAsSensitive: rightChecker.hasRight('comment:sensitive:mark'),
    hasAcknowledgements: rightChecker.hasRight(
      `comment:${comment.type}:acknowledge`,
    ),

    hasCommentManagers: rightChecker.hasRight(
      `comment:${comment.type}:managers`,
    ),

    hasNewTranslations,
    hasCommentRank: company.addOns.includes('comment_rank'),
    hasConversations: rightChecker.hasRight('conversation:read'),
    translatable: hasTranslations,
    isSemanticTagsEnabled: rightChecker.hasRight('comment:semanticTags'),
    accountLocale,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  commentActions: bindActionCreators(
    {
      listCommentManagers: listCommentManagersAction,
      sendFeedback: sendFeedbackAction,
      sendSemanticTopicsFeedback: sendSemanticTopicsFeedbackAction,
      onTranslate: onTranslateAction,
      onRevertTranslate: onRevertTranslateAction,
      deleteComment: deleteCommentAction,
      acknowledgeComment: acknowledgeCommentAction,
      markAsSensitive: markAsSensitiveAction,
      markNotSensitive: markNotSensitiveAction,
    },
    dispatch,
  ),
  notificationActions: bindActionCreators(
    { showSuccessNotification: showSuccessNotificationAction },
    dispatch,
  ),
  uxFlowsActions: bindActionCreators(
    { complete: completeUXFlowAction },
    dispatch,
  ),
});

// @ts-expect-error - type null is not assignable to type Comment
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedComment);
