import { fromJS, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Action from './ActionRecord';
import Segment from './SegmentRecord';
import { validateTestingSchema } from './utils';
import Value from './ValueRecord';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class ActionsGroupRecord
  extends Record({
    id: undefined,
    actions: undefined,
    category: undefined,
    group: undefined,
    title: undefined,

    segment: undefined,
    value: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  actions: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionsGroupRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionsGroupRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  getTotalActions() {
    return this.actions ? this.actions.size : 0;
  }

  static createFromApi(group: $TSFixMe, team: $TSFixMe) {
    const {
      relationships: { actions, segment, value },
    } = group;

    return new ActionsGroupRecord(
      fromJS({
        id: group.id,
        actions: actions.map(
          (
            // @ts-expect-error no implicit any
            action,
          ) => Action.createFromApi(action, team),
        ),
        segment: segment ? Segment.createFromApi(segment) : undefined,
        value: value ? Value.createFromApi(value) : undefined,
        category: Action.createFromApi(actions[0], team).get('category'),
        ...group.attributes,
      }),
    );
  }
}
