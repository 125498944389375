import { OrderedMap } from 'immutable';

import AccessGroup from '@peakon/records/AccessGroupRecord';

const groups = (
  state = OrderedMap<string, AccessGroup>(),
  action: $TSFixMe,
): OrderedMap<string, AccessGroup> => {
  switch (action.type) {
    case 'ACCESS_GROUP_FLUSH': {
      return OrderedMap();
    }
    case 'ACCESS_GROUP_LIST_SUCCESS': {
      const { data } = action.data;

      return OrderedMap(
        data
          .map(AccessGroup.createFromApi)
          .map((group: AccessGroup) => [group.id, group]),
      );
    }
    case 'ACCESS_GROUP_DELETE_SUCCESS': {
      const { id } = action.data;

      return state.delete(id);
    }
    case 'ACCESS_GROUP_PATCH_SUCCESS':
    case 'ACCESS_GROUP_CREATE_SUCCESS': {
      const { data } = action.data;

      const group = AccessGroup.createFromApi(data);

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.set(group.id as string, group);
    }
    case 'ACCESS_GROUP_MEMBER_ADD_LOADING':
    case 'ACCESS_GROUP_MEMBER_REMOVE_FAILED': {
      const { groupId: id } = action.data;

      return state.updateIn(
        [id, 'memberCount'],
        (memberCount) => memberCount + 1,
      );
    }
    case 'ACCESS_GROUP_MEMBER_ADD_FAILED':
    case 'ACCESS_GROUP_MEMBER_REMOVE_SUCCESS': {
      const { groupId: id } = action.data;

      return state.updateIn(
        [id, 'memberCount'],
        (memberCount) => memberCount - 1,
      );
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default groups;
