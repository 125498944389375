import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  order: z.enum(['asc', 'desc']).optional(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class ActionFilters
  extends Record({
    aboveAnonymity: 'above',
    access: null,
    context: undefined,
    deadline: null,
    order: 'asc',
    sort: 'deadline',
    status: 'open',
    category: null,
    categoryGroup: 'all',
  })
  implements Schema
{
  aboveAnonymity: $TSFixMe;
  access: $TSFixMe;
  context: $TSFixMe;
  deadline: $TSFixMe;
  order!: Schema['order'];
  sort: $TSFixMe;
  status: 'open' | 'completed' | 'archived' = 'open';
  category: $TSFixMe;
  categoryGroup: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionFilters',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionFilters',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get dashboardContext() {
    return this.context;
  }

  isFiltering() {
    return Boolean(this.access || this.deadline);
  }
}
