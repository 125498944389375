import type { User } from '@sentry/react';
import * as Sentry from '@sentry/react';

import env from '@peakon/shared/utils/env';
import { ErrorReporterType } from '@peakon/shared/utils/errorReporter';

import { Session, isSharedDashboardSession } from '../types/Session';

const getPerson = (session: Session) => {
  const included =
    isSharedDashboardSession(session) || !session.included
      ? []
      : session.included;

  const employee = included.find((i) => i.type === 'employees');
  const company = included.find((i) => i.type === 'companies');
  const account = included.find((i) => i.type === 'accounts');

  const accountId = account ? account.id : null;
  const companyId = company ? company.id : null;

  if (employee) {
    return {
      id: accountId,
      companyId,
      employeeId: employee ? employee.id : null,
    };
  }

  return {
    id: accountId,
    companyId,
  };
};

export const updateErrorSession = (
  errorReporter: ErrorReporterType,
  session: Session,
) => {
  if (session) {
    const person = getPerson(session);
    errorReporter.updateConfig({
      person,
    });
    if (env.clusterEnv === 'staging') {
      const user: User = {
        id: person.id || undefined,
        companyId: person.companyId,
      };
      if (person.employeeId) {
        user.employeeId = person.employeeId;
      }
      Sentry.getCurrentScope().setUser(user);
    }
  }
};
