const getInitialState = () => ({
  actionsCreated: 0,
  commentInteractions: 0,
  activeManagers: 0,
});

const overview = (
  state = getInitialState(),
  action: $TSFixMe,
): ReturnType<typeof getInitialState> => {
  switch (action.type) {
    case 'MANAGER_METRICS_OVERVIEW_SUCCESS': {
      const {
        data: { attributes },
      } = action.data;

      return {
        ...state,
        ...attributes,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default overview;
