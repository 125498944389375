import React from 'react';

import MarkdownView, { GlobalConfiguration } from 'react-showdown';

import badgeExtensions from '@peakon/showdown-badges';

import xss from '../../utils/xss';

GlobalConfiguration.setOption('extensions', [badgeExtensions]);

type Props = {
  children: string;
};

const Markdown = ({ children }: Props) => (
  <MarkdownView
    markdown={children}
    sanitizeHtml={(html) => xss.process(html)}
  />
);

// eslint-disable-next-line import/no-default-export
export default Markdown;
