import { fromJS, List, OrderedMap, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { ActionsGroup, Attribute } from './';
import Action from './ActionRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class ActionsTeamRecord
  extends Record({
    id: undefined,
    abbreviation: undefined,
    actions: undefined,
    groups: undefined,
    name: undefined,
    direct: undefined,
    isLoading: false,
    responses: undefined,

    attribute: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  actions: $TSFixMe;
  groups: $TSFixMe;
  abbreviation: $TSFixMe;
  direct: $TSFixMe;
  isLoading: $TSFixMe;
  responses: $TSFixMe;
  attribute: $TSFixMe;
  name: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionsTeamRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionsTeamRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  getTotalActions(sort: $TSFixMe) {
    if (sort === 'group') {
      return this.groups
        ? this.groups.reduce(
            (total: $TSFixMe, group: $TSFixMe) =>
              total + group.getTotalActions(),
            0,
          )
        : 0;
    }

    return this.actions.size;
  }

  static createFromApi(team: $TSFixMe) {
    const {
      attributes: { responses },
      relationships: {
        actions,
        context: {
          attributes,
          relationships: { attribute },
        },
        groups,
      },
    } = team;

    return new ActionsTeamRecord(
      fromJS({
        id: team.id,
        actions: List(
          fromJS(
            actions
              ? actions.map(
                  (
                    // @ts-expect-error no implicit any
                    action,
                  ) => Action.createFromApi(action, team.id),
                )
              : [],
          ),
        ),
        groups: groups
          ? OrderedMap(
              groups.map(
                (
                  // @ts-expect-error no implicit any
                  group,
                ) => [
                  group.attributes.group,
                  ActionsGroup.createFromApi(group, team.id),
                ],
              ),
            )
          : OrderedMap(),
        attribute: Attribute.createFromApi(attribute),
        responses,
        ...attributes,
      }),
    );
  }
}
