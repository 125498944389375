import React from 'react';

import { EmployeeDashboardBulbIcon as ManagerFocusIcon } from '@peakon/bedrock/icons/graphic';
import { Link } from '@peakon/bedrock/react/link';
import { Heading2, Paragraph } from '@peakon/bedrock/react/typography';
import { StackH, StackV } from '@peakon/bedrock/react-alpha/stack';
import { Grid } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

const HC_ARTICLE_URL =
  'https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/acting-on-feedback/action-planning/submit-team-suggestions-on-focus-areas.html';

export function ConfidentialityDisclaimer() {
  return (
    <div className={styles.root}>
      <Grid>
        <Grid.Row>
          <Grid.Col sm={12}>
            <StackH spacing="08" padding="16">
              <ManagerFocusIcon aria-hidden />
              <StackV spacing="16">
                <Heading2 level={1}>
                  {t('team_suggestions__standalone__header__title')}
                </Heading2>
                <Paragraph>
                  {t('team_suggestions__standalone__header__description')}
                  &nbsp;
                  <Link
                    href={HC_ARTICLE_URL}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {t('team_suggestions__standalone__header__link_text')}
                  </Link>
                </Paragraph>
              </StackV>
            </StackH>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
}
