import React, { useEffect, useState, Fragment } from 'react';

import store from 'store2';

import { Button } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { ButtonLink } from '@peakon/bedrock/react/link';
import { Paragraph } from '@peakon/bedrock/react/typography';
import { t } from '@peakon/shared/features/i18next/t';

import { useShellContext } from '../../context/ShellContext';

const INTERVAL = 5 * 60 * 1000;

export const SystemNewVersionBar = () => {
  const { version, onNewVersion } = useShellContext();
  const [newVersion, setNewVersion] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleReload = () => {
    window.location.reload();
  };

  const handleVisibilityChange = () => {
    if (!isModalOpen && !window.document.hidden) {
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);

    window.document.removeEventListener(
      'visibilitychange',
      handleVisibilityChange,
    );
  };

  useEffect(() => {
    if (!version) {
      return;
    }

    const disableVersionChecker = store.local.get('disable_version_checker');
    if (disableVersionChecker) {
      return;
    }

    let interval: ReturnType<typeof setInterval>;

    const poller = async () => {
      try {
        const response = await fetch('/source-version');
        const newVersionFromResponse = await response.text();

        if (
          newVersionFromResponse.length > 0 &&
          newVersionFromResponse !== version
        ) {
          setNewVersion(newVersionFromResponse);
          onNewVersion();

          window.document.addEventListener(
            'visibilitychange',
            handleVisibilityChange,
          );
        }

        clearInterval(interval);
        interval = setInterval(poller, INTERVAL);
      } catch {
        // noop
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
    poller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  if (!newVersion) {
    return null;
  }

  return (
    <Fragment>
      <Paragraph bold>{t('new_source_version')}</Paragraph>
      <ButtonLink onClick={handleReload}>
        {t('new_source_version__refresh')}
      </ButtonLink>
      <Modal
        heading={t('new_version_modal__text', {
          replace: { emphasis_start: '', emphasis_end: '' },
        })}
        open={isModalOpen}
        onDismiss={handleModalClose}
        closeLabel={t('common__close')}
        data-test-id="new-version-modal"
      >
        <Modal.Actions>
          <Button variant="primary" onClick={handleReload}>
            {t('new_version_modal__refresh_to_update')}
          </Button>
          <Button variant="secondary" onClick={handleModalClose}>
            {t('new_version_modal__not_now')}
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};
