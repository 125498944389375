import React from 'react';

import { Redirect, useParams } from 'react-router';

import { Spinner } from '@peakon/bedrock/react/spinner';
import api from '@peakon/shared/utils/api';
import { assignWindowLocation } from '@peakon/shared/utils/window';

import { useAuthenticationTokenQuery } from './useAuthenticationTokenQuery/useAuthenticationTokenQuery';

import styles from './styles.css';

export const getRedirectUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const searchParams = params.get('searchParams');
  const returnTo = params.get('returnTo') ?? '';

  let url = new URL(returnTo, window.location.origin);

  if (searchParams) {
    const parsedSearchParams = new URLSearchParams(searchParams);
    const redirectTo = parsedSearchParams.get('redirectTo');

    if (redirectTo) {
      url = new URL(redirectTo, window.location.origin);
      parsedSearchParams.delete('redirectTo');
    }

    for (const [key, value] of parsedSearchParams) {
      if (!url.searchParams.has(key)) {
        url.searchParams.append(key, value);
      }
    }
  }
  return url.toString();
};

const SubdomainTransfer = () => {
  const { transferToken } = useParams<{ transferToken: string }>();
  const { data: bearerToken, isLoading } = useAuthenticationTokenQuery({
    transferToken,
  });

  if (isLoading) {
    return (
      <div
        data-test-id="subdomain-transfer-loading"
        className={styles.loadingScreen}
      >
        <Spinner size="48" />
      </div>
    );
  }

  if (!bearerToken) {
    api.auth.unstoreToken();
    return <Redirect to="/login" />;
  }

  api.auth.authenticate(bearerToken, {
    remember: true,
  });

  // Need to do a full page refresh here in case the user needs to be redirected to the employee dashboard, which requires server-side routing
  assignWindowLocation(getRedirectUrl());
  return null;
};

// eslint-disable-next-line import/no-default-export
export default SubdomainTransfer;
