import React from 'react';

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { NotFoundScreen } from '@peakon/shared/components/NotFoundScreen/NotFoundScreen';

import { ConversationSlideIn } from '../../components/comments/ConversationSlideIn/ConversationSlideIn';
import { withLazy } from '../../components/LazyLoaded';
import { RestrictedRoute } from '../../components/RestrictedRoute';
import RedirectToAvailableRoute from '../../components/RestrictedRoute/RedirectToAvailableRoute';
import ConversationsProvider from '../../providers/ConversationsProvider';
import ProductTourProvider from '../../providers/ProductTourProvider';

const Comment = withLazy(() => import('../Comment'));
const Comments = withLazy(() => import('../Comments'));
const Driver = withLazy(() => import('../Driver'));
const Drivers = withLazy(() => import('../Drivers'));
const Experience = withLazy(() => import('../Experience'));
const ExperienceReport = withLazy(() => import('../Experience/Report'));
const Report = withLazy(
  () => import('../../components/externalMetrics/Report'),
);
const Heatmap = withLazy(() => import('../HeatmapContainer'));
const NpsSummary = withLazy(() => import('../NpsSummary/SummaryRoute'));
const Overview = withLazy(() => import('../GroupOverview'));
const UsageMetrics = withLazy(() => import('../UsageMetrics/UsageMetrics'));
const Question = withLazy(() => import('../Question'));
const QuestionResults = withLazy(() => import('../QuestionResults'));
const SegmentCards = withLazy(() => import('../SegmentCards'));
const SegmentOverview = withLazy(
  () => import('../../components/dashboard/SegmentOverview'),
);
const Topics = withLazy(() => import('../Topics'));
const TrendsReport = withLazy(() => import('../TrendsReport/TrendsReport'));

const DashboardContainer = () => {
  const location = useLocation();

  return (
    <ProductTourProvider>
      <ConversationsProvider>
        <Switch>
          <RestrictedRoute
            rights={['curatedInsights:read']}
            feature="curatedInsights"
            path="/dashboard/curated_insights"
            component={TrendsReport}
          />
          <RestrictedRoute
            path="/dashboard/experience/:phase"
            addOn={['employee_experience', 'engagement']}
            categoryGroup="engagement"
            categoryGroupAccessType="full"
            component={ExperienceReport}
          />
          <RestrictedRoute
            addOn={['employee_experience', 'engagement']}
            categoryGroup="engagement"
            categoryGroupAccessType="full"
            component={Experience}
            path="/dashboard/experience"
          />
          <Redirect
            exact
            from="/dashboard/segment/:segmentId"
            to={{
              pathname: `/dashboard/group/engagement/segment/:segmentId`,
              search: location.search,
            }}
          />
          <RestrictedRoute
            rights={['score:driver:read']}
            path="/dashboard/segment/:segmentId/question/:id"
            component={Question}
          />
          <Route path="/dashboard/(driver|segment)" component={Driver} />
          <RestrictedRoute
            path="/dashboard/segments/cards"
            rights={['dataset:compare']}
            component={SegmentCards}
          />
          <RestrictedRoute
            path="/dashboard/segments"
            rights={['dataset:compare']}
            component={Heatmap}
          />
          <RestrictedRoute
            addOn={['external_metrics']}
            path="/dashboard/external_metrics"
            rights={['externalMetric:score']}
            component={Report}
          />
          <RestrictedRoute
            addOn="comment_topics"
            rights={[
              'comment:driver:read',
              'comment:text:read',
              'comment:value:read',
            ]}
            path="/dashboard/topics"
            component={Topics}
          />
          <RestrictedRoute
            rights={['score:driver:read']}
            path="/dashboard/question/:id"
            component={Question}
          />
          <RestrictedRoute
            path="/dashboard/comments/:id([0-9]+)"
            rights={[
              'comment:driver:read',
              'comment:text:read',
              'comment:value:read',
            ]}
            sessionRight={true}
            component={Comment}
          />
          <RestrictedRoute
            path="/dashboard/comments"
            rights={[
              'comment:driver:read',
              'comment:text:read',
              'comment:value:read',
            ]}
            component={Comments}
          />
          <RestrictedRoute
            path="/dashboard/nps-summary"
            rights={[
              'comment:driver:read',
              'comment:text:read',
              'comment:value:read',
            ]}
            feature="npsSummary"
            component={NpsSummary}
          />

          <RestrictedRoute
            path="/dashboard/group/:group(engagement|transformation_change|diversity_inclusion|health_wellbeing|other|covid19|onboarding|exit)/drivers"
            rights={['dataset:read']}
            component={Drivers}
          />
          <RestrictedRoute
            rights={['questionScoresPage:read']}
            path="/dashboard/group/:group(engagement|transformation_change|diversity_inclusion|health_wellbeing|other|covid19|values|onboarding|exit)/questions"
            component={QuestionResults}
          />
          <RestrictedRoute
            rights={['dataset:compare']}
            path="/dashboard/group/:group(engagement|diversity_inclusion|health_wellbeing|transformation_change|other|covid19)/segment/:segmentId"
            component={SegmentOverview}
          />
          <RestrictedRoute
            path="/dashboard/group/:group(engagement|diversity_inclusion|health_wellbeing|transformation_change|other|covid19)"
            rights={['dataset:read']}
            component={Overview}
            categoryGroupAccessType="overview"
          />
          <RestrictedRoute
            path="/dashboard/metrics*"
            rights={['usageMetrics:read']}
            component={UsageMetrics}
            feature="newManagerMetrics"
          />
          <Route
            exact
            path={['/dashboard/drivers', '/dashboard/questions']}
            component={() => <RedirectToAvailableRoute />}
          />
          <Route component={NotFoundScreen} />
        </Switch>
        <ConversationSlideIn />
      </ConversationsProvider>
    </ProductTourProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default DashboardContainer;
