import get from 'lodash/get';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';

import Category from '@peakon/records/CategoryRecord';
import PriorityDriver from '@peakon/records/PriorityDriverRecord';
import Segment from '@peakon/records/SegmentRecord';

export const initialState = {
  attrition: null,
  changes: null,
  classification: null,
  interval: 'year',
  isLoadingObservations: false,
  isLoadingOverview: true,
  isLoadingParticipation: true,
  isLoadingPriorities: false,
  mainCategory: null,
  observations: [],
  parser: 'categories',
  participation: {},
  globalParticipation: {},
  participationObservations: [],
  priority: null,
  priorityStatus: null,
  recentObservations: [],
  scores: {},
  segment: null,
  story: null,
  subcategories: [],
  value: null,
  uuid: null,
  teamSuggestionsCount: null,
  unreadTeamSuggestionsCount: null,
};

function checkOrder(state: $TSFixMe, data: $TSFixMe) {
  return !state.uuid || state.uuid === data.uuid;
}

function parseScore(data: $TSFixMe, main: boolean = false) {
  if (isEmpty(data)) {
    return {};
  }

  const { id, attributes } = data;

  const parsed = {
    attrition: attributes.attrition,
    changes: attributes.changes,
    classification: attributes.classification,
    grade: attributes.grade,
    id,
    observations: attributes.observations,
    participation: attributes.participation,
    globalParticipation: attributes.globalParticipation,
    priorityStatus: attributes.priorityStatus,
    scores: attributes.scores,
    source: attributes.source,
    statusSetAt: attributes.statusSetAt,
    priorityManagerMessage: attributes.priorityManagerMessage,
    priorityId: attributes.priorityDriverId,
    teamSuggestionsCount: attributes.teamSuggestionsCount,
    unreadTeamSuggestionsCount: attributes.unreadTeamSuggestionsCount,
    allowTeamSuggestions: attributes.allowTeamSuggestions,
  };

  if (main) {
    return {
      ...parsed,
      recentObservations: parsed.observations || [],
      ...parseRelationships(data),
    };
  }

  return parsed;
}

function flattenObservations(observations: $TSFixMe, subdriver: $TSFixMe) {
  return (
    observations
      // @ts-expect-error TS(7006): Parameter 'observation' implicitly has an 'any' ty... Remove this comment to see the full error message
      .map((observation) => {
        return get(observation, ['scores', 'subdrivers', subdriver]);
      })
      .filter(identity)
  );
}

function legacyAdaptor(data: $TSFixMe) {
  const driver = get(data, 'attributes.driver');
  const subdrivers = get(data, 'attributes.scores.subdrivers');

  return Object.keys(subdrivers || {}).reduce((acc, id) => {
    return acc.concat({
      // @ts-expect-error TS(2769): No overload matches this call.
      id,
      driver,
      subdriver: id,
      scores: get(data, ['attributes', 'scores', 'subdrivers', id]),
      changes: {
        previous: get(data, [
          'attributes',
          'changes',
          'previous',
          'subdrivers',
          id,
        ]),
        first: get(data, [
          'attributes',
          'changes',
          'previous',
          'subdrivers',
          id,
        ]),
        accepted: get(data, [
          'attributes',
          'changes',
          'previous',
          'subdrivers',
          id,
        ]),
      },
      observations: flattenObservations(
        get(data, 'attributes.observations'),
        id,
      ),
    });
  }, []);
}

function getByCategories(data: $TSFixMe) {
  // when it's a subdriver
  if (data.length === 1) {
    return {
      category: data[0],
      subcategories: [],
    };
  }

  // @ts-expect-error TS(7006): Parameter 'scores' implicitly has an 'any' type.
  const rootCategory = data.find((scores) => {
    return !get(scores, 'relationships.category.relationships.parentCategory');
  });

  return {
    category: rootCategory,
    subcategories: data.filter(
      (category: $TSFixMe) => category.id !== rootCategory.id,
    ),
  };
}

function parseObservations(data: $TSFixMe) {
  return get(data, 'attributes.observations', []);
}

function parseRelationships({
  attributes: { classification, priorityStatus },
  // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
  relationships: { category, story, priority, setByEmployee } = {},
}: $TSFixMe) {
  return {
    category,
    story,
    setByEmployee,
    classification,
    priorityStatus,
    priority:
      priority && priority.attributes
        ? PriorityDriver.createFromApi(priority)
        : null,
  };
}

const scores = (state = initialState, action: $TSFixMe) => {
  switch (action.type) {
    case 'SCORE_OVERVIEW_CLEAR': {
      return {
        ...initialState,
        uuid: state.uuid, // we need to retain this info for checkOrder to work
      };
    }

    case 'SCORE_OVERVIEW_INTERVAL_CHANGED': {
      return {
        ...state,
        interval: action.data,
      };
    }

    case 'PRIORITIES_DRIVER_READ_LOADING': {
      return {
        ...state,
        isLoadingPriorities: true,
      };
    }

    case 'PRIORITY_UNSET_DRIVER_PRIORITY_SUCCESS': {
      return {
        ...state,
        story: null,
        priorityStatus: null,
        priority: null,
      };
    }

    case 'PRIORITY_SET_DRIVER_PRIORITY_SUCCESS':
    case 'PRIORITIES_DRIVER_READ_SUCCESS': {
      const { data } = action.data;

      const { priority, priorityStatus, classification, story } =
        parseRelationships(data);

      return {
        ...state,
        isLoadingPriorities: false,
        priority,
        priorityStatus,
        classification,
        story,
      };
    }

    case 'CONTEXT_CHANGED':
    case 'QUESTION_OVERVIEW_READ_LOADING':
    case 'ENGAGEMENT_OVERVIEW_READ_LOADING':
    case 'CATEGORY_OVERVIEW_READ_LOADING':
    case 'SEGMENT_OVERVIEW_READ_LOADING':
    case 'VALUE_OVERVIEW_READ_LOADING': {
      return {
        ...initialState,
        uuid: action.data.uuid,
      };
    }

    case 'ENGAGEMENT_OBSERVATIONS_READ_LOADING':
    case 'QUESTION_OBSERVATIONS_READ_LOADING':
    case 'CATEGORY_OBSERVATIONS_READ_LOADING':
    case 'SEGMENT_OBSERVATIONS_READ_LOADING':
    case 'VALUE_OBSERVATIONS_READ_LOADING': {
      return {
        ...state,
        isLoadingObservations: true,
      };
    }

    case 'GROUP_TIMELINE_PARTICIPATION_LOADING': {
      return {
        ...state,
        isLoadingParticipation: true,
        uuid: action.data.uuid,
      };
    }

    case 'GROUP_TIMELINE_PARTICIPATION_SUCCESS': {
      if (!checkOrder(state, action.data)) {
        return state;
      }

      const { observations } = action.data;

      return {
        ...state,
        isLoadingParticipation: false,
        participationObservations: observations,
      };
    }

    case 'QUESTION_OBSERVATIONS_READ_SUCCESS':
    case 'ENGAGEMENT_OBSERVATIONS_READ_SUCCESS':
    case 'VALUE_OBSERVATIONS_READ_SUCCESS': {
      const { data } = action.data;

      return {
        ...state,
        isLoadingObservations: false,
        // @ts-expect-error TS(2339): Property 'observations' does not exist on type '{}... Remove this comment to see the full error message
        observations: parseScore(data, false).observations,
        subcategories: state.subcategories.map((subcategory) => {
          return {
            // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
            ...subcategory,
            observations: flattenObservations(
              get(data, 'attributes.observations'),
              // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
              subcategory.id,
            ),
          };
        }),
      };
    }

    case 'CATEGORY_OBSERVATIONS_READ_SUCCESS': {
      const { data } = action.data;

      const { category, subcategories } = getByCategories(data);

      return {
        ...state,
        isLoadingObservations: false,
        observations: parseObservations(category),
        subcategories: state.subcategories.map((subcategory) => {
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
          const id = subcategory.id;

          return {
            // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
            ...subcategory,
            observations: parseObservations(
              // @ts-expect-error TS(7006): Parameter 's' implicitly has an 'any' type.
              subcategories.find((s) => s.id === id),
            ),
          };
        }),
      };
    }

    case 'SEGMENT_OBSERVATIONS_READ_SUCCESS': {
      return {
        ...state,
        isLoadingObservations: false,
        observations: parseObservations(action.data.data),
      };
    }

    case 'QUESTION_OVERVIEW_READ_SUCCESS':
    case 'ENGAGEMENT_OVERVIEW_READ_SUCCESS': {
      const { data } = action.data;

      if (!checkOrder(state, action.data)) {
        return state;
      }

      const segment = get(data, 'relationships.segment');

      return {
        ...state,
        isLoadingOverview: false,
        ...parseScore(data, true),
        parser: 'engagement',
        subcategories: legacyAdaptor(data),
        segment: segment ? Segment.createFromApi(segment) : undefined,
      };
    }

    case 'SEGMENT_OVERVIEW_READ_SUCCESS': {
      const { data } = action.data;

      if (!checkOrder(state, action.data)) {
        return state;
      }

      const categoryScores = get(data, 'relationships.categoryScores');

      let categories;
      if (categoryScores) {
        categories = getByCategories(categoryScores);
      }

      const segment = get(data, 'relationships.segment');

      return {
        ...state,
        isLoadingOverview: false,
        ...parseScore(data, true),
        segment: segment ? Segment.createFromApi(segment) : undefined,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        subcategories: categories.subcategories.map(parseScore),
      };
    }

    case 'ENGAGEMENT_OVERVIEW_V2_READ_SUCCESS': {
      const { data } = action.data;

      return {
        ...state,
        isLoadingOverview: false,
        ...parseScore(data, true),
        parser: 'engagement',
      };
    }

    case 'CATEGORY_OVERVIEW_READ_SUCCESS': {
      const { data } = action.data;

      if (!checkOrder(state, action.data)) {
        return state;
      }
      const { category, subcategories } = getByCategories(data);

      return {
        ...state,
        isLoadingOverview: false,
        ...parseScore(category, true),
        parser: 'categories',
        subcategories: subcategories.map(parseScore),
      };
    }

    case 'GROUP_OVERVIEW_PARTICIPATION_SUCCESS': {
      const { data, mainCategory } = action.data;

      if (!checkOrder(state, action.data)) {
        return state;
      }

      return {
        ...state,
        isLoadingOverview: false,
        ...parseScore(data, true),
        parser: 'categories',
        mainCategory: Category.createFromApi(mainCategory),
      };
    }

    case 'VALUE_OVERVIEW_READ_SUCCESS': {
      const { data } = action.data;

      return {
        ...state,
        isLoadingOverview: false,
        ...parseScore(data, true),
        value: data.relationships.value,
      };
    }

    case 'MARK_TEAM_SUGGESTIONS_AS_READ': {
      return { ...state, unreadTeamSuggestionsCount: 0 };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default scores;
