import { List } from 'immutable';
import get from 'lodash/get';

import { Attribute, AttributeOption } from '@peakon/records';

const attributes = (
  state = List<Attribute>(),
  action: $TSFixMe,
): List<Attribute> => {
  switch (action.type) {
    case 'ACCESS_GROUP_CREATE_SUCCESS': {
      const {
        data: { relationships },
      } = action.data;

      // if group has attribute, add it
      if (relationships && relationships.attribute) {
        const attr = Attribute.createFromApi(relationships.attribute);

        return state.push(attr);
      }

      return state;
    }
    case 'ACCESS_GROUP_LIST_SUCCESS': {
      const { data } = action.data;

      // see if any group has an attribute
      const filteredAttributes = data
        .filter(
          (attr: $TSFixMe) =>
            attr.relationships && attr.relationships.attribute,
        )
        // @ts-expect-error TS(7006): Parameter 'attr' implicitly has an 'any' type.
        .map((attr) => get(attr, 'relationships.attribute'))
        .map(Attribute.createFromApi);

      // @ts-expect-error TS(2740): Type 'Iterable<number, Attribute>' is missing the ... Remove this comment to see the full error message
      return state.concat(filteredAttributes);
    }
    case 'ATTRIBUTE_READ_SUCCESS': {
      const { data } = action.data;

      return List(data.map(Attribute.createFromApi));
    }
    case 'ATTRIBUTE_APPEND_SUCCESS': {
      const { data } = action.data;

      return (
        state
          .concat(data.map(Attribute.createFromApi))
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          .groupBy((attr) => attr.id)
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          .map((attr) => attr.first())
          .toList()
      );
    }
    case 'ATTRIBUTE_OPTION_ADDED': {
      const { id, option } = action.data;

      return state.update(
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        state.findIndex((item) => item.id === id),
        (item) =>
          item.set(
            'options',
            item.get('options', []).push(new AttributeOption(option)),
          ),
      );
    }
    case 'ATTRIBUTE_OPTION_REMOVED': {
      const { id, optionId } = action.data;

      return state.update(
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        state.findIndex((item) => item.id === id),
        (item) =>
          item.set(
            'options',
            // @ts-expect-error TS(7006): Parameter 'option' implicitly has an 'any' type.
            item.get('options', []).filter((option) => option.id !== optionId),
          ),
      );
    }
    case 'ATTRIBUTE_GET_OPTIONS_SUCCESS': {
      const { id, data } = action.data;
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const index = state.findIndex((attribute) => attribute.id === id);

      if (index === -1) {
        return state;
      }

      return state.update(index, (item) =>
        item.set('options', List(data.map(AttributeOption.createFromApi))),
      );
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default attributes;
