import React from 'react';

import AcknowledgementIcon from '@peakon/shared/components/AcknowledgementIcon';

import Tooltip from './Tooltip';

import styles from './styles.css';

type Props = {
  counts: Record<string, number>;
};

const AcknowledgementCounts = ({ counts }: Props) => (
  <div className={styles.root}>
    <div className={styles.icons}>
      {Object.keys(counts).map(
        (type) =>
          Boolean(counts[type]) && (
            <div key={type} className={styles.icon}>
              <AcknowledgementIcon
                size="tiny"
                // @ts-expect-error - Type 'string' is not assignable to type '"thanks_for_sharing" | "great_idea" | "working_on_it" | "would_love_to_talk_in_person" | "i_agree" | undefined'.
                type={type}
              />
            </div>
          ),
      )}
    </div>
    <Tooltip counts={counts} />
  </div>
);

// eslint-disable-next-line import/no-default-export
export default AcknowledgementCounts;
