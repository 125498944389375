import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Context from './ContextRecord';
import { validateTestingSchema } from './utils';

const DEFAULT_FIELDS = {
  id: undefined,
  answerCount: 0,
  closesAt: undefined,
  employeeCount: 0,
  nextReminderAt: undefined,
  opensAt: undefined,
  remindedCount: 0,
  reminderScheduledCount: undefined,
  reminderSentAt: undefined,
  sendScheduledCount: undefined,
  sentCount: undefined,
  sentTodayCount: undefined,
  remindedTodayCount: undefined,
};

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  name: z.string(),
  closed: z.boolean(),
  closesAt: z.string().date(),
  context: z.object({}).passthrough(),
  status: z.enum(['open', 'closed', 'hard-closed', 'created']),
  lastResentAt: z.string().date().nullable(),
  type: z.enum(['automatic', 'test']),
  responseId: z.string(),
  opensAt: z.string().date(),
});
type Schema = z.infer<typeof schema>;

class Round
  extends Record({
    ...DEFAULT_FIELDS,
    closed: undefined,
    name: undefined,
    status: undefined,
    type: undefined,
    context: undefined,
    lastResentAt: undefined,
    responseId: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  answerCount!: number;
  closed?: boolean;
  closesAt?: string;
  context?: Context;
  employeeCount!: number;
  lastResentAt?: string;
  name?: string;
  nextReminderAt?: string;
  opensAt?: string;
  remindedCount!: number;
  remindedTodayCount?: number;
  reminderScheduledCount?: number;
  reminderSentAt?: string;
  responseId?: string;
  sendScheduledCount?: number;
  sentCount?: number;
  sentTodayCount?: number;
  status?: 'created' | 'open' | 'closed' | 'hard-closed';
  type?: 'automatic' | 'test';

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'RoundRecord schema',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'RoundRecord schema',
      environments: ['staging'],
      logLevel: 'warning',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get dashboardContext() {
    return this.context;
  }

  get participationRate() {
    return this.employeeCount
      ? (100 * this.answerCount) / this.employeeCount
      : 0;
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes,
      relationships: {
        schedule: {
          relationships: { context },
        },
        response,
      },
    } = data;

    return new Round(
      fromJS({
        id,
        ...attributes,
        context: context ? Context.createFromApi(context) : undefined,
        responseId: response ? response.id : null,
      }),
    );
  }

  static get defaultFields() {
    return Object.keys(DEFAULT_FIELDS);
  }
}

// eslint-disable-next-line import/no-default-export
export default Round;
