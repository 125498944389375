import { Map } from 'immutable';

import { UxFlow } from '@peakon/records';

const flows = (
  state: Map<string, UxFlow> = Map(),
  action: $TSFixMe,
): Map<string, UxFlow> => {
  switch (action.type) {
    case 'FLOW_CHECK_SUCCESS': {
      const { entity, name, completed } = action.data;
      const flow = new UxFlow({ entity, name, completed });

      return state.set(flow.id, flow);
    }

    case 'FLOW_COMPLETE_SUCCESS':
    case 'FLOW_REMOVE_SUCCESS': {
      const { completed, entity, name } = action.data;
      const id = UxFlow.id(entity, name);

      if (state.has(id)) {
        return state.update(id, (flow) => flow.set('completed', completed));
      }

      const flow = new UxFlow({ entity, name, completed });

      return state.set(flow.id, flow);
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default flows;
