import * as Sentry from '@sentry/react';

import ErrorReporter from '@peakon/error-reporter';

import env from './env';
import { getRouteInformation } from '../features/analytics/productAreas';

function reportToSentry({
  error,
  data,
  level,
}: {
  error: string | Error;
  data?: { [key: string]: unknown };
  level?: 'info' | 'warning' | 'debug';
}) {
  if (!Sentry.getClient()) {
    return;
  }

  Sentry.withScope(function (scope) {
    data && scope.setContext('data', data);

    if (typeof error === 'string') {
      Sentry.captureMessage(error, level ?? 'error');
    } else {
      level && scope.setLevel(level);
      Sentry.captureException(error);
    }
  });
}

export const getErrorReporterWrapper = (errorReporter: ErrorReporter) => {
  Sentry.init({
    dsn: 'https://5af3394097464071f98cc22e8fbeda2d@o4507498218192896.ingest.de.sentry.io/4507526746079312',
    integrations: [
      Sentry.browserTracingIntegration(),
      // Or, if you are using react router, use the appropriate integration
      // See docs for support for different versions of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      // Sentry.reactRouterV6BrowserTracingIntegration({
      //   useEffect: React.useEffect,
      //   useLocation,
      //   useNavigationType,
      //   createRoutesFromChildren,
      //   matchRoutes,
      // }),
    ],
    environment: env.clusterEnv,
    // For finer control of sent transactions you can adjust this value, or
    // use tracesSampler
    // between 0 and 1
    tracesSampleRate: 1.0, // 100% of your transactions will get recorded and sent
    beforeSendTransaction(transactionEvent) {
      if (!transactionEvent.transaction) {
        return transactionEvent;
      }
      const routeInfo = getRouteInformation({
        pathname: transactionEvent.transaction,
        search: '',
      });

      if (!routeInfo) {
        return null;
      }

      return { ...transactionEvent, transaction: routeInfo.obfuscatedUrl };
    },

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  });

  return {
    error: (message: string | Error, data?: { [key: string]: unknown }) => {
      reportToSentry({ error: message, data });
      errorReporter.error(message, data);
    },
    info: (message: string | Error, data?: { [key: string]: unknown }) => {
      reportToSentry({ error: message, data, level: 'info' });
      errorReporter.info(message, data);
    },
    warning: (message: string | Error, data?: { [key: string]: unknown }) => {
      reportToSentry({ error: message, data, level: 'warning' });
      errorReporter.warning(message, data);
    },
    debug: (message: string | Error, data?: { [key: string]: unknown }) => {
      reportToSentry({ error: message, data, level: 'debug' });
      errorReporter.debug(message, data);
    },
    updateConfig: (config: Parameters<ErrorReporter['updateConfig']>[0]) => {
      errorReporter.updateConfig(config);
    },
  };
};

const getErrorReporter = () => {
  const errorReporter = new ErrorReporter({
    accessToken: env.rollbar ? env.rollbar.client : '',
    environment: env.clusterEnv,
    version: env.version,
  });

  if (env.clusterEnv === 'staging') {
    return getErrorReporterWrapper(errorReporter);
  }

  return errorReporter;
};

const errorReporter = window.__peakon_error__ ?? getErrorReporter();
window.__peakon_error__ = errorReporter;

export type ErrorReporterType = ReturnType<typeof getErrorReporter>;

// eslint-disable-next-line import/no-default-export
export default errorReporter;
