import { List } from 'immutable';

import { Question } from '@peakon/records';
import { Question as ZodInferredQuestionType } from '@peakon/shared/schemas/api/questions';

const QUESTION_GROUP = [
  'open_ended',
  'engagement',
  'diversity_inclusion',
  'health_wellbeing',
  'transformation_change',
  'covid19',
  'other',
] as const;

type QuestionListType = {
  group: (typeof QUESTION_GROUP)[number];
  data: ZodInferredQuestionType[];
};

type QuestionToggleType = {
  active: boolean;
  data: ZodInferredQuestionType;
  id: string;
};

type QuestionUpdateType = {
  id: string;
  attributes?: {
    text: string;
    translations: { [key: string]: string };
  };
  data: ZodInferredQuestionType;
};

type QuestionCreateType = {
  data: ZodInferredQuestionType;
};

type QuestionDeleteType = {
  id: string;
};

type Action = {
  type: string;
  data:
    | QuestionListType
    | QuestionToggleType
    | QuestionUpdateType
    | QuestionCreateType
    | QuestionDeleteType;
};

const groupQuestions = (state = List<Question>(), action: Action) => {
  switch (action.type) {
    case 'GROUP_QUESTIONS_LIST_SUCCESS': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const { data } = action.data as QuestionListType;

      return List(data.map(Question.createFromApi));
    }

    case 'QUESTION_TOGGLE_STATUS_SUCCESS': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const { id, active } = action.data as QuestionToggleType;

      const index = state.findIndex((question) => question?.id === id);

      if (index === -1) {
        return state;
      }

      return state.update(index, (question) =>
        question.set('status', active ? 'active' : 'inactive'),
      );
    }

    case 'QUESTION_UPDATE_SUCCESS': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const { id, data } = action.data as QuestionUpdateType;

      const index = state.findIndex((question) => question?.id === id);

      if (index === -1) {
        return state;
      }

      /**
       *
       * NOTE: PEAKONISSUE-10254
       *
       * Questions being updated from somewhere else after initial load.
       *
       * Case:
       *
       * This is an issue that can happen when a question is moved between groups
       * after the user loaded it.
       *
       * This can happen when a user comes back to a long running session, tries
       * to update the question but another user moved the question to a
       * different group in the mean time.
       *
       * It can also happen if a user has multiple tabs open and moves the question
       * in one tab and then edits it in another.
       *
       * Issue
       *
       * If the question is moved to another group when updated, then it should
       * no longer be part of the "current" group, meaning we should remove it
       * from this list. This becomes extra important since some question selectors
       * depends on being able to find the group for a question in `state.categories`,
       * but we only ever have the "current" categories in there, meaning that
       * if we move the question to another group, that group won't be part of `state.categories`
       * and the selectors will blow up at runtime.
       *
       * We used to use the `isMovingGroup` from the question form to handle
       * this, but since that would have been calculated based on some stale
       * data, that isn't reliable.
       *
       * The only thing we can do is to compare the old and the new question `group` here.
       *
       */

      const oldGroup = state.get(index).category?.group;

      /**
       *
       * NOTE: The type for the data isn't correct at all
       *
       * The actual value we get here depends on what happened (changing group,
       * category etc..). So, sometimes not even the relationships are there.
       *
       */

      // @ts-expect-error - Property 'relationships' does not exist on type '{ id: string; type: "categories"; }'.
      const newGroup = data?.relationships?.category?.relationships?.group?.id;

      const questionWasMovedToADifferentGroup = Boolean(
        newGroup && oldGroup !== newGroup,
      );

      if (questionWasMovedToADifferentGroup) {
        return state.filter((question) => question.id !== id);
      }

      return state.update(index, () => Question.createFromApi(data));
    }

    case 'QUESTION_CREATE_SUCCESS': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const { data } = action.data as QuestionCreateType;

      return state.push(Question.createFromApi(data));
    }

    case 'QUESTION_DELETE_SUCCESS': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const { id } = action.data as QuestionDeleteType;

      return state.filter((question) => question?.id !== id);
    }

    case 'DRIVER_QUESTIONS_RESET':
    case 'GROUP_QUESTIONS_RESET': {
      return List<Question>();
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default groupQuestions;
