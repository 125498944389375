import { List, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import EmployeeRecord from './EmployeeRecord';

const schema = z.object({
  isLoadingNext: z.boolean().optional(),
  members: z.array(z.object({})).optional(),
  nextUrl: z.string().optional(),
  total: z.number().optional(),
  totalFiltered: z.number().nullish(),
});
type Schema = Omit<z.infer<typeof schema>, 'members'> & {
  members?: List<EmployeeRecord>;
};

class BulkNotify
  extends Record({
    isLoadingNext: false,
    members: List(),
    nextUrl: undefined,
    total: undefined,
    totalFiltered: undefined,
  })
  implements Schema
{
  isLoadingNext: Schema['isLoadingNext'];
  members: Schema['members'];
  nextUrl: Schema['nextUrl'];
  total: Schema['total'];
  totalFiltered: Schema['totalFiltered'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'BulkNotify',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}

// eslint-disable-next-line import/no-default-export
export default BulkNotify;
