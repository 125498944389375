import z from 'zod';

import { translatedStringSchema } from '@peakon/shared/features/i18next/t';

const employeeFragment = z.object({
  id: z.string(),
  type: z.literal('employees'),
  attributes: z.object({
    avatar: z.string().optional(),
    name: z.string(),
  }),
});

const priorityFragment = z.object({
  id: z.string(),
  type: z.literal('priority_drivers'),
  attributes: z.object({
    allowTeamSuggestions: z.boolean(),
    driver: z.string(),
    managerMessage: z.string().optional(),
    source: z.enum(['company', 'peakon']),
    status: z.string(),
    statusSetAt: z.date(),
  }),
});

const categoryFragment = z.object({
  id: z.string(),
  type: z.literal('categories'),
  attributes: z.object({
    descriptionTranslated: translatedStringSchema,
    group: z.string(),
    nameTranslated: translatedStringSchema,
    standard: z.string(),
  }),
  relationships: z.object({
    parentCategory: z.object({
      id: z.string(),
      type: z.literal('categories'),
    }),
  }),
});

const contextFragment = z.object({
  id: z.string(),
  type: z.literal('contexts'),
  attributes: z.object({
    logo: z.string().nullable(),
    name: z.string(),
  }),
});

const teamSuggestionsFragment = z
  .object({
    id: z.string(),
    type: z.literal('team_suggestions'),
    attributes: z.object({
      text: z.string(),
    }),
  })
  .optional();

const IncludedSchema = z.union([
  categoryFragment,
  employeeFragment,
  priorityFragment,
  contextFragment,
  teamSuggestionsFragment,
]);

const standaloneTeamSuggestionsResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal('bearer_tokens'),
  }),
  included: z.array(IncludedSchema),
});

const standaloneTeamSuggestionsSessionResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal('sessions'),
    attributes: z.object({
      locale: z.string(),
    }),
  }),
  included: z.array(
    z.object({
      type: z.literal('companies'),
      id: z.string(),
      attributes: z.object({
        logo: z.string(),
        name: z.string(),
      }),
    }),
  ),
});

export type StandaloneTeamSuggestionsSessionResponse = z.infer<
  typeof standaloneTeamSuggestionsSessionResponseSchema
>;

export type StandaloneTeamSuggestionsCategory = z.infer<
  typeof categoryFragment
>;
export type StandaloneTeamSuggestionsEmployee = z.infer<
  typeof employeeFragment
>;
export type StandaloneTeamSuggestionsPriority = z.infer<
  typeof priorityFragment
>;
export type StandaloneTeamSuggestionsContext = z.infer<typeof contextFragment>;

export type StandaloneTeamSuggestionsTeamSuggestion = z.infer<
  typeof teamSuggestionsFragment
>;

export type StandaloneTeamSuggestionsResponse = z.infer<
  typeof standaloneTeamSuggestionsResponseSchema
>;

type IncludedTypes = z.infer<typeof IncludedSchema>;

export function isCategory(
  category: IncludedTypes,
): category is StandaloneTeamSuggestionsCategory {
  return category?.type === 'categories';
}

export function isEmployee(
  employee: IncludedTypes,
): employee is StandaloneTeamSuggestionsEmployee {
  return employee?.type === 'employees';
}

export function isPriority(
  priority: IncludedTypes,
): priority is StandaloneTeamSuggestionsPriority {
  return priority?.type === 'priority_drivers';
}

export function isContext(
  context: IncludedTypes,
): context is StandaloneTeamSuggestionsContext {
  return context?.type === 'contexts';
}

export function isTeamSuggestion(
  teamSuggestion: IncludedTypes,
): teamSuggestion is StandaloneTeamSuggestionsTeamSuggestion {
  return teamSuggestion?.type === 'team_suggestions';
}

export function isParentCategory(
  category: StandaloneTeamSuggestionsCategory,
): boolean {
  return category?.relationships?.parentCategory === null;
}
