import React, { ReactElement } from 'react';

import { List as ImmutableList } from 'immutable';
import Media from 'react-media';

import { Button } from '@peakon/bedrock/react/button';
import { Placeholder, SlideIn, Typography } from '@peakon/components';
import { MOBILE_MAX_WIDTH } from '@peakon/shared/constants/breakpoints';
import { t } from '@peakon/shared/features/i18next/t';

import { EmployeeList } from './EmployeeList';
import { EmployeeListNoAccess } from './EmployeeListNoAccess';
import ContextLabel from '../ContextLabel';

import styles from './styles.css';

export type EmployeeSlideInProps = {
  isOpen?: boolean;
  dashboardContext?: $TSFixMe;
  segment?: $TSFixMe;
  accessDenied?: boolean;
  description?: string;
  employees?: ImmutableList<$TSFixMe>;
  handleOutsideClick?: () => void;
  hasEmployeeAccess?: boolean;
  headerContent?: React.ReactNode;
  isLoading?: boolean;
  isSegmentManager?: boolean;
  loadMore?: () => void;
  noResultsMessage?: string;
  onManagerRemove?: (id: string) => void;
  showTotal?: boolean;
  totalEmployees?: number;
  title?: string;
  subTitle?: string | ReactElement;
  testId?: string;
};

const EmployeeSlideIn = ({
  accessDenied,
  description,
  handleOutsideClick,
  headerContent,
  isLoading,
  totalEmployees,
  employees = ImmutableList(),
  dashboardContext,
  segment,
  title,
  subTitle,
  testId,
  noResultsMessage,
  showTotal,
  isSegmentManager,
  loadMore,
  onManagerRemove,
  hasEmployeeAccess,
  isOpen,
}: EmployeeSlideInProps) => {
  const contextRef = segment || dashboardContext;

  const renderTotal = () => {
    if (!showTotal || (!isLoading && totalEmployees === 0)) {
      return null;
    }

    return (
      <div className={styles.total}>
        {employees.isEmpty() && isLoading ? (
          <Placeholder style={{ height: 17, width: 128 }} />
        ) : isSegmentManager ? (
          <span>
            {t('attribute_segments_list__managers')} {totalEmployees}
          </span>
        ) : (
          t('employee_slidein__total', {
            replace: {
              count: totalEmployees,
            },
          })
        )}
      </div>
    );
  };

  return (
    <SlideIn
      isInteractive
      show={isOpen}
      onClose={handleOutsideClick}
      closeButtonLabel={t('common__close')}
      testId={testId}
      title={
        segment || dashboardContext ? (
          <div className={styles.header}>
            <ContextLabel
              dashboardContext={segment || dashboardContext}
              modifier="slideIn"
              type="chat"
            />

            {renderTotal()}
            {description && (
              <div className={styles.description}>{description}</div>
            )}

            {headerContent}
            {subTitle && (
              <div className={styles.headerSubTitle}>{subTitle}</div>
            )}
          </div>
        ) : (
          title && (
            <div className={styles.header}>
              <Typography.H2>{title}</Typography.H2>
            </div>
          )
        )
      }
      aria-label={
        contextRef &&
        t('a11y__surveyed_employees', {
          replace: { name: contextRef.translatedName || contextRef.name },
        })
      }
    >
      <SlideIn.Panel>
        <SlideIn.Content className={styles.root}>
          {accessDenied ? (
            <EmployeeListNoAccess />
          ) : (
            <EmployeeList
              isLoading={isLoading}
              totalEmployees={totalEmployees}
              employees={employees}
              noResultsMessage={noResultsMessage}
              isSegmentManager={isSegmentManager}
              loadMore={loadMore}
              onManagerRemove={onManagerRemove}
              hasEmployeeAccess={hasEmployeeAccess}
            />
          )}
        </SlideIn.Content>
        <SlideIn.Footer>
          <Media query={{ minWidth: MOBILE_MAX_WIDTH + 1 }}>
            {(matches) => (
              <Button
                onClick={handleOutsideClick}
                variant="secondary"
                size={matches ? 'medium' : 'small'}
              >
                {t('common__close')}
              </Button>
            )}
          </Media>
        </SlideIn.Footer>
      </SlideIn.Panel>
    </SlideIn>
  );
};

// eslint-disable-next-line import/no-default-export
export default EmployeeSlideIn;
