import React from 'react';

import { Paragraph } from '@peakon/bedrock/react/typography';
import { Modal } from '@peakon/components';

import { ProductTourModal } from '../types';

import styles from './styles.css';

type Props = {
  step: ProductTourModal;
};

// eslint-disable-next-line import/no-default-export
export default function ModalContent({ step }: Props) {
  if (step.illustration) {
    return (
      <React.Fragment>
        <div className={styles.illustration}>
          <img
            alt=""
            height={200}
            src={step.illustration.srcSet[0]}
            srcSet={`${step.illustration.srcSet[0]}, ${step.illustration.srcSet[1]} 2x`}
            width={400}
          />
        </div>
        <ModalTextContent step={step} />
      </React.Fragment>
    );
  }

  if (step.video) {
    return (
      <React.Fragment>
        <div className={styles.illustration}>
          <video
            aria-hidden
            src={step.video.src}
            controls={false}
            loop
            playsInline
            muted
            autoPlay
            height={264}
            width={400}
          />
        </div>
        <ModalTextContent step={step} />
      </React.Fragment>
    );
  }

  return <ModalTextContent step={step} />;
}

function ModalTextContent({ step }: Props) {
  return (
    <React.Fragment>
      <Modal.Title className={styles.title} data-test-id="product-tour-title">
        {step.getTitle?.()}
      </Modal.Title>
      <span data-test-id="product-tour-text">
        {step.getText &&
          [step.getText()]
            .flat()
            .map((translation, index) => (
              <Paragraph key={index}>{translation}</Paragraph>
            ))}
      </span>
    </React.Fragment>
  );
}
