import React from 'react';

import classnames from 'classnames';

import {
  AcknowledgementsThanksForSharingIcon,
  AcknowledgementsGreatIdeaIcon,
  AcknowledgementsWorkingOnItIcon,
  AcknowledgementsTalkInPersonIcon,
  AcknowledgementsIAgreeIcon,
} from '@peakon/bedrock/icons/graphic';

import { AcknowledgementType } from '../../constants/acknowledgementTypes';

import styles from './styles.css';

const mapAcknowledgementTypeToIcon = {
  thanks_for_sharing: AcknowledgementsThanksForSharingIcon,
  great_idea: AcknowledgementsGreatIdeaIcon,
  working_on_it: AcknowledgementsWorkingOnItIcon,
  would_love_to_talk_in_person: AcknowledgementsTalkInPersonIcon,
  i_agree: AcknowledgementsIAgreeIcon,
};

type Props = {
  className?: string;
  size?: 'tiny' | 'small' | 'normal' | 'large' | 'huge';
  type?: AcknowledgementType;
  as?: 'div' | 'span';
};

const AcknowledgementIcon = ({
  as: Component = 'div',
  className,
  size = 'normal',
  type,
  ...props
}: Props) => {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const Icon = mapAcknowledgementTypeToIcon[type];

  if (!Icon) {
    return null;
  }

  return (
    <Component
      className={classnames(styles.root, className, {
        [styles[size]]: size,
      })}
      {...props}
      data-test-id={`${type}-acknowledgement-icon`}
    >
      <Icon />
    </Component>
  );
};

// eslint-disable-next-line import/no-default-export
export default AcknowledgementIcon;
