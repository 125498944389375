import { Record, fromJS, List } from 'immutable';
import { z } from 'zod';

import { AccountResponse } from '@peakon/shared/schemas/api/accounts';
import {
  ColorMode,
  Role,
  TwofactorStatus,
} from '@peakon/shared/types/AccountRecord';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

export type Location = {
  city?: string;
  country?: string;
  countryCode?: string;
};

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  settings: z.any(),
  colorMode: z.any(),
  bounceReason: z.any(),
  localeEffective: z.any(),
  twofactorStatus: z.any(),
  timezoneEffective: z.any(),
  bouncedAt: z.any(),
  role: z.any(),
  timezone: z.any(),
  locale: z.any(),
  email: z.any(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Account
  extends Record({
    id: '',
    email: undefined,
    role: undefined,
    locale: undefined,
    timezone: undefined,
    bouncedAt: undefined,
    bounceReason: undefined,
    twofactorStatus: 'disabled',
    localeEffective: undefined,
    timezoneEffective: undefined,
    rights: List(),
    colorMode: 'normal',
    // Location
    city: undefined,
    country: undefined,
    countryCode: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  email?: string;
  locale?: string;
  localeEffective?: string;
  timezone?: string;
  timezoneEffective?: string;
  bouncedAt?: Date;
  bounceReason?: string;
  role?: Role;
  twofactorStatus!: TwofactorStatus;
  rights?: List<string>;
  colorMode?: ColorMode;
  city?: string;
  country?: string;
  countryCode?: string;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Account',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'Account',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  setLocation({ city, country, countryCode }: Location) {
    return this.merge({
      city,
      country,
      countryCode,
    });
  }

  getLocation(): Location {
    return {
      city: this.city,
      country: this.country,
      countryCode: this.countryCode,
    };
  }

  static createFromApi(data: AccountResponse) {
    const { id, attributes } = data;

    return new Account(
      fromJS({
        id,
        colorMode: attributes?.settings?.colorMode ?? 'normal',
        ...attributes,
      }),
    );
  }
}
