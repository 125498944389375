import React from 'react';

import classnames from 'classnames';

import { NotFoundScreen } from '@peakon/shared/components/NotFoundScreen/NotFoundScreen';
import { TranslatedString, t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type TitleProps = {
  children: TranslatedString;
};

const Title = ({ children }: TitleProps) => (
  <span className={styles.title}>{children}</span>
);

type DetailsProps = {
  children: TranslatedString;
};

const Details = ({ children }: DetailsProps) => (
  <span className={styles.detail}>{children}</span>
);

type CustomErrorScreenProps = {
  children: React.ReactNode;
};

export const CustomErrorScreen = ({ children }: CustomErrorScreenProps) => (
  <div className={classnames(styles.container, styles.genericError)}>
    {children}
  </div>
);

CustomErrorScreen.Title = Title;
CustomErrorScreen.Details = Details;

type GenericErrorScreenProps = {
  message?: TranslatedString;
  title?: TranslatedString;
};

export const GenericErrorScreen = ({
  title,
  message,
}: GenericErrorScreenProps) => (
  <div className={classnames(styles.container, styles.genericError)}>
    <h1 className={styles.title}>{title || t('generic_error__title')}</h1>
    <span className={styles.detail}>
      {message || t('generic_error__text_v2')}
    </span>
  </div>
);

type ErrorScreenProps = {
  message?: TranslatedString;
  title?: TranslatedString;
  type?: 404 | 422 | 500;
};

const ErrorScreen = ({ message, title, type = 404 }: ErrorScreenProps) => {
  if (type === 404) {
    return <NotFoundScreen />;
  }

  return <GenericErrorScreen title={title} message={message} />;
};

// eslint-disable-next-line import/no-default-export
export default ErrorScreen;
