import React from 'react';

import { FormProviderProps, FormProvider } from 'react-hook-form';
import z from 'zod';

import { RequiredFieldsProvider } from './RequiredFieldsProvider';

export type FormProps<T extends z.Schema> = FormProviderProps<z.infer<T>> & {
  schema: T;
  children: React.ReactNode;
};

export function ZodFormProvider<T extends z.Schema>({
  children,
  schema,
  ...methods
}: FormProps<T>) {
  return (
    <RequiredFieldsProvider schema={schema}>
      <FormProvider {...methods}>{children}</FormProvider>
    </RequiredFieldsProvider>
  );
}
