import React from 'react';

import { Button } from '@peakon/bedrock/react/button';
import { Toggle, Tooltip } from '@peakon/components';
import { Context } from '@peakon/records/';
import ContextLabel from '@peakon/shared/components/context/ContextLabel';
import { t } from '@peakon/shared/features/i18next/t';
import formatYearless from '@peakon/shared/utils/formatYearless';

import { useUpdateContextMutation } from '../queries/useUpdateContextMutation';

import styles from './styles.css';

export type ShareAttributes = {
  createdAt: Date;
  url: string;
  isPublic: boolean;
  access: $TSFixMe;
};

export type SharedDashboardCardProps = {
  canSetToPublic: boolean;
  shareAttributes: ShareAttributes;
  shareContext: $TSFixMe;
  revokeDashboard: (context: $TSFixMe) => void;
  toggleIsPublic: () => void;
};

export const SharedDashboardCard = ({
  shareContext,
  shareAttributes,
  canSetToPublic,
  revokeDashboard,
  toggleIsPublic,
}: SharedDashboardCardProps) => {
  const { mutate: updateContext } = useUpdateContextMutation({
    onSuccess: () => toggleIsPublic(),
  });

  const getCreatedDataFormatted = (createdAt: Date): string => {
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    return formatYearless(createdAt, 'LL');
  };

  return (
    <div className={styles.item} data-test-id="shared-dashboard-card">
      <div className={styles.itemContext}>
        <ContextLabel
          context={Context.createFromApi(shareContext)}
          modifier="list"
        />
        <div className={styles.createdAt}>
          {t('share_settings__created_at', {
            replace: {
              date: getCreatedDataFormatted(shareAttributes.createdAt),
            },
          })}
        </div>
      </div>
      <div className={styles.itemActions}>
        <a href={shareAttributes.url} target="_blank" rel="noopener noreferrer">
          {t('share_settings__open')}
        </a>
        {canSetToPublic && (
          <div className={styles.switch}>
            <Toggle
              checked={shareAttributes.isPublic}
              onChange={() =>
                updateContext({
                  id: shareContext.id,
                  access: shareAttributes.access,
                  isPublic: !shareAttributes.isPublic,
                })
              }
            >
              <Tooltip
                title={
                  shareAttributes.isPublic
                    ? t('share_modal__private_type_public')
                    : t('share_modal__private_type_private')
                }
                placement="top"
                trigger="mouseenter"
              >
                {shareAttributes.isPublic
                  ? t('share_settings__public')
                  : t('share_settings__private')}
              </Tooltip>
            </Toggle>
          </div>
        )}
        <Button
          variant="secondary"
          onClick={() => revokeDashboard(shareContext)}
          size="small"
          data-test-id="revoke-dashboard"
        >
          {t('share_settings__revoke')}
        </Button>
      </div>
    </div>
  );
};
