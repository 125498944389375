import React, { useEffect } from 'react';

import { z } from 'zod';

import { Button } from '@peakon/bedrock/react/button';
import { Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import usePrevious from '@peakon/shared/hooks/usePrevious';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { ProgressBar } from '../../TaskNotification/ProgressBar/ProgressBar';
import { TaskNotification } from '../../TaskNotification/TaskNotification';
import { Task } from '../types';

import styles from './styles.css';

const completedDataSchema = z.object({
  status: z.literal('completed'),
});

const errorDataSchema = z.object({
  status: z.literal('error'),
  error: z.string(),
});

const inProgressDataSchema = z.object({
  status: z.literal('inProgress'),
  percent: z.number().min(0).max(100),
});

const dataSchema = z.union([
  inProgressDataSchema,
  completedDataSchema,
  errorDataSchema,
]);

const typeSchema = z.literal('attributeStandardMapping');

const attributeStandardMappingTask = z.object({
  id: z.string(),
  type: typeSchema,
  data: dataSchema,
  onComplete: z.function(z.tuple([]).rest(z.never()), z.void()),
});

type AttributeStandardMappingTask = z.infer<
  typeof attributeStandardMappingTask
>;

export type AttributeStandardMappingTaskInitializationData = Pick<
  AttributeStandardMappingTask,
  'type' | 'onComplete'
>;

export const isAttributeStandardMappingTask = (
  task: Task,
): task is AttributeStandardMappingTask => {
  if (typeSchema.safeParse(task.type).success) {
    validateData(task, attributeStandardMappingTask, {
      errorMessagePrefix: 'AttributeStandardMappingBackgroundTask',
    });
    return true;
  }
  return false;
};

export const AttributeStandardMappingTaskNotification = ({
  task,
  onClose,
}: {
  task: AttributeStandardMappingTask;
  onClose: () => void;
}) => {
  const previousStatus = usePrevious(task.data.status);

  useEffect(() => {
    if (
      task.data.status !== previousStatus &&
      task.data.status === 'completed'
    ) {
      task.onComplete();
    }
  }, [previousStatus, task]);

  if (task.data.status === 'inProgress') {
    return (
      <TaskNotification
        taskId={task.id}
        heading={t(
          'background_task__attribute_standard_mapping__updating__title',
        )}
        onClose={onClose}
        status="inProgress"
      >
        <TaskNotification.Content>
          <div className={styles.description}>
            <Typography.P>
              {/* TODO (PEAKONADX-989): replace with background_task__attribute_standard_mapping__updating__text once translations are available */}
              {t('background_task__attribute_cleanup__deleting__text')}
            </Typography.P>
          </div>
          <ProgressBar percent={task.data.percent} />
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  if (task.data.status === 'completed') {
    return (
      <TaskNotification
        taskId={task.id}
        heading={t('background_task__attribute_standard_mapping__ready__title')}
        onClose={onClose}
        status="completed"
      >
        <TaskNotification.Content>
          <div className={styles.description}>
            <Typography.P>
              {t('background_task__attribute_standard_mapping__ready__text')}
            </Typography.P>
          </div>
          <Button variant="primary" onClick={onClose}>
            {t('background_task__attribute_standard_mapping__ready__ok')}
          </Button>
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  return (
    <TaskNotification
      taskId={task.id}
      heading={t('background_task__attribute_standard_mapping__error__title')}
      onClose={onClose}
      status="error"
    >
      <TaskNotification.Content>
        <Typography.P className={styles.error}>{task.data.error}</Typography.P>
      </TaskNotification.Content>
    </TaskNotification>
  );
};
