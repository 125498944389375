import { fromJS, List, Map, Record } from 'immutable';
import { z } from 'zod';

import { AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import { localeIdsSchema } from '@peakon/shared/features/i18next/localesValidation';
import { translatedStringSchema } from '@peakon/shared/features/i18next/t';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Acknowledgement from './AcknowledgementRecord';
import CommentFeedback from './CommentFeedbackRecord';
import Employee from './EmployeeRecord';
import Question from './QuestionRecord';
import Topic from './TopicRecord';

const getFeedbackObject = (feedbackData: $TSFixMe) => {
  if (!feedbackData) {
    return undefined;
  }

  return new CommentFeedback({
    isRelevant: feedbackData.isRelevant,
    isBiased: feedbackData.isBiased,
    isBiasedComment: feedbackData.isBiasedComment,
  });
};

const semanticTagSchema = z.object({
  id: z.string(),
  name: translatedStringSchema,
  locale: localeIdsSchema,
});
type SemanticTag = z.infer<typeof semanticTagSchema>;

const highlightReasonsEnum = z.enum([
  'focused_content',
  'high_score',
  'low_score',
  'text_length',
]);
type HighlightReason = z.infer<typeof highlightReasonsEnum>;

const schema = z.object({
  id: z.string(),
  acknowledgement: z.object({}).nullable().optional(),
  acknowledgementCounts: z
    .object({
      great_idea: z.number().optional(),
      i_agree: z.number().optional(),
      thanks_for_sharing: z.number().optional(),
      working_on_it: z.number().optional(),
      would_love_to_talk_in_person: z.number().optional(),
    })
    .optional(),
  answeredAt: z.string().date().optional(),
  closesAt: z.string().date().optional(),
  driver: z.string().nullable().optional(),
  highlighted: z.boolean().optional(),
  highlightReasons: z.array(highlightReasonsEnum).optional(),
  // FIXME: this should use `localeIdsSchema` when we figure out what to do
  // with comments in production that have invalid locales
  // https://workday-dev.slack.com/archives/C06FVJNT43Z/p1719584587219839
  locale: z.string().nullable(),
  managers: z.array(z.object({})).optional(),
  messageCount: z.number().min(0).optional(),
  new: z.boolean().optional(),
  question: z.object({}).optional(),
  score: z.number().int().min(0).max(10).nullable(),
  sensitive: z.boolean().optional(),
  sensitiveMatches: z.array(translatedStringSchema).optional(),
  subdriver: z.string().optional(),
  text: translatedStringSchema,
  translation: translatedStringSchema.optional(),
  topics: z.array(z.object({})).optional(),
  // https://github.com/peakon/api/blob/master/services/comment/constants.ts
  type: z.enum(['driver', 'text', 'value']),
  commentFeedback: z.object({}).optional(),
  semanticTags: z.array(semanticTagSchema).optional(),

  // non-API props
  active: z.boolean().optional(),
  isLoadingManagers: z.boolean().optional(),
  translated: z.boolean().optional(),
  commentTranslation: z
    .object({
      sourceLocale: z.string(),
      targetLocale: z.string(),
      text: translatedStringSchema,
    })
    .optional(),
});
type Schema = z.infer<typeof schema>;

class Comment
  extends Record({
    id: undefined,
    acknowledgement: undefined,
    acknowledgementCounts: undefined,
    active: undefined,
    answeredAt: undefined,
    closesAt: undefined,
    driver: undefined,
    highlighted: undefined,
    highlightReasons: undefined,
    isLoadingManagers: undefined,
    locale: undefined,
    managers: undefined,
    messageCount: 0,
    new: false,
    question: undefined,
    score: undefined,
    sensitive: undefined,
    sensitiveMatches: undefined,
    subdriver: undefined,
    text: undefined,
    translation: undefined,
    translated: false,
    topics: undefined,
    type: undefined,
    commentFeedback: undefined,
    semanticTags: undefined,
    commentTranslation: undefined,
  })
  implements
    Omit<
      Schema,
      | 'highlightReasons'
      | 'managers'
      | 'semanticTags'
      | 'sensitiveMatches'
      | 'topics'
      | 'acknowledgementCounts'
      | 'commentTranslation'
    >
{
  id!: Schema['id'];
  acknowledgement?: Acknowledgement | null;
  acknowledgementCounts?: Map<AcknowledgementType, number>;
  active: Schema['active'];
  answeredAt!: Schema['answeredAt'];
  closesAt: Schema['closesAt'];
  commentFeedback: Schema['commentFeedback'];
  driver: Schema['driver'];
  highlighted: Schema['highlighted'];
  highlightReasons?: List<HighlightReason>;
  isLoadingManagers: Schema['isLoadingManagers'];
  locale!: Schema['locale'];
  managers?: List<Employee>;
  messageCount: Schema['messageCount'];
  new: Schema['new'];
  question!: Question;
  score!: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | null;
  semanticTags?: List<SemanticTag>;
  sensitive: Schema['sensitive'];
  sensitiveMatches?: List<string>;
  subdriver: Schema['subdriver'];
  text!: Schema['text'];
  topics?: List<Topic>;
  translated: Schema['translated'];
  translation: Schema['translation'];
  commentTranslation?: Map<'text' | 'sourceLocale' | 'targetLocale', string>;
  type!: Schema['type'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CommentRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes: {
        commentRelevance,
        commentType,
        comment,
        topicsInfo,
        semanticSearchFeedbackData,
        ...other
      },
      relationships: {
        acknowledgement,
        question,
        semanticTagFeedbacks,
        semanticTags,
        commentTranslation,
      },
    } = data;

    const highlighted = commentRelevance && commentRelevance.relevant;

    return new Comment(
      fromJS({
        id,
        commentTranslation: commentTranslation
          ? commentTranslation.attributes
          : undefined,
        type: commentType,
        text: comment,
        highlighted,
        highlightReasons: highlighted ? commentRelevance.reasons : undefined,
        topics: topicsInfo
          ? topicsInfo.map(
              ({ topicId, matches, name }: $TSFixMe) =>
                new Topic({
                  id: topicId,
                  matches,
                  name,
                }),
            )
          : undefined,
        commentFeedback: getFeedbackObject(
          semanticSearchFeedbackData ?? semanticTagFeedbacks?.attributes,
        ),
        ...other,
        acknowledgement:
          acknowledgement && Acknowledgement.createFromApi(acknowledgement),
        question: question && Question.createFromApi(question),
        semanticTags: semanticTags?.map(
          (
            // @ts-expect-error no implicit any
            tag,
          ) => ({
            id: tag.id,
            name: tag.attributes?.name,
            locale: tag.attributes?.locale,
          }),
        ),
      }),
    );
  }

  get categoryId() {
    return this.question ? this.question.categoryId : null;
  }
}

// eslint-disable-next-line import/no-default-export
export default Comment;
