import React from 'react';

import { Link } from 'react-router-dom';

import { NavigationInsightTopicsIcon as Icon } from '@peakon/bedrock/icons/system';

import styles from './styles.css';

type Props = {
  name: string;
  locale?: string | null;
  to: string;
};

export const TopicBadge = ({ name, locale, to }: Props) => (
  <Link
    className={styles.root}
    to={to}
    data-test-id="comment-topic"
    lang={locale || undefined}
  >
    <Icon className={styles.icon} />
    <span>{name}</span>
  </Link>
);
