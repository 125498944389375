import { List, Map } from 'immutable';
import forEach from 'lodash/forEach';

import { FormEditor } from '@peakon/records';
import AccessGroup from '@peakon/records/AccessGroupRecord';
import { ACCESS_SETTINGS_GROUPS } from '@peakon/records/settings';

import {
  getGroupNextCategories,
  getGroupNextGroupSettings,
  getAccessByGroup,
} from './utils';

export const sideEffects = (settings: $TSFixMe, id: $TSFixMe) => {
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  settings[id] = false;

  forEach(ACCESS_SETTINGS_GROUPS, (props, childSetting) => {
    if (props.hasParent(id)) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      settings[childSetting] = false;
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      settings = sideEffects(settings, childSetting);
    }
  });

  return settings;
};

export const categorySideEffects = ({ settings, accessByGroup }: $TSFixMe) => {
  forEach(ACCESS_SETTINGS_GROUPS, (setting, id) => {
    if (!setting.hasGroupAccess(accessByGroup)) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      settings[id] = false;
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      settings = sideEffects(settings, id);
    }
  });

  return settings;
};

const settings = (state = new FormEditor(), action: $TSFixMe): FormEditor => {
  switch (action.type) {
    case 'ACCESS_SETTINGS_CREATE_SUCCESS': {
      const { specialist, data: categoryGroups } = action.data;

      const accessCategoryGroups = Map(
        categoryGroups
          .filter(
            (group: $TSFixMe) => group.id !== 'text' && group.id !== 'values',
          )
          // @ts-expect-error TS(7006): Parameter 'group' implicitly has an 'any' type.
          .map((group) => [group.id, !specialist]),
      );

      const accessSettings = Object.keys(ACCESS_SETTINGS_GROUPS).reduce(
        (acc, curr) => {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
          acc[curr] = false;

          return acc;
        },
        {},
      );

      return FormEditor.startEditing(
        new AccessGroup({
          settings: Map(accessSettings),
          categoryGroupSettings: accessCategoryGroups,
          status: 'enabled',
          specialist: Boolean(specialist),
          level: specialist ? 'all' : undefined,
          memberType: specialist ? 'member' : undefined,
        }),
      );
    }
    case 'ACCESS_SETTINGS_EDIT_START_SUCCESS':
    case 'ACCESS_SETTINGS_EDIT_START': {
      const { group } = action.data;

      return FormEditor.startEditing(group);
    }
    case 'ACCESS_SETTINGS_CHANGE': {
      const { id, enabled } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return (
        enabled
          ? state.setIn(['current', 'settings', id], true)
          : state.updateIn(['current', 'settings'], (stateSettings) =>
              stateSettings.merge(sideEffects(stateSettings.toJS(), id)),
            )
      ) as FormEditor;
    }

    case 'ACCESS_CATEGORY_CHANGE': {
      const { groupId, categoryId, categoriesByGroup, enabled } = action.data;

      // Determine what's the next state for categoryGroupSettings and
      // the active categoryIds
      const { categoryGroupSettings, categoryIds } = getGroupNextCategories({
        enabled,
        categoriesByGroup,
        categoryId,
        groupId,
        categoryIds: state.getIn(['current', 'categoryIds']),
        categoryGroupSettings: state.getIn([
          'current',
          'categoryGroupSettings',
        ]),
      });

      // Calculate the type of access for each group, in order to determine
      // if any side-effects should be introduced on the next step
      const accessByGroup = getAccessByGroup({
        categoryGroupSettings,
        categoryIds,
        categoriesByGroup,
      });

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.updateIn(['current'], (group) => {
        const categorySideEffectsSettings = categorySideEffects({
          settings: group.settings.toJS(),
          accessByGroup,
        });

        return group.merge({
          categoryGroupSettings,
          categoryIds,
          settings: categorySideEffectsSettings,
        });
      }) as FormEditor;
    }

    case 'ACCESS_CATEGORY_ALL_CHANGE': {
      const { groupId, enabled, categoriesByGroup } = action.data;

      const { categoryGroupSettings, categoryIds } = getGroupNextCategories({
        enabled,
        categoriesByGroup,
        categoryId: null,
        groupId,
        categoryIds: state.getIn(['current', 'categoryIds']),
        categoryGroupSettings: state.getIn([
          'current',
          'categoryGroupSettings',
        ]),
      });

      // Calculate the type of access for each group, in order to determine
      // if any side-effects should be introduced on the next step
      const accessByGroup = getAccessByGroup({
        categoryGroupSettings,
        categoryIds,
        categoriesByGroup,
      });

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.updateIn(['current'], (group) => {
        const categorySideEffectsSettings = categorySideEffects({
          settings: group.settings.toJS(),
          accessByGroup,
        });

        return group.merge({
          categoryGroupSettings,
          categoryIds,
          settings: categorySideEffectsSettings,
        });
      }) as FormEditor;
    }

    case 'ACCESS_QUESTION_SET_CHANGE': {
      const { id, enabled } = action.data;

      const categoryGroupSettings = getGroupNextGroupSettings({
        categoryGroupSettings: state.current.categoryGroupSettings,
        groupId: id,
        enabled,
      });

      const accessByGroup = getAccessByGroup({
        categoryGroupSettings,
      });

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.updateIn(['current'], (group) => {
        return group.merge({
          categoryGroupSettings,
          settings: categorySideEffects({
            settings: group.settings.toJS(),
            accessByGroup,
          }),
        });
      }) as FormEditor;
    }
    case 'ACCESS_GROUP_UPDATE': {
      const { field, value } = action.data;

      // An access group of type manager cannot admin segments
      if (field === 'level' && value === 'manager') {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return state
          .setIn(['current', field], value)
          .setIn(['current', 'settings', 'segmentAdmin'], false) as FormEditor;
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.setIn(['current', field], value) as FormEditor;
    }
    case 'ACCESS_GROUP_ATTRIBUTE_CHANGE': {
      const { id, optionId } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.withMutations((editor) => {
        editor
          .setIn(['current', 'attributeId'], id)
          .setIn(['current', 'attributeOptionId'], optionId);
      }) as FormEditor;
    }
    case 'ACCESS_GROUP_INCLUDED_CHANGE': {
      const { includedSegments } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.withMutations((editor) => {
        editor.setIn(['current', 'includedSegments'], List(includedSegments));
      }) as FormEditor;
    }
    case 'ACCESS_GROUP_INCLUDED_REMOVE': {
      const { segmentId } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.withMutations((editor) => {
        editor.updateIn(['current', 'includedSegments'], (includedSegments) =>
          includedSegments.filter(
            (segment: $TSFixMe) => segment.id !== segmentId,
          ),
        );
      }) as FormEditor;
    }
    case 'ACCESS_GROUP_EXCLUDED_CHANGE': {
      const { excludedSegments } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.withMutations((editor) => {
        editor.setIn(['current', 'excludedSegments'], List(excludedSegments));
      }) as FormEditor;
    }
    case 'ACCESS_GROUP_EXCLUDED_REMOVE': {
      const { segmentId } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.withMutations((editor) => {
        editor.updateIn(['current', 'excludedSegments'], (excludedSegments) =>
          excludedSegments.filter(
            (segment: $TSFixMe) => segment.id !== segmentId,
          ),
        );
      }) as FormEditor;
    }
    case 'ACCESS_GROUP_CREATE_SUCCESS':
    case 'ACCESS_GROUP_PATCH_SUCCESS': {
      const { data } = action.data;

      return FormEditor.startEditing(AccessGroup.createFromApi(data));
    }

    case 'ACCESS_GROUP_MEMBER_ADD_LOADING':
    case 'ACCESS_GROUP_MEMBER_REMOVE_FAILED': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state
        .updateIn(['original', 'memberCount'], (memberCount) => memberCount + 1)
        .updateIn(
          ['current', 'memberCount'],
          (memberCount) => memberCount + 1,
        ) as FormEditor;
    }
    case 'ACCESS_GROUP_MEMBER_ADD_FAILED':
    case 'ACCESS_GROUP_MEMBER_REMOVE_SUCCESS': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state
        .updateIn(['original', 'memberCount'], (memberCount) => memberCount - 1)
        .updateIn(
          ['current', 'memberCount'],
          (memberCount) => memberCount - 1,
        ) as FormEditor;
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default settings;
