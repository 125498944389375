import { useQuery } from '@tanstack/react-query';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';

import { queryKeys } from './queryKeys';
import { catchHandler } from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';
import {
  SensitiveCommentConceptType,
  SensitiveCommentConceptStatus,
} from '../SensitiveCommentsConcept';

export type SensitiveConceptType = `${SensitiveCommentConceptType}` | null;

export type SensitiveConcept = {
  type: string;
  id: string;
  attributes: {
    type: SensitiveConceptType;
    status: SensitiveCommentConceptStatus;
    notifications: string;
    name: string;
    standard: boolean;
  };
  relationships: {
    keywords: [
      {
        type: string;
        id: string;
        attributes: {
          keyword: string;
          locale: string;
        };
        relationships: {
          concept: {
            type: string;
            id: string;
          };
        };
      },
    ];
  };
};

const URL_PARAMS = {
  include: 'keywords',
  fields: {
    concepts: '+',
    keywords: 'keyword,locale',
  },
};

const fetchConcepts = async (): Promise<{ data: SensitiveConcept[] }> => {
  const response = await api.get('/sensitive/concepts', URL_PARAMS);
  return jsonapiparser(response);
};

export const useSensitiveConceptsQuery = (enabled: boolean) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: queryKeys.concepts(),
    onError: (error) => dispatch(catchHandler(error)),
    select: ({ data }) => data,
    queryFn: fetchConcepts,
    enabled,
  });
};
