import { List, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import LegacyPagination from './LegacyPaginationRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class ManagingSegments
  extends Record({
    employees: new LegacyPagination(),
    segments: List(),
  })
  implements Schema
{
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ManagingSegments',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ManagingSegments',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
