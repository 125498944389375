import React from 'react';

import { Segment } from '@peakon/records';
import { createTranslationMap } from '@peakon/shared/features/i18next/createTranslationMap';
import { t } from '@peakon/shared/features/i18next/t';
import { BenchmarkType } from '@peakon/shared/types/Benchmark';

import ContextLabel from '../../../ContextLabel';

import styles from './styles.css';

type BenchmarkLabelProps = {
  id?: string;
  benchmark?: Segment;
  percentile?: number;
  type: NonNullable<BenchmarkType>;
};

export const useBenchmarkTypeTranslationMap = () => {
  const benchmarkTypeTranslationMap = createTranslationMap({
    automatic: t('benchmark_type__automatic'),
    overall: t('benchmark_type__overall'),
    industry: t('benchmark_type__industry'),
    context: t('benchmark_type__context'),
  } as const satisfies Record<BenchmarkLabelProps['type'], string>);

  return { benchmarkTypeTranslationMap };
};

const useGetLabel = (percentile: $TSFixMe) => {
  const percentage = percentile > 50 ? 100 - percentile : percentile;
  if (percentile === 50) {
    return t('dashboard_settings__engagement_score__average', {
      replace: { percentage },
    });
  }
  if (percentile < 50) {
    return t('dashboard__benchmark__bottom', { replace: { percentage } });
  }
  return t('dashboard__benchmark__top', { replace: { percentage } });
};

const BenchmarkLabel = ({
  id,
  benchmark,
  percentile,
  type,
}: BenchmarkLabelProps) => {
  const { benchmarkTypeTranslationMap } = useBenchmarkTypeTranslationMap();
  const label = useGetLabel(percentile);

  return (
    <div className={styles.root}>
      {type !== 'context' && (
        <div className={styles.type}>
          {benchmarkTypeTranslationMap.get(type)}
          {type !== 'overall' && <span>:</span>}
        </div>
      )}
      {type === 'overall' && percentile && <span> - {label}</span>}
      {type === 'industry' && (
        <div>
          {id}
          {percentile && <span> - {label}</span>}
        </div>
      )}
      {type === 'context' && (
        <ContextLabel
          avatarSize="tiny"
          dashboardContext={benchmark}
          modifier="list"
        />
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default BenchmarkLabel;
