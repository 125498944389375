import { createContext } from 'react';

import noop from 'lodash/noop';

const OverlayContext = createContext({
  onShowOverlay: noop,
  onHideOverlay: noop,
});

// eslint-disable-next-line import/no-default-export
export default OverlayContext;
