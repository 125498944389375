import { useMutation } from '@tanstack/react-query';

import jsonapiparser from '@peakon/jsonapiparser';
import { SegmentResponse } from '@peakon/shared/schemas/api/segments';
import api from '@peakon/shared/utils/api';

import { SegmentData } from '../SegmentBenchmarkForm';

const SEGMENT_PARAMS = {
  include: 'attribute,benchmark,benchmark.attribute',
  fields: {
    segments: '+,benchmark',
  },
};

type UpdateSegmentParams = {
  segmentId: string;
  data: SegmentData;
};

const updateSegment = async ({
  segmentId,
  data,
}: UpdateSegmentParams): Promise<{ data: SegmentResponse }> => {
  const response = await api.patch(`/segments/${segmentId}`, SEGMENT_PARAMS, {
    data,
  });

  return jsonapiparser(response);
};

export const useUpdateSegmentMutation = () => {
  return useMutation({
    mutationFn: updateSegment,
  });
};
