import React from 'react';

import { Button } from '@peakon/bedrock/react/button';
import PageHeader from '@peakon/shared/components/PageHeader';
import { t } from '@peakon/shared/features/i18next/t';

export type ValuesHeaderProps = {
  isAdding: boolean;
  onAddValue: () => void;
};

const ValuesHeader = ({ onAddValue, isAdding }: ValuesHeaderProps) => (
  <PageHeader>
    <PageHeader.Nav>
      <PageHeader.Title>{t('navbar__values')}</PageHeader.Title>
      <PageHeader.Actions>
        <Button
          variant="primary"
          disabled={isAdding}
          size="small"
          onClick={onAddValue}
        >
          {t('values__value__form__header')}
        </Button>
      </PageHeader.Actions>
    </PageHeader.Nav>
  </PageHeader>
);

// eslint-disable-next-line import/no-default-export
export default ValuesHeader;
