import React from 'react';

import classnames from 'classnames';

import { TranslatedString } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

export type BadgeVariant = 'primary' | 'secondary';

type BadgeProps = {
  label: TranslatedString;
  variant: BadgeVariant;
} & Omit<React.ComponentProps<'span'>, 'className' | 'style'>;

export function Badge({ label, variant, ...restOfProps }: BadgeProps) {
  return (
    <span
      className={classnames(styles.badge, {
        [styles[variant]]: variant,
      })}
      {...restOfProps}
    >
      {label}
    </span>
  );
}
