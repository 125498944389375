import z from 'zod';

import { translatedStringSchema } from '../../../features/i18next/t';

export const httpErrorSchema = z.instanceof(Error).and(
  z.object({
    code: z.number(),
    // status: z.number(), Technically, status also exists on this error type, but since it is simply an alias for code, I am ignoring it. This will force us to consistently use `code` instead of `status` in our codebase.
    message: translatedStringSchema,
  }),
);

export type HttpError = z.infer<typeof httpErrorSchema>;

/**
 * Checks if the error matches the shape of an HttpError from {@link https://www.npmjs.com/package/standard-http-error standard-http-error} (a library used by the backend to throw errors)
 *
 * @example
 * if(isHttpError(error) && error.code === 403) { ... }
 */
export const isHttpError = (error: unknown): error is HttpError => {
  return httpErrorSchema.safeParse(error).success;
};
