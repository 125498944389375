import { configureStore } from '@reduxjs/toolkit';
import { List } from 'immutable';
import {
  persistStore,
  createTransform,
  PersistConfig,
  Transform,
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import localStore from 'store2';

import rootReducer from './reducers';
import createErrorMiddleware from './utils/errorMiddleware';
import errorReporter from './utils/errorReporter';
import logMiddleware from './utils/logMiddleware';

export const STORE_VERSION = 5;

export const clearUnknownKeys = () => {
  const regexp = new RegExp(`peakon\\.${STORE_VERSION}\\.(\\w)*`);

  const previous = localStore
    .keys()
    .filter((k) => /^peakon\./.test(k))
    .filter((k) => {
      // only consider previous when the middle key is numerical
      return !regexp.test(k) && /peakon\.(\d+)\.(\w)*/.test(k);
    });

  previous.forEach((key) => {
    localStore.remove(key);
  });
};

const transforms: Transform<unknown, unknown>[] = [
  createTransform(
    // state coming from redux to serialized and stored
    (inbound: $TSFixMe, key) => {
      if (key === 'filters' && inbound) {
        // We only care about filters that are currently enabled: it's
        // all the info we need to redo the same query. This simplifies
        // merging with new data and uses less space.
        // @ts-expect-error TS(7006): Parameter 'f' implicitly has an 'any' type.
        const filtered = inbound.filter((f) => f.enabled);
        return Array.isArray(filtered) ? filtered : filtered.toJS();
      }

      return Array.isArray(inbound) ? inbound : inbound.toJS();
    },
    // state coming from storage, about to be rehydrated
    (outbound: $TSFixMe, key) => {
      if (
        ['filters', 'filterOptions', 'filterColumns'].includes(String(key)) &&
        Array.isArray(outbound)
      ) {
        return List(outbound);
      }

      return outbound;
    },
  ),
];

const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: 'root',
  whitelist: ['filters', 'filterColumns', 'filterOptions'],
  blacklist: ['_persist'],
  transforms,
  storage,
  version: STORE_VERSION,
  keyPrefix: `peakon.${STORE_VERSION}.`,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createDefaultStore = (reducer = persistedReducer) => {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
      const errorReporterMiddleware = createErrorMiddleware(
        // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
        (...payload: $TSFixMe) => errorReporter.error(...payload),
      );
      const logger = logMiddleware(10);

      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
        .prepend(logger)
        .prepend(errorReporterMiddleware);
    },
  });
};

export const store = createDefaultStore(persistedReducer);
export const persistor = persistStore(store, null, clearUnknownKeys);

export function configureTestStore(initialState = {}) {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: [thunk],
  });
}

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers', () => {
    store.replaceReducer(persistedReducer);
  });
}
