import React from 'react';

import { EmptyStateLock } from '@peakon/bedrock/illustrations/react/empty-state';
import { Paragraph } from '@peakon/bedrock/react/typography';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

export const EmployeeListNoAccess = () => {
  return (
    <div className={styles.error}>
      <span className={styles.accessDeniedIcon} aria-hidden>
        <EmptyStateLock />
      </span>
      <Paragraph>{t('employees_list__access_denied')}</Paragraph>
    </div>
  );
};
