import React, { useEffect, useRef } from 'react';

export function usePrevious<T>(
  value: T,
  { defaultValue }: { defaultValue?: T } = {},
) {
  const ref: React.MutableRefObject<T | undefined> = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  if (typeof ref.current === 'undefined') {
    return defaultValue;
  }

  return ref.current;
}
