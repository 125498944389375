import { List } from 'immutable';
import moment from 'moment';

import { Editor, Employee, FilterOption } from '@peakon/records';

const employeeEditor = (state = new Editor(), action: $TSFixMe): Editor => {
  switch (action.type) {
    case 'EDITOR_START': {
      const { employee, attributes } = action.data;
      return Editor.editEmployee(employee, attributes);
    }
    case 'EDITOR_CREATE': {
      const { attributes, features } = action.data;
      return Editor.createEmployee(attributes, features);
    }
    case 'EDITOR_RESET': {
      return state.set('current', state.original);
    }
    case 'EMPLOYEE_UPDATE_SUCCESS': {
      const { data } = action.data;

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.saveChanges(Employee.createFromApi(data));
    }
    case 'EDITOR_RESET_FIELD': {
      const { id } = action.data;

      return state.reset(id);
    }
    case 'EDITOR_UPDATE_FEATURE': {
      const { feature, value } = action.data;

      return state.updateFeature(feature, value);
    }
    case 'EDITOR_UPDATE_ATTRIBUTE_VALID': {
      const { id, valid } = action.data;

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.updateIn(['current', 'attributes', id], (attribute) => {
        return attribute.setIn(['valid'], valid);
      });
    }
    case 'EDITOR_UPDATE_ATTRIBUTE': {
      const { id, value, translation } = action.data;

      return state.updateAttribute(id, value, translation);
    }
    case 'EDITOR_UPDATE_ATTRIBUTE_START': {
      const { id, type, date } = action.data;

      return state.updateValidFrom(id, type, date).addField(id);
    }
    case 'EDITOR_UPDATE_EMPLOYEE': {
      const { field, value } = action.data;

      return state.updateEmployee(field, value);
    }
    case 'EMPLOYEE_BULK_UPDATE_SUCCESS':
    case 'EDITOR_STOP': {
      return new Editor();
    }
    case 'EDITOR_SELECT_FIELD': {
      const { id } = action.data;

      return state.addField(id).enable(id);
    }
    case 'EDITOR_DESELECT_FIELD': {
      const { id } = action.data;

      return state.removeField(id).reset(id);
    }
    case 'EDITOR_GET_ATTRIBUTE_OPTIONS_SUCCESS': {
      const { id, data } = action.data;

      const options = List(
        data.map(
          (option: $TSFixMe) =>
            new FilterOption({
              id: option.id,
              label: option.attributes.name,
              labelTranslated: option.attributes.nameTranslated,
            }),
        ),
      );

      if (!state.hasIn(['current', 'attributes', id])) {
        return state;
      }

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state
        .updateIn(['current', 'attributes', id], (attribute) =>
          attribute.setIn(['options'], options),
        )
        .updateIn(['original', 'attributes', id], (attribute) =>
          attribute.setIn(['options'], options),
        );
    }
    case 'EDITOR_GET_MORE_ATTRIBUTE_OPTIONS_SUCCESS': {
      const { id, data } = action.data;

      if (!state.hasIn(['current', 'attributes', id])) {
        return state;
      }

      const newOptions = List(
        data.map(
          (option: $TSFixMe) =>
            new FilterOption({
              id: option.id,
              label: option.attributes.name,
              labelTranslated: option.attributes.nameTranslated,
            }),
        ),
      );

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state
        .updateIn(['current', 'attributes', id, 'options'], (options) =>
          options.concat(newOptions),
        )
        .updateIn(['original', 'attributes', id, 'options'], (options) =>
          options.concat(newOptions),
        );
    }
    case 'EDITOR_REGENERATE_KIOSK_CODE_SUCCESS': {
      const {
        data: {
          attributes: { kioskCode },
        },
      } = action.data;

      return state
        .setIn(['original', 'employee', 'kioskCode'], kioskCode)
        .setIn(['current', 'employee', 'kioskCode'], kioskCode);
    }
    case 'EMPLOYEE_NOTIFY_SUCCESS': {
      const invitedToAdministerAt = moment.utc().format('YYYY-MM-DD');

      return state.originalSetIn(
        ['employee', 'invitedToAdministerAt'],
        invitedToAdministerAt,
      );
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default employeeEditor;
