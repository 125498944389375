import React from 'react';

import classnames from 'classnames';
import escape from 'lodash/escape';

import { t } from '@peakon/shared/features/i18next/t';
import xss from '@peakon/shared/utils/xss';

import { EmployeeAvatar } from './EmployeeAvatar';
import { useRelativeTime } from './useRelativeTime';
import { ConversationKind } from '../../../context/ConversationsContext';
import { Message } from '../../../queries/conversations/types/message';

import styles from './styles.css';

const markdownMention = (text: string) => {
  return text.replace(/\*\*(@\S(?:[\s\S]*?\S)?)\*\*/g, '<span>$1</span>');
};

export type ConversationMessageProps = {
  currentEmployeeName?: string;
  animated?: boolean;
  message: Omit<Message, 'animated'>;
};

const ConversationMessage = ({
  message,
  animated = false,
  currentEmployeeName = message.employee?.name,
}: ConversationMessageProps) => {
  const timeAgo = useRelativeTime(message.createdAt);

  const { isCurrentUser, kind, anonymous, employee, text, createdAt } = message;
  const isNote = kind === ConversationKind.note;

  return (
    <div
      className={classnames(styles.root, {
        [styles.end]: isCurrentUser || isNote,
        [styles[message.kind]]: message.kind,
        [styles.animated]: animated,
      })}
      data-test-id={`conversation-message-${message.kind}`}
    >
      <div className={styles.author}>
        {isCurrentUser && anonymous && (
          <span>
            {t('conversations__reply__you')}{' '}
            <span>{t('employee_identity_not_displayed')}</span>
          </span>
        )}
        {!isCurrentUser && anonymous && (
          <span>{t('employee_identity_not_displayed')}</span>
        )}
        {!anonymous && (
          <span>{isCurrentUser ? currentEmployeeName : employee?.name}</span>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.avatar}>
          <EmployeeAvatar anonymous={anonymous} employee={employee} />
        </div>
        <div className={styles.textWrapper}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: xss.process(markdownMention(escape(text))),
            }}
            className={styles.text}
          />
          <time className={styles.time} dateTime={createdAt}>
            {isNote
              ? t('conversation__message__sent_at__note', {
                  replace: { time_ago: timeAgo },
                })
              : t('conversation__message__sent_at__reply', {
                  replace: { time_ago: timeAgo },
                })}
          </time>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ConversationMessage;
