import { useMutation } from '@tanstack/react-query';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';

type QueryParams = {
  onSuccess: (name: string) => void;
};

type UpdateConceptParams = {
  id: string;
  name: string;
};

const updateConcept = async (
  concept: UpdateConceptParams,
): Promise<$TSFixMe> => {
  return await api.patch(`/sensitive/concepts/${concept.id}`, null, {
    data: {
      type: 'sensitive_concepts',
      id: concept.id,
      attributes: { name: concept.name },
    },
  });
};

export const useUpdateConceptMutation = ({ onSuccess }: QueryParams) => {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: updateConcept,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: ({ data: { attributes } }) => {
      onSuccess(attributes.name);
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t(
            'engagement__settings__sensitive_comments_custom_concept_updated',
          ),
        }),
      );
    },
  });
};
