export const CORE_QUESTION_SETS = [
  'engagement',
  'diversity_inclusion',
  'health_wellbeing',
  'transformation_change',
] as const;

export const MOMENTS_THAT_MATTER_QUESTION_SETS = [
  'onboarding',
  'exit',
] as const;

export const ALL_QUESTION_SETS = [
  ...CORE_QUESTION_SETS,
  'other',
  'covid19',
  ...MOMENTS_THAT_MATTER_QUESTION_SETS,
] as const;

export const EXPORT_CONSTANTS = Object.fromEntries(
  ALL_QUESTION_SETS.map((set) => [set, 'driver']).concat([
    ['values', 'value'],
    ['text', 'text'],
  ]),
);

export const STANDARD_QUESTION_SETS = [
  ...ALL_QUESTION_SETS.filter((set) => set !== 'other'),
  'text',
];

export type CategoryGroup = (typeof ALL_QUESTION_SETS)[number];
export type CoreCategoryGroup = (typeof CORE_QUESTION_SETS)[number];
export type ExportableCategoryGroup = Extract<
  CategoryGroup,
  'engagement' | 'diversity_inclusion' | 'health_wellbeing'
>;

export type StandardCategoryGroup = Exclude<CategoryGroup, 'other'> | 'text';
