import React from 'react';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import { Combobox } from '@peakon/components';
import type { Options } from '@peakon/localization';
import { t } from '@peakon/shared/features/i18next/t';

import { getLocales } from './utils/getLocales';

import styles from './styles.css';

type Props = {
  asPortal?: boolean;
  isFilter?: boolean;
  localizationOptions?: Options;
  selected?: string | null;
  onSelect: (localeId: string) => void;
  localizedNames?: boolean;
  disabled?: boolean;
  id?: string;
  label?: string;
  placeholder?: string;
  defaultValueLabel?: string;
  isAbsolutePosition?: boolean;
  forcedLocales?: $TSFixMe[];
};

export function LanguageSelector({
  asPortal,
  isFilter,
  localizationOptions,
  selected,
  onSelect,
  localizedNames,
  disabled,
  id,
  label,
  placeholder,
  defaultValueLabel,
  isAbsolutePosition,
  forcedLocales,
}: Props) {
  const availableLocales = getLocales(
    localizedNames ?? true,
    localizationOptions ?? {
      employee: true,
      disabled: [],
    },
    isFilter,
  );

  const locales = !isEmpty(forcedLocales)
    ? availableLocales.filter((locale) => {
        // @ts-expect-error 'forcedLocales' is possibly 'undefined'.ts(18048)
        return forcedLocales.includes(locale.value);
      })
    : availableLocales;

  const option = locales ? locales.find((o) => o.value === selected) : null;

  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  label = label || t('account__a11y__locale__placeholder');
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  placeholder = isFilter
    ? placeholder || t('filters__select__placeholder')
    : placeholder || t('account__locale__placeholder');
  // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  defaultValueLabel = isFilter
    ? null
    : defaultValueLabel || t('account__locale__placeholder');

  return (
    <div
      data-test-id="language-selector"
      className={classnames({
        [styles.fixed]: isAbsolutePosition ?? false,
      })}
    >
      <Combobox id={id} selectedItem={selected} onSelect={onSelect}>
        <Combobox.Select
          placeholder={placeholder}
          defaultValueLabel={defaultValueLabel}
          disabled={disabled}
          hasClear={false}
        >
          {label && (
            <VisuallyHidden id="combobox-label">{label}</VisuallyHidden>
          )}
          {selected && <span>{option?.label}</span>}
          {!selected && defaultValueLabel && (
            <span className={styles.placeholder}>{defaultValueLabel}</span>
          )}
        </Combobox.Select>
        <Combobox.Menu
          fitTargetWidth
          popperNode={asPortal ? window.document.body : undefined}
          strategy="fixed"
        >
          {locales.map(({ value, label: localesLabel }) => (
            <Combobox.Option key={value} value={value}>
              {localesLabel}
            </Combobox.Option>
          ))}
          {!isFilter && (
            <Combobox.DefaultOption>{defaultValueLabel}</Combobox.DefaultOption>
          )}
        </Combobox.Menu>
      </Combobox>
    </div>
  );
}
