// eslint-disable-next-line no-restricted-syntax
export enum DriverSorting {
  Priority = 'priority',
  Score = 'score',
  Benchmark = 'difference',
}

// eslint-disable-next-line no-restricted-syntax
export enum ScoreAggregation {
  Aggregate = 'aggregated',
  Mix = 'mixed',
  Separate = 'split',
}

// eslint-disable-next-line no-restricted-syntax
export enum OverallScore {
  Average = 'average',
  eNPS = 'enps',
}

// eslint-disable-next-line no-restricted-syntax
export enum AllDataTime {
  threeMonths = 12,
  sixMonths = 26,
  oneYear = 52,
}

// eslint-disable-next-line no-restricted-syntax
export enum EmployeeTime {
  oneMonth = 4,
  threeMonths = 12,
  sixMonths = 26,
  differenceOff = 0,
}

// eslint-disable-next-line no-restricted-syntax
export enum FormerEmployeeTime {
  oneMonth = 4,
  threeMonths = 12,
  sixMonths = 26,
}

// eslint-disable-next-line no-restricted-syntax
export enum CommentDateVisibility {
  Round = 'round',
  Date = 'date',
}

// eslint-disable-next-line no-restricted-syntax
export enum ConversationEmail {
  Full = 'full',
  Limited = 'limited',
}

export const ScoreModeValue = {
  nps: 'nps',
  mean: 'mean',
} as const;

export type ScoreMode = {
  [Key in keyof typeof ScoreModeValue]: (typeof ScoreModeValue)[Key];
}[keyof typeof ScoreModeValue];
