import localStorage from 'store2';

import PeakonApi, { createTypedClient } from '@peakon/peakon-js';
import { getSnapshot, setToken } from '@peakon/shell/hooks/useTokenFromStorage';

import env from './env';
import errorReporter from './errorReporter';
import { isPreviewApp } from './isPreviewApp';
import { redirectTo } from './redirectTo';
import { assignWindowLocation } from './window';
import { APP_SUBDOMAIN, DEV_SUBDOMAIN } from '../features/subdomains/constants';
import { getCurrentSubdomain } from '../features/subdomains/getCurrentSubdomain';
import { getSubdomainHref } from '../features/subdomains/getSubdomainHref';

export const getBaseUrl = () => {
  const subdomain = getCurrentSubdomain();

  if (subdomain === APP_SUBDOMAIN || subdomain === DEV_SUBDOMAIN) {
    return `${ENV.api}`;
  }

  if (isPreviewApp()) {
    return `${window.location.origin.replace(/-\d*.preview/, '')}`;
  }

  return `${window.location.origin}`;
};

export const baseUrl = getBaseUrl();
const api = new PeakonApi({
  baseUrl,
  retryAuthUsingParam: true,
  providers: {
    sync: ENV.sync.api,
    slack: ENV.slack.api,
    microsoftTeams: ENV.microsoftTeams.api,
  },
  testAgent: localStorage.get('peakon.testAgent'),
});

api.configure({
  onError: (error: $TSFixMe, data: $TSFixMe, response: $TSFixMe) => {
    const currentSubdomain = getCurrentSubdomain();

    // If sudomains are required, we log the user out and redirect them to the
    // login page, which in turn will redirect them to the "enter subdomain" page.
    // We're using the `forceSubdomain` environment variable here because we don't
    // have access to any feature flips for unauthenticated users.
    if (env.forceSubdomain && currentSubdomain === APP_SUBDOMAIN) {
      localStorage.remove('auth_token');
      return redirectTo('/login', { keepQueryParamsOnRedirect: true });
    }

    if (response?.status === 401) {
      if (getSnapshot('initial_auth_token')) {
        localStorage.set('auth_token', getSnapshot('initial_auth_token'));
        setToken('initial_auth_token', null);
        return redirectTo('/');
      } else {
        localStorage.remove('auth_token');
        setToken('initial_auth_token', null);
      }

      const subdomain = data?.data?.subdomain;

      if (subdomain && subdomain !== currentSubdomain) {
        const subdomainHref = getSubdomainHref({
          href: window.location.href,
          currentSubdomain,
          newSubdomain: subdomain,
        });

        return assignWindowLocation(subdomainHref);
      }

      // if the 401 errors happen on the any of the login pages, redirecting to /login causes issues since the pages lose their internal state
      if (!window.location.pathname.includes('/login')) {
        return redirectTo('/login');
      }
    }

    errorReporter.error(error, data);
  },
});

export const typedApi = createTypedClient(api);

// eslint-disable-next-line import/no-default-export
export default api;
