import { Record, List } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  frequency: z.number(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class FrequencySchedule
  extends Record({
    frequency: undefined,
    steady: undefined,
    seconds: undefined,
    recommended: false,
    rounds: List(),
  })
  implements Schema
{
  frequency!: Schema['frequency'];
  steady!: number;
  seconds!: number;
  recommended?: boolean;
  rounds!: List<{
    count: number;
    seconds: number;
  }>;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'FrequencySchedule',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'FrequencySchedule',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
