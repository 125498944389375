import { useEffect } from 'react';

import get from 'lodash/get';
import { useHistory } from 'react-router';

import { hasRight } from '@peakon/shared/utils';
import { useShellContext } from '@peakon/shell/index';

export const useDataSettingsRedirect = () => {
  const { session } = useShellContext();
  const history = useHistory();

  const hasCompanyAdminRight = hasRight(
    get(session, 'data.attributes.rights'),
    ['company:admin'],
  );
  const hasSensitiveComments = hasRight(
    get(session, 'data.attributes.rights'),
    ['sensitive:admin'],
  );

  useEffect(() => {
    if (!hasCompanyAdminRight) {
      if (hasSensitiveComments) {
        history.replace('/admin/data-settings/sensitive-comments');
      } else {
        history.replace('/');
      }
    }
  }, [hasCompanyAdminRight, hasSensitiveComments, history]);
};
