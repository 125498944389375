import React, { useEffect, useState } from 'react';

import {
  Button,
  Combobox,
  Grid,
  InputField,
  Label,
  // @ts-expect-error TS(2305): Module '"@peakon/components"' has no exported memb... Remove this comment to see the full error message
  OptionType,
} from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import { getQuestionItemLocales } from '../../../constants/locales';

import styles from './translationStyles.css';

export type Translation = {
  translationId: string;
  name: string;
  description: string;
};

export type TranslationProps = {
  translation?: Translation;
  onTranslationChanged: (
    oldTranslationId: string,
    translation: Translation,
  ) => void;
  onTranslationAdded: (translation: Translation) => void;
  onRemove: (translationId: string) => void;
  alreadyUsedLanguageKeys?: string[];
};

export const Translation = ({
  translation,
  onTranslationChanged,
  onTranslationAdded,
  alreadyUsedLanguageKeys,
  onRemove,
}: TranslationProps) => {
  const [supportedLanguages, setSupportedLanguages] = useState<OptionType[]>();
  const [translationValue, setTranslationValue] = useState(
    translation?.name ?? '',
  );

  const isNew = !translation?.translationId;

  const [translationDescription, setTranslationDescription] = useState(
    translation?.description ?? '',
  );
  const [currentTranslationId, setCurrentTranslationId] = useState(
    translation?.translationId ?? '',
  );

  useEffect(() => {
    setTranslationValue(translation?.name ?? '');
    setTranslationDescription(translation?.description ?? '');
    setCurrentTranslationId(translation?.translationId ?? '');
  }, [translation]);

  const [selectedItem, setSelectedItem] = useState<OptionType>();

  useEffect(() => {
    const questionLocales = getQuestionItemLocales({
      shouldTranslateTitle: true,
    });

    const supportedLangs: OptionType[] = [];
    questionLocales.forEach((language) => {
      const valueLabel = { value: language.id, label: language.title };
      if (language.id === currentTranslationId) {
        setSelectedItem(valueLabel);
      }

      if (!alreadyUsedLanguageKeys?.includes(valueLabel.value)) {
        supportedLangs.push(valueLabel);
      }
    });

    setSupportedLanguages(supportedLangs);
  }, [alreadyUsedLanguageKeys, currentTranslationId]);

  const handleDescriptionChanged = (description: string) => {
    setTranslationDescription(description);
    if (!isNew) {
      onTranslationChanged(currentTranslationId, {
        translationId: currentTranslationId,
        description,
        name: translationValue,
      });
    }
  };

  const handleNameChanged = (name: string) => {
    setTranslationValue(name);
    if (!isNew) {
      onTranslationChanged(currentTranslationId, {
        translationId: currentTranslationId,
        description: translationDescription,
        name,
      });
    }
  };

  const handleOnTranslationChange = (translationId: string | null) => {
    if (translationId) {
      const newTranslation = {
        translationId,
        description: translationDescription,
        name: translationValue,
      };

      if (currentTranslationId && !isNew) {
        onTranslationChanged(currentTranslationId, newTranslation);
      }
      setCurrentTranslationId(translationId);
    }
  };

  return (
    <div className={styles.translationContainer}>
      <Grid.Col sm={3}>
        <Label>{t('custom_question_translations__language')}</Label>
        <Combobox
          selectedItem={currentTranslationId}
          onChange={handleOnTranslationChange}
        >
          <Combobox.Menu fitTargetWidth>
            {supportedLanguages?.map((item) => (
              <Combobox.Option key={item.value} value={item.value}>
                {item.label}
              </Combobox.Option>
            ))}
          </Combobox.Menu>
          <Combobox.Select
            hasClear={false}
            placeholder={t('custom_question_translations__locale_placeholder')}
          >
            {selectedItem?.label}
          </Combobox.Select>
        </Combobox>
      </Grid.Col>
      <Grid.Col sm={4}>
        <InputField
          label={t('values__value__name')}
          value={translationValue}
          onChange={handleNameChanged}
          maxLength={256}
          placeholder={t('values__value__name_placeholder')}
        />
      </Grid.Col>

      <Grid.Col sm={4}>
        <InputField
          label={t('values__value__description')}
          value={translationDescription}
          onChange={handleDescriptionChanged}
          maxLength={4096}
          placeholder={t('values__value__description_placeholder')}
        />
      </Grid.Col>

      <Grid.Col sm={1}>
        {translation?.translationId ? (
          <Button
            type="warning"
            size="medium"
            onClick={() => {
              currentTranslationId && onRemove(currentTranslationId);
            }}
          >
            {t('custom_question_translations__remove')}
          </Button>
        ) : (
          <Button
            type="secondary"
            size="medium"
            onClick={() => {
              const newTranslation = {
                translationId: currentTranslationId,
                description: translationDescription,
                name: translationValue,
              };
              onTranslationAdded(newTranslation);
            }}
            disabled={
              !currentTranslationId ||
              !translationDescription ||
              !translationValue
            }
          >
            {t('custom_question_translations__add')}
          </Button>
        )}
      </Grid.Col>
    </div>
  );
};
