import { t } from '@peakon/shared/features/i18next/t';

export const getSectors = () =>
  [
    { value: 'Consumer', label: t('industry__consumer') },
    {
      value: 'Consumer Discretionary',
      label: t('industry__consumer_discretionary'),
      benchmark: '5.8',
    },
    {
      value: 'Consumer Durables & Apparel',
      label: t('industry__consumer_durables_apparel'),
    },
    { value: 'Consumer Services', label: t('industry__consumer_services') },
    {
      value: 'Food, Beverage & Tobacco',
      label: t('industry__food_beverage_tobacco'),
    },
    { value: 'Media', label: t('industry__media') },
    { value: 'Retailing', label: t('industry__retailing') },

    { value: 'Education', label: t('industry__education') },
    { value: 'Energy & Utilities', label: t('industry__energy_utilities') },
    {
      value: 'Independent Power and Renewable Electricity Producers',
      label: t('industry__independent_power_renewable_electricity_producers'),
    },
    { value: 'Finance', label: t('industry__finance') },
    {
      value: 'Diversified Financials',
      label: t('industry__diversified_financials'),
    },
    {
      value: 'Insurance',
      label: t('industry__insurance'),
    },
    { value: 'Government', label: t('industry__government') },
    { value: 'Healthcare', label: t('industry__healthcare') },
    {
      value: 'Health Care Equipment & Services',
      label: t('industry__health_care_equipment_services'),
    },
    {
      value: 'Pharmaceuticals, Biotechnology & Life Sciences',
      label: t('industry__pharmaceuticals_biotechnology_life_sciences'),
    },
    { value: 'Materials', label: t('industry__materials') },
    { value: 'Manufacturing', label: t('industry__manufacturing') },
    { value: 'Capital Goods', label: t('industry__capital_goods') },

    { value: 'Non-profit', label: t('industry__nonprofit') },
    {
      value: 'Professional Services',
      label: t('industry__professional_services'),
    },
    {
      value: 'Commercial & Professional Services',
      label: t('industry__commercial_professional_services'),
    },

    { value: 'Technology', label: t('industry__technology') },

    { value: 'Software & Services', label: t('industry__software_services') },
    {
      value: 'Technology Hardware & Equipment',
      label: t('industry__technology_hardware_equipment'),
    },
    { value: 'Transportation', label: t('industry__transportation') },
    {
      value: 'Hotels, Restaurants & Leisure',
      label: t('industry__hotels_restaurants_leisure'),
    },
  ] as const;

export type Sector = ReturnType<typeof getSectors>[number]['value'];
