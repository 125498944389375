import { match } from 'path-to-regexp';

import { Location } from '../../types/ReactRouter';

type ProductArea =
  | 'Login'
  | 'Signup'
  | 'Profile'
  | 'Manager Dashboard'
  | 'Administration'
  | 'Improve'
  | 'Personal Dashboard'
  | 'Shared Dashboard'
  | 'Actions'
  | 'Survey'
  | 'Other';

/**
 * This is a mapping of product areas to the routes we have in our application.
 * We use the matching routes in the data we send to the analytics, so we need to match against as specific URL as possible and we can't generalize to /admin/* for example. This also lists all of the groupIds explicitly, because we want to be able to distinguish between the different groups in the analytics.
 */
const productAreaRouteMappings: Array<{
  productArea: ProductArea | 'Technical Route';
  matchingRoutes: Array<{
    path: `/${string}`;
    allowedSearchParams?: Array<string>;
  }>;
}> = [
  {
    productArea: 'Login',
    matchingRoutes: [
      { path: '/login' },
      { path: '/login/logout' },
      { path: '/login/forgot' },
      { path: '/login/forgot/:token' },
      { path: '/login/twofactor' },
    ],
  },
  {
    productArea: 'Signup',
    matchingRoutes: [{ path: '/signup/confirm/:token' }],
  },
  {
    productArea: 'Manager Dashboard',
    matchingRoutes: [
      // '/dashboard/group/:groupId',
      { path: '/dashboard/group/engagement' },
      { path: '/dashboard/group/transformation_change' },
      { path: '/dashboard/group/diversity_inclusion' },
      { path: '/dashboard/group/health_wellbeing' },
      { path: '/dashboard/group/other' },
      { path: '/dashboard/group/covid19' },

      // '/dashboard/group/:groupId/drivers',
      { path: '/dashboard/group/engagement/drivers' },
      { path: '/dashboard/group/transformation_change/drivers' },
      { path: '/dashboard/group/diversity_inclusion/drivers' },
      { path: '/dashboard/group/health_wellbeing/drivers' },
      { path: '/dashboard/group/other/drivers' },
      { path: '/dashboard/group/covid19/drivers' },

      // '/dashboard/group/:groupId/questions',
      { path: '/dashboard/group/engagement/questions' },
      { path: '/dashboard/group/transformation_change/questions' },
      { path: '/dashboard/group/diversity_inclusion/questions' },
      { path: '/dashboard/group/health_wellbeing/questions' },
      { path: '/dashboard/group/other/questions' },
      { path: '/dashboard/group/covid19/questions' },

      // '/dashboard/group/:groupId/segment/:segmentId',
      { path: '/dashboard/group/engagement/segment/:segmentId' },
      { path: '/dashboard/group/transformation_change/segment/:segmentId' },
      { path: '/dashboard/group/diversity_inclusion/segment/:segmentId' },
      { path: '/dashboard/group/health_wellbeing/segment/:segmentId' },
      { path: '/dashboard/group/other/segment/:segmentId' },
      { path: '/dashboard/group/covid19/segment/:segmentId' },

      { path: '/dashboard/curated_insights' },
      {
        path: '/dashboard/comments',
        allowedSearchParams: ['sensitive', 'explore'],
      },
      { path: '/dashboard/comments/:id' },
      { path: '/dashboard/topics/simple' },
      { path: '/dashboard/topics/simple/overview/:id' },
      { path: '/dashboard/topics/simple/segment/:segmentId' },
      { path: '/dashboard/topics/simple/segment/:segmentId/category/:id' },
      { path: '/dashboard/topics/simple/category/:id' },
      { path: '/dashboard/topics/semantic' },
      { path: '/dashboard/topics/semantic/overview/:id' },
      { path: '/dashboard/experience' },
      { path: '/dashboard/experience/:phase' },
      { path: '/dashboard/external_metrics' },
      { path: '/dashboard/segments' },
      { path: '/dashboard/segments/cards' },
      { path: '/dashboard/segments/attrition' },
      { path: '/dashboard/segment/:segmentId/question/:id' },
      { path: '/dashboard/segment/:segmentId/driver/:categoryId' },
      {
        path: '/dashboard/driver/:categoryId',
        allowedSearchParams: ['filter'],
      },
      { path: '/dashboard/metrics' },
      { path: '/dashboard/metrics/' },
      { path: '/dashboard/nps-summary' },
      { path: '/metrics' },
      { path: '/metrics/managers/actions' },
      { path: '/metrics/managers/comments' },
      { path: '/metrics/managers/activity' },
      { path: '/conversations/reply/:token' },
      { path: '/team_suggestions/:token' },
    ],
  },
  {
    productArea: 'Profile',
    matchingRoutes: [
      { path: '/account' },
      { path: '/account/subscriptions' },
      { path: '/account/change-password' },
      { path: '/account/security' },
      { path: '/unsubscribe/:token' },
    ],
  },
  {
    productArea: 'Administration',
    matchingRoutes: [
      { path: '/admin' },
      { path: '/admin/schedules' },
      { path: '/admin/schedules/activity' },
      { path: '/admin/schedules/new' },
      { path: '/admin/schedules/edit/:segmentId/schedule' },
      { path: '/admin/schedules/edit/:segmentId/email' },
      { path: '/admin/schedules/edit/:segmentId/survey_settings' },
      { path: '/admin/schedules/edit/:segmentId/attributes' },
      { path: '/admin/schedules/edit/:segmentId/kiosk' },
      { path: '/admin/schedules/edit/:segmentId/frequency' },
      { path: '/admin/schedules/edit/:segmentId/target' },
      { path: '/admin/schedules/edit/:segmentId/sms' },
      { path: '/admin/values' },
      { path: '/admin/questions' },
      { path: '/admin/questions/engagement' },
      { path: '/admin/questions/transformation_change' },
      { path: '/admin/questions/diversity_inclusion' },
      { path: '/admin/questions/health_wellbeing' },
      { path: '/admin/questions/other' },
      { path: '/admin/questions/covid19' },
      { path: '/admin/company' },
      { path: '/admin/company/data' },
      { path: '/admin/company/email' },
      { path: '/admin/company/consent-request' },
      { path: '/admin/company/integrations' },
      { path: '/admin/company/custom-domain' },
      { path: '/admin/attributes' },
      { path: '/admin/integrations' },
      { path: '/admin/integrations/:integrationId' },
      { path: '/admin/integrations/:integrationId/start' },
      { path: '/admin/integrations/:integrationId/connect' },
      { path: '/admin/data-settings/dashboard' },
      { path: '/admin/data-settings/benchmark' },
      { path: '/admin/data-settings/data-aggregation' },
      { path: '/admin/data-settings/comments' },
      { path: '/admin/data-settings/sharing' },
      { path: '/admin/data-settings/benchmark' },
      { path: '/admin/data-settings/sensitive-comments' },
      { path: '/admin/data-settings/recalculation' },
      { path: '/admin/improve/resources' },
      { path: '/admin/improve/actions' },
      { path: '/admin/employees' },
      { path: '/admin/employees/new' },
      { path: '/admin/employees/:employeeId' },
      { path: '/admin/employees/:employeeId/access' },
      { path: '/admin/employees/:employeeId/debug' },
      { path: '/admin/employees/:employeeId/managing' },
      { path: '/admin/employees/:employeeId/rounds' },
      { path: '/admin/employees/bulk-edit' },
      { path: '/admin/employees/import/completed' },
      { path: '/admin/access/groups' },
      { path: '/admin/access/groups/new' },
      { path: '/admin/access/groups/:groupId' },
      { path: '/admin/access/specialist' },
      { path: '/admin/access/specialist/:specialistId' },
      { path: '/admin/branding' },
      { path: '/admin/branding/:companyId' },
      { path: '/admin/external_metrics' },
      { path: '/admin/external_metrics/:metricId' },
      { path: '/kiosk-export' },
    ],
  },
  {
    productArea: 'Improve',
    matchingRoutes: [
      { path: '/improve' },
      { path: '/improve/company' },
      { path: '/courses/:courseId' },
      { path: '/courses/:courseId/lesson/:lessonId' },
    ],
  },
  {
    productArea: 'Personal Dashboard',
    matchingRoutes: [
      { path: '/employee-dashboard' },
      { path: '/employee-dashboard/my-scores' },

      // '/employee-dashboard/my-scores/group/:groupId/category/:categoryId',
      {
        path: '/employee-dashboard/my-scores/group/engagement/category/:categoryId',
      },
      {
        path: '/employee-dashboard/my-scores/group/transformation_change/category/:categoryId',
      },
      {
        path: '/employee-dashboard/my-scores/group/diversity_inclusion/category/:categoryId',
      },
      {
        path: '/employee-dashboard/my-scores/group/health_wellbeing/category/:categoryId',
      },
      {
        path: '/employee-dashboard/my-scores/group/other/category/:categoryId',
      },
      {
        path: '/employee-dashboard/my-scores/group/covid19/category/:categoryId',
      },

      { path: '/employee-dashboard/my-comments' },
      { path: '/employee-dashboard/my-comments/comment/:id' },

      { path: '/employee-dashboard/focus-areas' },
      { path: '/employee-dashboard/manager-actions' },
    ],
  },
  {
    productArea: 'Shared Dashboard',
    matchingRoutes: [
      { path: '/share/:token' },

      // '/share/:shareId/:groupId',
      { path: '/share/:shareId/engagement' },
      { path: '/share/:shareId/transformation_change' },
      { path: '/share/:shareId/diversity_inclusion' },
      { path: '/share/:shareId/health_wellbeing' },
      { path: '/share/:shareId/other' },
      { path: '/share/:shareId/covid19' },

      // '/share/:shareId/:groupId/driver/:categoryId',
      { path: '/share/:shareId/engagement/driver/:categoryId' },
      { path: '/share/:shareId/transformation_change/driver/:categoryId' },
      { path: '/share/:shareId/diversity_inclusion/driver/:categoryId' },
      { path: '/share/:shareId/health_wellbeing/driver/:categoryId' },
      { path: '/share/:shareId/other/driver/:categoryId' },
      { path: '/share/:shareId/covid19/driver/:categoryId' },
    ],
  },
  {
    productArea: 'Actions',
    matchingRoutes: [{ path: '/actions' }, { path: '/actions/my-team' }],
  },
  {
    productArea: 'Survey',
    matchingRoutes: [{ path: '/surveys/no-surveys' }],
  },
  { productArea: 'Other', matchingRoutes: [{ path: '/what-is-peakon' }] },
  {
    productArea: 'Technical Route',
    matchingRoutes: [
      { path: '/' }, // serves as a hob to redirect users to whatever page they have access to in the manager dashboard
      { path: '/dashboard' }, // serves as a hob to redirect users to whatever page they have access to in the manager dashboard
      { path: '/dashboard/drivers' }, // serves as a hob to redirect users to whatever page they have access to in the manager dashboard
      { path: '/dashboard/questions' }, // serves as a hob to redirect users to whatever page they have access to in the manager dashboard
      { path: '/microsoft-teams/start' },
      { path: '/microsoft-teams/complete' },
      { path: '/oauth/:integrationId/complete' },
      { path: '/workday/complete' },
      { path: '/slack/complete' },
      { path: '/bamboohr/complete' },
      { path: '/saml/:companyId/assert' },
      { path: '/employee-dashboard/transfer/:token' },
      { path: '/transfer/subdomain/:token' },
      { path: '/transfer/preview/survey' },
      { path: '/transfer/employee/survey/:responseId' },
    ],
  },
];

export function getRouteInformation({
  pathname,
  search,
}: Pick<Location, 'pathname' | 'search'>) {
  for (const productAreaRouteMapping of productAreaRouteMappings) {
    for (const matchingRoute of productAreaRouteMapping.matchingRoutes) {
      if (match(matchingRoute.path)(pathname)) {
        const urlSearchParams = new URLSearchParams(search);

        const searchParams = matchingRoute.allowedSearchParams?.map((param) => [
          param,
          urlSearchParams.get(param),
        ]);

        return {
          productArea: productAreaRouteMapping.productArea,
          obfuscatedUrl: matchingRoute.path,
          searchParams: searchParams ? Object.fromEntries(searchParams) : null,
        };
      }
    }
  }
}
