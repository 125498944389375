import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

const schema = z.object({
  query: z.string(),
  results: z.string().array(),
  nextUrl: z.string().optional(),
});

type Schema = z.infer<typeof schema>;

class CacheQuery
  extends Record({
    query: undefined,
    results: [],
    nextUrl: undefined,
  })
  implements Schema
{
  query!: Schema['query'];
  results!: Schema['results'];
  nextUrl: Schema['nextUrl'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CacheQuery',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}

// eslint-disable-next-line import/no-default-export
export default CacheQuery;
