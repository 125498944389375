import React, { useMemo, useState } from 'react';

import classnames from 'classnames';

import { Button } from '@peakon/bedrock/react/button';
import { Card } from '@peakon/bedrock/react/card';
import { Checkbox, Label } from '@peakon/components';
import { Segment } from '@peakon/records';
import { t } from '@peakon/shared/features/i18next/t';
import { SegmentResponse } from '@peakon/shared/schemas/api/segments';

import { useUpdateSegmentMutation } from './queries/useUpdateSegmentMutation';
import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { Sector } from '../../../../../data/sectors';
import { useAppDispatch } from '../../../../../utils/reduxHooks';
import { SegmentsPickerInput } from '../../../../segments/SegmentsPickerInput';
import { BenchmarkSelector } from '../../BenchmarkSelector/BenchmarkSelector';
import { IndustrySelector } from '../../IndustrySelector/IndustrySelector';
import {
  PercentileSelector,
  Percentile,
} from '../../PercentileSelector/PercentileSelector';

import styles from './styles.css';

type SegmentsBenchmarkFormProps = {
  onSubmit: (segment: Segment) => void;
};

export type BenchmarkType = null | 'industry' | 'overall' | 'context';

export type SegmentData = {
  type: 'segments';
  attributes: {
    benchmarkType?: BenchmarkType;
    benchmarkSelector?: {
      id?: string;
      percentile?: Percentile;
      sector?: string;
    };
    benchmarkPropagate?: boolean;
  };
};

type BenchmarkState = {
  segments: $TSFixMe[];
  benchmarkType: BenchmarkType;
  benchmarkSegments: $TSFixMe[];
  industry?: Sector;
  percentile?: Percentile;
  benchmarkPropagate: boolean;
};

const INITIAL_BENCHMARK: BenchmarkState = {
  segments: [],
  benchmarkType: 'overall',
  benchmarkSegments: [],
  industry: undefined,
  percentile: undefined,
  benchmarkPropagate: false,
};

const SegmentsBenchmarkForm = ({ onSubmit }: SegmentsBenchmarkFormProps) => {
  const [benchmark, setBenchmark] = useState<BenchmarkState>(INITIAL_BENCHMARK);
  const dispatch = useAppDispatch();
  const { mutate: updateSegment, isLoading } = useUpdateSegmentMutation();

  const isValid = useMemo(() => {
    const { segments, benchmarkType, industry, benchmarkSegments } = benchmark;

    if (
      segments.length === 0 ||
      !benchmarkType ||
      (benchmarkType === 'industry' && !industry) ||
      (benchmarkType === 'context' && benchmarkSegments.length === 0)
    ) {
      return false;
    }

    return true;
  }, [benchmark]);

  const handleSubmit = async () => {
    const {
      segments,
      benchmarkType,
      industry,
      percentile,
      benchmarkPropagate,
      benchmarkSegments,
    } = benchmark;

    let benchmarkSelector;

    switch (benchmarkType) {
      case 'industry':
        benchmarkSelector = {
          percentile,
          sector: industry,
        };
        break;
      case 'context':
        benchmarkSelector = {
          id: benchmarkSegments[0].contextId,
        };
        break;
      default:
        benchmarkSelector = {
          percentile:
            benchmarkType === 'overall' && percentile ? percentile : undefined,
        };
        break;
    }

    const data: SegmentData = {
      type: 'segments',
      attributes: {
        benchmarkType,
        benchmarkSelector,
        benchmarkPropagate,
      },
    };

    updateSegment(
      {
        segmentId: segments[0].id,
        data,
      },
      {
        onError: (error) => dispatch(catchHandler(error)),
        onSuccess: ({ data: segmentData }: { data: SegmentResponse }) => {
          const segment = Segment.createFromApi(segmentData);
          if (segment) {
            setBenchmark(INITIAL_BENCHMARK);
            onSubmit(segment);
            dispatch(
              showSuccessNotification({
                message: t('groups__access__button__add__segment__success'),
              }),
            );
          }
        },
      },
    );
  };

  return (
    <div className={styles.root}>
      <Card>
        <div className={styles.cardInner}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Label htmlFor="segment-picker-input">
                {t('segment_benchmark_form__segment')}
              </Label>
              <SegmentsPickerInput
                onRemove={() =>
                  setBenchmark({
                    ...benchmark,
                    segments: [],
                  })
                }
                onSelect={(segments) =>
                  setBenchmark({
                    ...benchmark,
                    segments,
                  })
                }
                placeholder={t('segment_benchmark_form__placeholder')}
                selected={benchmark.segments}
                single
                testId="segment-benchmark-segment-picker"
              />
            </div>
            <div
              className={styles.col}
              data-test-id="segment-benchmark-type-combobox-container"
            >
              <Label htmlFor="benchmark-selector">
                {t('segment_benchmark_form__benchmark')}
              </Label>
              <BenchmarkSelector
                hasContext
                onChange={({ value }) =>
                  setBenchmark({
                    ...benchmark,
                    benchmarkType: value,
                  })
                }
                value={benchmark.benchmarkType}
              />
            </div>
            {benchmark.benchmarkType === 'industry' && (
              <div
                className={styles.col}
                data-test-id="segment-industry-combobox-container"
              >
                <Label htmlFor="industry-selector">
                  {t('segment_benchmark_form__benchmark_industry')}
                </Label>
                <IndustrySelector
                  onChange={(value) =>
                    setBenchmark({
                      ...benchmark,
                      industry: value,
                    })
                  }
                  value={benchmark.industry}
                />
              </div>
            )}
            {benchmark.benchmarkType === 'context' && (
              <div className={styles.col}>
                <Label htmlFor="industry-selector">
                  {t('segment_benchmark_form__benchmark_segment')}
                </Label>
                <SegmentsPickerInput
                  onRemove={() =>
                    setBenchmark({
                      ...benchmark,
                      benchmarkSegments: [],
                    })
                  }
                  onSelect={(benchmarkSegments) =>
                    setBenchmark({
                      ...benchmark,
                      benchmarkSegments,
                    })
                  }
                  placeholder={t('segment_benchmark_form__placeholder')}
                  selected={benchmark.benchmarkSegments}
                  single
                  testId="segment-benchmark-context-picker"
                />
              </div>
            )}
            {benchmark.benchmarkType !== 'context' && (
              <div
                className={classnames(styles.col, styles.small)}
                data-test-id="segment-benchmark-percentile-combobox-container"
              >
                <Label htmlFor="percentile-selector">
                  {t('company_benchmark_settings__percentile')}
                </Label>
                <PercentileSelector
                  onChange={({ value }) =>
                    setBenchmark({
                      ...benchmark,
                      percentile: value,
                    })
                  }
                  value={benchmark.percentile}
                />
              </div>
            )}
          </div>
          <div className={styles.actions}>
            <div className={styles.checkbox}>
              <Checkbox
                aria-label={t('company_benchmark_settings__propagate')}
                checked={benchmark.benchmarkPropagate}
                onChange={(benchmarkPropagate) =>
                  setBenchmark({ ...benchmark, benchmarkPropagate })
                }
              >
                {t('company_benchmark_settings__propagate')}
              </Checkbox>
            </div>
            <Button
              busy={isLoading}
              disabled={!isValid}
              onClick={handleSubmit}
              variant="primary"
            >
              {t('segment_benchmark_form__add')}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default SegmentsBenchmarkForm;
