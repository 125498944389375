import { Record, List, fromJS, Map } from 'immutable';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import { z } from 'zod';

import {
  TranslatedString,
  translatedStringSchema,
} from '@peakon/shared/features/i18next/t';
import {
  AttributeOptionResponse,
  AttributeResponse,
} from '@peakon/shared/schemas/api/attributes';
import {
  AttributeAccess,
  AttributeStandard,
  AttributeStatus,
  AttributeType,
} from '@peakon/shared/types/Attribute';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import AttributeRange from './AttributeRangeRecord';
import Employee from './EmployeeRecord';
import Segment from './SegmentRecord';
import {
  getRelationships,
  sortRanges,
  sortTranslations,
  validateTestingSchema,
} from './utils';

export type ValidFrom = 'now' | 'start' | 'date';

type Meta = {
  start: string;
};

const attributeOptionSchema = z.object({
  id: z.string(),
});
const testingAttributeOptionSchema = attributeOptionSchema.extend({
  // no testing props yet
});
type AttributeOptionSchema = z.infer<typeof attributeOptionSchema>;

export class AttributeOption
  extends Record({
    id: undefined,
    title: '',
    titleTranslated: undefined,
    type: undefined,
    isNew: false,
  })
  implements AttributeOptionSchema
{
  id!: AttributeOptionSchema['id'];
  title!: string;
  titleTranslated?: TranslatedString;
  type?: string;
  isNew!: boolean;

  constructor(props: unknown = {}) {
    validateRecord(props, attributeOptionSchema, {
      errorMessagePrefix: 'AttributeOption',
    });
    validateTestingSchema(props, testingAttributeOptionSchema, {
      errorMessagePrefix: 'AttributeOption',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: AttributeOptionResponse) {
    return new AttributeOption(
      fromJS({
        id: data.id,
        title: data.attributes.name,
        titleTranslated: data.attributes.nameTranslated,
      }),
    );
  }
}

const attributeSchema = z.object({
  id: z.string().optional(),
});
const testingAttributeSchema = attributeSchema.extend({
  nameTranslated: translatedStringSchema.optional(),
  meta: z
    .object({
      id: z.string().optional(),
      type: z.literal('attributes').optional(),
      start: z.string().optional(),
      links: z.object({
        self: z.string(),
        first: z.string().optional(),
        last: z.string().optional(),
        next: z.string().optional(),
      }),
    })
    .optional(),
  excludedSegments: z.array(z.any()).optional(), //
  name: z.string().optional(),
  nameTranslations: z.any(),
  ranges: z.array(z.any()).optional(),
  links: z.any(),
  type: z.enum(['employee', 'option', 'number', 'date', 'tree']).optional(),
  includedSegments: z.array(z.any()).optional(),
  description: z.string().nullable().optional(),
  descriptionTranslations: z.any(),
  options: z.array(z.any()).optional(),
  comparisonAccess: z.any(),
  employeeAccess: z.any(),
  descriptionTranslated: z.any(),
  sensitive: z.any(),
  comparisonAccessLocked: z.any(),
  standard: z.any(),
  primary: z.any(),
  rejectExternal: z.boolean().optional(),
  open: z.boolean().optional(),
  employeeAccessLocked: z.boolean().optional(),
  valid: z.boolean().optional(),
  aliases: z.array(z.any()).optional(),
});
export type AttributeSchema = z.infer<typeof attributeSchema>;

// eslint-disable-next-line import/no-default-export
export default class Attribute
  extends Record({
    id: '',
    aliases: List(),
    comparisonAccess: 'unrestricted',
    comparisonAccessLocked: false,
    description: undefined,
    descriptionTranslated: undefined,
    descriptionTranslations: Map(),
    disableSegmentation: undefined,
    disableSegmentationEditable: undefined,
    disableSegmentationReason: undefined,
    employeeAccess: 'unrestricted',
    employeeAccessLocked: false,
    meta: undefined,
    name: undefined,
    nameTranslated: undefined,
    nameTranslations: Map(),
    open: false,
    options: List(),
    primary: false,
    rejectExternal: false,
    sensitive: false,
    standard: undefined,
    status: undefined,
    trueBenchmark: undefined,
    type: undefined,

    // attribute change is valid from
    validFrom: undefined,
    validFromDate: undefined,

    // relationships
    ranges: List(),
    links: Map(),

    includedSegments: undefined,
    excludedSegments: undefined,

    // Custom
    selectedOption: undefined,
    selectedOptionTranslated: undefined,
    value: undefined,
    valid: true,

    // For drag and drop
    sort: undefined,
  })
  implements AttributeSchema
{
  id!: string;
  aliases?: List<string>;
  comparisonAccess!: AttributeAccess;
  comparisonAccessLocked!: boolean;
  description?: string;
  descriptionTranslated?: TranslatedString;
  descriptionTranslations?: Map<string, string>;
  disableSegmentation?: boolean;
  disableSegmentationEditable?: boolean;
  disableSegmentationReason?: string;
  employeeAccess!: AttributeAccess;
  employeeAccessLocked?: boolean;
  excludedSegments?: List<Segment>;
  includedSegments?: List<Segment>;
  // FIXME: when creating/editing an attribute we use Map
  //        when creating from api we use List
  //        we need to make it consistent and use either Map or List everywhere
  // eslint-disable-next-line
  links?: List<Attribute> | Map<'child' | 'parent', Attribute>;
  meta?: Meta;
  name?: string;
  nameTranslated?: TranslatedString;
  nameTranslations?: Map<string, string>;
  open?: boolean;
  options?: List<AttributeOption>;
  primary?: boolean;
  ranges?: List<AttributeRange>;
  rejectExternal?: false;
  selectedOption?: string;
  selectedOptionTranslated?: TranslatedString;
  sensitive?: boolean;
  // FIXME: rename prop to avoid clashing with Record base props
  // @ts-expect-error Property 'sort' in type 'Attribute' is not assignable to the same property in base type
  sort?: number;
  standard?: AttributeStandard;
  status?: AttributeStatus;
  trueBenchmark?: boolean;
  type!: AttributeType;
  valid!: boolean;
  validFrom?: ValidFrom;
  validFromDate?: Date;
  value?: string;

  constructor(props: unknown = {}) {
    validateRecord(props, attributeSchema, {
      errorMessagePrefix: 'Attribute',
    });
    validateTestingSchema(props, testingAttributeSchema, {
      errorMessagePrefix: 'Attribute',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get testId() {
    return this.standard || this.name?.toLowerCase().replaceAll(' ', '-');
  }

  isTargetted() {
    return (
      (this.includedSegments && !this.includedSegments.isEmpty()) ||
      (this.excludedSegments && !this.excludedSegments.isEmpty())
    );
  }

  hasEditableSegmentStandards() {
    const editableSegmentStandards = [
      'department',
      'gender',
      'level',
      'office',
      'separation_reason',
      'type',
    ];

    return this.standard && editableSegmentStandards.includes(this.standard);
  }

  static revive({ options: _options, ...data }: Attribute) {
    return new Attribute(
      fromJS({
        ...data,
      }),
    );
  }

  static createFromApi(data: AttributeResponse): Attribute {
    const { id, type, attributes } = data;

    let relationships, combination, options, ranges, links;

    if ('relationships' in data) {
      relationships = data.relationships;
      combination = relationships?.links;
      options = relationships?.options;
      ranges = relationships?.ranges;
    }

    if ('links' in data) {
      links = data.links;
    }

    const attrOptions =
      attributes.type === 'option' && options
        ? options.map(
            (option: AttributeOptionResponse) =>
              new AttributeOption({
                id: option.id,
                title: option.attributes ? option.attributes.name : null,
                type: option.type,
              }),
          )
        : List();

    let attrRanges;
    if (attributes.type === 'number' || attributes.type === 'date') {
      attrRanges = !isEmpty(ranges)
        ? List(
            sortRanges(
              ranges,
              'standard' in attributes ? attributes.standard : undefined,
            ).map((range) => new AttributeRange(range)),
          )
        : List([
            new AttributeRange({
              from: null,
              to: null,
            }),
          ]);
    }

    let attrLinks;
    if (attributes.type === 'link') {
      attrLinks = combination
        ? List(combination.map((link) => Attribute.createFromApi(link)))
        : List();
    }

    const nameTranslations = sortTranslations(
      'nameTranslations' in attributes ? attributes.nameTranslations : null,
    );
    // @ts-expect-error TS(2339): Property 'includedSegments' does not exist on type... Remove this comment to see the full error message
    const { includedSegments, excludedSegments } =
      getRelationships(relationships);

    const descriptionTranslations = sortTranslations(
      'descriptionTranslations' in attributes
        ? attributes.descriptionTranslations
        : null,
    );

    return new Attribute(
      fromJS({
        id,
        ...attributes,
        options: attrOptions,
        ranges: attrRanges,
        links: attrLinks,
        nameTranslations,
        descriptionTranslations,
        description: 'description' in attributes ? attributes.description : '',
        includedSegments,
        excludedSegments,
        // FIXME: why do we need 'id' and 'type' here?
        meta: {
          id,
          type,
          links,
        },
      }),
    );
  }

  reset() {
    return this.merge({
      validFrom: undefined,
      validFromDate: undefined,
    });
  }

  getValidFrom() {
    if (this.validFrom === 'now' || this.validFrom === 'start') {
      return this.validFrom;
    }

    return this.validFrom === 'date'
      ? moment(this.validFromDate).format('YYYY-MM-DD')
      : null;
  }

  getOptionValue() {
    if (typeof this.validFrom !== 'undefined') {
      return {
        start: this.getValidFrom(),
        value: this.selectedOption,
      };
    }

    return this.selectedOption;
  }

  getSelectOptionValue() {
    return this.selectedOption
      ? {
          label: this.selectedOption,
          labelTranslated: this.selectedOptionTranslated,
        }
      : this.selectedOption;
  }

  getNumberValue() {
    if (typeof this.validFrom !== 'undefined') {
      return {
        start: this.getValidFrom(),
        value: this.value,
      };
    }

    return this.value;
  }

  getTreeValue() {
    if (typeof this.validFrom !== 'undefined') {
      return {
        start: this.getValidFrom(),
      };
    }

    return this.value;
  }

  getDateValue(format = 'L') {
    const value = this.value
      ? typeof this.value === 'string'
        ? moment(this.value, format).format('YYYY-MM-DD')
        : moment(this.value).format('YYYY-MM-DD')
      : this.value;

    if (typeof this.validFrom !== 'undefined') {
      return {
        start: this.getValidFrom(),
        value,
      };
    }

    return value;
  }

  getEmployeeValue() {
    if (
      !this.name ||
      (typeof this.value === 'undefined' &&
        typeof this.validFrom === 'undefined')
    ) {
      return;
    }

    let value = {
      relationships: {
        [this.name]: {},
      },
    };

    if (typeof this.value !== 'undefined') {
      value = merge(value, {
        relationships: {
          [this.name]: {
            data: {
              type: 'employees',
              id: this.value,
            },
          },
        },
      });
    }

    if (typeof this.validFrom !== 'undefined') {
      value = merge(value, {
        relationships: {
          [this.name]: {
            meta: {
              start: this.getValidFrom(),
            },
          },
        },
      });
    }

    return value;
  }

  getJsonApiValue() {
    return this.type === 'employee'
      ? this.getValue()
      : this.name
        ? {
            attributes: {
              [this.name]: this.getValue(),
            },
          }
        : {};
  }

  hasValue() {
    switch (this.type) {
      case 'option':
        return typeof this.selectedOption !== 'undefined';
      case 'number':
      case 'date':
      case 'employee':
      case 'tree':
        return typeof this.value !== 'undefined';
      default:
        return true;
    }
  }

  getResetValue() {
    switch (this.type) {
      case 'option': {
        return this.selectedOption;
      }
      case 'date':
      case 'number':
      case 'tree': {
        return this.value;
      }
      case 'employee': {
        return Map({ id: this.value });
      }
    }
  }

  getValue() {
    switch (this.type) {
      case 'option': {
        return this.getOptionValue();
      }
      case 'number':
        return this.getNumberValue();
      case 'tree': {
        return this.getTreeValue();
      }
      case 'date': {
        return this.getDateValue();
      }
      case 'employee': {
        return this.getEmployeeValue();
      }
      default:
        return null;
    }
  }

  static createFromApiWithValues(data: AttributeResponse, employee: Employee) {
    const attribute = this.createFromApi(data);
    return attribute.setFromEmployee(employee);
  }

  setFromEmployee(employee: Employee) {
    const { name, type } = this;

    if (!employee) {
      return this;
    }

    switch (type) {
      case 'option': {
        const value = employee.getIn(['attributes', name]);
        return this.merge({
          selectedOption: value,
          selectedOptionTranslated: employee.hasIn(['optionAttributes', name])
            ? employee.getIn(['optionAttributes', name, 'titleTranslated'])
            : value,
        });
      }
      case 'date': {
        const value = employee.getIn(['attributes', name]);
        const date = value ? moment(value, 'YYYY-MM-DD').toDate() : value;

        return this.set('value', date);
      }
      case 'number':
      case 'tree': {
        return this.set('value', employee.getIn(['attributes', name]));
      }
      case 'employee': {
        if (employee.hasIn(['employeeAttributes', name])) {
          const value = employee.getIn(['employeeAttributes', name, 'id']);

          return this.set('value', value);
        }
        return this;
      }
      default:
        return this;
    }
  }

  toJsonApi() {
    // @ts-expect-error Property 'toJSON' does not exist on type 'Attribute'. Did you mean 'toJS'?ts(2551)
    return Attribute.toJsonApi(this.toJSON());
  }

  static toJsonApi(data: Attribute) {
    const {
      id: _id,
      name,
      status,
      open,
      options,
      meta,
      type,
      selectedOption: _selectedOption,
      value: _value,
      valid: _valid,
      sensitive: _sensitive,
      aliases: _aliases,
      comparisonAccess: _comparisonAccess,
      ranges: _ranges,
      links: _links,
      description: _description,
      descriptionTranslations: _descriptionTranslations,
      nameTranslations: _nameTranslations,
      rejectExternal: _rejectExternal,
      ...attributes
    } = data;

    const model = {
      ...meta,
      attributes: {
        name,
        status,
        open,
        type,
        ...attributes,
      },
    };

    if (type !== 'option') {
      return model;
    }

    return {
      ...model,
      relationships: {
        options: {
          data: (options || []).map((option?: AttributeOption) =>
            pickBy(
              {
                type: 'attribute_options',
                id: option?.isNew ? null : option?.id,
                attributes: {
                  name: option?.title,
                },
              },
              identity,
            ),
          ),
        },
      },
    };
  }

  getFormattedValue() {
    let value;

    switch (this.type) {
      case 'option':
        value = this.selectedOptionTranslated || this.selectedOption;
        break;

      case 'date':
        value = this.value;

        if (value) {
          const momentDate = moment(value, 'YYYY-MM-DD');

          if (momentDate.isValid()) {
            value = momentDate.format('ll');
          }
        }

        break;

      case 'employee':
        value = this.value;

        if (value) {
          const option = this.options?.find(
            (optionItem?: AttributeOption) => optionItem?.id === this.value,
          );

          if (option) {
            value = option;
          }
        }

        break;

      default:
        value = this.value;
    }

    return value;
  }
}
