import React from 'react';

import { Heading5 } from '@peakon/bedrock/react/typography';
import { useHierarchyLevel } from '@peakon/shared/components/HierarchySection';

import styles from './styles.css';

type Props = {
  question?: string;
  type?: 'text' | 'driver' | 'value';
  value?: string;
};

export const CommentQuestion = ({ question, type, value }: Props) => {
  const headingLevel = useHierarchyLevel(2);

  return (
    <Heading5 level={headingLevel} data-test-id="comment-question-title">
      {type === 'value' ? (
        <span>
          <strong className={styles.questionValue}>{value}</strong>
          &nbsp;-&nbsp;
        </span>
      ) : null}
      {question}
    </Heading5>
  );
};
