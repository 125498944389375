import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import isEqual from 'lodash/isEqual';
import { Prompt } from 'react-router';
import localStore from 'store2';

import { RadioGroup } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import { updateSettings } from '../../../../actions/CompanyActions';
import ReviewChangesBar from '../../../../components/employees/EmployeeEditor/ReviewChangesBar';
import { useFeature } from '../../../../hooks/useFeature';
import {
  DriverSorting,
  OverallScore,
  ScoreAggregation,
} from '../../../../reducers/CompanySettingsTypes';
import { useAppDispatch, useAppSelector } from '../../../../utils/reduxHooks';
import { useDataSettingsRedirect } from '../utils/useDataSettingsRedirect';

import styles from './styles.css';

type Settings = {
  driversOrder: DriverSorting;
  engagementScore: OverallScore;
  driverMode: ScoreAggregation;
} & Record<string, $TSFixMe>;

export const DashboardSettings = () => {
  useDataSettingsRedirect();
  const dispatch = useAppDispatch();
  const companySettings = useAppSelector((state) => state.company.settings);

  const [settings, setSettings] = useState<Settings>({
    driversOrder: companySettings.get('driversOrder'),
    driverMode: companySettings.get('driverMode'),
    engagementScore: companySettings.get('engagementScore'),
  });

  const [originalSettings, setOriginalSettings] = useState<Settings>({
    driversOrder: companySettings.get('driversOrder'),
    driverMode: companySettings.get('driverMode'),
    engagementScore: companySettings.get('engagementScore'),
  });

  const hasMixedDriverScore = useFeature('mixedDriverScore');
  const [isSubmittingChanges, setIsSubmittingChanges] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(!isEqual(settings, originalSettings));
  }, [settings, originalSettings]);

  const getSettingsDiff = (
    currentSettings: Settings,
    previousSettings: Settings,
  ): Partial<Settings> => {
    const settingsDiff: Partial<Settings> = {};

    each(currentSettings, (value, setting) => {
      if (value && value !== previousSettings[setting]) {
        settingsDiff[setting] = value;
      }
    });

    return settingsDiff;
  };

  const handleConfirmChanges = async () => {
    if (!isSubmittingChanges) {
      setIsSubmittingChanges(true);

      try {
        const updatedSettings = getSettingsDiff(settings, originalSettings);
        await dispatch(updateSettings(updatedSettings));

        localStore.set(
          'peakon.ui.scoreMode',
          settings.engagementScore === OverallScore.eNPS ? 'nps' : 'mean',
        );

        setIsDirty(false);
        setOriginalSettings(cloneDeep(settings));
      } catch {
      } finally {
        setIsSubmittingChanges(false);
      }
    }
  };

  const handleCancelChanges = () => {
    setIsDirty(false);
    setSettings(cloneDeep(originalSettings));
  };

  return (
    <div className={styles.root}>
      <div className={styles.rowWrap}>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            {t('dashboard_settings__driver_sorting')}
          </div>

          <RadioGroup
            value={settings?.driversOrder}
            onChange={(value) => {
              // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
              setSettings({ ...settings, driversOrder: value });
            }}
          >
            <div className={styles.settings}>
              <RadioGroup.Radio
                name="driver-order-priority"
                value={DriverSorting.Priority}
                testId="driver-order-priority"
              >
                <div className={styles.label}>
                  <strong>
                    {t('dashboard_settings__driver_order__priority')}
                    <span className={styles.standard}>
                      ({t('dashboard_settings__standard')})
                    </span>
                  </strong>

                  <span className={styles.settingInfo}>
                    {t('dashboard_settings__driver_order__priority_info')}
                  </span>
                </div>
              </RadioGroup.Radio>
            </div>

            <div className={styles.settings}>
              <RadioGroup.Radio
                name="driver-order-score"
                value={DriverSorting.Score}
                testId="driver-order-score"
              >
                <div className={styles.label}>
                  <strong>
                    {t('dashboard_settings__driver_order__score')}
                  </strong>
                  <span className={styles.settingInfo}>
                    {t('dashboard_settings__driver_order__score_info')}
                  </span>
                </div>
              </RadioGroup.Radio>
            </div>
            <div className={styles.settings}>
              <RadioGroup.Radio
                name="driver-order-difference"
                value={DriverSorting.Benchmark}
                testId="driver-order-difference"
              >
                <div className={styles.label}>
                  <strong>
                    {t('dashboard_settings__driver_order__difference')}
                  </strong>
                  <span className={styles.settingInfo}>
                    {t('dashboard_settings__driver_order__difference_info')}
                  </span>
                </div>
              </RadioGroup.Radio>
            </div>
          </RadioGroup>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            {t('dashboard_settings__engagement_score')}
          </div>

          <RadioGroup
            value={settings?.engagementScore}
            onChange={(value) => {
              // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
              setSettings({ ...settings, engagementScore: value });
            }}
          >
            <div className={styles.settings}>
              <RadioGroup.Radio
                value={OverallScore.Average}
                testId="engagement-score-average"
              >
                <div className={styles.label}>
                  <strong>
                    {t('dashboard_settings__engagement_score__average')}
                    <span className={styles.standard}>
                      ({t('dashboard_settings__standard')})
                    </span>
                  </strong>
                  <span className={styles.settingInfo}>
                    {t('dashboard_settings__engagement_score__average_info')}
                  </span>
                </div>
              </RadioGroup.Radio>
            </div>

            <div className={styles.settings}>
              <RadioGroup.Radio
                value={OverallScore.eNPS}
                testId="engagement-score-enps"
              >
                <div className={styles.label}>
                  <strong>
                    {t('dashboard_settings__engagement_score__enps')}
                  </strong>
                  <span className={styles.settingInfo}>
                    {t('dashboard_settings__engagement_score__enps_info')}
                  </span>
                </div>
              </RadioGroup.Radio>
            </div>
          </RadioGroup>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            {t('dashboard_settings__driver_mode')}
          </div>

          <RadioGroup
            value={settings?.driverMode}
            onChange={(value) => {
              // @ts-expect-error TS(2322): Type 'RadioInputValue | undefined' is not assignab... Remove this comment to see the full error message
              setSettings({ ...settings, driverMode: value });
            }}
          >
            <div className={styles.settings}>
              <RadioGroup.Radio
                value={ScoreAggregation.Aggregate}
                testId="driver-mode-aggregated"
              >
                <div className={styles.label}>
                  <strong>
                    {t('dashboard_settings__driver_mode__aggregated')}
                    <span className={styles.standard}>
                      ({t('dashboard_settings__standard')})
                    </span>
                  </strong>
                  <span className={styles.settingInfo}>
                    {t('dashboard_settings__driver_mode__aggregated_info')}
                  </span>
                </div>
              </RadioGroup.Radio>
            </div>

            {hasMixedDriverScore && (
              <div className={styles.settings}>
                <RadioGroup.Radio
                  value={ScoreAggregation.Mix}
                  testId="driver-mode-mixed"
                >
                  <div className={styles.label}>
                    <strong>
                      {t('dashboard_settings__driver_mode__mixed')}
                    </strong>
                    <span className={styles.settingInfo}>
                      {t('dashboard_settings__driver_mode__mixed_info')}
                    </span>
                  </div>
                </RadioGroup.Radio>
              </div>
            )}
            <div className={styles.settings}>
              <RadioGroup.Radio
                value={ScoreAggregation.Separate}
                testId="driver-mode-split"
              >
                <div className={styles.label}>
                  <strong>{t('dashboard_settings__driver_mode__split')}</strong>
                  <span className={styles.settingInfo}>
                    {t('dashboard_settings__driver_mode__split_info')}
                  </span>
                </div>
              </RadioGroup.Radio>
            </div>
          </RadioGroup>
        </div>
      </div>

      <ReviewChangesBar
        isDirty={isDirty}
        confirmLabel={t('save')}
        onConfirm={handleConfirmChanges}
        onCancel={handleCancelChanges}
      />
      <Prompt
        when={isDirty}
        message={() => t('schedules__form__unsaved_changes')}
      />
    </div>
  );
};
