import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import api from '@peakon/shared/utils/api';

export type ToggleValueProps = {
  valueId: string;
  isEnabled: boolean;
};

const toggleValue = ({ valueId, isEnabled }: ToggleValueProps) => {
  return api.patch(`/values/${valueId}`, null, {
    data: {
      type: 'values',
      id: valueId,
      attributes: {},
      relationships: {
        question: {
          data: {
            type: 'questions',
            attributes: {
              status: isEnabled ? 'active' : 'inactive',
            },
          },
        },
      },
    },
  });
};

const useToggleValue = ({
  onError,
}: UseMutationOptions<unknown, Error, ToggleValueProps, unknown>) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({ valueId, isEnabled }: ToggleValueProps) =>
      toggleValue({ valueId, isEnabled }),
    onError,
    onSettled: async () => {
      await client.invalidateQueries(['admin_values']);
    },
  });
};

// eslint-disable-next-line import/no-default-export
export default useToggleValue;
