import React, { useState } from 'react';

import { Grid, Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import useDocumentTitle from '@peakon/shared/hooks/usePageTitle';

import { EmptyState } from './EmptyState';
import useValues from './queries/useValues';
import { ValueEdit } from './ValueEdit';
import { ValueRow } from './ValueRow';
import { catchHandler } from '../../../actions/NotificationActions';
import AdminSpinner from '../../../components/admin/AdminSpinner/AdminSpinner';
import { ValuesHeader } from '../../../components/navigation/pages';
import { useAppDispatch } from '../../../utils/reduxHooks';

import styles from './styles.css';

export const ValuesMainPage = () => {
  useDocumentTitle(t('values__page_title'));
  const dispatch = useAppDispatch();

  const [isAddingValue, setIsAddingValue] = useState(false);
  const {
    data: values,
    isLoading,
    refetch: refetchValues,
  } = useValues({
    onError(error) {
      dispatch(catchHandler(error));
    },
  });

  const handleAddValue = () => {
    setIsAddingValue(true);
  };

  const handleValueUpdatedOrDeleted = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
    refetchValues();
  };

  const handleValueCreatedOrUpdated = () => {
    setIsAddingValue(false);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
    refetchValues();
  };

  return (
    <React.Fragment>
      <ValuesHeader onAddValue={handleAddValue} isAdding={isAddingValue} />
      <div className={styles.container} data-test-id="admin-values-page">
        <Grid>
          {isLoading && (
            <Grid.Row>
              <Grid.Col sm={12}>
                <AdminSpinner />
              </Grid.Col>
            </Grid.Row>
          )}
          {isAddingValue && (
            <Grid.Row>
              <Grid.Col sm={12}>
                <ValueEdit
                  isCreating={true}
                  valueData={undefined}
                  onCancel={() => setIsAddingValue(false)}
                  onCreateOrUpdate={handleValueCreatedOrUpdated}
                />
              </Grid.Col>
            </Grid.Row>
          )}

          {!isLoading &&
            (values && values.length > 0 ? (
              <React.Fragment>
                <Grid.Row>
                  <Grid.Col sm={12}>
                    <Typography.P>{t('values__page_title')}</Typography.P>
                  </Grid.Col>
                </Grid.Row>
                {values?.map((value) => (
                  <ValueRow
                    key={value.id}
                    value={value}
                    onValueUpdatedOrDeleted={handleValueUpdatedOrDeleted}
                  />
                ))}
              </React.Fragment>
            ) : (
              <EmptyState />
            ))}
        </Grid>
      </div>
    </React.Fragment>
  );
};
