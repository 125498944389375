import React, { Fragment, useState, useEffect, ElementType } from 'react';

import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { CSSTransition } from 'react-transition-group';

import { IconButton } from '@peakon/bedrock/react/button';
import { Portal, Overlay } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import {
  hideSupportWidget,
  showSupportWidget,
} from '@peakon/shared/utils/supportWidget';

import styles from './styles.css';

type FocusTrapDelayedProps = {
  children?: React.ReactNode;
  timeout: number;
};

function FocusTrapDelayed({ children, timeout }: FocusTrapDelayedProps) {
  /*
    Delay the focus trap to allow the animation to finish
  */
  const [active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, timeout);
  }, [timeout]);

  return (
    <FocusTrap
      active={active}
      focusTrapOptions={{ clickOutsideDeactivates: true }}
    >
      <div>{children}</div>
    </FocusTrap>
  );
}

type MenuSlideInProps = {
  children?: React.ReactNode;
  isShown?: boolean;
  handleOverlayClick?: () => void;
};

function MenuSlideIn({
  children,
  isShown,
  handleOverlayClick,
  ...props
}: MenuSlideInProps) {
  useEffect(() => {
    if (isShown) {
      hideSupportWidget();
    }
    return () => {
      showSupportWidget();
    };
  }, [isShown]);

  return (
    <Fragment>
      <Portal>
        <CSSTransition
          className={styles.menuSlideIn}
          classNames={{
            enter: styles.enter,
            exit: styles.exit,
          }}
          in={isShown}
          timeout={300}
          unmountOnExit
        >
          <div aria-modal role="dialog" {...props}>
            {children}
          </div>
        </CSSTransition>
      </Portal>
      <Overlay onClick={handleOverlayClick} show={isShown} />
    </Fragment>
  );
}

type MenuSlideInHeaderProps = {
  onClick?: () => void;
  icon: ElementType;
  label?: string;
  title?: string | null;
};

export function MenuSlideInHeader({
  onClick,
  icon: Icon,
  label,
  title,
}: MenuSlideInHeaderProps) {
  return (
    <div className={styles.header}>
      <IconButton
        icon={<Icon />}
        onClick={onClick}
        variant="tertiary"
        rounded
        accessibleName={label || title || t('common__close')}
      />

      {title && <div className={styles.title}>{title}</div>}
    </div>
  );
}

type TopLevelMenuProps = {
  children?: React.ReactNode;
  isShown?: boolean;
};

export function TopLevelMenu({ children, isShown }: TopLevelMenuProps) {
  return (
    <div
      className={classnames(styles.topLevelMenu, {
        [styles.isShown]: isShown,
      })}
    >
      <FocusTrap
        active={isShown}
        focusTrapOptions={{ clickOutsideDeactivates: true }}
      >
        <div className={styles.topLevelMenuInner}>{children}</div>
      </FocusTrap>
    </div>
  );
}

type ProductMenuProps = {
  children?: React.ReactNode;
  isShown?: boolean;
};

export function ProductMenu({ children, isShown }: ProductMenuProps) {
  return (
    <CSSTransition
      className={styles.productMenu}
      classNames={{
        enter: styles.enter,
        exit: styles.exit,
      }}
      in={isShown}
      timeout={300} // same as css --animation-duration
      unmountOnExit
    >
      <div>
        <FocusTrapDelayed timeout={300}>{children}</FocusTrapDelayed>
      </div>
    </CSSTransition>
  );
}

// eslint-disable-next-line import/no-default-export
export default MenuSlideIn;
