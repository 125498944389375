import contentDisposition from 'content-disposition';
import FileSaver from 'file-saver';
import each from 'lodash/each';
import get from 'lodash/get';
// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';

import { AppError } from '@peakon/error-reporter';
import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import { futch } from '../utils';

export const IMAGE_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/tiff',
];

export type DownloadResult = {
  success: boolean;
  errorMessage?: string;
};

class FileService {
  async upload(
    file: File,
    type: string,
    {
      maxSize = 5 * 1024 * 1024,
      onProgress,
      isImage = true,
    }: {
      maxSize?: number;
      onProgress?: (event: ProgressEvent) => void;
      isImage?: boolean;
    } = {},
  ) {
    if (isImage && !IMAGE_TYPES.includes(file.type)) {
      throw new AppError(t('file__type__invalid'));
    }

    if (file.size && file.size > maxSize) {
      throw new AppError(
        t('file__too-large_icu', {
          replace: {
            maxSizeMb: Math.round((maxSize / (1024 * 1024)) * 100) / 100,
          },
        }),
      );
    }

    const response = await api.file.upload(type, file.name);

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    await this.sendFile(file, response.url, response.settings, onProgress);

    return {
      field: type,
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      value: response.settings.key,
    };
  }

  async sendFile(
    file: File,
    url: string,
    settings: Record<string, string>,
    onProgress?: (event: ProgressEvent) => void,
  ) {
    const formData = new FormData();

    // Don't break if https://github.com/peakon/api/pull/8769 gets rolled back
    if (!settings['Content-Type']) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      settings['Content-Type'] = file.type;
    }

    each(settings, (setting, key) => {
      formData.append(key, setting);
    });

    formData.append('file', file);

    await futch(
      url,
      {
        method: 'POST',
        body: formData,
      },

      onProgress,
    );
  }

  async downloadFile(
    url: string,
    fileName: string,
    options: unknown = {},
    isCustomUrl = false,
  ): Promise<DownloadResult> {
    try {
      if (options) {
        const query = qs.stringify(options);
        if (query) {
          // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
          url += `?${query}`;
        }
      }

      const bearer = api.auth.restoreToken();
      const downloadUrl = isCustomUrl ? url : `${api.baseUrl}/api/v1${url}`;

      const params = {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      };

      const response = await fetch(downloadUrl, params);

      if (response.status >= 400 && response.status < 500) {
        return { success: false, errorMessage: response.statusText };
      }

      if (response.headers.has('Content-Disposition')) {
        const cd = response.headers.get('Content-Disposition') ?? '';
        const cdParsed = contentDisposition.parse(cd);
        if (cdParsed.parameters.filename) {
          // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
          fileName = cdParsed.parameters.filename;
        }
      }

      const blob = await response.blob();

      FileSaver.saveAs(blob, fileName, { autoBom: true });
      return { success: true };
    } catch (error) {
      // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'string |... Remove this comment to see the full error message
      return { success: false, errorMessage: get(error, 'data', error) };
    }
  }
}

const fileService = new FileService();
// eslint-disable-next-line import/no-default-export
export default fileService;
