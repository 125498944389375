import { fromJS, List, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Context from './ContextRecord';
import Segment from './SegmentRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class ScheduleRecord
  extends Record({
    code: undefined,
    context: undefined,
    duration: undefined,
    employeeCount: undefined,
    frequency: undefined,
    id: undefined,
    nextEnd: undefined,
    nextEndOverride: undefined,
    nextStart: undefined,
    nextStartOverride: undefined,
    questionLimit: undefined,
    rights: undefined,
    status: undefined,
    weekday: undefined,
    respondentCount: undefined,
    recipientCount: undefined,
    recipientWithCircleCount: undefined,

    excludedSegments: undefined,
    includedSegments: undefined,
    overlaps: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  context: $TSFixMe;
  excludedSegments: $TSFixMe;
  frequency: $TSFixMe;
  includedSegments: $TSFixMe;
  recipientCount: $TSFixMe;
  recipientWithCircleCount: $TSFixMe;
  status: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ScheduleRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ScheduleRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get dashboardContext() {
    return this.context;
  }

  get enabled() {
    return this.status === 'enabled';
  }

  get recipients() {
    return {
      total: this.recipientCount,
      withCircle: this.recipientWithCircleCount,
    };
  }

  isManual() {
    return this.frequency === 0;
  }

  hasIncludedOrExcludedSegments() {
    return !this.excludedSegments.isEmpty() || !this.includedSegments.isEmpty();
  }

  static createFromApi(schedule: $TSFixMe) {
    const {
      id,
      attributes,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      relationships: { context, excludedSegments, includedSegments } = {},
    } = schedule;

    return new ScheduleRecord(
      fromJS({
        id,
        ...attributes,
        context: context ? Context.createFromApi(context) : undefined,
        excludedSegments: excludedSegments
          ? List(excludedSegments.map(Segment.createFromApi))
          : List(),
        includedSegments: includedSegments
          ? List(includedSegments.map(Segment.createFromApi))
          : List(),
      }),
    );
  }

  toJsonApi() {
    return {
      data: {
        type: 'schedules',
        attributes: {
          frequency: this.frequency,
        },
      },
    };
  }
}
