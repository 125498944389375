import React from 'react';

import z from 'zod';

import { Paragraph } from '@peakon/bedrock/react/typography';
import { Stack } from '@peakon/bedrock/react-alpha/stack';
import { SubmitButton, ZodForm } from '@peakon/shared/components/Form';
import { CharacterLimitedTextArea } from '@peakon/shared/components/Form/custom/CharacterLimitedTextArea';
import { t } from '@peakon/shared/features/i18next/t';

import { useAddTeamSuggestion } from '../../queries/useAddTeamSuggestion';

import styles from './styles.css';

type Props = {
  priorityId: string;
};

const MAX_SUGGESTION_LENGTH = 2000;

const schema = z.object({
  text: z.string().trim().nonempty().max(MAX_SUGGESTION_LENGTH),
});

export function TeamSuggestionCardForm({ priorityId }: Props) {
  const { mutateAsync: addTeamSuggestion } = useAddTeamSuggestion();

  const handleSubmit = async (values: z.infer<typeof schema>) => {
    await addTeamSuggestion({
      text: values.text,
      priorityId,
    }).catch(() => {
      // Error is handled by the hook
      // but we have to swallow it in order to not break the form
    });
  };

  return (
    <ZodForm schema={schema} onSubmit={handleSubmit} mode="onChange">
      <div className={styles.formSection}>
        <CharacterLimitedTextArea<typeof schema>
          label={t('team_suggestions__standalone__card__text_area_label')}
          name="text"
          rows={3}
          maxLength={MAX_SUGGESTION_LENGTH}
        />
        <Stack direction="horizontal" spacing="08" noWrap alignBlock="center">
          <SubmitButton>
            {t('team_suggestions__standalone__card__button')}
          </SubmitButton>
          <Paragraph size="small" variant="hint" bold>
            {t('team_suggestions__standalone__card__disclaimer')}
          </Paragraph>
        </Stack>
      </div>
    </ZodForm>
  );
}
