import React, { createContext, useContext } from 'react';

import { Schema, ZodRawShape } from 'zod';

import { getRequiredFields } from '../utils/getRequiredFields';
import { ZodPaths, ZodSchemaOrRecord } from '../utils/types';

const RequiredFieldsContext = createContext<string[] | undefined>(undefined);

type RequiredFieldsProviderProps = {
  schema: Schema;
  children: React.ReactNode;
};

export const RequiredFieldsProvider = ({
  schema,
  children,
}: RequiredFieldsProviderProps) => {
  const requiredFields = getRequiredFields(schema);

  return (
    <RequiredFieldsContext.Provider value={requiredFields}>
      {children}
    </RequiredFieldsContext.Provider>
  );
};

export function useRequiredFields<T extends ZodSchemaOrRecord = ZodRawShape>() {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return useContext(RequiredFieldsContext) as ZodPaths<T>[] | undefined;
}
