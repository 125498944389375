import MetricRecord from './MetricRecord';
import FormEditorRecord from '../FormEditorRecord';

class MetricFormRecord extends FormEditorRecord {
  static startEditing(original: MetricRecord) {
    return new MetricFormRecord({
      original,
      current: original,
    });
  }

  toJsonApi() {
    const {
      id,
      name,
      description,
      minValue,
      maxValue,
      status,
      relation,
      category,
      attribute,
    } = this.current;

    const relationships = {
      attribute: {
        data: {
          type: 'attributes' as const,
          id: attribute.id,
        },
      },
      category: {
        data: {
          type: 'categories' as const,
          id: category.id,
        },
      },
    };

    return {
      data: {
        id,
        type: 'external_metrics' as const,
        attributes: {
          name,
          description,
          minValue: minValue !== '' ? minValue : null,
          maxValue: maxValue !== '' ? maxValue : null,
          status,
          relation,
        },
        relationships,
      },
    };
  }

  isInvalid() {
    return (
      !this.current.name ||
      !this.current.category ||
      !this.current.attribute ||
      Boolean(
        this.current.maxValue && this.current.minValue >= this.current.maxValue,
      )
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default MetricFormRecord;
