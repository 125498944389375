import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { SegmentResponse } from '@peakon/shared/schemas/api/segments';
import { AttributeAccess } from '@peakon/shared/types/Attribute';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class SegmentGroup
  extends Record({
    attributeId: undefined,
    comparisonAccess: undefined,
    id: undefined,
    name: undefined,
    nameTranslated: undefined,
    negativeCount: undefined,
    positiveCount: undefined,
    segmentCount: undefined,
  })
  implements Schema
{
  attributeId?: string;
  comparisonAccess?: AttributeAccess;
  id!: Schema['id'];
  name?: string;
  nameTranslated?: string;
  negativeCount?: number;
  positiveCount?: number;
  segmentCount?: number;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'SegmentGroup',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'SegmentGroup',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get type() {
    if (this.id !== 'critical' && this.id !== 'link') {
      return 'attribute';
    }

    return this.id;
  }

  isCritical() {
    return this.id === 'critical';
  }

  isLink() {
    return this.id === 'link';
  }

  static createFromApi(data: SegmentResponse) {
    const {
      id,
      attributes,
      relationships: { attribute },
    } = data;

    let attributeProps;
    if (attribute) {
      const {
        id: attributeId,
        attributes: { comparisonAccess, name, nameTranslated } = {},
      } = attribute;

      attributeProps = {
        attributeId,
        comparisonAccess,
        name,
        nameTranslated,
      };
    }

    return new SegmentGroup(
      fromJS({
        id,
        ...attributes,
        ...attributeProps,
      }),
    );
  }

  isRestricted() {
    return this.id !== 'link' && this.comparisonAccess === 'restricted';
  }
}
