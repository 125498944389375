import { useMutation } from '@tanstack/react-query';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';
import { SensitiveCommentConceptStatus } from '../SensitiveCommentsConcept';

type QueryParams = {
  onSuccess: (attributes: { status: SensitiveCommentConceptStatus }) => void;
};

type UpdateParams = {
  conceptId: string;
  attributes: {
    status: SensitiveCommentConceptStatus;
  };
};

const updateStatus = async ({
  attributes,
  conceptId,
}: UpdateParams): Promise<$TSFixMe> => {
  return await api.patch(`/sensitive/concepts/${conceptId}`, null, {
    data: {
      type: 'sensitive_concepts',
      id: conceptId,
      attributes,
    },
  });
};

export const useUpdateStatusMutation = ({ onSuccess }: QueryParams) => {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: updateStatus,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: ({ data: { attributes } }) => {
      onSuccess(attributes);
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message:
            attributes.status === SensitiveCommentConceptStatus.Active
              ? t('engagement__settings__sensitive_comments_concept_on')
              : t('engagement__settings__sensitive_comments_concept_off'),
        }),
      );
    },
  });
};
