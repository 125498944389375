import React, { ReactNode, Fragment, useReducer } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import sum from 'lodash/sum';
import toArray from 'lodash/toArray';
import { Link } from 'react-router-dom';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Tooltip as TippyTooltip } from 'react-tippy';

import { QuestionSetupCustomizedTranslationIcon as TranslationIcon } from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { Card } from '@peakon/bedrock/react/card';
import { BodyText } from '@peakon/bedrock/react/typography';
import { Category, Comment as CommentRecord } from '@peakon/records';
import { AsyncButton } from '@peakon/shared/components/AsyncButton/AsyncButton';
import DateLabel from '@peakon/shared/components/DateLabel';
import { Flex } from '@peakon/shared/components/Flex/Flex';
import { ScoreNumber } from '@peakon/shared/components/ScoreNumber';
import { AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import {
  getCurrentLocaleInfo,
  getEmployeeScopedLocaleInfo,
} from '@peakon/shared/features/i18next/helpers';
import { validateLocaleId } from '@peakon/shared/features/i18next/localesValidation';
import { t, todoT } from '@peakon/shared/features/i18next/t';
import { getTranslatedLocaleNamesMap } from '@peakon/shared/features/i18next/translatedLocaleNames';

import { Google } from './assets/GoogleLogo';
import { CommentDriverLabel } from './CommentDriverLabel';
import { CommentDropdown } from './CommentDropdown';
import {
  CommentFeedback,
  FeedbackType,
} from './CommentFeedback/CommentFeedback';
import { CommentFooter } from './CommentFooter';
import { CommentManagers } from './CommentManagers';
import { CommentQuestion } from './CommentQuestion';
import { CommentScore } from './CommentScore';
import { CommentTextIcon } from './CommentTextIcon';
import { HighlightBanner } from './HighlightBanner';
import { HighlightedCommentText } from './HighlightedCommentText';
import { SensitiveBanner } from './SensitiveBanner';
import { TopicBadge } from './TopicBadge';
import { isSameLanguage } from '../../utils/languageUtils';
import AcknowledgementCounts from '../acknowledgement/AcknowledgementCounts';
import { Badge } from '../ValueBadge';

import styles from './styles.css';

function CommentText({
  children,
  lang,
}: {
  children: ReactNode;
  lang?: string | null;
}) {
  return (
    <p
      data-test-id="comment-text"
      className={styles.comment}
      lang={lang || undefined}
    >
      {children}
    </p>
  );
}

function CommentDate({ date, isRound }: { date: string; isRound: boolean }) {
  return isRound ? (
    <TippyTooltip
      animateFill={false}
      animation="shift"
      arrow
      delay={[100, 200]}
      inertia
      popperOptions={{
        modifiers: {
          keepTogether: {
            enabled: true,
          },

          preventOverflow: { boundariesElement: 'viewport' },
        },
      }}
      position="bottom"
      title={t('comments__round_close_date')}
    >
      <BodyText as="div" bold size="small" variant="hint">
        <DateLabel date={date} />
      </BodyText>
    </TippyTooltip>
  ) : (
    <BodyText as="div" bold size="small" variant="hint">
      <DateLabel date={date} />
    </BodyText>
  );
}

function negate(value: boolean) {
  return !value;
}

function useToggle(defaultValue = false) {
  return useReducer(negate, defaultValue);
}

export type Props = {
  comment: CommentRecord;
  category?: Category;
  commentDateVisibility?: 'date' | 'round';
  contextId?: string;
  segmentId?: string;
  hasAcknowledgements?: boolean;
  hasCommentManagers?: boolean;
  hasCommentRank?: boolean;
  hasConversations?: boolean;
  isSemanticTagsEnabled?: boolean;
  isExplore?: boolean;
  onAcknowledge?: (
    type: AcknowledgementType,
    opts: { optOutConfirm?: boolean },
  ) => Promise<void>;
  onConversationsClick?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: () => void;
  onLoadManagers?: () => void;
  onMarkAsSensitive?: () => void;
  onRevertTranslate?: (id: string) => void;
  onTranslate?: (id: string) => void;
  commentFeedback?: {
    isRelevant?: boolean;
    isBiased?: boolean;
    isBiasedComment?: string;
  };
  sendFeedback?: (
    isRelevant: boolean,
    isBiased: boolean,
    biasedComment: string,
  ) => Promise<boolean>;
  shouldConfirmAcknowledgement?: boolean;
  topicId?: string;
  translatable?: boolean;
  translated?: boolean;
  withHighlightBanner?: boolean;
  feedbackType?: (typeof FeedbackType)[keyof typeof FeedbackType];
  hasNewTranslations?: boolean;
};

export function Comment({
  commentDateVisibility = 'date',
  translatable = false,
  withHighlightBanner = true,
  comment,
  translated,
  topicId,
  isExplore,
  contextId,
  hasCommentManagers,
  onLoadManagers,

  category,
  segmentId,
  onTranslate,
  onRevertTranslate,

  hasCommentRank,
  commentFeedback,
  sendFeedback,
  feedbackType,
  hasConversations,
  onAcknowledge,
  onConversationsClick,
  onDelete,
  onMarkAsSensitive,
  shouldConfirmAcknowledgement,
  isSemanticTagsEnabled,
  hasAcknowledgements,
  hasNewTranslations,
}: Props) {
  const [showCommentInOriginalLanguage, toggleShowCommentInOriginalLanguage] =
    useToggle(!comment.commentTranslation);

  const locale = getCurrentLocaleInfo();
  const translatedLocaleNamesMap = getTranslatedLocaleNamesMap();

  const topicHighlight = topicId && comment.topics && !comment.topics.isEmpty();
  const sensitiveHighlight =
    !isExplore &&
    comment.sensitive &&
    comment.sensitiveMatches &&
    !comment.sensitiveMatches.isEmpty();
  const searchHighlight = comment.text.includes('**');
  const isHighlighted = topicHighlight || sensitiveHighlight || searchHighlight;

  const nonTranslatedContent = isHighlighted ? (
    <HighlightedCommentText comment={comment} topicId={topicId} />
  ) : (
    comment.text
  );

  function renderNewCommentTranslation() {
    const commentTranslationId = `comment-original-text-${comment.id}`;

    if (
      !translatable ||
      !comment.locale ||
      isSameLanguage(comment.locale, locale.id)
    ) {
      return (
        <CommentText lang={comment.locale}>{nonTranslatedContent}</CommentText>
      );
    }

    return (
      <div>
        <Flex gap={8} flexDirection="column">
          <div className={styles.translation}>
            <Flex gap={8} flexDirection="column">
              {comment.commentTranslation ? (
                <CommentText
                  lang={comment.commentTranslation.get('targetLocale')}
                >
                  {comment.commentTranslation.get('text')}
                </CommentText>
              ) : translated ? (
                <CommentText lang={locale.language}>
                  {comment.translation}
                </CommentText>
              ) : null}

              <Flex gap={4} alignItems="center">
                <Flex gap={8} alignItems="center">
                  <TranslationIcon className={styles.translationIcon} />

                  {comment.commentTranslation || translated ? (
                    <BodyText variant="hint" size="small">
                      {todoT(
                        `This has been translated into ${translatedLocaleNamesMap.get(
                          validateLocaleId(
                            comment.commentTranslation?.get('targetLocale') ||
                              locale.id,
                          ),
                        )} by <logo>Google</logo>`,
                        {
                          components: {
                            logo: <Google className={styles.googleIcon} />,
                          },
                        },
                      )}
                    </BodyText>
                  ) : (
                    <BodyText variant="hint" size="small">
                      {todoT(
                        `This seems to be written in ${translatedLocaleNamesMap.get(
                          validateLocaleId(
                            getEmployeeScopedLocaleInfo(comment.locale).id,
                          ),
                        )}.`,
                      )}
                    </BodyText>
                  )}
                </Flex>

                {comment.commentTranslation ? (
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={toggleShowCommentInOriginalLanguage}
                    aria-controls={commentTranslationId}
                    aria-expanded={showCommentInOriginalLanguage}
                    // We should't be changing the contents of the button - the expanded state will do
                    aria-label={todoT('Show original')}
                  >
                    {showCommentInOriginalLanguage
                      ? todoT('Hide original')
                      : todoT('Show original')}
                  </Button>
                ) : (
                  <AsyncButton
                    variant="tertiary"
                    size="small"
                    // @ts-expect-error - Type () => void is not assignable to type () => Promise<unknown>
                    onClick={
                      translated
                        ? () =>
                            // @ts-expect-error - Cannot invoke an object which is possibly 'undefined'.
                            onRevertTranslate(comment.id)
                        : () =>
                            // @ts-expect-error - Cannot invoke an object which is possibly 'undefined'.
                            onTranslate(comment.id)
                    }
                  >
                    {translated
                      ? todoT('Hide translation')
                      : todoT('Translate to {language}', {
                          replace: {
                            language: translatedLocaleNamesMap.get(
                              validateLocaleId(locale.id),
                            ),
                          },
                        })}
                  </AsyncButton>
                )}
              </Flex>
            </Flex>
          </div>
        </Flex>

        <div id={commentTranslationId}>
          {showCommentInOriginalLanguage ? (
            <div className={styles.expandableCommentContent}>
              <CommentText lang={comment.locale}>
                {nonTranslatedContent}
              </CommentText>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  function renderComment() {
    return (
      <CommentText
        lang={translated ? locale.language : comment.locale ?? undefined}
      >
        {translated ? comment.translation : nonTranslatedContent}
      </CommentText>
    );
  }

  function renderTranslate() {
    if (
      !translatable ||
      !comment.locale ||
      isSameLanguage(comment.locale, locale.language)
    ) {
      return null;
    }

    const onClick = translated ? onRevertTranslate : onTranslate;

    const language = translatedLocaleNamesMap.get(validateLocaleId(locale.id));

    const originalLanguage = translatedLocaleNamesMap.get(
      validateLocaleId(getEmployeeScopedLocaleInfo(comment.locale).id),
    );

    return (
      <div className={styles.translate}>
        <AsyncButton
          size="small"
          variant="secondary"
          onClick={() =>
            // @ts-expect-error - Cannot invoke an object which is possibly 'undefined'.
            onClick(comment.id)
          }
        >
          {translated
            ? t('dashboard__original-btn', {
                replace: { language: originalLanguage },
              })
            : t('dashboard__translate-btn', { replace: { language } })}
        </AsyncButton>

        <div className={styles.translateText}>
          <BodyText size="small" variant="hint">
            {translated
              ? t('dashboard__original-text', {
                  replace: { language },
                })
              : t('dashboard__translate-text', {
                  replace: { language: originalLanguage },
                })}
          </BodyText>
        </div>
      </div>
    );
  }

  function renderCommentInfo(isCardDisabled: boolean) {
    const isRound = commentDateVisibility === 'round';
    const date = isRound ? comment.closesAt : comment.answeredAt;

    return (
      <ul className={styles.info}>
        {date ? (
          <li className={styles.infoItem}>
            {isCardDisabled ? (
              <CommentDate date={date} isRound={isRound} />
            ) : (
              <Link
                className={styles.permalink}
                to={`/dashboard/comments/${comment.id}${
                  contextId ? `?contextId=${contextId}` : ''
                }`}
              >
                <CommentDate date={date} isRound={isRound} />
              </Link>
            )}
          </li>
        ) : null}

        {isNumber(comment.score) && (
          <li className={styles.infoItem}>
            <BodyText as="div" bold variant="hint" size="small">
              <span>{t('dashboard__input__score')}</span>:{' '}
              <ScoreNumber
                fontSize={12}
                inline
                score={comment.score.toFixed(0)}
              />
            </BodyText>
          </li>
        )}

        {category && !category.isGroup('text') ? (
          <li className={styles.infoItem}>
            <Flex alignItems="center">
              <CommentDriverLabel
                category={category}
                contextId={contextId}
                segmentId={segmentId}
              />
              {comment.question.value && <Badge />}
            </Flex>
          </li>
        ) : null}

        {hasCommentManagers && !isCardDisabled && (
          <li className={classnames(styles.infoItem, styles.manager)}>
            <CommentManagers
              isLoading={comment.isLoadingManagers}
              managers={comment.managers}
              onLoad={onLoadManagers}
            />
          </li>
        )}
      </ul>
    );
  }

  function renderBanner() {
    if (comment.sensitive) {
      return (
        <SensitiveBanner
          matches={
            comment.sensitiveMatches
              ? comment.sensitiveMatches.toJS()
              : undefined
          }
        />
      );
    }

    if (
      withHighlightBanner &&
      hasCommentRank &&
      comment.highlighted &&
      comment.highlightReasons
    ) {
      return <HighlightBanner reasons={comment.highlightReasons} />;
    }

    return null;
  }

  function renderTags() {
    const { semanticTags } = comment;
    if (!isSemanticTagsEnabled || !semanticTags) {
      return;
    }
    const tagBadges = [];

    // @ts-expect-error - Type 'List<{ id: string; locale: "id" | "en" | "en-GB" | "en-US" | "ar" | "az" | "bg" | "bn" | "ca" | "cs" | "da" | "de" | "el" | "es" | "es-LA" | "et" | "fi" | "fo" | "fr" | "fr-FR" | "fr-CA" | "gu-IN" | ... 44 more ... | "zu"; name: string & BRAND<...>; }>' must have a '[Symbol.iterator]()' method that returns an iterator.
    for (const tag of semanticTags) {
      const id = tag.get('id');
      const name = tag.get('name');
      const tagLocale = tag.get('locale');

      // Hide the self tags, when on topics detail page
      if (id === topicId) {
        continue;
      }

      tagBadges.push(
        <TopicBadge
          key={`${id}-${name}`}
          name={name}
          locale={tagLocale}
          to={`/dashboard/topics/semantic/overview/${id}`}
        />,
      );
    }

    if (tagBadges.length === 0) {
      return;
    }

    return <div className={styles.secondary}>{tagBadges}</div>;
  }

  function getAcknowledgementCount() {
    const acknowledgementCounts = comment.acknowledgementCounts?.toJS();

    let totalAcknowledgementCounts =
      hasAcknowledgements &&
      acknowledgementCounts &&
      sum(toArray(acknowledgementCounts));

    // hide counts when only current user has acknowledged
    if (comment.acknowledgement && totalAcknowledgementCounts === 1) {
      totalAcknowledgementCounts = 0;
    }

    return { totalAcknowledgementCounts, acknowledgementCounts };
  }

  function renderAcknowledgementCountAndTopicTags() {
    const { totalAcknowledgementCounts, acknowledgementCounts } =
      getAcknowledgementCount();

    const tags = [];

    // @ts-expect-error - Type 'List<TopicRecord>' must have a '[Symbol.iterator]()' method that returns an iterator.
    for (const tag of comment?.topics) {
      // Hide the self tags, when on topics detail page
      if (tag.id === topicId) {
        continue;
      }

      tags.push(
        <TopicBadge
          key={tag.id}
          name={tag.name}
          locale={comment.locale}
          to={`/dashboard/topics/simple/overview/${tag.id}`}
        />,
      );
    }

    if (!totalAcknowledgementCounts && !tags.length) {
      return;
    }

    return (
      <div className={styles.secondary}>
        {totalAcknowledgementCounts ? (
          <div className={styles.acknowledgementCounts}>
            <AcknowledgementCounts counts={acknowledgementCounts} />
          </div>
        ) : null}
        {tags}
      </div>
    );
  }

  const isCardDisabled = commentFeedback ? !commentFeedback.isRelevant : false;

  return (
    <Fragment>
      <div
        data-test-id="comment-container"
        id={`comment-item-${comment.id}`}
        className={classnames(styles.container, {
          [styles.disabled]: isCardDisabled,
        })}
      >
        <Card>
          {renderBanner()}

          <div className={styles.header}>
            <div className={styles.iconArea}>
              {comment.type === 'text' ? (
                <CommentTextIcon />
              ) : (
                <CommentScore type={comment.type} score={comment.score} />
              )}
            </div>

            <div className={styles.questionArea}>
              <CommentQuestion
                question={comment.question.text}
                type={comment.type}
                value={get(comment.question, 'value.name')}
              />
            </div>

            <div className={styles.dropdownArea}>
              <CommentDropdown
                isSensitive={comment.sensitive}
                onDelete={onDelete}
                onMarkAsSensitive={onMarkAsSensitive}
              />
            </div>

            <div className={styles.infoArea}>
              {renderCommentInfo(isCardDisabled)}
            </div>
          </div>

          <div className={styles.content}>
            {hasNewTranslations ? (
              renderNewCommentTranslation()
            ) : (
              <Fragment>
                {renderTranslate()}
                {renderComment()}
              </Fragment>
            )}
            {renderAcknowledgementCountAndTopicTags()}
            {renderTags()}
          </div>
          {!isCardDisabled && (
            <CommentFooter
              acknowledgement={comment.acknowledgement}
              active={comment.active}
              hasAcknowledgements={hasAcknowledgements}
              hasConversations={Boolean(hasConversations)}
              messageCount={comment.messageCount}
              onAcknowledge={onAcknowledge}
              onConversationsClick={onConversationsClick}
              shouldConfirmAcknowledgement={Boolean(
                shouldConfirmAcknowledgement,
              )}
            />
          )}
        </Card>
      </div>

      {isExplore ? (
        <CommentFeedback
          isRelevant={commentFeedback ? commentFeedback.isRelevant : undefined}
          isBiased={commentFeedback ? commentFeedback.isBiased : undefined}
          isFeedbackProvided={Boolean(commentFeedback)}
          biasedComment={
            commentFeedback ? commentFeedback.isBiasedComment : undefined
          }
          // @ts-expect-error - Type 'Function | undefined' is not assignable 'Function'
          sendFeedback={sendFeedback}
          // @ts-expect-error - Type '"SemanticSearch" | "SemanticTopics" | undefined' is not assignable to type '"SemanticSearch" | "SemanticTopics"'.
          feedbackType={feedbackType}
        />
      ) : null}
    </Fragment>
  );
}
