import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import * as localization from '@peakon/localization';
import { validateLocaleId } from '@peakon/shared/features/i18next/localesValidation';
import { getTranslatedLocaleNamesMap } from '@peakon/shared/features/i18next/translatedLocaleNames';

export const DEFAULT_LOCALE = 'en';

const ANALYZER_IDS = [
  'ar',
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'fr',
  'hu',
  'id',
  'it',
  'lt',
  'lv',
  'nl',
  'no',
  'pt',
  'ro',
  'ru',
  'sv',
  'th',
  'tr',
  'hr',
  'sl',
  'sk',
  'et',
  'fr',
  'pt',
  'pl',
];

type GetItemLocalesParam = {
  shouldTranslateTitle: boolean;
};

type GetLocalesParam = GetItemLocalesParam & {
  shouldTranslateTitle: boolean;
  translated?: boolean;
  employee?: boolean;
};

const getLocales = ({
  shouldTranslateTitle,
  translated,
  employee,
}: GetLocalesParam) => {
  const locales = localization.all({
    translated,
    employee,
  });

  const items = map(locales, (locale) => ({
    type: 'item',
    id: locale.id,

    title: shouldTranslateTitle
      ? getTranslatedLocaleNamesMap().get(validateLocaleId(locale.id))
      : locale.title,
    value: locale,
  }));

  return sortBy(items, 'title');
};

export const getQuestionItemLocales = ({
  shouldTranslateTitle,
}: GetItemLocalesParam) => {
  return getLocales({
    shouldTranslateTitle,
    translated: true,
  });
};

export const getAnalyzerLocales = ({
  shouldTranslateTitle,
}: GetItemLocalesParam) => {
  const locales = getLocales({
    shouldTranslateTitle,
  });

  return locales.filter((item) => ANALYZER_IDS.includes(item.id));
};

export const getEmployeeLocales = ({
  shouldTranslateTitle,
}: GetItemLocalesParam) => {
  return getLocales({
    shouldTranslateTitle,
    employee: true,
  });
};
