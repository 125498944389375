import React, { useState } from 'react';

import escape from 'lodash/escape';

import { Button } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { t } from '@peakon/shared/features/i18next/t';

// eslint-disable-next-line no-restricted-syntax
export enum ConfirmationModalType {
  Revoke,
  RevokeAll,
  MakeAllPrivate,
}

export type ConfirmationModalProps = {
  modalType?: ConfirmationModalType;
  onConfirm?: () => void;
  onCancel?: () => void;
  isVisible: boolean;
  name?: string;
  testId?: string;
};

export const ConfirmationModal = ({
  modalType,
  onConfirm,
  onCancel,
  isVisible,
  name,
  testId,
}: ConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  // @ts-expect-error TS(2366): Function lacks ending return statement and return ... Remove this comment to see the full error message
  const getModalTitle = (): string => {
    switch (modalType) {
      case ConfirmationModalType.MakeAllPrivate:
        return t('share_settings__mark_all_private__header');
      case ConfirmationModalType.Revoke:
        return t('share_modal__revoke__header');
      case ConfirmationModalType.RevokeAll:
        return t('share_settings__revoke_all__header');
    }
  };

  // @ts-expect-error TS(2366): Function lacks ending return statement and return ... Remove this comment to see the full error message
  const getModalContent = (): string => {
    switch (modalType) {
      case ConfirmationModalType.MakeAllPrivate:
        return t('share_settings__mark_all_private__confirm');
      case ConfirmationModalType.Revoke:
        return t('share_modal__revoke__confirm', {
          replace: { name: escape(name) },
        });
      case ConfirmationModalType.RevokeAll:
        return t('share_settings__revoke_all__confirm');
    }
  };

  // @ts-expect-error TS(2366): Function lacks ending return statement and return ... Remove this comment to see the full error message
  const getModalConfirmLabel = (): string => {
    switch (modalType) {
      case ConfirmationModalType.MakeAllPrivate:
        return t('share_settings__mark_all_private__button');
      case ConfirmationModalType.Revoke:
        return t('share_modal__revoke__button');
      case ConfirmationModalType.RevokeAll:
        return t('share_settings__revoke_all__button');
    }
  };

  const handleCancel = (): void => {
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onCancel();
  };

  const handleConfirm = () => {
    setIsLoading(true);

    try {
      onConfirm?.();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      closeLabel={t('cancel')}
      heading={getModalTitle()}
      onDismiss={handleCancel}
      open={isVisible}
      data-test-id={testId}
    >
      <Modal.Content>{getModalContent()}</Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel} variant="secondary">
          {t('cancel')}
        </Button>
        <Button onClick={handleConfirm} variant="primary" busy={isLoading}>
          {getModalConfirmLabel()}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
