import React, { useEffect, useState } from 'react';

import { Grid } from '@peakon/components';

import { TranslationsType } from './queries/schema';
import { Translation } from './Translation';

export type TranslationsProps = {
  translations?: TranslationsType;
  onTranslationsChanged: (translations: TranslationsType) => void;
};

const parseTranslations = (originalTranslations?: TranslationsType) => {
  if (originalTranslations) {
    const parsedTranslations: Translation[] = [];
    Object.entries(originalTranslations).forEach((translation) =>
      parsedTranslations.push({
        translationId: translation[0],
        name: translation[1].name,
        description: translation[1].description,
      }),
    );

    parsedTranslations.push({ translationId: '', name: '', description: '' });
    return parsedTranslations;
  }
  return undefined;
};

export const Translations = ({
  translations,
  onTranslationsChanged,
}: TranslationsProps) => {
  const [currentTranslations, setCurrentTranslations] = useState<Translation[]>(
    parseTranslations(translations) ?? [
      { translationId: '', name: '', description: '' },
    ],
  );

  const handleOnTranslationAdded = (translation: Translation) => {
    const newTranslations = currentTranslations.slice(0, -1);
    newTranslations.push(translation);
    newTranslations.push({
      translationId: '',
      name: '',
      description: '',
    });
    setCurrentTranslations(newTranslations);
  };

  const handleOnTranslationChanged = (
    previousTranslationId: string,
    translation: Translation,
  ) => {
    const newTranslations = currentTranslations.slice();
    const index = newTranslations.findIndex(
      (it) => it.translationId === previousTranslationId,
    );
    newTranslations.splice(index, 1, translation);
    setCurrentTranslations(newTranslations);
  };

  const handleRemoveTranslation = (translationId: string) => {
    setCurrentTranslations(
      currentTranslations.filter((tr) => tr.translationId !== translationId),
    );
  };

  useEffect(() => {
    // transform data into key (translationId) value (name,description) object as this is how it is stored, not in the array as we use it here
    const transformed = currentTranslations
      .filter((it) => it.translationId)
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr.translationId]: {
            name: curr.name,
            description: curr.description,
          },
        }),
        {},
      );

    onTranslationsChanged(transformed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTranslations]);

  return (
    <Grid>
      {currentTranslations?.map((item: Translation) => (
        <Translation
          key={item.translationId}
          translation={item}
          onTranslationChanged={handleOnTranslationChanged}
          onTranslationAdded={handleOnTranslationAdded}
          alreadyUsedLanguageKeys={currentTranslations?.map(
            (it) => it.translationId,
          )}
          onRemove={handleRemoveTranslation}
        />
      ))}
    </Grid>
  );
};
