import React from 'react';

import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import z from 'zod';

export type FormProps<T extends z.Schema> = Omit<
  React.ComponentPropsWithRef<'form'>,
  'onError' | 'onSubmit'
> & {
  onSubmit: SubmitHandler<z.infer<T>>;
  onError?: SubmitErrorHandler<z.infer<T>>;
};

export function Form<T extends z.Schema>({
  children,
  onSubmit,
  onError,
  ...nativeFormProps
}: FormProps<T>) {
  const methods = useFormContext<T>();

  return (
    <form
      onSubmit={methods.handleSubmit(onSubmit, onError)}
      noValidate
      {...nativeFormProps}
    >
      {children}
    </form>
  );
}
