import { z } from 'zod';

import { validateData } from '../../utils/validateData/validateData';

export const localeIdsSchema = z.enum([
  'en',
  'en-GB',
  'en-US',
  'ar',
  'az',
  'bg',
  'bn',
  'ca',
  'cs',
  'da',
  'de',
  'el',
  'es',
  'es-LA',
  'et',
  'fi',
  'fo',
  'fr',
  'fr-FR',
  'fr-CA',
  'gu-IN',
  'he',
  'hi',
  'hr',
  'hu',
  'id',
  'is',
  'it',
  'ja',
  'kn',
  'ko',
  'lo',
  'lt',
  'lv',
  'mi',
  'mk',
  'ml',
  'mn',
  'mr',
  'ms',
  'my',
  'nl',
  'no',
  'pa-IN',
  'pl',
  'pt',
  'pt-PT',
  'pt-BR',
  'ro',
  'ru',
  'sk',
  'sl',
  'sr-CS',
  'sv',
  'ta',
  'te',
  'th',
  'tl',
  'tr',
  'uk',
  'ur',
  'vi',
  'vls-BE',
  'xh',
  'zh-CN',
  'zh-TW',
  'zu',
]);

export type ValidLocaleIds = z.infer<typeof localeIdsSchema>;

export function validateLocaleId(id: string) {
  return validateData(id, localeIdsSchema, {
    errorMessagePrefix: 'localeIdsSchema',
  });
}
