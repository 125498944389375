import { Schedule } from '@peakon/records';

const schedules = (
  state: Schedule | null = null,
  action: $TSFixMe,
): Schedule | null => {
  switch (action.type) {
    case 'SCHEDULE_QUESTION_LIMIT_REMOVED': {
      // @ts-expect-error TS(2740): Type 'Map<string, any>' is missing the following p... Remove this comment to see the full error message
      return state.set('questionLimit', null);
    }
    case 'SCHEDULE_UPDATE_SUCCESS': {
      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.mergeWith(
        (oldVal: $TSFixMe, newVal: $TSFixMe, key: $TSFixMe) => {
          if (key === 'context') {
            return oldVal;
          }

          return newVal;
        },
        // @ts-expect-error TS(2769): No overload matches this call.
        state,
        Schedule.createFromApi(action.data),
      );
    }
    case 'SCHEDULE_READ_SUCCESS': {
      return new Schedule(action.data);
    }
    case 'SCHEDULE_UPDATE_FREQUENCY': {
      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.set('frequency', action.data);
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default schedules;
