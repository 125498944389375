import React, { useState } from 'react';

import isNumber from 'lodash/isNumber';

import { Button } from '@peakon/bedrock/react/button';
import { InputField, Label } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

export type CustomValueSettingProps = {
  initial: number;
  min: number;
  max?: number;
  onCancel: () => void;
  onSet: (selected: number) => void;
  id?: string;
  'aria-describedby'?: string;
  'data-test-id'?: string;
};

export const CustomValueSetting = ({
  initial,
  min,
  max,
  onCancel,
  onSet,
  id,
  'aria-describedby': ariaDescribedBy,
  'data-test-id': dataTestId = 'custom-value-setting',
}: CustomValueSettingProps) => {
  const [currentValue, setCurrentValue] = useState(initial);

  const isInvalid =
    !isNumber(currentValue) ||
    currentValue < min ||
    (max !== undefined && currentValue > max);

  return (
    <div className={styles.customValueSettingRoot} data-test-id={dataTestId}>
      <Label htmlFor={id}>{t('custom_anonymity__set_custom')}</Label>
      <InputField
        min={min}
        max={max}
        // @ts-expect-error TS(2322): Type 'number' is not assignable to type '(string &... Remove this comment to see the full error message
        value={currentValue}
        inputType="number"
        onChange={(value) => setCurrentValue(Number(value))}
        id={id}
        aria-describedby={ariaDescribedBy}
      />
      <Button
        variant="primary"
        onClick={() => onSet(currentValue)}
        disabled={isInvalid}
      >
        {t('custom_frequency_form__set')}
      </Button>
      <Button variant="secondary" onClick={onCancel}>
        {t('cancel')}
      </Button>
    </div>
  );
};
