import { useMutation, useQueryClient } from '@tanstack/react-query';
import escape from 'lodash/escape';

import { TranslatedString, t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import { getAttributesQueryKeys } from './useGetAttributesQuery';
import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';

type UpdateAttributeParams = {
  id: string;
  isActive: boolean;
};

type Attribute = {
  attributes: {
    trueBenchmark: boolean;
    nameTranslated: TranslatedString;
  };
};

const updateAttribute = async ({
  id,
  isActive,
}: UpdateAttributeParams): Promise<Attribute> => {
  const body = {
    data: {
      id,
      type: 'attributes',
      attributes: {
        trueBenchmark: isActive,
      },
    },
  };

  // @ts-expect-error TS(2339): Property 'data' does not exist on type 'unknown'.
  const { data } = await api.patch(`/attributes/${id}`, null, body);
  return data;
};

const useTranslationMap = () => {
  const trueBenchmarksTranslationMap = {
    enabled: (nameTranslated: TranslatedString) =>
      t('true_benchmarks__attribute_enabled', {
        replace: { attribute: escape(nameTranslated) },
      }),
    disabled: (nameTranslated: TranslatedString) =>
      t('true_benchmarks__attribute_disabled', {
        replace: { attribute: escape(nameTranslated) },
      }),
  } as const;

  return { trueBenchmarksTranslationMap };
};

export const useUpdateAttributeMutation = () => {
  const { trueBenchmarksTranslationMap } = useTranslationMap();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: updateAttribute,
    onSuccess: ({ attributes: { nameTranslated, trueBenchmark } }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
      queryClient.invalidateQueries({
        queryKey: getAttributesQueryKeys.getAttributes(),
        exact: true,
      });

      dispatch(
        showSuccessNotification({
          message: trueBenchmark
            ? trueBenchmarksTranslationMap.enabled(nameTranslated)
            : trueBenchmarksTranslationMap.disabled(nameTranslated),
        }),
      );
    },
    onError: (error: Error) => {
      dispatch(catchHandler(error));
    },
  });
};
