import { Map, Record, fromJS, List } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import CategoryRecord from './CategoryRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  matches: z.any(),
  name: z.any(),
  size: z.any(),
  bundle: z.any(),
  mean: z.any(),
  summary: z.any(),
  driver: z.any(),
  count: z.any(),
  topic: z.any(),
  nps: z.any(),
  score: z.any(),
  locale: z.any(),
  category: z.any(),
  highlights: z.any(),
  group: z.any(),
});
type Schema = z.infer<typeof schema>;

class TopicRecord
  extends Record({
    bundle: undefined,
    category: undefined,
    count: 1,
    group: undefined,
    highlights: List(),
    id: undefined,
    locale: undefined,
    matches: undefined,
    mean: undefined,
    name: undefined,
    nps: Map(),
    score: undefined,
    summary: undefined,
    topic: undefined,
    translated: false,
    translatedName: undefined,
    translatedSummary: undefined,
  })
  implements Schema
{
  highlights: $TSFixMe;
  mean: $TSFixMe;
  nps: $TSFixMe;
  translatedName: $TSFixMe;
  translatedSummary: $TSFixMe;
  id!: Schema['id'];
  locale?: string;
  category?: CategoryRecord;
  name?: string;
  // @ts-expect-error - this overrides the underlying iterables `count()` method
  count?: number;
  topic?: unknown;
  group?: unknown;
  translated?: boolean;
  summary?: string;
  matches: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'TopicRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'TopicRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  isHighlighted() {
    return !this.highlights.isEmpty();
  }

  translate(name: $TSFixMe, summary: $TSFixMe) {
    return this.merge({
      translated: true,
      translatedName: name,
      translatedSummary: summary,
    });
  }

  isTranslated() {
    return (
      typeof this.translatedName !== 'undefined' ||
      typeof this.translatedSummary !== 'undefined'
    );
  }

  getScore(mode: $TSFixMe) {
    if (mode === 'nps') {
      return this.nps.get('score');
    }

    return this.mean;
  }

  get type() {
    return 'category';
  }

  static createFromApi(data: $TSFixMe) {
    const {
      relationships: { category },
    } = data;

    return new TopicRecord(
      fromJS({
        id: data.id,
        ...data.attributes,
        highlights: List(data.attributes.highlights),
        category: category ? CategoryRecord.createFromApi(category) : undefined,
      }),
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default TopicRecord;
