import React from 'react';

import { useQuery } from '@tanstack/react-query';

import { EmptyStateLock } from '@peakon/bedrock/illustrations/react/empty-state';
import { Button } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { Heading3, Paragraph } from '@peakon/bedrock/react/typography';
import { Modal } from '@peakon/components';
import jsonapiparser from '@peakon/jsonapiparser';
import { createTranslationMap } from '@peakon/shared/features/i18next/createTranslationMap';
import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import styles from './styles.css';

const HUMAN_PLAN_MAP = {
  essential: 'Essential',
  business: 'Business',
  premier: 'Premier',
} as const;

type AddOnData = {
  data: {
    attributes: {
      plan: keyof typeof HUMAN_PLAN_MAP;
      name: string;
    };
  };
};

type Props = {
  ctaLabel?: string;
  isOpen: boolean;
  onClose: () => void;
} & (
  | {
      feature: 'survey_edit_questions' | 'multiple_custom_drivers';
    }
  | {
      fetchAddOn: true;
      feature: 'collaboration_shareable';
    }
);

export const PaywallModal = ({
  ctaLabel,
  isOpen,
  onClose,
  ...restProps
}: Props) => {
  const { isInitialLoading, data } = useQuery({
    queryKey: ['addOn', restProps.feature],
    queryFn: () =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      api
        .get(`/add_ons/${restProps.feature}`)
        .then(jsonapiparser) as Promise<AddOnData>,
    enabled: 'fetchAddOn' in restProps,
    select: ({
      data: {
        attributes: { plan, name },
      },
    }) => ({ plan, name }),
  });

  const paywallTranslationMap =
    'fetchAddOn' in restProps
      ? null
      : createTranslationMap({
          survey_edit_questions: t('paywall__survey_edit_questions'),
          multiple_custom_drivers: t('paywall__multiple_custom_drivers'),
        } as const satisfies Record<typeof restProps.feature, string>);

  return (
    <Modal
      modalClassName={styles.root}
      initialFocus="[data-test-id='paywall-confirm']"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Content>
        <div className={styles.content}>
          <EmptyStateLock />
          {isInitialLoading ? (
            <div className={styles.loader}>
              <Spinner />
            </div>
          ) : (
            <div className={styles.titleContainer}>
              <Heading3 level={2}>
                {'fetchAddOn' in restProps
                  ? data &&
                    t('paywall__plan-upgrade', {
                      replace: {
                        humanFeature: data.name,
                        plan: HUMAN_PLAN_MAP[data.plan],
                      },
                    })
                  : paywallTranslationMap?.get(restProps.feature)}
              </Heading3>
            </div>
          )}

          <Paragraph>{t('paywall__contact_sales_v2')}</Paragraph>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className={styles.actions}>
          <div className={styles.button}>
            <Button
              fullWidth
              id="confirm-button"
              variant="primary"
              onClick={onClose}
              data-test-id="paywall-confirm"
            >
              {ctaLabel ?? t('paywall__confirm')}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
