import { APP_SUBDOMAIN } from '@peakon/shared/features/subdomains/constants';
import { getCurrentSubdomain } from '@peakon/shared/features/subdomains/getCurrentSubdomain';
import { getSubdomainHref } from '@peakon/shared/features/subdomains/getSubdomainHref';

export function redirectURI(path: $TSFixMe, { keepSubdomain = false } = {}) {
  const url = window.location.origin + path;
  const parsedUrl = new URL(url);
  const port = parsedUrl.port ? `:${parsedUrl.port}` : '';

  const currentSubdomain = getCurrentSubdomain();
  let href = `${parsedUrl.protocol}//${parsedUrl.hostname}${port}`;

  if (!keepSubdomain && currentSubdomain !== APP_SUBDOMAIN) {
    href = getSubdomainHref({
      href,
      newSubdomain: APP_SUBDOMAIN,
      currentSubdomain,
    });
  }

  return `${href}${parsedUrl.pathname}`;
}
