import React from 'react';

import { useController } from 'react-hook-form';
import { z } from 'zod';

import { Select as BRSelect } from '@peakon/bedrock/react/form';

import { ControlledProps, ZodSchemaOrRecord } from '../../utils/types';
import { useRequiredFields } from '../RequiredFieldsProvider';

type SelectFieldProps = React.ComponentPropsWithoutRef<typeof BRSelect>;

type Props<T extends ZodSchemaOrRecord> = ControlledProps<T, SelectFieldProps>;

export function Select<T extends ZodSchemaOrRecord = z.ZodRawShape>(
  props: Props<T>,
) {
  const { name, shouldUnregister, disabled, required, ...restSelectProps } =
    props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    shouldUnregister,
    disabled,
  });

  const requiredFields = useRequiredFields();
  const isRequired = Boolean(required ?? requiredFields?.includes(name));

  return (
    <BRSelect
      status={error && 'error'}
      feedbackMessage={error?.message}
      disabled={disabled}
      required={isRequired}
      {...restSelectProps}
      {...field}
    />
  );
}

Select.Option = BRSelect.Option;
