import { Record, Map } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

class FormEditorRecord
  extends Record({
    current: undefined,
    original: undefined,
  })
  implements Schema
{
  current: $TSFixMe;
  original: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'FormEditorRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'FormEditorRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  isEditing() {
    return typeof this.original !== 'undefined';
  }

  isDirty() {
    if (!this.isEditing()) {
      return false;
    }

    if (typeof this.current.equals === 'function') {
      return !this.current.equals(this.original);
    }

    return this.current !== this.original;
  }

  diff() {
    if (!this.current) {
      return Map();
    }

    return this.current.keySeq().reduce(
      (
        // @ts-expect-error no implicit any
        modified,
        // @ts-expect-error no implicit any
        key,
      ) => {
        const current = this.current.get(key);
        const original = this.original.get(key);

        return current !== original ? modified.set(key, current) : modified;
      },
      Map(),
    );
  }

  static startEditing(original: $TSFixMe) {
    return new FormEditorRecord({
      original,
      current: original,
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default FormEditorRecord;
