import { MOBILE_MAX_WIDTH } from '@peakon/shared/constants/breakpoints';

import { t } from '../../../../../features/i18next/t';
import { ProductName } from '../types';
import { isActive, getPath, getDefaultQueryParams } from '../utils';

const analysis: ProductName = 'analysis';

export const getAnalysisMenu = ({
  rights,
  contextId,
  categoryGroupWithDrivers,
  categoryGroup,
  screenWidth,
  features,
}: {
  rights: { [x: string]: string };
  contextId?: string;
  categoryGroupWithDrivers?: string | null;
  categoryGroup?: string | null;
  screenWidth: number;
  features: string[];
}) => {
  return {
    name: analysis,
    dataTestId: 'mpn-analysis',
    menuLabel: t('multi_product__analysis'),
    children: [
      {
        menuLabel: t('multi_product__analysis__menu_header__segments'),
        name: 'segments',
        children: [
          {
            name: 'heatMap',
            menuLabel: t('multi_product__analysis__menu_item__heat_map'),
            getPath: getPath({
              path: '/dashboard/segments',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(
                screenWidth > MOBILE_MAX_WIDTH &&
                  rights.analysis &&
                  rights.compare,
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/dashboard/group/:groupId/segment/:segmentId',
                ],
              });
            },
          },
          {
            name: 'cards',
            menuLabel: t('multi_product__analysis__menu_item__cards'),
            getPath: getPath({
              path: '/dashboard/segments/cards',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(
                rights.analysis &&
                  rights.compareEngagement &&
                  rights.segmentsCards,
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'attrition',
            menuLabel: t('multi_product__analysis__menu_item__attrition'),
            getPath: getPath({
              path: '/dashboard/segments/attrition',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(
                screenWidth > MOBILE_MAX_WIDTH &&
                  rights.analysis &&
                  rights.attrition &&
                  (rights.compareEngagement || rights.compare),
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
        ],
      },
      {
        menuLabel: t('multi_product__analysis__menu_header__data'),
        name: 'data',
        children: [
          {
            name: 'drivers',
            menuLabel: t('multi_product__analysis__menu_item__drivers'),
            getPath: getPath({
              path: `/dashboard/group/${categoryGroupWithDrivers}/drivers`,
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(
                rights.analysis && rights.dataset && categoryGroupWithDrivers,
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  '/dashboard/group/:group/drivers',
                  '/dashboard/segment/:segmentId',
                  '/dashboard/segment/:segmentId/driver/:driverId',
                  '/dashboard/driver/:driverId',
                  '/dashboard/driver/:driverId/subdriver/:subdriverId',
                ],
              });
            },
          },
          {
            name: 'questions',
            menuLabel: t('multi_product__analysis__menu_item__question_scores'),
            getPath: getPath({
              path: `/dashboard/group/${categoryGroup}/questions`,
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(
                rights.analysis && rights.questionOverview && categoryGroup,
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  '/dashboard/group/:group/questions',
                  '/dashboard/question/:questionId',
                  '/dashboard/segment/:segmentId/question/:id',
                ],
              });
            },
          },
        ],
      },
      {
        menuLabel: t('multi_product__analysis__menu_header__report'),
        name: 'report',
        children: [
          {
            name: 'employeeExperience',
            menuLabel: t(
              'multi_product__analysis__menu_item__employee_experience_cycle',
            ),

            getPath: getPath({
              path: '/dashboard/experience',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.insight && rights.employeeExperience);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/dashboard/experience/:experienceId',
                ],
              });
            },
          },
          {
            name: 'impact',
            menuLabel: t('multi_product__analysis__menu_item__impact'),
            getPath: getPath({
              path: '/dashboard/external_metrics',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.analysis && rights.externalMetricsReport);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'managerBehavior',
            menuLabel: t(
              'multi_product__analysis__menu_item__manager_usage_metrics',
            ),
            getPath: getPath({
              path: `/metrics/managers/${
                rights.action ? 'actions' : 'comments'
              }`,
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(
                !features.includes('newManagerMetrics') && rights.usageMetrics,
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/metrics/managers/:metricId',
                ],
              });
            },
          },
          {
            name: 'managerBehaviorNew',
            menuLabel: t('multi_product__analysis__menu_item__usage_metrics'),
            getPath: getPath({
              path: `/dashboard/metrics/`,
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(
                features.includes('newManagerMetrics') && rights.usageMetrics,
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/dashboard/metrics/',
                ],
              });
            },
          },
        ],
      },
    ],
  };
};
