import z from 'zod';

import { accountSchema } from './accounts';
import { companySchema } from './companies';
import { contextSchema } from './contexts';
import { employeeBaseSchema } from './employees';

// https://github.com/peakon/api/blob/master/libs/scopes/scope_group/index.js
const scopeGroup = [
  'all',
  'regular',
  'employee',
  'kiosk',
  'conversation',
  'mobile',
  'partnerConsultant',
  'proxyView',
  'priorityDriver',
] as const;
export const ScopeGroupEnum = z.enum(scopeGroup);

const status = ['skipped', 'required', 'completed'] as const;
export const ReasonStatusEnum = z.enum(status);
export const TwofactorStatusEnum = z.enum(status);

const sessionAccountSchema = accountSchema.merge(
  z.object({
    attributes: accountSchema.shape.attributes.omit({ lastSeenAt: true }),
  }),
);

export type TwoFactorStatus = z.infer<typeof TwofactorStatusEnum>;
export type ScopeGroup = z.infer<typeof ScopeGroupEnum>;
export type ReasonStatus = z.infer<typeof ReasonStatusEnum>;

const sessionRelationshipSchema = z.object({
  account: sessionAccountSchema,
  company: companySchema.optional(),
  employee: employeeBaseSchema
    .merge(
      z.object({
        attributes: employeeBaseSchema.shape.attributes.omit({
          createdAt: true,
          employmentStart: true,
          externalId: true,
          features: true,
          invitedToAdministerAt: true,
          isAnonymized: true,
          kioskCode: true,
          reportCount: true,
          source: true,
        }),
        relationships: z
          .object({
            account: z.object({
              id: z.string(),
            }),
          })
          .optional(),
      }),
    )
    .optional(),
});

export const sessionDataSchema = z.object({
  id: z.string(),
  type: z.literal('sessions'),
  attributes: z.object({
    address: z.string().nullable(),
    agent: z.object({
      browser: z.string().optional(),
      device: z.string().optional(),
      os: z.string().optional(),
      osVersion: z.string().optional(),
      version: z.string().optional(),
    }),
    createdAt: z.string(),
    current: z.boolean(),
    features: z.array(z.string()),
    mixpanel: z.object({ distinct_id: z.string() }).and(z.record(z.unknown())),
    lastUsedAt: z.string().nullable(),
    locale: z.string(),
    location: z.object({}).optional(),
    reasonStatus: ReasonStatusEnum,
    rights: z.array(z.string()),
    scopeGroup: ScopeGroupEnum,
    twofactorStatus: TwofactorStatusEnum,
  }),
  links: z
    .object({
      self: z.string(),
    })
    .optional(),
  relationships: sessionRelationshipSchema.optional(),
});

const shareSchema = z.object({
  attributes: z.object({
    access: z.array(z.string()),
    createdAt: z.string(),
    isPublic: z.boolean(),
    token: z.string(),
    url: z.string(),
  }),
  id: z.string(),
  relationships: z.object({
    context: z.object({
      data: z.object({
        id: z.string(),
        type: z.literal('contexts'),
      }),
    }),
  }),
  type: z.literal('shares'),
});

export const sharedDashboardSessionSchema = z.object({
  data: z.object({
    attributes: z.object({
      features: z.array(z.string()),
    }),
    data: z.object({
      id: z.string(),
      relationships: z.object({
        session: z
          .object({
            data: z.object({
              id: z.string(),
              type: z.literal('sessions'),
            }),
          })
          .optional(),
        source: z.object({
          data: z.object({
            id: z.string(),
            type: z.literal('shares'),
          }),
        }),
      }),
      type: z.literal('bearer_tokens'),
    }),
    included: z.array(z.object({})), // accounts, sessions, shares
  }),
  id: z.string(),
  relationships: z.object({
    session: sessionDataSchema
      .merge(
        z.object({
          relationships: sessionRelationshipSchema
            .merge(
              z.object({
                company: companySchema.pick({ id: true, type: true }),
                employee: employeeBaseSchema.pick({ id: true, type: true }),
              }),
            )
            .optional(),
        }),
      )
      .optional(),
    source: shareSchema.merge(
      z.object({
        relationships: shareSchema.shape.relationships.merge(
          z.object({
            context: contextSchema.pick({ id: true, type: true }),
          }),
        ),
      }),
    ),
  }),
  type: z.literal('bearer_tokens'),
});

export type SessionData = z.infer<typeof sessionDataSchema>;
