import { useEffect } from 'react';

import {
  TranslatedString,
  translatedStringSchema,
} from '../features/i18next/t';
import getPageTitle from '../utils/getPageTitle';

function usePageTitle(title: TranslatedString, { fallback = '' } = {}) {
  useEffect(() => {
    window.document.title = getPageTitle(title);

    return () => {
      window.document.title = getPageTitle(
        translatedStringSchema.parse(fallback),
      );
    };
  }, [title, fallback]);
}

// eslint-disable-next-line import/no-default-export
export default usePageTitle;
