import { useQuery } from '@tanstack/react-query';
import sortBy from 'lodash/sortBy';

import jsonapiparser from '@peakon/jsonapiparser';
import { TranslatedString } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import { catchHandler } from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';

const TRUE_BENCHMARKS_ATTRIBUTES = [
  'age',
  'department',
  'gender',
  'level',
  'tenure',
  'office',
];

const GET_ATTRIBUTES_PARAMS = {
  filter: {
    status: 'active',
    comparisonAccess: 'unrestricted',
  },
  fields: {
    attributes: 'name,nameTranslated,standard,trueBenchmark',
  },
};

type Attribute = {
  id: string;
  attributes: {
    standard: string;
    trueBenchmark: boolean;
    nameTranslated: TranslatedString;
  };
};

export const getAttributesQueryKeys = {
  getAttributes: () => ['data_settings__true_benchmark'] as const,
};

const getAttributes = async (): Promise<Attribute[]> => {
  const response = await api.get('/attributes', GET_ATTRIBUTES_PARAMS);
  return jsonapiparser(response)?.data;
};

export const useGetAttributesQuery = () => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: getAttributesQueryKeys.getAttributes(),
    queryFn: getAttributes,
    placeholderData: [],
    select: (data) => {
      if (!data) {
        return [];
      }

      return sortBy(
        data.filter((attribute) =>
          TRUE_BENCHMARKS_ATTRIBUTES.includes(attribute.attributes.standard),
        ),
        'attributes.name',
      );
    },
    onError: (error: Error) => {
      dispatch(catchHandler(error));
    },
  });
};
