import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

export type UxFlowEntity = 'account' | 'employee' | 'company';

const schema = z.object({
  name: z.string(),
  completed: z.boolean(),
});
const testingSchema = schema.extend({
  entity: z.any(),
});
type Schema = z.infer<typeof schema>;

class UxFlowRecord
  extends Record({
    id: undefined,
    entity: undefined,
    name: undefined,
    completed: false,
  })
  implements Schema
{
  entity?: UxFlowEntity;
  name!: Schema['name'];
  completed!: Schema['completed'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'UxFlowRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'UxFlowRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get id() {
    if (!this.entity || !this.name) {
      throw Error(`Invalid uxflow ${!this.entity ? 'entity' : 'name'}`);
    }

    return UxFlowRecord.id(this.entity, this.name);
  }

  static id(entity: UxFlowEntity, name: string) {
    return `${entity}-${name}`;
  }
}

// eslint-disable-next-line import/no-default-export
export default UxFlowRecord;
