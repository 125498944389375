import React, { forwardRef } from 'react';

import styles from '../styles.css';

type Props = {
  isSelected?: boolean;
  t: $TSFixMeFunction;
};

const EmptyNotification = forwardRef<HTMLDivElement, Props>(
  ({ t, isSelected: _, ...props }, ref) => {
    return (
      <div {...props} ref={ref} className={styles.empty}>
        <div className={styles.emptyTitle}>
          {t('multi_product__notifications__dropdown__none')}
        </div>
      </div>
    );
  },
);

EmptyNotification.displayName = 'EmptyNotification';

// eslint-disable-next-line import/no-default-export
export default EmptyNotification;
