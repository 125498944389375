import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import SegmentRecord from '../SegmentRecord';
import { validateTestingSchema } from '../utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

class SegmentActivityRecord
  extends Record({
    id: undefined,
    daysActive: undefined,
    lastActivity: undefined,
    totalManagers: undefined,

    segment: undefined,
  })
  implements Schema
{
  id!: Schema['id'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'SegmentActivityRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'SegmentActivityRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const {
      attributes,
      relationships: { segment },
    } = data;

    return new SegmentActivityRecord(
      fromJS({
        id: segment.id,
        ...attributes,
        segment: SegmentRecord.createFromApi(segment),
      }),
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default SegmentActivityRecord;
