import { useQuery } from '@tanstack/react-query';

import { jsonapiparser } from '@peakon/shared/utils';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { valuesResponse } from './schema';

export type UseValueParams = {
  onError: (error: Error) => void;
};

const getValues = async () => {
  const params = {
    fields: {
      questions: [
        'status',
        'context',
        'includedSegments',
        'excludedSegments',
      ].join(','),

      values: ['translations', '+'].join(','),
    },
    include: [
      'question',
      'question.context',
      'question.excludedSegments',
      'question.excludedSegments.attribute',
      'question.includedSegments',
      'question.includedSegments.attribute',
    ].join(','),
  };

  const response = await api.get('/values', params);

  return validateData(jsonapiparser(response), valuesResponse, {
    errorMessagePrefix: 'valuesResponse',
  });
};

const useValues = ({ onError }: UseValueParams) => {
  return useQuery({
    queryKey: ['admin_values'],
    queryFn: getValues,
    select: (data) => data.data,
    onError,
  });
};

// eslint-disable-next-line import/no-default-export
export default useValues;
