import isArray from 'lodash/isArray';
import merge from 'lodash/merge';

import { SegmentType, TranslationsType } from './queries/schema';

export const transformToApiObject = (
  includedSegments: SegmentType[],
  excludedSegments: SegmentType[],
  name: string,
  description: string,
  translations: TranslationsType,
  status?: string,
) => {
  return (isCreate: boolean) => {
    let relationships = {};

    if (isArray(includedSegments) && includedSegments.length > 0) {
      relationships = merge(relationships, {
        includedSegments: {
          data: includedSegments.map((segment) => ({
            type: 'segments' as const,
            id: segment.id,
          })),
        },
      });
    } else {
      relationships = merge(relationships, {
        includedSegments: {
          data: null,
        },
      });
    }

    if (isArray(excludedSegments) && excludedSegments.length > 0) {
      relationships = merge(relationships, {
        excludedSegments: {
          data: excludedSegments.map((segment) => ({
            type: 'segments' as const,
            id: segment.id,
          })),
        },
      });
    } else {
      relationships = merge(relationships, {
        excludedSegments: {
          data: null,
        },
      });
    }

    return {
      type: 'values' as const,
      attributes: {
        name,
        description,
        translations,
      },
      relationships: {
        question: {
          data: {
            type: 'questions' as const,
            attributes: {
              status: isCreate ? 'active' : status,
            },
            relationships,
          },
        },
      },
    };
  };
};
