import React from 'react';

import { ScoresFlagsPriorityMcpDiscIcon as PriorityIcon } from '@peakon/bedrock/icons/graphic';
import { Heading4 } from '@peakon/bedrock/react/typography';
import { StackH } from '@peakon/bedrock/react-alpha/stack';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  segmentName?: string;
};

export function TeamSuggestionCardHeader({ segmentName }: Props) {
  return (
    <div className={styles.cardHeader}>
      <StackH spacing="04" alignBlock="center">
        <PriorityIcon width={32} height={32} />
        <Heading4 level={4}>
          {t('focus_area__card__header_title', {
            replace: { segmentName },
          })}
        </Heading4>
      </StackH>
    </div>
  );
}
