import React, { useState } from 'react';

import {
  NavigationBasicNavigationChevronDownIcon as ArrowExpand,
  NavigationBasicNavigationChevronUpIcon as ArrowCollapse,
} from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { Card } from '@peakon/bedrock/react/card';
import {
  Grid,
  InputField,
  Spinner,
  TextArea,
  Typography,
} from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import { SegmentType, TranslationsType, ValueType } from './queries/schema';
import useCreateOrUpdateValue from './queries/useCreateOrUpdateValue';
import { Translations } from './Translations';
import { transformToApiObject } from './ValueHelper';
import { catchHandler } from '../../../actions/NotificationActions';
import { SegmentsPickerInput } from '../../../components';
import { useAppDispatch } from '../../../utils/reduxHooks';

import styles from './styles.css';

export type ValueEditProps = {
  valueData?: ValueType;
  isCreating: boolean;
  onCancel: () => void;
  onCreateOrUpdate?: () => void;
  onDelete?: () => void;
};
export const ValueEdit = ({
  valueData,
  isCreating,
  onCancel,
  onCreateOrUpdate,
  onDelete,
}: ValueEditProps) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState(valueData?.name ?? '');
  const [description, setDescription] = useState(valueData?.description ?? '');
  const [isTranslationListExpanded, setIsTranslationListExpanded] =
    useState(false);
  const [includedSegments, setIncludedSegments] = useState<SegmentType[]>(
    valueData?.question?.includedSegments ?? [],
  );

  const [translations, setTranslations] = useState(
    valueData?.translations ?? null,
  );
  const [excludedSegments, setExcludedSegments] = useState<SegmentType[]>(
    valueData?.question?.excludedSegments ?? [],
  );

  const [numberOfTranslations, setNumberOfTranslations] = useState(
    valueData?.translations ? Object.keys(valueData.translations).length : 0,
  );

  const [isAdvancedExpanded, setIsAdvancedExpanded] = useState<boolean>(
    includedSegments.length > 0 || excludedSegments.length > 0,
  );

  const handleOnExcludedRemove = (item: string) => {
    setExcludedSegments(excludedSegments.filter((it) => it.id !== item));
  };

  const handleOnIncludedRemove = (item: string) => {
    setIncludedSegments(includedSegments.filter((it) => it.id !== item));
  };

  const handleTranslationsChanged = (updatedTranslations: TranslationsType) => {
    setNumberOfTranslations(
      updatedTranslations ? Object.keys(updatedTranslations).length : 0,
    );
    setTranslations(updatedTranslations);
  };

  const { mutateAsync: createOrUpdateValue, isLoading } =
    useCreateOrUpdateValue({
      onError: (error) => {
        dispatch(catchHandler(error));
      },
    });

  const constructValueApiObject = transformToApiObject(
    includedSegments,
    excludedSegments,
    name,
    description,
    translations,
    valueData?.question.status,
  );

  const handleOnCreateOrUpdate = async () => {
    const valueObject = constructValueApiObject(isCreating);

    const values = {
      ...valueObject,
      isCreate: isCreating,
      valueId: isCreating ? undefined : valueData?.id,
    };

    await createOrUpdateValue(values);
    onCreateOrUpdate?.();
  };

  return (
    <Card>
      {isCreating && (
        <div className={styles.valueEditTitle}>
          {t('values__value__form__header')}
        </div>
      )}
      <div className={styles.container}>
        <Grid className={styles.rootEdit}>
          <div className={styles.valueEditMain}>
            <Grid.Row>
              <Grid.Col sm={4}>
                <InputField
                  label={t('values__value__name')}
                  value={name}
                  onChange={setName}
                  maxLength={256}
                  placeholder={t('values__value__name_placeholder')}
                />
              </Grid.Col>
              <Grid.Col sm={8}>
                <TextArea
                  value={description}
                  onChange={setDescription}
                  label={t('values__value__description')}
                  maxLength={4096}
                  placeholder={t('values__value__description_placeholder')}
                />
              </Grid.Col>
            </Grid.Row>
          </div>
          <Grid.Row>
            <Grid.Col sm={12}>
              <Typography.Label>
                {t('sendouts__drivers__localization')}
              </Typography.Label>
            </Grid.Col>
            <Grid.Col sm={12}>
              <div className={styles.toggleTranslations}>
                {numberOfTranslations ? (
                  <span>
                    {t('sendouts__drivers__translations', {
                      replace: {
                        count: numberOfTranslations,
                      },
                    })}
                  </span>
                ) : (
                  <span>{t('sendouts__drivers__no_localizations')}</span>
                )}
                <div className={styles.toggleTranslationsButton}>
                  {isTranslationListExpanded ? (
                    <React.Fragment>
                      <Button
                        size="small"
                        variant="tertiary"
                        onClick={() => setIsTranslationListExpanded(false)}
                      >
                        - {t('survey_values__hide_translations')}
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      size="small"
                      variant="tertiary"
                      onClick={() => setIsTranslationListExpanded(true)}
                    >
                      + {t('survey_values__add_translations')}
                    </Button>
                  )}
                </div>
              </div>
              {isTranslationListExpanded && (
                <Translations
                  translations={valueData?.translations}
                  onTranslationsChanged={handleTranslationsChanged}
                />
              )}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col sm={12}>
              <div className={styles.advancedToggler}>
                <Button
                  icon={
                    isAdvancedExpanded ? <ArrowCollapse /> : <ArrowExpand />
                  }
                  variant="tertiary"
                  size="small"
                  onClick={() => setIsAdvancedExpanded(!isAdvancedExpanded)}
                >
                  {t('questions__form__advanced')}
                </Button>
                <hr className={styles.ruler} />
              </div>
            </Grid.Col>
          </Grid.Row>
          {isAdvancedExpanded && (
            <div className={styles.advancedContainer}>
              <Grid.Row>
                <Grid.Col sm={4}>
                  <Typography.Label>
                    {t('questions_form__target')}
                  </Typography.Label>
                  <div>{t('schedules__company')}</div>
                </Grid.Col>

                <Grid.Col sm={4}>
                  <Typography.Label>
                    {t('questions_form__filtered_to')}
                  </Typography.Label>
                  <SegmentsPickerInput
                    included
                    placeholder={t('questions_form__including__placeholder')}
                    selected={includedSegments}
                    onSelect={setIncludedSegments}
                    onRemove={handleOnIncludedRemove}
                  />
                </Grid.Col>

                <Grid.Col sm={4}>
                  <Typography.Label>
                    {t('questions_form__excluding')}
                  </Typography.Label>
                  <SegmentsPickerInput
                    excluded
                    placeholder={t('questions_form__excluding__placeholder')}
                    selected={excludedSegments}
                    onSelect={setExcludedSegments}
                    onRemove={handleOnExcludedRemove}
                  />
                </Grid.Col>
              </Grid.Row>
            </div>
          )}

          <Grid.Row>
            <div
              className={
                isCreating
                  ? styles.editValueCreateFooter
                  : styles.editValueFooter
              }
            >
              {!isCreating && (
                <Button variant="warning" onClick={onDelete}>
                  {t('delete')}
                </Button>
              )}
              <div className={styles.footerCancelCreateButtons}>
                <Button variant="tertiary" onClick={onCancel}>
                  {t('values__form__cancel')}
                </Button>

                <Button variant="primary" onClick={handleOnCreateOrUpdate}>
                  {isLoading ? (
                    <Spinner color="white" />
                  ) : isCreating ? (
                    t('values__form__create')
                  ) : (
                    t('values__form__update')
                  )}
                </Button>
              </div>
            </div>
          </Grid.Row>
        </Grid>
      </div>
    </Card>
  );
};
