import jsonapiparser from '@peakon/jsonapiparser';
import { Category, Question } from '@peakon/records';
import api from '@peakon/shared/utils/api';

import { currentContext } from '../selectors/ContextSelectors';
import { getDatasetParams } from '../selectors/SessionSelectors';
import { Dispatch, GetState } from '../types/redux';
import { asyncDispatch } from '../utils';

export const QUESTION_FIELDS = {
  fields: {
    questions: Question.defaultFields.join(','),
    categories: Category.defaultFields.join(','),
  },

  include: [
    'question.category',
    'question.category.parentCategory',
    'category.parentCategory',
  ].join(','),
};

export const getQuestionOverview =
  (id: string, params = {}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const dashboardContext = currentContext(state);

    const datasetParams = getDatasetParams(state);

    return asyncDispatch({
      dispatch,
      resource:
        // @ts-expect-error Property 'changes' / 'observations' does not exist on type '{}'.ts(2339)
        !params.changes && params.observations
          ? 'QUESTION_OBSERVATIONS_READ'
          : 'QUESTION_OVERVIEW_READ',
      data: { id, uuid: `questionOverview.${id}` },
      action: api
        .get(
          `/scores/contexts/${dashboardContext.id}/question/${id}`,
          datasetParams({
            ...QUESTION_FIELDS,
            ...params,
          }),
        )
        .then(jsonapiparser),
    });
  };

export const getQuestionsScores =
  (group = 'engagement') =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const dashboardContext = currentContext(state);

    const datasetParams = {
      ...getDatasetParams(state)(QUESTION_FIELDS),
      // The old /engagement/ endpoint always gives you all questions in the group, including subcategory questions
      // The new /scores/ endpoint will only give you subcategory questions if you ask for them explicitly, for
      // consistency with the score by category/group endpoints.
      // Just add the parameter regardless:
      includeSubcategories: true,
    };

    return asyncDispatch({
      dispatch,
      resource: 'ENGAGEMENT/QUESTIONS_LIST',
      data: { group },
      action: api
        .get(
          `/scores/contexts/${dashboardContext.id}/questions/group/${group}`,
          datasetParams,
        )
        .then(jsonapiparser),
    });
  };

export const getCategoryQuestions =
  (categoryId: string) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const dashboardContext = currentContext(state);

    const datasetParams = getDatasetParams(state)({
      include: 'question',
      fields: { questions: 'text' },
      includeSubcategories: true,
    });

    return asyncDispatch({
      dispatch,
      resource: 'ENGAGEMENT/CATEGORY_QUESTIONS_LIST',
      action: api
        .get(
          `/scores/contexts/${dashboardContext.id}/questions/category/${categoryId}`,
          datasetParams,
        )
        .then(jsonapiparser)
        .then((response) =>
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          response.data.map(
            (engagementQuestion: $TSFixMe) =>
              engagementQuestion.relationships.question,
          ),
        ),
    });
  };
