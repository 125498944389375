import { combineReducers } from '@reduxjs/toolkit';

import overview from './overview';
import segmentActionManagers from './segmentActionManagers';
import segmentActions from './segmentActions';
import segmentActivity from './segmentActivity';
import segmentActivityManagers from './segmentActivityManagers';
import segmentCommentManagers from './segmentCommentManagers';
import segmentComments from './segmentComments';
import segmentTables from './segmentTables';

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  overview,
  segmentActionManagers,
  segmentActions,
  segmentCommentManagers,
  segmentComments,
  segmentActivity,
  segmentActivityManagers,
  segmentTables,
});
