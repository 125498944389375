import { combineReducers } from '@reduxjs/toolkit';

import brandings from './brandings';
import form from './form';
import createForm from '../utils/createForm';

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  brandings,
  form: createForm(form, {
    persistAndReset: ['BRANDING_GET_SUCCESS', 'BRANDING_UPDATE_SUCCESS'],
    reset: 'BRANDING_FORM_RESET',
  }),
});
