import z from 'zod';

const translationSchema = z.object({
  name: z.string(),
  description: z.string(),
});

const translationsSchema = z.record(z.string(), translationSchema).nullable();

export type TranslationType = z.infer<typeof translationSchema>;
export type TranslationsType = z.infer<typeof translationsSchema>;

// not fully defined, only proprerties directly used in Values area
const segmentSchema = z.object({
  id: z.string(),
});

export type SegmentType = z.infer<typeof segmentSchema>;

const questionSchema = z.object({
  id: z.string(),
  excludedSegments: z.array(segmentSchema).optional(),
  includedSegments: z.array(segmentSchema).optional(),
  status: z.enum(['active', 'inactive']),
});

const valueSchema = z.object({
  id: z.string(),
  _type: z.literal('values'),
  name: z.string(),
  description: z.string(),
  translations: translationsSchema,
  question: questionSchema,
});

export type ValueType = z.infer<typeof valueSchema>;

export const valuesResponse = z.object({
  data: z.array(valueSchema),
});
