const toggleSupportWidget = (isVisible: boolean) => {
  try {
    if (window.zE) {
      isVisible
        ? window.zE('webWidget', 'show')
        : window.zE('webWidget', 'hide');
    }
  } catch {
    // noop
  }
};

export const showSupportWidget = () => toggleSupportWidget(true);

export const hideSupportWidget = () => toggleSupportWidget(false);
