import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';

import { currentContext } from '../selectors/ContextSelectors';
import { GetState, Dispatch } from '../types/redux';
import { asyncDispatch } from '../utils';

const DEFAULT_PARAMS = {
  fields: {
    categories: [
      'description',
      'descriptionTranslated',
      'descriptionTranslations',
      'group',
      'legacyDriver',
      'legacySubdriver',
      'main',
      'name',
      'nameTranslated',
      'nameTranslations',
      'parentCategory',
      'standard',
    ].join(','),
  },

  include: 'parentCategory',
};

export const fetchCategories = (
  context: $TSFixMe,
  params: { [x: string]: $TSFixMe },
  { isAdmin = false } = {},
) => {
  return api
    .get(
      isAdmin ? '/categories' : `/categories/contexts/${context.id}`,
      pickBy(params, identity),
    )
    .then(jsonapiparser);
};

export const listCategories =
  (group: $TSFixMe, options: $TSFixMe = {}, context?: $TSFixMe) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const dashboardContext = context || currentContext(state);

    const params = {
      ...options,
      ...DEFAULT_PARAMS,
    };

    if (group) {
      params['filter[group]'] = group;
    }

    return asyncDispatch({
      dispatch,
      resource: 'CATEGORY_LIST',
      data: options,
      action: fetchCategories(dashboardContext, params, options),
    });
  };

export const getCategoryFromLegacy =
  (driverId: string, subdriverId: string) => (dispatch: Dispatch) =>
    dispatch(
      listCategories(null, {
        filter: {
          legacyDriver: driverId,
          // stringifying null here because qs.stringify
          // would otherwise strip it and send empty value
          legacySubdriver: subdriverId || 'null',
        },
      }),
    );

export const getCategory = (id: string) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'CATEGORY_GET',
    action: api
      .get(`/categories/${id}`, {
        fields: {
          categories: [
            'descriptionTranslated',
            'group',
            'main',
            'nameTranslated',
            'parentCategory',
            'standard',
            'theory',
            'legacyDriver',
            'legacySubdriver',
          ].join(','),
        },

        include: 'parentCategory',
      })
      .then((response) => jsonapiparser(response)),
  });

export const createCategory =
  (body: $TSFixMe, { showNotification = true } = {}) =>
  (dispatch: Dispatch) =>
    asyncDispatch({
      dispatch,
      resource: 'CATEGORY_CREATE',
      showNotification,
      action: api
        .post('/categories', DEFAULT_PARAMS, body)
        .then((response) => jsonapiparser(response)),
    });

export const updateCategory =
  (id: string, body: $TSFixMe) => (dispatch: Dispatch) =>
    asyncDispatch({
      dispatch,
      resource: 'CATEGORY_UPDATE',
      data: { id },
      action: api
        .patch(`/categories/${id}`, DEFAULT_PARAMS, body)
        .then((response) => jsonapiparser(response)),
    });

export const deleteCategory = (id: string) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'CATEGORY_DELETE',
    data: { id },
    action: api.delete(`/categories/${id}`),
  });

export const flushCategories = () => ({
  type: 'CATEGORY_FLUSH',
});
