import React from 'react';

import { Spinner } from '@peakon/bedrock/react/spinner';

import styles from './styles.css';

const AdminSpinner = () => {
  return (
    <div className={styles.spinner}>
      <Spinner size="48" />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default AdminSpinner;
