// eslint-disable-next-line no-restricted-imports
import mixpanel from 'mixpanel-browser';
import { z } from 'zod';

import env from '../../utils/env';
import { validateData } from '../../utils/validateData/validateData';

let isMixpanelInitialized = false;

// this function only exists to help the test reset the state of this variable. Don't call from actual code
export const resetIsMixpanelInitialized = () => {
  isMixpanelInitialized = false;
};

/**
 * Initialize Mixpanel
 * @param application The application in which the events will be sent from. We will enrich every event with that information.
 */
const init = (
  application:
    | 'manager-dashboard'
    | 'employee-dashboard'
    | 'shareable-dashboard',
) => {
  if (env.mixpanel?.enabled) {
    mixpanel.init(env.mixpanel.projectToken, {
      api_host: env.mixpanel.host,
      disable_persistence: true,
      ip: false,
      batch_requests: false,
      property_blacklist: [
        '$referrer',
        '$initial_referrer',
        '$current_url',
        'current_page_title',
        'current_url_search',
        'current_url_path',
      ],
    });

    isMixpanelInitialized = true;
    mixpanel.register({ application }, { persistent: false });
  }
};
/**
 * Set enrichment data to be included in all subsequent events.
 */
const setEnrichmentData = (enrichmentData: Record<string, unknown>) => {
  if (isMixpanelInitialized) {
    mixpanel.register(enrichmentData, { persistent: false });
  }
};

/**
 * Identify the current user, and ensure the analytics enrichment data will be included in subsequent events.
 */
const identifyUser = (
  analyticsEnrichmentData: {
    distinct_id: string;
  } & Record<string, unknown>,
) => {
  if (isMixpanelInitialized) {
    const { distinct_id: distinctId } = analyticsEnrichmentData;
    mixpanel.identify(distinctId);
    setEnrichmentData(analyticsEnrichmentData);
  }
};

/**
 * Clears any enrichment data sent with events and un-identifies the user.
 */
const clearEnrichmentData = () => {
  if (isMixpanelInitialized) {
    mixpanel.reset();
  }
};

/**
 * Time an event by including the time between this call and a later `analytics.track(eventName)` call for the same event in the properties sent with the event.
 */
const timeEvent = (eventName: string) => {
  validateData(eventName, snakeCaseSchema, {
    errorMessagePrefix: 'snakeCaseSchema',
  });
  if (isMixpanelInitialized) {
    mixpanel.time_event(eventName);
  }
};

/**
 * Track an event
 */
const track = (eventName: string, properties?: Record<string, unknown>) => {
  validateData(eventName, snakeCaseSchema, {
    errorMessagePrefix: 'snakeCaseSchema',
  });
  if (isMixpanelInitialized) {
    mixpanel.track(eventName, {
      ...properties,
      viewport_height: window.innerHeight,
      viewport_width: window.innerWidth,
    });
  }
};

/**
 * Track a page view. Should **not** be used outside of `useTrackPageViews`.
 */
export const trackPageView = (properties?: Record<string, unknown>) => {
  if (isMixpanelInitialized) {
    mixpanel.track_pageview({
      ...properties,
      viewport_height: window.innerHeight,
      viewport_width: window.innerWidth,
    });
  }
};

const snakeCaseSchema = z.custom<string>((value) => {
  const isSnakeCase = /^[a-z]+(_?(_)[a-z]+)+$/.test(value);
  return typeof value === 'string' && isSnakeCase;
}, 'Please use snake_case event names');

export const analytics = {
  init,
  identifyUser,
  setEnrichmentData,
  clearEnrichmentData,
  timeEvent,
  track,
};
