import React, { Fragment, useState } from 'react';

import { NavigationNavigationLevel1Monochrome05MetricsIcon as FeedbackIcon } from '@peakon/bedrock/icons/system';
import { Alert } from '@peakon/bedrock/react/alert';
import { Button } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { RadioButton } from '@peakon/bedrock/react/form';
import { Checkbox, TextArea } from '@peakon/components';
import { AsyncButton } from '@peakon/shared/components/AsyncButton/AsyncButton';
import { t } from '@peakon/shared/features/i18next/t';

import {
  ModalTranslationKeys,
  getFooterTextKey,
  getTranslationsObjectByType,
} from './translationsHelper';

import styles from './styles.css';

export const FeedbackType = {
  SemanticSearch: 'SemanticSearch',
  SemanticTopics: 'SemanticTopics',
} as const;

type CommentFeedbackProps = {
  isRelevant?: boolean;
  isBiased?: boolean;
  biasedComment?: string;
  isFeedbackProvided: boolean;
  sendFeedback: (
    isRelevant: boolean,
    isBiased: boolean,
    biasedComment: string,
  ) => Promise<boolean>;
  feedbackType: (typeof FeedbackType)[keyof typeof FeedbackType];
};

// eslint-disable-next-line no-restricted-syntax
enum RadioButtonRelevancy {
  Relevant = '0',
  NotRelevant = '1',
}

export const CommentFeedback = ({
  isRelevant,
  isBiased,
  biasedComment,
  isFeedbackProvided: isFeedbackProvidedProps,
  sendFeedback,
  feedbackType = FeedbackType.SemanticSearch,
}: CommentFeedbackProps): JSX.Element => {
  const commentMaxLength = 500;

  const [commentRelevancy, setCommentRelevancy] = useState(
    isRelevant === undefined || isRelevant === true
      ? RadioButtonRelevancy.Relevant
      : RadioButtonRelevancy.NotRelevant,
  );

  const [comment, setComment] = useState(biasedComment ?? '');
  const [isBiasedResult, setIsBiasedResult] = useState(isBiased || false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFeedbackProvided, setIsFeedbackProvided] = useState(
    isFeedbackProvidedProps,
  );

  const submitFeedback = async () => {
    setIsSubmitting(true);

    if (
      await sendFeedback(
        commentRelevancy === RadioButtonRelevancy.Relevant,
        isBiasedResult,
        comment,
      )
    ) {
      setIsDialogOpen(false);
    }
    setIsFeedbackProvided(true);
    setIsSubmitting(false);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    // reset dialog states
    setCommentRelevancy(
      isRelevant === undefined || isRelevant === true
        ? RadioButtonRelevancy.Relevant
        : RadioButtonRelevancy.NotRelevant,
    );
    setIsBiasedResult(isBiased || false);
    setComment(biasedComment ?? '');
  };

  const translations = getTranslationsObjectByType(feedbackType);

  const footerTextKey = getFooterTextKey(
    feedbackType,
    isFeedbackProvided,
    commentRelevancy === RadioButtonRelevancy.Relevant,
    isBiasedResult,
  );

  return (
    <Fragment>
      <div
        className={styles.resultFeedback}
        data-test-id="comment-feedback-result"
      >
        <div data-test-id="comment-feedback-label">{footerTextKey}</div>
        <Button
          size="small"
          onClick={() => setIsDialogOpen(true)}
          variant="secondary"
          icon={<FeedbackIcon height={16} width={16} />}
          data-test-id="comment-feedback-button"
        >
          {isFeedbackProvided
            ? translations[ModalTranslationKeys.CommentButtonChange]
            : translations[ModalTranslationKeys.CommentButton]}
        </Button>
      </div>

      <Modal
        heading={translations[ModalTranslationKeys.ModalTitle]}
        description={translations[ModalTranslationKeys.ModalSubTitle]}
        open={isDialogOpen}
        onDismiss={() => {
          setIsDialogOpen(false);
        }}
        closeLabel={t('common__close')}
        data-test-id="comment-feedback-modal"
      >
        <Modal.Content>
          <Fragment>
            <div className={styles.alert}>
              <Alert variant="info" withIcon={false}>
                <Alert.Paragraph>
                  {translations[ModalTranslationKeys.DialogInfo]}
                </Alert.Paragraph>
              </Alert>
            </div>
            <fieldset disabled={isSubmitting} className={styles.fieldset}>
              <legend className={styles.legend}>
                {translations[ModalTranslationKeys.DialogRelevancySubTitle]}
              </legend>

              <RadioButton
                label={translations[ModalTranslationKeys.RadioButtonRelevant]}
                name="comment-feedback-relevancy-radio"
                data-test-id="comment-feedback-relevant-radio"
                value={RadioButtonRelevancy.Relevant}
                checked={commentRelevancy === RadioButtonRelevancy.Relevant}
                onChange={(event) =>
                  setCommentRelevancy(
                    // @ts-expect-error - Argument of type 'string' is not assignable to parameter of type 'SetStateAction<RadioButtonRelevancy>'.
                    event.target.value,
                  )
                }
              />

              <RadioButton
                label={
                  translations[ModalTranslationKeys.RadioButtonNotRelevant]
                }
                name="comment-feedback-relevancy-radio"
                disabled={isSubmitting}
                data-test-id="comment-feedback-not-relevant-radio"
                value={RadioButtonRelevancy.NotRelevant}
                checked={commentRelevancy === RadioButtonRelevancy.NotRelevant}
                onChange={(event) =>
                  setCommentRelevancy(
                    // @ts-expect-error - Argument of type 'string' is not assignable to parameter of type 'SetStateAction<RadioButtonRelevancy>'.
                    event.target.value,
                  )
                }
              />
            </fieldset>
            <div className={styles.divider} />

            <Checkbox
              className={styles.checkbox}
              checked={isBiasedResult}
              onChange={() => {
                if (isBiasedResult) {
                  setComment('');
                }
                setIsBiasedResult(!isBiasedResult);
              }}
              testId="comment-feedback-biased-checkbox"
              disabled={isSubmitting}
            >
              {translations[ModalTranslationKeys.CheckboxIsBiased]}
            </Checkbox>

            <div className={styles.commentDetails}>
              <div>{translations[ModalTranslationKeys.TextBoxBiasedLabel]}</div>
              <div>{`${comment?.length}/${commentMaxLength}`}</div>
            </div>
            <TextArea
              maxLength={commentMaxLength}
              rows={4}
              value={comment}
              placeholder={
                translations[ModalTranslationKeys.TextBoxBiasedPlaceHolder]
              }
              disabled={!isBiasedResult || isSubmitting}
              onChange={(value) => setComment(value)}
              name="biasedCommentFeedbackMessage"
            />
          </Fragment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={closeDialog}
            variant="secondary"
            data-test-id="comment-feedback-modal-cancel"
          >
            {translations[ModalTranslationKeys.Cancel]}
          </Button>
          <AsyncButton
            onClick={submitFeedback}
            variant="primary"
            disabled={isSubmitting}
            data-test-id="comment-feedback-modal-submit"
          >
            {translations[ModalTranslationKeys.Submit]}
          </AsyncButton>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};
