import z from 'zod';

import { reportZodError } from '../reportZodError';

/**
 * Validates the data against the schema and returns the data if it's valid. If the data is not valid, it will report the error to RollBar and return the data anyway.
 *
 * This is because we are likely to have errors in our schemas initially and we want to try and render the data in the application anyway.
 *
 * @param data The data to validate
 * @param schema The schema to validate the data against
 * @returns The data initially passed in, typed according to the schema
 *
 * @example
 * const data = { name: 'John' };
 * const schema = z.object({ name: z.string() });
 * const validatedData = validateApiData(data, schema);
 * // validatedData is { name: 'John' }
 *
 * @example
 * const data = { name: 123 };
 * const schema = z.object({ name: z.string() });
 * const validatedData = validateApiData(data, schema);
 * // validatedData is { name: 123 }
 * // The error will be reported to RollBar
 **/
export const validateData = <T extends z.ZodTypeAny>(
  data: unknown,
  schema: T,
  {
    errorMessagePrefix,
  }: {
    errorMessagePrefix: string;
  },
): z.infer<T> => {
  const result = schema.safeParse(data);
  if (result.success) {
    return data;
  }

  reportZodError({
    error: result.error,
    errorMessagePrefix,
    data,
  });

  return data;
};
