import { fromJS, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  standard: z.any(),
  nameTranslated: z.any(),
  access: z.any(),
  questionsUpgradeDue: z.any(),
  name: z.any(),
  resource: z.any(),
  hasOtherDrivers: z.any(),
  hasOutcome: z.any(),
  categoryIds: z.any(),
});
type Schema = z.infer<typeof schema>;

class CategoryGroupRecord
  extends Record({
    id: undefined,
    name: undefined,
    nameTranslated: undefined,
    standard: undefined,
    access: undefined,
    hasOutcome: false,
    hasOtherDrivers: false,
    categoryIds: null,
    resource: null,
    questionsUpgradeDue: false,
  })
  implements Schema
{
  id!: Schema['id'];
  resource: $TSFixMe;
  nameTranslated!: string;
  hasOtherDrivers!: boolean;
  questionsUpgradeDue!: boolean;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CategoryGroupRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'CategoryGroupRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  hasComments() {
    return this.resource.includes('comment');
  }

  static createFromApi(
    data: $TSFixMe,
    {
      keepStandard,
      access,
      hasOutcome,
      hasOtherDrivers,
      categories,
      resource,
    }: $TSFixMe = {},
  ) {
    let {
      attributes: { name, standard, nameTranslated, questionsUpgradeDue },
    } = data;

    if (!keepStandard) {
      standard = standard === 'text' ? 'open_ended' : standard;
    }

    return new CategoryGroupRecord(
      fromJS({
        id: standard,
        name,
        nameTranslated,
        standard,
        access,
        hasOtherDrivers,
        hasOutcome,
        resource,
        questionsUpgradeDue,
        categoryIds: categories
          ? categories.map(
              (
                // @ts-expect-error no implicit any
                category,
              ) => category.id,
            )
          : null,
      }),
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default CategoryGroupRecord;
