import React, { Fragment } from 'react';

import { CompanyBenchmark } from '../../../../components/admin/DataSettings/CompanyBenchmark/CompanyBenchmark';
import { SegmentsBenchmark } from '../../../../components/admin/DataSettings/SegmentsBenchmark/SegmentsBenchmark';
import { TrueBenchmark } from '../../../../components/admin/DataSettings/TrueBenchmark/TrueBenchmark';
import { useAppSelector } from '../../../../utils/reduxHooks';
import { useDataSettingsRedirect } from '../utils/useDataSettingsRedirect';

export const BenchmarkSettings = () => {
  useDataSettingsRedirect();
  const company = useAppSelector((state) => state.company);

  const hasTrueBenchmark = company ? company.hasAddOn('benchmark_true') : false;
  const hasBenchmarkSegments = company
    ? company.hasAddOn('benchmark_segments')
    : false;

  if (!company) {
    return null;
  }

  return (
    <Fragment>
      {hasTrueBenchmark && <TrueBenchmark />}
      <CompanyBenchmark company={company} />
      {hasBenchmarkSegments && <SegmentsBenchmark />}
    </Fragment>
  );
};
