import { Record, List } from 'immutable';
import pickBy from 'lodash/pickBy';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

const identity = (property: $TSFixMe) => {
  if (Array.isArray(property)) {
    return property.length > 0;
  }

  return typeof property !== 'undefined';
};

class GroupFrequency
  extends Record({
    id: undefined,
    type: undefined,
    group: undefined,
    scheduleId: undefined,
    overallFrequency: undefined,
    childFrequency: undefined,
    main: false,
    hasActiveQuestions: true,
    status: null,
    defaults: {},
    categories: List(),
  })
  implements Schema
{
  id!: Schema['id'];
  hasActiveQuestions?: boolean;
  categories: $TSFixMe;
  childFrequency: $TSFixMe;
  defaults: $TSFixMe;
  group: $TSFixMe;
  overallFrequency: $TSFixMe;
  status: $TSFixMe;
  type: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'GroupFrequency',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'GroupFrequency',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  getValue(key: $TSFixMe) {
    return this.default(this.get(key), this.defaults[key]);
  }

  getRecommended(key: $TSFixMe) {
    return this.defaults[key];
  }

  default(value: $TSFixMe, defaultValue: $TSFixMe) {
    // value 0 is a valid value, so we can check for falsy
    if (!value && value !== 0) {
      return defaultValue;
    }

    return value;
  }

  supports(type: $TSFixMe) {
    return typeof this.defaults[type] !== 'undefined';
  }

  isEnabled() {
    return this.status === 'enabled';
  }

  toHypotheticalJson() {
    if (this.type === 'category') {
      return {
        id: this.id,
        overallFrequency: this.overallFrequency,
      };
    }

    return pickBy(
      {
        overallFrequency: this.overallFrequency,
        childFrequency: this.childFrequency,
        status: this.status,
        categories: this.categories
          .map(
            (
              // @ts-expect-error no implicit any
              groupCategoryFrequency,
            ) => groupCategoryFrequency.toHypotheticalJson(),
          )
          .toArray(),
      },
      identity,
    );
  }

  toJsonApi() {
    if (this.type === 'category') {
      return {
        type: 'schedule_frequency_categories',
        id: this.id,
        attributes: {
          categoryId: Number(this.id),
          childFrequency: this.childFrequency,
          overallFrequency: this.overallFrequency,
          defaults: this.defaults,
        },
        relationships: undefined,
      };
    }

    return {
      type: 'schedule_frequency_groups',
      attributes: {
        group: this.group,
        overallFrequency: this.overallFrequency,
        childFrequency: this.childFrequency,
        defaults: this.defaults,
        status: this.status,
      },
      relationships: {
        frequencyCategories: {
          data: this.categories
            .map(
              (
                // @ts-expect-error no implicit any
                groupCategoryFrequency,
              ) => groupCategoryFrequency.toJsonApi(),
            )
            .toArray(),
        },
      },
    };
  }

  static fromGroup({
    attributes,
    relationships: { frequencyCategories = [] } = {},
  }: $TSFixMe = {}) {
    return new GroupFrequency({
      type: 'group',
      id: attributes.group,
      ...attributes,
      categories: List(frequencyCategories.map(GroupFrequency.fromCategory)),
    });
  }

  static fromCategory({ id, attributes }: $TSFixMe) {
    return new GroupFrequency({
      type: 'category',
      id,
      ...attributes,
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default GroupFrequency;
