import { Record } from 'immutable';
import { z } from 'zod';

import { localeIdsSchema } from '@peakon/shared/features/i18next/localesValidation';
import { translatedStringSchema } from '@peakon/shared/features/i18next/t';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

const translationSchema = z.object({
  locale: localeIdsSchema,
  translation: translatedStringSchema,
  language: z.string().optional(),
  autoFocus: z.boolean().optional(),
});

type TranslationSchema = z.infer<typeof translationSchema>;

export class Translation
  extends Record({
    autoFocus: undefined,
    language: undefined,
    locale: undefined,
    translation: undefined,
  })
  implements TranslationSchema
{
  autoFocus: TranslationSchema['autoFocus'];
  language: TranslationSchema['language'];
  // @ts-expect-error - Records will initialize this
  locale: TranslationSchema['locale'];
  // @ts-expect-error - Records will initialize this
  translation: TranslationSchema['translation'];

  constructor(props: unknown) {
    validateRecord(props, translationSchema, {
      errorMessagePrefix: 'translationSchema',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}

// eslint-disable-next-line import/no-default-export
export default Translation;
