import React from 'react';

export function Google({
  children,
  className,
}: {
  children?: string;
  className?: string;
}) {
  return (
    <svg
      aria-label={children}
      className={className}
      width="49"
      height="16"
      viewBox="0 0 49 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.088 5.79265V7.43132H10.01C9.89267 8.35332 9.58533 9.02665 9.11733 9.49465C8.546 10.0653 7.65333 10.6947 6.088 10.6947C3.67267 10.6947 1.78533 8.74865 1.78533 6.33398C1.78533 3.91932 3.67267 1.97332 6.088 1.97332C7.39 1.97332 8.34133 2.48532 9.044 3.14398L10.2 1.98732C9.21933 1.05132 7.91733 0.333984 6.088 0.333984C2.78067 0.333984 0 3.02665 0 6.33398C0 9.64132 2.78067 12.334 6.088 12.334C7.87267 12.334 9.21933 11.7487 10.2733 10.6513C11.356 9.56798 11.6927 8.04598 11.6927 6.81665C11.6927 6.43665 11.6633 6.08532 11.6047 5.79265H6.088Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5946 4.46094C14.4533 4.46094 12.7073 6.0896 12.7073 8.3336C12.7073 10.5629 14.4533 12.2063 16.5946 12.2063C18.7366 12.2063 20.4819 10.5629 20.4819 8.3336C20.4819 6.0896 18.7366 4.46094 16.5946 4.46094ZM16.5946 10.6803C15.4213 10.6803 14.4086 9.71227 14.4086 8.3336C14.4086 6.94027 15.4213 5.98627 16.5946 5.98627C17.7679 5.98627 18.7806 6.94027 18.7806 8.3336C18.7806 9.71227 17.7679 10.6803 16.5946 10.6803Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.648 5.32694H35.5893C35.2073 4.8716 34.474 4.46094 33.55 4.46094C31.614 4.46094 29.928 6.1476 29.928 8.3336C29.928 10.5043 31.614 12.2063 33.55 12.2063C34.474 12.2063 35.2073 11.7956 35.5893 11.3263H35.648V11.8676C35.648 13.3489 34.856 14.1409 33.5793 14.1409C32.538 14.1409 31.8926 13.3929 31.6286 12.7623L30.1473 13.3783C30.5726 14.4049 31.702 15.6669 33.5793 15.6669C35.5746 15.6669 37.2613 14.4936 37.2613 11.6329V4.66027H35.648V5.32694ZM33.6973 10.6803C32.524 10.6803 31.63 9.68294 31.63 8.3336C31.63 6.9696 32.524 5.98627 33.6973 5.98627C34.8566 5.98627 35.7653 6.98427 35.7653 8.34827C35.7653 9.6976 34.8566 10.6803 33.6973 10.6803Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2614 4.46094C23.12 4.46094 21.374 6.0896 21.374 8.3336C21.374 10.5629 23.12 12.2063 25.2614 12.2063C27.4034 12.2063 29.1487 10.5629 29.1487 8.3336C29.1487 6.0896 27.4034 4.46094 25.2614 4.46094ZM25.2614 10.6803C24.088 10.6803 23.076 9.71227 23.076 8.3336C23.076 6.94027 24.088 5.98627 25.2614 5.98627C26.4347 5.98627 27.4474 6.94027 27.4474 8.3336C27.4474 9.71227 26.4347 10.6803 25.2614 10.6803Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5947 0.494141H40.2681V12.2068H38.5947V0.494141Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4381 10.6803C44.5727 10.6803 43.9567 10.2849 43.5601 9.5076L48.7381 7.3656L48.5621 6.9256C48.2394 6.06027 47.2567 4.46094 45.2474 4.46094C43.2521 4.46094 41.5947 6.03094 41.5947 8.3336C41.5947 10.5049 43.2374 12.2063 45.4381 12.2063C47.2127 12.2063 48.2394 11.1209 48.6647 10.4896L47.3447 9.6096C46.9047 10.2556 46.3034 10.6803 45.4381 10.6803ZM45.3121 5.91227C46.0007 5.91227 46.5841 6.26294 46.7787 6.76627L43.2814 8.21627C43.2814 6.5836 44.4361 5.91227 45.3121 5.91227Z"
        fill="#757575"
      />
    </svg>
  );
}
