import React, { useState } from 'react';

import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import {
  NavigationBasicNavigationChevronDownIcon,
  NavigationBasicNavigationChevronUpIcon,
} from '@peakon/bedrock/icons/system';
import { Button, IconButton } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { RadioGroup, Toggle } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import { useDeleteConceptMutation } from './queries/useDeleteConceptMutation';
import { useUpdateNotificationsMutation } from './queries/useUpdateNotificationsMutation';
import { useUpdateStatusMutation } from './queries/useUpdateStatusMutation';
import { SensitiveCommentsConceptForm } from './SensitiveCommentsConceptForm';

import formStyles from './formStyles.css';
import styles from './styles.css';

export type SensitiveConceptKeyword = {
  id: string;
  keyword: string;
  locale: string;
  primaryLocale: boolean;
};

// eslint-disable-next-line no-restricted-syntax
export enum SensitiveCommentConceptType {
  Danger = 'danger',
  Health = 'health',
  Discrimination = 'discrimination',
  Ethics = 'ethics',
}

// eslint-disable-next-line no-restricted-syntax
export enum SensitiveCommentConceptNotifications {
  Instant = 'instant',
  Digest = 'digest',
}

// eslint-disable-next-line no-restricted-syntax
export enum SensitiveCommentConceptStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type SensitiveConcept = {
  name: string;
  keywords?: SensitiveConceptKeyword[];
  enabled: boolean;
  notifications: SensitiveCommentConceptNotifications;
  id?: string;
  preferredLocale?: string;
  standard?: boolean;
  status: SensitiveCommentConceptStatus;
  type?: SensitiveCommentConceptType;
};

export type SensitiveCommentsConceptProps = {
  concept: SensitiveConcept;
  canModifyKeywords: boolean;
  onConceptDeleted: () => void;
  onConceptRenamed: (name: string) => void;
};

export const SensitiveCommentsConcept = ({
  concept,
  canModifyKeywords,
  onConceptDeleted,
  onConceptRenamed,
}: SensitiveCommentsConceptProps) => {
  const [isEnabled, setIsEnabled] = useState(concept.enabled);
  const [conceptName, setConceptName] = useState(concept.name);
  const [conceptKeywords, setConceptKeywords] = useState(concept.keywords);
  const [notifications, setNotifications] = useState(concept.notifications);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { mutate: updateNotifications } = useUpdateNotificationsMutation({
    onSuccess: ({ data: { attributes } }) =>
      setNotifications(attributes.notifications),
  });

  const { mutate: updateStatus } = useUpdateStatusMutation({
    onSuccess: (attributes) =>
      setIsEnabled(attributes.status === SensitiveCommentConceptStatus.Active),
  });

  const { mutate: deleteSensitiveConcept, isLoading: isLoadingDeletion } =
    useDeleteConceptMutation({
      onSuccess: () => {
        onConceptDeleted();
        setIsDeleteModalOpen(false);
      },
    });

  const numberOfOtherLocales = (
    keywords: SensitiveConceptKeyword[],
  ): number => {
    const primaryLocales = keywords.filter((keyword) => keyword.primaryLocale);
    const otherLocales = keywords.filter((keyword) => !keyword.primaryLocale);

    return primaryLocales.length > 5
      ? keywords.length - 5
      : otherLocales.length;
  };

  const handleConceptDelete = () => {
    if (concept.id) {
      deleteSensitiveConcept(concept.id);
    }
  };

  const handleConceptToggled = (isCurrentlyEnabled: boolean): void => {
    if (concept.id) {
      updateStatus({
        conceptId: concept.id,
        attributes: {
          status: isCurrentlyEnabled
            ? SensitiveCommentConceptStatus.Active
            : SensitiveCommentConceptStatus.Inactive,
        },
      });
    }
  };

  const handleNotificationsChanged = (
    currentNotifications: SensitiveCommentConceptNotifications,
  ) => {
    if (concept.id) {
      updateNotifications({
        conceptId: concept.id,
        attributes: { notifications: currentNotifications },
      });
    }
  };

  const handleToggleOpenFormDetails = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleConceptSaved = (name: string) => {
    setConceptName(name);
    onConceptRenamed(name);
  };

  const handleAddKeywords = (keyword: SensitiveConceptKeyword): void => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const keywords = conceptKeywords.slice();
    keywords.push(keyword);
    setConceptKeywords(keywords);
  };

  const handleDeleteKeyword = (keywordsId: string): void => {
    setConceptKeywords(
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      conceptKeywords.filter((keyword) => keyword.id !== keywordsId),
    );
  };

  const conceptTitleId = `concept-title-id-${uuidv4()}`;

  function deleteModal() {
    return (
      <Modal
        closeLabel={t('cancel')}
        heading={t(
          'engagement__settings__sensitive_comments_custom_confirm_header',
        )}
        onDismiss={() => setIsDeleteModalOpen(false)}
        open={isDeleteModalOpen}
      >
        <Modal.Content>
          <div>
            {t('engagement__settings__sensitive_comments_custom_confirm')}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            variant="secondary"
          >
            {t('cancel')}
          </Button>

          <Button
            onClick={handleConceptDelete}
            variant="primary"
            busy={isLoadingDeletion}
            data-test-id="confirm-delete"
          >
            {t(
              'engagement__settings__sensitive_comments_custom_confirm_button',
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <tr className={classnames({ [styles.opened]: isFormOpen })}>
        <td className={styles.sensitiveConcept}>
          <div>
            <span id={conceptTitleId}>{conceptName}</span>
          </div>
        </td>

        <td>
          <div className={styles.sensitiveWord}>
            {conceptKeywords?.length === 0 ? (
              <span>
                {t('engagement__settings__sensitive_comments_list_empty')}
              </span>
            ) : (
              <React.Fragment>
                {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                {conceptKeywords
                  .filter((keyword) => keyword.primaryLocale)
                  .map(
                    (keyword, index) =>
                      index < 5 && (
                        <div
                          key={keyword.id}
                          className={classnames(
                            styles.word,
                            formStyles.variationsWord,
                            {
                              [styles.wordDisabled]: !isEnabled,
                            },
                          )}
                        >
                          {keyword.keyword}
                        </div>
                      ),
                  )}

                {/* @ts-expect-error TS(2345): Argument of type 'SensitiveConceptKeyword[] | unde... Remove this comment to see the full error message */}
                {numberOfOtherLocales(conceptKeywords) > 0 && (
                  <div
                    className={classnames(
                      styles.word,
                      formStyles.variationsWord,
                      styles.wordMore,
                      {
                        [styles.wordDisabled]: !isEnabled,
                      },
                    )}
                  >
                    {/* @ts-expect-error TS(2345): Argument of type 'SensitiveConceptKeyword[] | unde... Remove this comment to see the full error message */}
                    +{numberOfOtherLocales(conceptKeywords)}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </td>

        <td>
          <RadioGroup
            value={notifications}
            orientation="vertical"
            // @ts-expect-error TS(2345): Argument of type 'RadioInputValue | undefined' is ... Remove this comment to see the full error message
            onChange={(value) => handleNotificationsChanged(value)}
            aria-label={t('engagement__settings__sensitive_comments_frequency')}
          >
            <RadioGroup.Radio
              value={SensitiveCommentConceptNotifications.Instant}
              disabled={!isEnabled}
            >
              {t(
                'engagement__settings__sensitive_comments_frequency_label_immediately',
              )}
            </RadioGroup.Radio>

            <RadioGroup.Radio
              value={SensitiveCommentConceptNotifications.Digest}
              disabled={!isEnabled}
            >
              {t(
                'engagement__settings__sensitive_comments_frequency_label_daily',
              )}
            </RadioGroup.Radio>
          </RadioGroup>
        </td>

        <td className={styles.sensitiveSwitch}>
          <Toggle
            checked={isEnabled}
            onChange={handleConceptToggled}
            disabled={!canModifyKeywords && !concept.type}
            aria-label={t('engagement__settings__sensitive_comments_monitor')}
          />
        </td>

        {(canModifyKeywords || concept.type) && (
          <td className={styles.sensitiveDropDown}>
            <IconButton
              variant="secondary"
              size="small"
              onClick={handleToggleOpenFormDetails}
              aria-expanded={isFormOpen}
              aria-describedby={conceptTitleId}
              icon={
                isFormOpen ? (
                  <NavigationBasicNavigationChevronUpIcon />
                ) : (
                  <NavigationBasicNavigationChevronDownIcon />
                )
              }
              accessibleName={t(
                'engagement__settings__sensitive_comments_edit',
              )}
            />
          </td>
        )}
      </tr>

      {isFormOpen && (
        <tr>
          <td colSpan={5} className={styles.conceptsTableForm}>
            <SensitiveCommentsConceptForm
              aria-labelledby={conceptTitleId}
              concept={{ ...concept, keywords: conceptKeywords }}
              canModifyKeywords={canModifyKeywords}
              isCreating={false}
              onCancelled={() => setIsFormOpen(false)}
              onDelete={() => setIsDeleteModalOpen(true)}
              onSave={handleConceptSaved}
              onAddKeyword={handleAddKeywords}
              onDeleteKeyword={handleDeleteKeyword}
            />
          </td>
        </tr>
      )}

      {deleteModal()}
    </React.Fragment>
  );
};
