import React from 'react';

import moment from 'moment';

import yearlessDate from '@peakon/yearless-date';

const CURRENT_YEAR = new Date().getFullYear();

const getCommentDate = (date: $TSFixMe) => {
  const format =
    date.year() !== CURRENT_YEAR ? 'LL' : yearlessDate.resolve('LLS');

  return date.format(format);
};

export type DateLabelProps = {
  date?: string | Date;
};

const DateLabel = ({ date, ...props }: DateLabelProps) => {
  if (!date) {
    return null;
  }

  const momentDate = moment(date);
  return (
    <time {...props} dateTime={momentDate.format('YYYY-MM-DD')}>
      {getCommentDate(momentDate)}
    </time>
  );
};

// eslint-disable-next-line import/no-default-export
export default DateLabel;
