import { Record, List, Set } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import CategoryRecord from './CategoryRecord';
import TopicRecord from './TopicRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  topics: z.any(),
  title: z.any(),
  count: z.any(),
  category: z.any(),

  // not on current types
  group: z.any(),
  driver: z.any(),
});
type Schema = z.infer<typeof schema>;

class TopicGroupRecord
  extends Record({
    id: undefined,
    group: undefined,
    title: undefined,
    count: 1,
    topics: List(),
    category: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  title?: string;
  category?: CategoryRecord;
  topics!: List<TopicRecord>;
  // @ts-expect-error - this overrides the underlying iterable `count()` method
  count!: number;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'TopicGroupRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'TopicGroupRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes,
      relationships: { topics = [], category },
    } = data;

    return new TopicGroupRecord({
      id,
      ...attributes,
      topics: List(topics.map(TopicRecord.createFromApi)),
      category: category && CategoryRecord.createFromApi(category),
    });
  }

  get type() {
    if (!this.category) {
      return null;
    }

    return this.category.group;
  }

  get url() {
    const baseUrl = '/dashboard/topics/simple/';

    // @ts-expect-error - Object is possibly 'undefined'
    return `${baseUrl}category/${this.category.id}`;
  }

  getLanguages() {
    return this.topics.reduce(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (languages, topic) => languages!.add(topic!.locale!),
      Set<string>(),
    );
  }

  getUrl(segmentId?: string) {
    if (!segmentId) {
      return this.url;
    }

    const baseUrl = `/dashboard/topics/simple/segment/${segmentId}/`;

    // @ts-expect-error - Object is possibly 'undefined'
    return `${baseUrl}category/${this.category.id}`;
  }

  get categoryId() {
    if (!this.category) {
      return null;
    }
    return this.category.id;
  }
}

// eslint-disable-next-line import/no-default-export
export default TopicGroupRecord;
