import { Record, fromJS, List, Map } from 'immutable';
import get from 'lodash/get';
import { z } from 'zod';

import { SegmentResponse } from '@peakon/shared/schemas/api/segments';
import { AttributeAccess, AttributeType } from '@peakon/shared/types/Attribute';
import { BenchmarkType } from '@peakon/shared/types/Benchmark';
import hasRight from '@peakon/shared/utils/hasRight';
import { Right } from '@peakon/shared/utils/rights/types';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Attribute from './AttributeRecord';
import { CategoryGroup } from './constants/questionSets';
import DashboardContext from './ContextRecord';
import SegmentManager from './SegmentManagerRecord';
import Translation from './TranslationRecord';
import {
  getRelationships,
  sortTranslations,
  validateTestingSchema,
} from './utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  nameTranslated: z.any(),
  managers: z.any(),
  identifier: z.any(),
  direct: z.any(),
  logo: z.any(),
  name: z.any(),
  benchmarkPropagate: z.any(),
  nameTranslations: z.any(),
  abbreviation: z.any(),
  benchmarkType: z.any(),
  type: z.enum(['option', 'employee', 'date', 'number']).optional(),
  attribute: z.any(),
  email: z.any(),
  benchmarkSelector: z.any(),
  benchmark: z.any(),
  standard: z.any(),

  // probably typo somewhere
  contextid: z.string().optional(),
  contextId: z.string().optional(),
  benchmarkId: z.string().nullable().optional(),
  benchmarkid: z.string().nullable().optional(),
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Segment
  extends Record({
    id: undefined,
    abbreviation: undefined,
    contextId: undefined,
    direct: undefined,
    email: undefined,
    employeeCount: undefined,
    employeeCounts: undefined,
    externalId: undefined,
    identifier: undefined,
    links: undefined,
    logo: undefined,
    name: undefined,
    nameTranslated: undefined,
    nameTranslations: Map(),
    standard: undefined,
    comparisonAccess: undefined,
    rights: List(),

    benchmark: undefined,
    benchmarkPropagate: undefined,
    benchmarkId: undefined,
    benchmarkSelector: undefined,
    benchmarkType: undefined,

    loading: false,
    group: undefined,

    // relationships
    attribute: undefined,
    managers: List(),
    standardLabel: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  abbreviation?: string;
  comparisonAccess?: AttributeAccess;
  contextId?: string;
  direct?: boolean;
  email?: string;
  employeeCount?: number;
  employeeCounts?: Map<'employed' | 'hired' | 'left', number>;
  externalId?: string;
  group?: CategoryGroup;
  identifier?: string;
  links?: number;
  loading!: boolean;
  logo?: string;
  name?: string;
  nameTranslated?: string;
  nameTranslations?: Map<string, Translation>;
  rights?: List<string>;
  standard?: string;
  // eslint-disable-next-line
  benchmark?: Segment;
  benchmarkPropagate?: boolean;
  benchmarkId?: string;
  benchmarkSelector?: Map<string, unknown>;
  benchmarkType?: BenchmarkType;
  attribute?: Attribute;
  managers!: List<SegmentManager>;
  standardLabel?: string;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Segment',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'Segment',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  canBeViewedAs(currentContext: DashboardContext) {
    return (
      currentContext &&
      !this.isContext(currentContext.id) &&
      this.hasRight('dataset:read')
    );
  }

  isContext(contextId?: string) {
    return this.contextId && this.contextId === contextId;
  }

  isType(type: AttributeType) {
    return this.attribute?.type === type;
  }

  hasRight(right: Right) {
    return hasRight(this.rights?.toArray(), right);
  }

  hasLinks() {
    return typeof this.links !== 'undefined' && this.links > 0;
  }

  static createFromApi(data: SegmentResponse): Segment {
    // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
    const { id, attributes, relationships: { employee, managers } = {} } = data;

    const nameTranslations = sortTranslations(attributes?.nameTranslations);
    // @ts-expect-error TS(2339): Property 'attribute' does not exist on type '{}'.
    const { attribute, benchmark } = getRelationships(data.relationships);

    return new Segment(
      fromJS({
        id,
        email: get(employee, 'relationships.account.attributes.email'),
        identifier: get(employee, 'attributes.identifier'),
        attribute,
        ...attributes,
        nameTranslations,
        managers: managers
          ? managers.map(SegmentManager.createFromApi)
          : List(),
        benchmark,
      }),
    );
  }

  toOption() {
    return {
      value: this.id,
      label: this.name,
      attribute: this.attribute,
      direct: this.direct,
    };
  }
}
