import store from 'store2';

export const onTestToggle = (testMode: boolean) => {
  store.session.set('peakon.ui.test.enabled', testMode);

  return {
    type: 'DATA_TEST_MODE',
    data: testMode,
  };
};

export const onSetClosesAt = (closesAt: $TSFixMe) => {
  store.session.set('peakon.ui.closesAt', closesAt);

  return {
    type: 'SETTINGS_SET_CLOSES_AT',
    data: closesAt,
  };
};
