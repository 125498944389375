import React from 'react';

import classnames from 'classnames';

import { RadioGroup } from '@peakon/components';

import styles from './styles.css';

const colorMapping = {
  success: 'positive',
  danger: 'negative',
  warning: 'warning',
};

type ItemProps = {
  color?: 'success' | 'warning' | 'danger';
  icon?: React.ReactNode;
  label?: string;
  value?: string;
};

const Item = ({
  color = 'success',
  icon,
  label,
  value,
  ...props
}: ItemProps) => (
  <RadioGroup.Button
    className={styles.item}
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type '"positive... Remove this comment to see the full error message
    accent={colorMapping[color]}
    value={value}
    {...props}
  >
    {icon}
    {label}
  </RadioGroup.Button>
);

type OptionsGroupProps = {
  children?: React.ReactNode;
  description?: string;
  name?: string;
  onChange?: $TSFixMeFunction;
  size?: string;
  title?: string;
  value?: $TSFixMe;
};

const OptionsGroup = ({
  children,
  description,
  name,
  onChange,
  size,
  title,
  value,
}: OptionsGroupProps) => (
  <div
    className={classnames(styles.root, {
      // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
      [styles[size]]: size,
    })}
  >
    <div className={styles.title}>{title}</div>
    {description && <div className={styles.description}>{description}</div>}
    {/* @ts-expect-error TS(2322): Type '$TSFixMeFunction | undefined' is not assigna... Remove this comment to see the full error message */}
    <RadioGroup name={name} value={value} onChange={onChange} variant="card">
      {children}
    </RadioGroup>
  </div>
);

OptionsGroup.Item = Item;

// eslint-disable-next-line import/no-default-export
export default OptionsGroup;
