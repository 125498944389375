import { useMutation, useQueryClient } from '@tanstack/react-query';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import { sharedDashboardsQueryKeys } from './keys';
import {
  catchHandler,
  showSuccessNotification,
} from '../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../utils/reduxHooks';

type Params = {
  onSuccess: () => void;
};

const deleteSharedDashboard = (contextId: string): Promise<void> =>
  // @ts-expect-error TS(2322): Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
  api.delete(`/shares/contexts/${contextId}`);

export const useDeleteSharedMutation = ({ onSuccess }: Params) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSharedDashboard,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
      queryClient.invalidateQueries({
        queryKey: sharedDashboardsQueryKeys.sharedDashboards(),
        exact: true,
      });
      onSuccess();
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t('share_modal__revoked'),
        }),
      );
    },
  });
};
