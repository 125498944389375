import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from '../utils';

const schema = z.object({
  name: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

class ManagerActivityRecord
  extends Record({
    employeeId: undefined,
    name: undefined,
    daysActive: undefined,
    lastActivity: undefined,
  })
  implements Schema
{
  name!: Schema['name'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ManagerActivityRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ManagerActivityRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const { attributes } = data;

    return new ManagerActivityRecord(fromJS(attributes));
  }
}

// eslint-disable-next-line import/no-default-export
export default ManagerActivityRecord;
