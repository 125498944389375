import { Record, List, Map } from 'immutable';
import { z } from 'zod';

import { validateData } from '@peakon/shared/utils/validateData/validateData';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { COMPANY_SIZES } from './constants/companies';
import { validateTestingSchema } from './utils';

const dataSchema = z.object({
  id: z.string(),
  attributes: z.any(),
  links: z.any(),
  type: z.literal('companies'),
  relationships: z
    .object({
      companySubdomains: z
        .array(
          z.object({
            type: z.literal('company_subdomains'),
            id: z.string(),
            attributes: z.object({
              subdomain: z.string(),
              primary: z.boolean(),
            }),
          }),
        )
        .optional(),
    })
    .optional(),
});

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  companySize: z.any(),
  createdAt: z.any(),
  features: z.any(),
  addOns: z.any(),
  domains: z.any(),
  name: z.any(),
  employeeFeatures: z.any(),
  settings: z.any(),
  meta: z.any(),
  primarySubdomain: z.any(),
  sector: z.any(),
  location: z.any(),
  accessAllowed: z.any(),
  benchmarkType: z.any(),
  timezone: z.any(),
  abbreviation: z.any(),
  logo: z.any(),
  benchmarkSelector: z.any(),
  testCompany: z.any(),

  // seems like a typo somewhere
  benchmarkId: z.null().optional(),
  benchmarkid: z.null().optional(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Company
  extends Record({
    id: undefined,
    abbreviation: undefined,
    accessAllowed: false,
    allowAllDomains: undefined,
    benchmarkSelector: undefined,
    benchmarkType: undefined,
    companySize: undefined,
    createdAt: undefined,
    employeeCount: undefined,
    location: undefined,
    logo: undefined,
    meta: undefined,
    name: undefined,
    sector: undefined,
    timezone: undefined,
    testCompany: undefined,
    primarySubdomain: undefined,
    addOns: List(),
    employeeFeatures: List(),
    features: List(),
    settings: Map(),
    domains: List(),
  })
  implements Schema
{
  id!: Schema['id'];
  addOns: $TSFixMe;
  features: $TSFixMe;
  settings: $TSFixMe;
  toJSON: $TSFixMe;
  primarySubdomain?: {
    name: string;
    id: string;
  };

  abbreviation: $TSFixMe;
  accessAllowed: $TSFixMe;
  allowAllDomains: $TSFixMe;
  benchmarkSelector: $TSFixMe;
  benchmarkType: $TSFixMe;
  companySize: $TSFixMe;
  createdAt: $TSFixMe;
  employeeCount: $TSFixMe;
  location: $TSFixMe;
  logo: $TSFixMe;
  meta: $TSFixMe;
  name: $TSFixMe;
  sector: $TSFixMe;
  timezone: $TSFixMe;
  testCompany: $TSFixMe;
  employeeFeatures: $TSFixMe;
  domains: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CompanyRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'CompanyRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  hasAddOn(addOn: $TSFixMe): boolean {
    return this.addOns.includes(addOn);
  }

  hasFeature(feature: $TSFixMe): boolean {
    return this.features.includes(feature);
  }

  get scoreMode() {
    const engagementScore = this.settings.get('engagementScore');

    return engagementScore === 'enps' ? 'nps' : 'mean';
  }

  get driversOrder() {
    return this.settings.get('driversOrder');
  }

  get commentDateVisibility() {
    return this.settings.get('commentDateVisibility');
  }

  get anonymityLevel() {
    return this.settings.get('anonymityLevel');
  }

  get driverMode() {
    return this.settings.get('driverMode');
  }

  static createFromApi(data: unknown) {
    const parsedData = validateData(data, dataSchema, {
      errorMessagePrefix: 'CompanyRecord dataSchema',
    });
    const { id, attributes, links, type, relationships } = parsedData;

    const primarySubdomain = relationships?.companySubdomains?.find(
      (subdomain) => subdomain.attributes.primary,
    );

    const {
      size: companySize,
      employeeFeatures,
      addOns,
      domains,
      features,
      createdAt,
      name,
      settings,
      ...other
    } = attributes;

    return new Company({
      id,
      companySize: companySize || COMPANY_SIZES[0],
      createdAt: new Date(createdAt),
      features: List(features),
      addOns: List(addOns),
      domains: List(domains),
      name: name === 'My company' ? undefined : name,
      employeeFeatures: List(employeeFeatures),
      settings: Map(settings),
      meta: {
        id,
        type,
        links,
      },
      primarySubdomain: primarySubdomain
        ? {
            name: primarySubdomain.attributes.subdomain,
            id: primarySubdomain.id,
          }
        : undefined,
      ...other,
    });
  }

  toJsonApi() {
    const {
      meta,
      id: _id,
      companySize,
      settings: _settings,
      employeeCount: _employeeCount,
      ...attributes
    } = this.toJSON();

    return {
      ...meta,
      attributes: {
        ...attributes,
        size: companySize,
      },
    };
  }
}
