import React, { useEffect } from 'react';

import { z } from 'zod';

import { EmployeeDashboardSpreadsheetImportedIcon as EmployeesImportFileIcon } from '@peakon/bedrock/icons/graphic';
import { Button } from '@peakon/bedrock/react/button';
import { Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import usePrevious from '@peakon/shared/hooks/usePrevious';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { ProgressBar } from '../../TaskNotification/ProgressBar/ProgressBar';
import { TaskNotification } from '../../TaskNotification/TaskNotification';
import { Task } from '../types';

import styles from './styles.css';

const completedDataSchema = z.object({
  status: z.literal('completed'),
  added: z.number(),
  removed: z.number(),
  skipped: z.number(),
  logs: z.array(z.string()),
  type: z.literal('managerImport'),
});

const errorDataSchema = z.object({
  status: z.literal('error'),
  error: z.string(),
});

const inProgressDataSchema = z.object({
  status: z.literal('inProgress'),
  percent: z.number().min(0).max(100),
});

const dataSchema = z.union([
  inProgressDataSchema,
  completedDataSchema,
  errorDataSchema,
]);

const typeSchema = z.literal('segmentManagersImport');

const segmentManagersImportTask = z.object({
  id: z.string(),
  type: typeSchema,
  data: dataSchema,
  onComplete: z.function(
    z.tuple([completedDataSchema]).rest(z.never()),
    z.void(),
  ),
  onSeeResults: z.function(z.tuple([]).rest(z.never()), z.void()),
});

export type SegmentManagersImportTask = z.infer<
  typeof segmentManagersImportTask
>;

export type SegmentManagersImportTaskInitializationData = Pick<
  SegmentManagersImportTask,
  'type' | 'onComplete' | 'onSeeResults'
>;

export const isSegmentManagersImportTask = (
  task: Task,
): task is SegmentManagersImportTask => {
  if (typeSchema.safeParse(task.type).success) {
    validateData(task, segmentManagersImportTask, {
      errorMessagePrefix: 'SegmentManagersImportBackgroundTask',
    });
    return true;
  }
  return false;
};

export const SegmentManagersImportTaskNotification = ({
  task,
  onClose,
}: {
  task: SegmentManagersImportTask;
  onClose: () => void;
}) => {
  const previousStatus = usePrevious(task.data.status);

  useEffect(() => {
    if (
      task.data.status !== previousStatus &&
      task.data.status === 'completed'
    ) {
      task.onComplete(task.data);
    }
  }, [previousStatus, task]);

  if (task.data.status === 'inProgress') {
    return (
      <TaskNotification
        taskId={task.id}
        //  TODO (PEAKONADX-989): replace with background_task__segment_managers_import_file__importing__title once translations are available
        heading={t('background_task__employee_import_file__importing__title')}
        onClose={onClose}
        status="inProgress"
      >
        <TaskNotification.Content>
          <div className={styles.description}>
            <Typography.P>
              {/* TODO (PEAKONADX-989): replace with background_task__segment_managers_import_file__importing__text once translations are available */}
              {t('background_task__employee_import_file__importing__text')}
            </Typography.P>
          </div>
          <ProgressBar percent={task.data.percent} />
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  if (task.data.status === 'completed') {
    return (
      <TaskNotification
        taskId={task.id}
        //  TODO (PEAKONADX-989): replace with background_task__segment_managers_import_file__ready__title once translations are available
        heading={t('background_task__employee_import_file__ready__title')}
        onClose={onClose}
        status="completed"
      >
        <TaskNotification.Content
          icon={EmployeesImportFileIcon}
          iconSize="large"
        >
          <div className={styles.description}>
            <Typography.P>
              {t('background_task__segment_managers_import_file__ready__text', {
                replace: {
                  added: task.data.added,
                  removed: task.data.removed,
                  skipped: task.data.skipped,
                },
              })}
              &nbsp;
              {task.data.logs.length > 0 &&
                //  TODO (PEAKONADX-989): replace with background_task__segment_managers_import_file__result-warnings once translations are available
                t('employees__import__result-warnings', {
                  replace: { count: task.data.logs.length },
                })}
            </Typography.P>
          </div>
          <Button
            variant="primary"
            onClick={() => {
              task.onSeeResults();
              onClose();
            }}
          >
            {/* TODO (PEAKONADX-989): replace with background_task__segment_managers_import_file__ready__details once translations are available */}
            {t('background_task__employee_import_file__ready__details')}
          </Button>
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  return (
    <TaskNotification
      taskId={task.id}
      //  TODO (PEAKONADX-989): replace with background_task__segment_managers_import_file__error__title once translations are available
      heading={t('background_task__employee_import_file__error__title')}
      onClose={onClose}
      status="error"
    >
      <TaskNotification.Content icon={EmployeesImportFileIcon} iconSize="large">
        <Typography.P className={styles.error}>{task.data.error}</Typography.P>
      </TaskNotification.Content>
    </TaskNotification>
  );
};
