/**
 * FULL CONTEXT: https://workday-dev.slack.com/archives/C04HVDL40NA/p1721805402237919
 *
 * This is a temporary file used to store translations that have nested variables because it is not supported in Phrase
 * The intention is to replace all of these strings in our codebase with simpler versions and once that is done we can remove this file
 */

export const temporaryNamespace = 'temporary';

export const temporaryTranslations = {
  tr: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Genel puan, {total, plural, one {anketi alan {bold}#{bold} {link}çalışan{link}} other {anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {answered, plural, one {{emphasis}#{emphasis} çalışanın} other {{emphasis}#{emphasis} çalışanın}} verdiği yanıtları baz alır',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, one {Anketi alan {bold}#{bold} {link}çalışan{link}} other {Anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu soruyu cevapladı',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{total, plural, one {Anketi alan {bold}#{bold} {link}çalışan{link}} other {Anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu değerle ilgili soruları cevapladı',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{total, plural, one {Anketi alan {bold}#{bold} {link}çalışan{link}} other {Anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu sonuçla ilgili soruları cevapladı',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{total, plural, one {Anketi alan {bold}#{bold} {link}çalışan{link}} other {Anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu alt etkenle ilgili soruları cevapladı',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{total, plural, one {{link}Anketi alan {bold}#{bold} çalışan{link}} other {{link}Anketi alan {bold}#{bold} çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu etkenle ilgili soruları cevapladı',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{{date} tarihinden bu yana değişiklik yok} 1{{date} tarihinden bu yana {emphasis}+{difference}{emphasis} puan artış oldu} other{{date} tarihinden bu yana {emphasis}{difference}{emphasis} puan düşüş oldu}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '{count, plural, one{Bir çalışana} other{{count} çalışana}} hatırlatma gönderilsin mi?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Her hafta} 2{2 haftada bir} 3{3 haftada bir} 4{Her ay} 5{5 haftada bir} 6{6 haftada bir} 7{7 haftada bir} 8{2 ayda bir} 9{9 haftada bir} 10{10 haftada bir} 11{11 haftada bir} 12{3 ayda bir} 0{Manual} other{{frequency} haftada bir}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{bölüm yöneticisi} other{altında +{level} düzey bulunan yönetici}}',
      },
    },
  },
  hu: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Az összesített pontszám {answered, plural, one {{emphasis}#{emphasis} munkavállaló} other {{emphasis}#{emphasis} munkavállaló}} válaszán alapul (összesen {total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt erre a kérdésre',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt az ezzel az értékkel kapcsolatos kérdésekre',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt az ezzel az eredménnyel kapcsolatos kérdésekre',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt az ezzel a másodlagos hajtóerővel kapcsolatos kérdésekre',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt az ezzel a hajtóerővel kapcsolatos kérdésekre',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# megkérdezett munkavállaló{link}} other {{link}# megkérdezett munkavállaló{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nincs változás {date} óta} 1{{emphasis}+{difference}{emphasis} növekedés {date} óta} other{{emphasis}{difference}{emphasis} csökkenés {date} óta}}',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Minden héten} 2{Minden 2. héten} 3{Minden 3. héten} 4{Minden hónapban} 5{Minden 5. héten} 6{Minden 6. héten} 7{Minden 7. héten} 8{Minden 2. hónapban} 9{Minden 9. héten} 10{Minden 10. héten} 11{Minden 11. héten} 12{Minden negyedévben} 0{Manuálisan} other{Minden {frequency}. héten}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{1. szintű vezető} other{+{level}. szintű vezető}}',
      },
    },
  },
  lt: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Bendras įvertis pagal atsakymus iš {answered, plural, one {{emphasis}#{emphasis} darbuotojo} few {{emphasis}#{emphasis} darbuotojų} many {{emphasis}#{emphasis} darbuotojų} other {{emphasis}#{emphasis} darbuotojų}} (iš {total, plural, one {{bold}#{bold} {link}darbuotojo, kuris gavo apklausą{link}} few {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} many {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojo, kuris gavo apklausą{link}} few {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} many {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}}} atsakė į šį klausimą',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojas, kuris gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}}} atsakė į klausimus apie šią vertybę',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojas, kuris gavo apklausą{link}} few {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}} many {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}}} atsakė į klausimus apie šį rezultatą',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojas, kuris gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}}} atsakė į klausimus apie šį antrinį veiksnį',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojo, kuris gavo apklausą{link}} few {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} many {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}}} atsakė į klausimus apie šį veiksnį',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# darbuotojas, kuris gavo apklausą {link}} few {{link}# darbuotojai, kurie gavo apklausą{link}} many {{link}# darbuotojai, kurie gavo apklausą{link}} other {{link}# darbuotojai, kurie gavo apklausą{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nėra pakeitimų nuo {date}} 1{{emphasis}+{difference}{emphasis} patobulinimų nuo {date}} other{{emphasis}{difference}{emphasis} sumažėjimų nuo {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Išsiųsti priminimą {count, plural, one{vienam darbuotojui} other{{count} employees}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Kas savaitę} 2{Kas antrą savaitę} 3{Kas trečią savaitę} 4{Kas mėnesį} 5{Kas penktą savaitę} 6{Kas šeštą savaitę} 7{Kas septintą savaitę} 8{Kas antrą mėnesį} 9{Kas devintą savaitę} 10{Kas dešimtą savaitę} 11{Kas vienuoliktą savaitę} 12{Kas ketvirtį} 0{Rankinis} other{Kas {frequency}. savaitę}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{tiesioginis vadovas} other{lygis +{level} vadovas}}',
      },
    },
  },
  nl: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'De totaalscore is gebaseerd op antwoorden van {answered, plural, one {{emphasis}#{emphasis} werknemer} other {{emphasis}#{emphasis} werknemers}} (van {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben deze vraag beantwoord',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben vragen beantwoord over deze waarde',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben vragen beantwoord over deze resultaten',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben vragen beantwoord over deze subdrijfveer',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben vragen beantwoord over deze drijfveer',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# werknemer die de enquête heeft ontvangen{link}} other {{link}# werknemers die de enquête hebben ontvangen{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Geen wijziging sinds {date}} 1{{emphasis}+{difference}{emphasis} verbetering sinds {date}} other{{emphasis}{difference}{emphasis} afname sinds {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Herinnering versturen naar {count, plural, one{één werknemer} other{{count} werknemers}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Elke week} 2{Eens in de twee weken} 3{Eens in de drie weken} 4{Elke maand} 5{Eens in de vijf weken} 6{Eens in de zes weken} 7{Eens in de zeven weken} 8{Om de maand} 9{Eens in de negen weken} 10{Eens in de tien weken} 11{Eens in de elf weken} 12{Elk kwartaal} 0{Handmatig} other{Eens in de {frequency} weken}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{lijnmanager} other{Manager met niveau +{level}}}',
      },
    },
  },
  ja: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          '総合スコアは、{answered, plural, other {{emphasis}#{emphasis}人の社員}}の回答に基づいています(アンケートを受け取ったのは{total, plural, other {{bold}#{bold} {link}人の社員{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち{emphasis}{answered}{emphasis}人がこの質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち{emphasis}{answered}{emphasis}人がこの価値に関する質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち{emphasis}{answered}{emphasis}人がこの成果に関する質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち {emphasis}{answered}{emphasis} 人がこのサブ推進要因に関する質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち {emphasis}{answered}{emphasis} 人がこの推進要因に関する質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, other {{link}#人の社員がアンケートを受け取りました{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{{date}以降変化なし} 1{{emphasis}+{difference}{emphasis} {date}以降改善} other{{emphasis}{difference}{emphasis} {date}以降減少}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '{count, plural, other{{count}人の社員}}にリマインダーを送信しますか?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{毎週} 2{隔週} 3{3週間ごと} 4{毎月} 5{5週間ごと} 6{6週間ごと} 7{7週間ごと} 8{2ヶ月ごと} 9{9週間ごと} 10{10週間ごと} 11{11週間ごと} 12{四半期ごと} 0{手動} other{{frequency}週間ごと}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{直属のマネージャ} other{{level}レベル上のマネジャー}}',
      },
    },
  },
  de: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Der Gesamt-Score ergibt sich aus den Antworten von {answered, plural, one {{emphasis}#{emphasis} Mitarbeiter} other {{emphasis}#{emphasis} Mitarbeitern}} (von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben diese Frage beantwortet',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben Fragen zu diesem Unternehmenswert beantwortet',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben Fragen zu dieser Auswirkung des Mitarbeiter-Engagements beantwortet',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben Fragen zu diesem Teilfaktor beantwortet',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben Fragen zu diesem Engagement-Faktor beantwortet',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# befragter Mitarbeiter{link}} other {{link}# befragte Mitarbeiter{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Keine Änderung seit {date}} 1{{emphasis}+{difference}{emphasis} Verbesserung seit {date}} other{{emphasis}{difference}{emphasis} Verringerung seit {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Erinnerung an {count, plural, one{einen Mitarbeiter} other{{count} Mitarbeiter}} senden?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Jede Woche} 2{Jede 2. Woche} 3{Jede 3. Woche} 4{Jeden Monat} 5{Jede 5. Woche} 6{Jede 6. Woche} 7{Jede 7. Woche} 8{Alle zwei Monate} 9{Jede 9. Woche} 10{Jede 10. Woche} 11{Jede 11. Woche} 12{Jedes Quartal} 0{Manuell} other{Jede {frequency}. Woche}}',
      },
      segment_managers_modal__level_tooltip: {
        message: '{level, select, 0{Manager} other{Stufe +{level} Manager}}',
      },
    },
  },
  ru: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Средний показатель основан на ответах {answered, plural, one {{emphasis}#{emphasis} сотрудника} few {{emphasis}#{emphasis} сотрудников} many {{emphasis}#{emphasis} сотрудников} other {{emphasis}#{emphasis} сотрудника}} (из {total, plural, one {{bold}#{bold} {link}, получившего  опрос{link}} few {{bold}#{bold} {link}, получивших опрос{link}} many {{bold}#{bold} {link}, получивших опрос{link}} other {{bold}#{bold} {link}, получивших опрос{link}}})',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# сотрудник получил опрос{link}} other {{link}несколько сотрудников получили опрос: #{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Нет изменений с {date}} 1{{emphasis}+{difference}{emphasis} улучшение с {date}} other{{emphasis}{difference}{emphasis} ухудшение с {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Отправить напоминание {count, plural, one{одному сотруднику} other{{count} сотрудникам}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Раз в неделю} 2{Раз в 2 недели} 3{Раз в 3 недели} 4{Раз в месяц} 5{Раз в 5 недель} 6{Раз в 6 недель} 7{Раз в 7 недель} 8{Раз в 2 месяца} 9{Раз в 9 недель} 10{Раз в 10 недель} 11{Раз в 11 недель} 12{Раз в квартал} 0{Manual} other{Раз в несколько недель ({frequency}).}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{линейный руководитель} other{Уровень +{level} руководитель}}',
      },
    },
  },
  pl: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Łączny wynik obliczany na podstawie odpowiedzi udzielonych przez {answered, plural, one {{emphasis}#{emphasis} pracownika} few{{emphasis}#{emphasis} pracowników} many{{emphasis}#{emphasis} pracowników} other{{emphasis}#{emphasis} pracownika}} (spośród {total, plural, one {{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na to pytanie: {emphasis}{answered}{emphasis} spośród {total, plural, one{{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na pytania dotyczące tej wartości: {emphasis}{answered}{emphasis} spośród {total, plural, one{{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na pytania dotyczące tego wyniku: {emphasis}{answered}{emphasis} spośród {total, plural, one{{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na pytania dotyczące tego podczynnika: {emphasis}{answered}{emphasis} spośród {total, plural, one{{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na pytania dotyczące tego czynnika: {emphasis}{answered}{emphasis} spośród {total, plural, one {{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one{{link}# pracownik, który otrzymał ankietę{link}} few{{link}# pracowników, którzy otrzymali ankietę{link}} many{{link}# pracowników, którzy otrzymali ankietę{link}} other{{link}# pracownika, który otrzymał ankietę{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Bez zmian od {date}} 1{{emphasis}+{difference}{emphasis} – poprawa od {date}} other{{emphasis}{difference}{emphasis} – pogorszenie od {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Wysłać przypomnienie do {count, plural, one{jednego pracownika} few{{count} pracowników} many{{count} pracowników} other{{count} pracownika}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Co tydzień} 2{Co dwa tygodnie} 3{Co trzy tygodnie} 4{Co miesiąc} 5{Co pięć tygodni} 6{Co sześć tygodni} 7{Co siedem tygodni} 8{Co drugi miesiąc} 9{Co dziewięć tygodni} 10{Co dziesięć tygodni} 11{Co jedenaście tygodni} 12{Co kwartał} 0{Wybrano ręcznie} other{Co {frequency}. tydzień}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{Bezpośredni przełożony} other{Przełożony pracowników z +{level} poziomów}}',
      },
    },
  },
  fi: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Kokonaispisteet perustuvat vastauksiin {answered, plural,one {{emphasis}#{emphasis} työntekijältä} other {{emphasis}#{emphasis} työntekijältä}} (/{total, plural,one {{bold}#{bold} {link}kyselyn saanut työntekijä{link}} other {{bold}#{bold} {link}kyselyn saanutta työntekijää{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän kysymykseen',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän arvoon liittyviin kysymyksiin',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän tulokseen liittyviin kysymyksiin',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän osatekijään liittyviin kysymyksiin',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän tekijään liittyviin kysymyksiin',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural,one {{link}# työntekijä on saanut kyselyn{link}}other {{link}# työntekijää on saanut kyselyn{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Ei muutosta alkaen {date}} 1{{emphasis}+{difference}{emphasis} parannusta alkaen {date}} other{{emphasis}{difference}{emphasis} vähennys alkaen {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Lähetä muistutus {count, plural, one{yhdelle työntekijälle} other{{count} työntekijälle}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Joka viikko} 2{Joka toinen viikko} 3{Joka kolmas viikko} 4{Kerran kuussa} 5{Joka viides viikko} 6{Joka kuudes viikko} 7{Joka seitsemäs viikko} 8{Joka toinen kuukausi} 9{Joka yhdeksäs viikko} 10{Joka 10. viikko} 11{Joka 11. viikko} 12{Neljännesvuosittain} 0{manuaalinen} other{Joka {frequency}. viikko}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{Linjaesihenkilö} other{Tason +{level} esihenkilö}}',
      },
    },
  },
  'zh-CN': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          '整体评分基于 {answered, plural, other {{emphasis}#{emphasis} 名员工}}的回复而得出（共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}）',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}，其中 {emphasis}{answered}{emphasis} 名员工回答了此问题',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}，其中 {emphasis}{answered}{emphasis} 名员工回答了有关此价值观的问题',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}，其中 {emphasis}{answered}{emphasis} 名员工回答了有关此结果的问题',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}，其中 {emphasis}{answered}{emphasis} 名员工回答了有关此次级驱动因素的问题',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message: '共有 {total, plural, other {{link}# 名员工收到调查{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{从 {date}起无变更} 1{{emphasis}+{difference}{emphasis} 从 {date}起改进} other{{emphasis}{difference}{emphasis} 从 {date}起减少}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message: '是否向 {count, plural, other{{count} 名员工}}发送提醒？',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{每周} 2{每 2 周} 3{每 3 周} 4{每月} 5{每 5 周} 6{每 6 周} 7{每 7 周} 8{每隔一月} 9{每 9 周} 10{每 10 周} 11{每 11 周} 12{每季度} 0{手动} other{每 {frequency} 周}}',
      },
      segment_managers_modal__level_tooltip: {
        message: '{level, select, 0{直接经理} other{级 +{level} 直接主管}}',
      },
    },
  },
  pt: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'A pontuação global baseia-se nas respostas de {answered, plural, one {{emphasis}#{emphasis} colaborador} other {{emphasis}#{emphasis} colaboradores}} (de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a esta pergunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este resultado',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este subfator',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este fator',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Sem alterações desde {date}} 1{{emphasis}+{difference}{emphasis} de melhoria desde {date}} other{{emphasis}{difference}{emphasis} de diminuição desde {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Enviar lembrete para {count, plural, one{um colaborador} other{{count} colaboradores}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Todas as semanas} 2{A cada 2 semanas} 3{A cada 3 semanas} 4{Todos os meses} 5{A cada 5 semanas} 6{A cada 6 semanas} 7{A cada 7 semanas} 8{A cada dois meses} 9{A cada 9 semanas} 10{A cada 10 semanas} 11{A cada 11 semanas} 12{A cada trimestre} 0{Manual} other{A cada {frequency}. semanas}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{gestor direto} other{Gestor de nível +{level}}}',
      },
    },
  },
  en: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'The overall score is based on responses from {answered, plural, one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered this question',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this value',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this outcome',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this sub-driver',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this driver',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# employee who received the survey{link}} other {{link}# employees who received the survey{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{No change since {date}} 1{{emphasis}+{difference}{emphasis} improvement since {date}} other{{emphasis}{difference}{emphasis} decrease since {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Send reminder to {count, plural, one{one employee} other{{count} employees}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Every week} 2{Every 2nd week} 3{Every 3rd week} 4{Every month} 5{Every 5th week} 6{Every 6th week} 7{Every 7th week} 8{Every other month} 9{Every 9th week} 10{Every 10th week} 11{Every 11th week} 12{Every quarter} 0{Manual} other{Every {frequency}. week}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{line manager} other{Level +{level} manager}}',
      },
    },
  },
  'pt-PT': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'A pontuação global baseia-se nas respostas de {answered, plural, one {{emphasis}#{emphasis} colaborador} other {{emphasis}#{emphasis} colaboradores}} (de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a esta pergunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este resultado',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este subfator',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este fator',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Sem alterações desde {date}} 1{{emphasis}+{difference}{emphasis} de melhoria desde {date}} other{{emphasis}{difference}{emphasis} de diminuição desde {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Enviar lembrete para {count, plural, one{um colaborador} other{{count} colaboradores}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Todas as semanas} 2{A cada 2 semanas} 3{A cada 3 semanas} 4{Todos os meses} 5{A cada 5 semanas} 6{A cada 6 semanas} 7{A cada 7 semanas} 8{A cada dois meses} 9{A cada 9 semanas} 10{A cada 10 semanas} 11{A cada 11 semanas} 12{A cada trimestre} 0{Manual} other{A cada {frequency}. semanas}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{gestor direto} other{Gestor de nível +{level}}}',
      },
    },
  },
  it: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Il punteggio complessivo si basa sulle risposte di {answered, plural, one {{emphasis}#{emphasis} dipendente} other {{emphasis}#{emphasis} dipendenti}} (su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} a questa domanda',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} alle domande su questo valore',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} alle domande su questo risultato',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} alle domande su questo fattore secondario',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} alle domande su questo fattore',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# dipendente che ha ricevuto il sondaggio{link}} other {{link}# dipendenti che hanno ricevuto il sondaggio{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nessun cambiamento dal {date}} 1{{emphasis}+{difference}{emphasis} di miglioramento dal {date}} other{{emphasis}{difference}{emphasis} di diminuzione dal {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Inviare un promemoria a {count, plural, one{# dipendente} other{{count} dipendenti}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Ogni settimana} 2{Ogni due settimane} 3{Ogni tre settimane} 4{Ogni mese} 5{Ogni cinque settimane} 6{Ogni sei settimane} 7{Ogni sette settimane} 8{Ogni due mesi} 9{Ogni nove settimane} 10{Ogni 10 settimane} 11{Ogni 11 settimane} 12{Ogni trimestre} 0{Manuale} other{Ogni {frequency}. settimane}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{Manager diretto} other{Manager di livello +{level}}}',
      },
    },
  },
  et: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Üldskoor põhineb {answered, plural, one {{emphasis}#{emphasis} töötaja} other {{emphasis}#{emphasis} töötaja}} vastustel (uuringulink saadeti {total, plural, one {{bold}#{bold}{link} töötajale{link}} other {{bold}#{bold}{link} töötajale{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on sellele küsimusele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on selle väärtuse küsimustele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on selle tulemuse küsimustele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on selle alamteguri küsimustele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on selle teguri küsimustele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# töötaja, kes sai uuringulingi{link}} other {{link}# töötajat, kes said uuringulingi{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Muutusi pole alates {date}} 1{{emphasis}+{difference}{emphasis} parem alates {date}} other{{emphasis}{difference}{emphasis} kehvem alates {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Kas saata meeldetuletus {count, plural, one{ühele töötajale} other{{count} töötajale}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Iga nädal} 2{Iga 2 nädala tagant} 3{Iga 3 nädala tagant} 4{Iga kuu} 5{Iga 5 nädala tagant} 6{Iga 6 nädala tagant} 7{Iga 7 nädala tagant} 8{Üle ühe kuu} 9{Iga 9 nädala tagant} 10{Iga 10 nädala tagant} 11{Iga 11 nädala tagant} 12{Iga kvartal} 0{Käsitsisisestus} other{Iga {frequency} nädala tagant}}',
      },
      segment_managers_modal__level_tooltip: {
        message: '{level, select, 0{otsene juht} other{Taseme +{level} juht}}',
      },
    },
  },
  'fr-CA': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Le score général est basé sur les réponses de {answered, plural, one {{emphasis}#{emphasis} employé} other {{emphasis}#{emphasis} employés}} (sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage{link}} other{{bold}#{bold} {link}employés qui ont reçu le sondage{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage a répondu{link}} other {{bold}#{bold} {link}employés qui ont reçu le sondage ont répondu{link}}} à cette question',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} aux questions à propos de cette valeur',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage a répondu{link}} other {{bold}#{bold} {link}employés qui ont reçu le sondage ont répondu{link}}} aux questions sur ce résultat',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage a répondu{link}} other {{bold}#{bold} {link}employés qui ont reçu le sondage ont répondu{link}}} aux questions sur ce sous-moteur',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage a répondu{link}} other {{bold}#{bold} {link}employés qui ont reçu le sondage ont répondu{link}}} aux questions sur ce moteur',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# employé qui a reçu le sondage{link}} other {{link}# employés qui ont reçu le sondage{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Pas de changement depuis le {date}} 1{{emphasis}+{difference}{emphasis} d’augmentation depuis le {date}} other{{emphasis}{difference}{emphasis} de diminution depuis le {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Envoyer un rappel à {count, plural, one{un employé} other{{count} employés}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Toutes les semaines} 2{Toutes les 2 semaines} 3{Toutes les 3 semaines} 4{Tous les mois} 5{Toutes les 5 semaines} 6{Toutes les 6 semaines} 7{Toutes les 7 semaines} 8{Tous les 2 mois} 9{Toutes les 9 semaines} 10{Toutes les 10 semaines} 11{Toutes les 11 semaines} 12{Tous les trimestres} 0{Manuel} other{Toutes les {frequency} semaines}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{gestionnaire direct} other{Gestionnaire de {level} niveau(x)}}',
      },
    },
  },
  fr: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          "Le score global est basé sur les réponses de {answered, plural, one {{emphasis}#{emphasis} collaborateur} other {{emphasis}#{emphasis} collaborateurs}} (sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}})",
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu à cette question.",
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur cette valeur.",
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce résultat.",
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce sous-moteur.",
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce moteur.",
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          "{total, plural, one {{link}# collaborateur ayant reçu l'enquête{link}} other {{link}# collaborateurs ayant reçu l'enquête{link}}}",
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Aucun changement depuis le {date}}1{Augmentation de {emphasis}{difference}{emphasis} depuis le {date}}other{Baisse de {emphasis}{difference}{emphasis} depuis le {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Envoyer un rappel à {count, plural, one{un collaborateur} other{{count} collaborateurs}} ?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Toutes les semaines} 2{Toutes les 2 semaines} 3{Toutes les 3 semaines} 4{Tous les mois} 5{Toutes les 5 semaines} 6{Toutes les 6 semaines} 7{Toutes les 7 semaines} 8{Tous les 2 mois} 9{Toutes les 9 semaines} 10{Toutes les 10 semaines} 11{Toutes les 11 semaines} 12{Tous les trimestres} 0{Manuel} other{Toutes les {frequency} semaines}}',
      },
      segment_managers_modal__level_tooltip: {
        message: '{level, select, 0{Manager direct} other{Manager N+{level}}}',
      },
    },
  },
  ro: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Punctajul global este bazat pe răspunsurile de la {answered, plural, one {{emphasis}#{emphasis} angajat} few {{emphasis}#{emphasis} angajați} other {{emphasis}#{emphasis} de angajați}} (din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la această întrebare',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la întrebări despre această valoare',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la întrebări despre acest rezultat',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la întrebări despre acest factor secundar',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la întrebări despre acest factor',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# angajat care a primit sondajul {link}} few {{link}# angajați care au primit sondajul{link}} other {{link}# de angajați care au primit sondajul{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nicio schimbare de la {date}} 1{Îmbunătățire de {emphasis}{difference}{emphasis} de la {date}} other{Scădere de {emphasis}{difference}{emphasis} de la {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Vrei să trimiți un memento către {count, plural, one{un angajat} few {{count} angajați} other{{count} de angajați}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{În fiecare săptămână} 2{La fiecare 2 săptămâni} 3{La fiecare 3 săptămâni} 4{În fiecare lună} 5{La fiecare 5 săptămâni} 6{La fiecare 6 săptămâni} 7{La fiecare 7 săptămâni} 8{La fiecare două luni} 9{La fiecare 9 săptămâni} 10{La fiecare 10 săptămâni} 11{La fiecare 11 săptămâni} 12{La fiecare trimestru} 0{Manual} other{La fiecare {frequency} săptămâni}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{Superior ierarhic} other{Superior ierarhic de nivel +{level}}}',
      },
    },
  },
  hi: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'कुल स्कोर सर्वेक्षण पाने वाले {total, plural,one {{bold}#{bold}{link} कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {answered, plural,one {{emphasis}#{emphasis} कर्मचारी} other {{emphasis}#{emphasis} कर्मचारियों}} के जवाबों पर आधारित है',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold} {link}कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {emphasis}{answered}{emphasis} ने इस प्रश्न का उत्तर दिया',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold} {link}कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {emphasis}{answered}{emphasis} ने इस मूल्य के प्रश्नों के उत्तर दिए',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold} {link}कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {emphasis}{answered}{emphasis} ने इस उप-ड्राइवर के प्रश्नों के उत्तर दिए',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold}{link} कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से{emphasis}{answered}{emphasis} ने इस उप-ड्राइवर के सवालों के जवाब दिए',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold} {link}कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {emphasis}{answered}{emphasis} ने इस ड्राइवर के प्रश्नों के उत्तर दिए',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link} सर्वेक्षण प्राप्त करने वाला # कर्मचारी{link}} other {{link}सर्वेक्षण प्राप्त करने वाले # कर्मचारी{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{{date} के बाद से कोई बदलाव नहीं} 1{{emphasis}+{difference}{emphasis} {date} के बाद से सुधार} other{{emphasis}{difference}{emphasis} {date} के बाद से ह्रास}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '{count, plural, one{कर्मचारी} other{{count} कर्मचारियों}} को रीमाइंडर भेजें?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{हर सप्ताह} 2{हर 2सरे सप्ताह} 3{हर 3सरे सप्ताह} 4{हर महीने} 5{हर 5वें सप्ताह} 6{हर 6वें सप्ताह} 7{हर 7वें सप्ताह} 8{हर दूसरे महीने} 9{हर 9वें सप्ताह} 10{हर 10वें सप्ताह} 11{हर 11वें सप्ताह} 12{हर तिमाही} 0{हस्तचालित} other{हर {frequency} सप्ताह}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{लाइन प्रबंधक} other{लेवल +{level} प्रबंधक}}',
      },
    },
  },
  'pt-BR': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'A pontuação geral é baseada nas respostas de {answered, plural, one {{emphasis}#{emphasis} colaborador} other {{emphasis}#{emphasis} colaboradores}} (de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} a essa pergunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} às perguntas sobre esse valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} às perguntas sobre esse resultado',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} às perguntas sobre esse submotivador',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} às perguntas sobre esse motivador',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# colaborador recebeu a pesquisa{link}} other {{link}# colaboradores receberam a pesquisa{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nenhuma alteração desde {date}} 1{{emphasis}+{difference}{emphasis} melhoria desde {date}} other{{emphasis}{difference}{emphasis} diminuição desde {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Enviar lembrete para {count, plural, one{um colaborador} other{{count} colaboradores}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Toda semana} 2{A cada duas semanas} 3{A cada três semanas} 4{Todo mês} 5{A cada cinco semanas} 6{A cada seis semanas} 7{A cada sete semanas} 8{A cada dois meses} 9{A cada nove semanas} 10{A cada dez semanas} 11{A cada 11 semanas} 12{Todo trimestre} 0{Manual} other{A cada {frequency}. semanas}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{Gestor de linha} other{Gestor de nível +{level}}}',
      },
    },
  },
  ko: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          '총점은 ({total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중) {answered, plural, other {직원 {emphasis}#{emphasis}명}}의 답변을 근거로 계산합니다.',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중 {emphasis}{answered}{emphasis}명이 이 질문에 답변함',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '이 가치체계에서 {total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중 {emphasis}{answered}{emphasis}명이 질문에 답변함',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '이 결과에서 {total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중 {emphasis}{answered}{emphasis}명이 질문에 답변함',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '이 하위 Driver에서 {total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중 {emphasis}{answered}{emphasis}명이 질문에 답변함',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message: '{total, plural, other {{link}#명의 설문 수신 직원{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{{date} 이후 변화 없음} 1{{date} 이후 {emphasis}+{difference}{emphasis} 개선} other{{date} 이후 {emphasis}{difference}{emphasis} 감소}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '{count, plural, other{{count}명의 직원}}에게 알림을 전송하시겠습니까?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{매주} 2{2주마다} 3{3주마다} 4{매달} 5{5주마다} 6{6주마다} 7{7주마다} 8{격월} 9{9주마다} 10{10주마다} 11{11주마다} 12{매분기} 0{수동} other{{frequency}주마다}}',
      },
      segment_managers_modal__level_tooltip: {
        message: '{level, select, 0{라인 매니저} other{레벨 +{level} 매니저}}',
      },
    },
  },
  'fr-FR': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          "Le score global est basé sur les réponses de {answered, plural, one {{emphasis}#{emphasis} collaborateur} other {{emphasis}#{emphasis} collaborateurs}} (sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}})",
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu à cette question.",
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur cette valeur.",
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce résultat.",
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce sous-moteur.",
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce moteur.",
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          "{total, plural, one {{link}# collaborateur ayant reçu l'enquête{link}} other {{link}# collaborateurs ayant reçu l'enquête{link}}}",
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Aucun changement depuis le {date}}1{Augmentation de {emphasis}{difference}{emphasis} depuis le {date}}other{Baisse de {emphasis}{difference}{emphasis} depuis le {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Envoyer un rappel à {count, plural, one{un collaborateur} other{{count} collaborateurs}} ?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Toutes les semaines} 2{Toutes les 2 semaines} 3{Toutes les 3 semaines} 4{Tous les mois} 5{Toutes les 5 semaines} 6{Toutes les 6 semaines} 7{Toutes les 7 semaines} 8{Tous les 2 mois} 9{Toutes les 9 semaines} 10{Toutes les 10 semaines} 11{Toutes les 11 semaines} 12{Tous les trimestres} 0{Manuel} other{Toutes les {frequency} semaines}}',
      },
      segment_managers_modal__level_tooltip: {
        message: '{level, select, 0{Manager direct} other{Manager N+{level}}}',
      },
    },
  },
  'zh-TW': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          '整體分數取決於 {answered, plural, one {{emphasis}#{emphasis} 名員工} other {{emphasis}#{emphasis} 名員工}}的回應 (共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此問題',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此價值觀的相關問題',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此結果的相關問題',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此次驅動因素的相關問題',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此驅動因素的相關問題',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}收到問券調查的 # 名員工{link}} other {{link}收到問卷調查的 # 名員工{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{自 {date} 起沒有變化} 1{自 {date} 起提高 {emphasis}+{difference}{emphasis}} other{自 {date} 起降低 {emphasis}{difference}{emphasis}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message: '要傳送提醒給 {count, plural, other{{count} 名員工}} 嗎？',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{每週} 2{每 2 週} 3{每 3 週} 4{每個月} 5{每 5 週} 6{每 6 週} 7{每 7 週} 8{每 2 個月} 9{每 9 週} 10{每 10 週} 11{每 11 週} 12{每季} 0{手動} other{每 {frequency}. 週}}',
      },
      segment_managers_modal__level_tooltip: {
        message: '{level, select, 0{直屬主管} other{上 +{level} 級主管}}',
      },
    },
  },
  'es-LA': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'La puntuación general se basa en las respuestas de {answered, plural, one {{emphasis}#{emphasis} empleado} other {{emphasis}#{emphasis} empleados}} (de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} esta pregunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} preguntas sobre este valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} preguntas sobre este resultado',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} preguntas sobre este subfactor',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} preguntas sobre este factor',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# empleado que recibió la encuesta{link}} other {{link}# empleados que recibieron la encuesta{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Sin cambios desde el {date}} 1{Mejoró {emphasis}{difference}{emphasis} desde el {date}} other{Disminuyó {emphasis}{difference}{emphasis} desde el {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '¿Quiere enviar un recordatorio a {count, plural, one{un empleado} other{{count} empleados}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Cada semana} 2{Cada 2 semanas} 3{Cada 3 semanas} 4{Cada mes} 5{Cada 5 semanas} 6{Cada 6 semanas} 7{Cada 7 semanas} 8{Cada 2 meses} 9{Cada 9 semanas} 10{Cada 10 semanas} 11{Cada 11 semanas} 12{Cada trimestre} 0{Manual} other{Cada {frequency} semanas}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{supervisor directo} other{supervisor de {level} niveles más}}',
      },
    },
  },
  cs: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Celkové skóre je založeno na odpovědích od {answered, plural, one{{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (z celkem {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'Odpovědi na tuto otázku: {emphasis}{answered}{emphasis} z {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'Zodpovězené otázky ohledně této hodnoty: {emphasis}{answered}{emphasis} z celkem {total, plural, one {{bold}#{bold} {link}dotazovaného zaměstnance{link}} few {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} many {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} other {{bold}#{bold} {link}dotazovaných zaměstnanců{link}}}',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'Zodpovězené otázky ohledně tohoto výsledku: {emphasis}{answered}{emphasis} z celkem {total, plural, one {{bold}#{bold} {link}dotazovaného zaměstnance{link}} few {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} many {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} other {{bold}#{bold} {link}dotazovaných zaměstnanců{link}}}',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'Zodpovězené otázky ohledně tohoto dílčího faktoru: {emphasis}{answered}{emphasis} z celkem {total, plural, one {{bold}#{bold} {link}dotazovaného zaměstnance{link}} few {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} many {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} other {{bold}#{bold} {link}dotazovaných zaměstnanců{link}}}',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'Zodpovězené otázky týkající se tohoto faktoru: {emphasis}{answered}{emphasis} z {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Žádná změna od {date}} 1{{emphasis}+{difference}{emphasis} zlepšení od {date}} other{{emphasis}{difference}{emphasis} zhoršení od {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Odeslat připomenutí {count, plural, one{jednomu zaměstnanci} other{{count} employees}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Každý týden} 2{Každý 2. týden} 3{Každý 3. týden} 4{Každý měsíc} 5{Každý 5. týden} 6{Každý 6. týden} 7{Každý 7. týden} 8{Každý druhý měsíc} 9{Každý 9. týden} 10{Každý 10. týden} 11{Každý 11. týden} 12{Každé čtvrtletí} 0{Ručně} other{Každý {frequency}. týden}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{přímý manažer} other{manažer úrovně +{level}}}',
      },
    },
  },
  lv: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Kopvērtējuma pamatā ir atbildes no {answered, plural, one {{emphasis}#{emphasis} darbinieka} other {{emphasis}#{emphasis} darbiniekiem}} (no {total, plural, one {{bold}#{bold} {link}darbinieka, kurš saņēma aptauju{link}} other {{bold}#{bold} {link}darbiniekiem, kurš saņēma aptauju{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz šo jautājumu',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz jautājumiem par šo vērtību',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz jautājumiem par šo iznākumu',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz jautājumiem par šo blakustimulu',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz jautājumiem par stimulu',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# darbinieks, kurš saņēma aptauju{link}} other {{link}# darbinieki, kuri saņēma aptauju{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nav izmaiņu kopš {date}} 1{{emphasis}+{difference}{emphasis} uzlabojuma kopš {date}} other{{emphasis}{difference}{emphasis} samazinājuma kopš {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Vai sūtīt atgādinājumu {count, plural, one{vienam darbiniekam} other{{count} darbiniekiem}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Katru nedēļu} 2{Katru 2. nedēļu} 3{Katru 3. nedēļu} 4{Katru mēnesi} 5{Katru 5. nedēļu} 6{Katru 6. nedēļu} 7{Katru 7. nedēļu} 8{Katru otro mēnesi} 9{Katru 9. nedēļu} 10{Katru 10. nedēļu} 11{Katru 11. nedēļu} 12{Katru ceturksni} 0{Manuāli} other{Katru {frequency}. nedēļu}}',
      },
    },
  },
  no: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Den samlede verdien er basert på svarene fra {answered, plural,one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (av {total, plural,one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på dette spørsmålet.',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på spørsmål om denne verdien.',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på spørsmål om dette utfallet.',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på spørsmål om denne underfaktoren.',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på spørsmål om denne faktoren.',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Ingen endring siden {date}} 1{{emphasis}+{difference}{emphasis} improvement since {date}} other{{emphasis}{difference}{emphasis} decrease since {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Vil du sende påminnelse til {count, plural, one{én ansatt} other{{count} employees}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Hver uke} 2{Annenhver uke} 3{Hver tredje uke} 4{Hver måned} 5{Hver femte uke} 6{Hver sjette uke} 7{Hver sjuende uke} 8{Annenhver måned} 9{Hver niende uke} 10{Hver tiende uke} 11{Hver ellevte uke} 12{Hvert kvartal} 0{Manuelt} other{Hver {frequency}. uke}}',
      },
      segment_managers_modal__level_tooltip: {
        message: '{level, select, 0{linjeleder} other{Nivå +{level}leder}}',
      },
    },
  },
  da: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Den samlede score er baseret på svar fra {answered, plural, one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede dette spørgsmål',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede spørgsmål om denne værdi',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede spørgsmål om dette resultat',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede spørgsmål om denne underliggende drivkraft',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede spørgsmål om denne drivkraft',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Ingen ændring side {date}} 1{{emphasis}+{difference}{emphasis} forbedring siden {date}} other{{emphasis}{difference}{emphasis} falder siden {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Send påmindelse til {count, plural, one{en medarbejder} other{{count} employees}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Hver uge} 2{Hver anden uge} 3{Hver tredje uge} 4{Hver måned} 5{Hver femte uge} 6{Hver sjette uge} 7{Hver syvende uge} 8{Hver anden måned} 9{Hver niende uge} 10{Hver tiende uge} 11{Hver ellevte uge} 12{Hvert kvartal} 0{Manuelt} other{Hver {frequency}. uge}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{afdelingschef} other{afdelingschef på niveau +{level}}}',
      },
    },
  },
  'en-US': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'The overall score is based on responses from {answered, plural, one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered this question',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this value',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this outcome',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this sub-driver',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this driver',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# employee who received the survey{link}} other {{link}# employees who received the survey{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{No change since {date}} 1{{emphasis}+{difference}{emphasis} improvement since {date}} other{{emphasis}{difference}{emphasis} decrease since {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Send reminder to {count, plural, one{one employee} other{{count} employees}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Every week} 2{Every 2nd week} 3{Every 3rd week} 4{Every month} 5{Every 5th week} 6{Every 6th week} 7{Every 7th week} 8{Every other month} 9{Every 9th week} 10{Every 10th week} 11{Every 11th week} 12{Every quarter} 0{Manual} other{Every {frequency}. week}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{line manager} other{Level +{level} manager}}',
      },
    },
  },
  th: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'คะแนนรวมได้มาจากคำตอบของพนักงาน {answered, plural, other {{emphasis}#{emphasis} คน}} (จากทั้งหมด {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}}ตอบคำถามนี้',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}}ตอบคำถามเกี่ยวกับค่านิยมนี้',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}}ตอบคำถามเกี่ยวกับผลลัพธ์นี้',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}}ตอบคำถามเกี่ยวกับปัจจัยขับเคลื่อนย่อยนี้',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}ตอบคำถามเกี่ยวกับปัจจัยผลักดันนี้',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, other {{link}พนักงาน # คนที่ได้รับแบบสำรวจ{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{ไม่มีการเปลี่ยนแปลงตั้งแต่ {date}} 1{{emphasis}+{difference}{emphasis} มีการปรับปรุงตั้งแต่ {date}} other{{emphasis}{difference}{emphasis} ลดลงตั้งแต่ {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'ส่งการเตือนไปยังพนักงาน {count, plural, other{{count} คน}}ใช่หรือไม่',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{ทุกสัปดาห์} 2{ทุกสัปดาห์ที่ 2} 3{ทุกสัปดาห์ที่ 3} 4{ทุกเดือน} 5{ทุกสัปดาห์ที่ 5} 6{ทุกสัปดาห์ที่ 6} 7{ทุกสัปดาห์ที่ 7} 8{เดือนเว้นเดือน} 9{ทุกสัปดาห์ที่ 9} 10{ทุกสัปดาห์ที่ 10} 11{ทุกสัปดาห์ที่ 11} 12{ทุกไตรมาส} 0{Manual} other{ทุก {frequency} สัปดาห์}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{หัวหน้าสายงาน} other{หัวหน้างานระดับ +{level}}}',
      },
    },
  },
  'en-GB': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'The overall score is based on responses from {answered, plural, one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered this question',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this value',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this outcome',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this sub-driver',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this driver',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# employee who received the survey{link}} other {{link}# employees who received the survey{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{No change since {date}} 1{{emphasis}+{difference}{emphasis} improvement since {date}} other{{emphasis}{difference}{emphasis} decrease since {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Send reminder to {count, plural, one{one employee} other{{count} employees}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Every week} 2{Every 2nd week} 3{Every 3rd week} 4{Every month} 5{Every 5th week} 6{Every 6th week} 7{Every 7th week} 8{Every other month} 9{Every 9th week} 10{Every 10th week} 11{Every 11th week} 12{Every quarter} 0{Manual} other{Every {frequency}. week}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{line manager} other{Level +{level} manager}}',
      },
    },
  },
  sv: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Den totala poängen baserar sig på svar från {answered, plural, one {{emphasis}#{emphasis} medarbetare} other {{emphasis}#{emphasis} medarbetare}} (av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} besvarade den här frågan',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} besvarade frågor om den här värderingen',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} besvarade frågor om det här resultatet',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} svarade på frågor om den här del-drivkraften',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} besvarade frågor om den här drivkraften',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# medarbetare som fick undersökningen{link}} other {{link}# medarbetare som fick undersökningen{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Ingen förändring sedan {date}} 1{{emphasis}+{difference}{emphasis} förbättring sedan {date}} other{{emphasis}{difference}{emphasis} minskning sedan {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Skicka påminnelse till {count, plural, one{en medarbetare} other{{count} medarbetare}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Varje vecka} 2{Varannan vecka} 3{Var 3:e vecka} 4{Varje månad} 5{Var 5:e vecka} 6{Var 6:e vecka} 7{Var 7:e vecka} 8{Varannan månad} 9{Var 9:e vecka} 10{Var 10:e vecka} 11{Var 11:e vecka} 12{Varje kvartal} 0{Manuellt} other{{frequency} veckors mellanrum}}',
      },
      segment_managers_modal__level_tooltip: {
        message: '{level, select, 0{linjechef} other{chef på nivå +{level}}}',
      },
    },
  },
  es: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'La puntuación general se basa en las respuestas de {answered, plural, one {{emphasis}#{emphasis} empleado} other {{emphasis}#{emphasis} empleados}} (de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a esta pregunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a las preguntas sobre este valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a las preguntas sobre estos resultados',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a las preguntas sobre este subfactor',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a las preguntas sobre este factor',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# empleado que recibió la encuesta{link}} other {{link}# empleados que recibieron la encuesta{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Sin cambios desde {date}} 1{{emphasis}+{difference}{emphasis} mejora desde {date}} other{{emphasis}{difference}{emphasis} descenso desde {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '¿Quieres enviar un recordatorio a {count, plural, one{un empleado} other{{count} empleados}}?',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{Cada semana} 2{Cada 2 semanas} 3{Cada 3 semanas} 4{Cada mes} 5{Cada 5 semanas} 6{Cada 6 semanas} 7{Cada 7 semanas} 8{Cada 2 meses} 9{Cada 9 semanas} 10{Cada 10 semanas} 11{Cada 11 semanas} 12{Cada trimestre} 0{Manual} other{Cada {frequency} semanas}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{Supervisor de línea} other{Supervisor de nivel +{level}}}',
      },
    },
  },
  ar: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'تعتمد الدرجة الإجمالية على استجابات {answered, plural, zero {} one {{emphasis}#{emphasis} موظف} two {{emphasis}#{emphasis} من الموظفين} few {{emphasis}#{emphasis} من الموظفين} many {{emphasis}#{emphasis} من الموظفين} other {{emphasis}#{emphasis} من الموظفين}} (من إجمالي {total, plural, one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}من الموظفين الذين تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}من الموظفين الذين تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}من الموظفين الذين تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}من الموظفين الذين تلقوا الاستطلاع{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن هذا السؤال',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن أسئلة هذه القيمة',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن أسئلة هذه النتيجة',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن أسئلة هذا الدافع الفرعي',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن أسئلة هذا الدافع',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}موظف واحد تلقى الاستطلاع{link}} other {{link}# من الموظفين تلقوا الاستطلاع{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{لا تغيير منذ {date}} 1{{emphasis}+{difference}{emphasis} تحسُّ منذ {date}} other{{emphasis}{difference}{emphasis} انخفاض منذ {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'هل تريد إرسال تذكير إلى {count, plural, one{موظف واحد} other{{count} من الموظفين}}؟',
      },
      'frequency_interval-every_n_week_v2': {
        message:
          '{frequency, select, 1{كل أسبوع} 2{كل ثاني أسبوع} 3{كل ثالث أسبوع} 4{كل شهر} 5{كل خامس أسبوع} 6{كل سادس أسبوع} 7{كل سابع أسبوع} 8{كل شهرين} 9{كل تاسع أسبوع} 10{كل عاشر أسبوع} 11{كل 11 أسبوعًا} 12{كل ثلاثة شهور} 0{يدوي} other{كل {frequency}. أسبوع}}',
      },
      segment_managers_modal__level_tooltip: {
        message:
          '{level, select, 0{مدير تنفيذي} other{مدير بالمستوى +{level}}}',
      },
    },
  },
};
